import { FC, forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { BrandProductTag, BrandProductTagGroup, HydratedBrandProductTag } from '@sparkplug/lib';

import { OnChangeParams } from '@features/product-tags/mutations';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import Dropdown from '@components/dropdown/Dropdown';
import { Dot, PlusIcon } from '@components/icons';
import Spinner from '@components/layout/Spinner';

import TagSelectorDropdownMenu from '../TagSelectorDropdownMenu';

import './SingleProductTagSelector.scss';

export interface SingleProductTagSelectorProps {
    tagGroups: BrandProductTagGroup[];
    selectedTags: HydratedBrandProductTag[];
    onAssignTag: (params: OnChangeParams) => void;
    onRemoveTag: (params: OnChangeParams) => void;
    isLoading?: boolean;
}

interface TagChipProps {
    tag: BrandProductTag;
    aiPredicted?: boolean;
    onClick?: () => void;
    onRemove?: () => void;
}

const TagChip = forwardRef<HTMLDivElement, TagChipProps>(
    ({ tag, aiPredicted, onClick, onRemove }, ref) => {
        return (
            <div className="chip-list-item" ref={ref}>
                <Chip
                    className={aiPredicted ? 'ai-predicted' : undefined}
                    dense
                    color="light-gray"
                    variant="flat"
                    avatar={<Dot style={{ color: tag.color, width: '12px', height: '12px' }} />}
                    label={tag.name}
                    onDelete={onRemove}
                    onClick={onClick}
                />
            </div>
        );
    },
);

const LoadingChip = () => {
    return (
        <div className="chip-list-item">
            <Chip
                className="loading-chip"
                color="light-gray"
                variant="flat"
                avatar={<Spinner className="loading-chip-spinner" size={12} />}
                label=""
            />
        </div>
    );
};

const SingleProductTagSelector: FC<SingleProductTagSelectorProps> = ({
    tagGroups,
    selectedTags: initialSelectedTags,
    onAssignTag,
    onRemoveTag,
    isLoading,
}) => {
    const [tagGroupIdx, setTagGroupIdx] = useState<number>(-1);
    const [tagChipIdx, setTagChipIdx] = useState<number>(-1);
    const [tagMenuOpen, setTagMenuOpen] = useState<boolean>(false);

    const [selectedTags, setSelectedTags] =
        useState<HydratedBrandProductTag[]>(initialSelectedTags);

    const handleDeleteTag = (tagToDelete: HydratedBrandProductTag) => {
        const newSelectedTags = selectedTags.filter((t) => t._id !== tagToDelete._id);
        setSelectedTags(newSelectedTags);

        requestAnimationFrame(() => {
            onRemoveTag({
                tagName: tagToDelete.name,
                tagGroupName: tagToDelete.groupName,
            });
        });
    };
    const handleClickTag = (dehydratedTag: BrandProductTag) => {
        const brandGroup = tagGroups.find((tg) => tg.tags.some((t) => t._id === dehydratedTag._id));

        if (brandGroup) {
            const hydratedNewTag: HydratedBrandProductTag = {
                ...dehydratedTag,
                groupName: brandGroup.name,
            };

            if (selectedTags.some((t) => t._id === hydratedNewTag._id)) {
                handleDeleteTag(hydratedNewTag);
                return;
            }

            const brandGroupHasSelectedTag = selectedTags.some((t) =>
                brandGroup.tags.some((tg) => tg._id === t._id),
            );

            if (brandGroupHasSelectedTag) {
                const currentSelectedTagsWithNewTag = selectedTags.map((currentTag) => {
                    if (brandGroup.tags.some((tg) => tg._id === currentTag._id)) {
                        return hydratedNewTag;
                    }

                    return currentTag;
                });

                setSelectedTags(currentSelectedTagsWithNewTag);
            } else if (!selectedTags.some((currentTag) => currentTag._id === hydratedNewTag._id)) {
                setSelectedTags([...selectedTags, hydratedNewTag]);
            }

            requestAnimationFrame(() => {
                onAssignTag({
                    tagName: hydratedNewTag.name,
                    tagGroupName: hydratedNewTag.groupName,
                });
            });
        }
    };

    const numTagGroups = tagGroups.length;
    const addTagAllowed = useMemo(
        () => selectedTags.length < numTagGroups,
        [selectedTags.length, numTagGroups],
    );

    const tagChipsRef = useRef<HTMLDivElement[] | null[]>([]);
    const addTagButtonRef = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        tagChipsRef.current = tagChipsRef.current.slice(0, selectedTags.length);
    }, [selectedTags]);

    useEffect(() => {
        if (tagChipIdx === -1 && !addTagAllowed) {
            setTagMenuOpen(false);
        }
    }, [tagChipIdx, addTagButtonRef.current]);

    const handleTagMenuOpen = (newTagGroupIdx: number, newTagChipIdx: number) => {
        setTagGroupIdx(newTagGroupIdx);
        setTagChipIdx(newTagChipIdx);
        setTagMenuOpen(true);
    };

    const handleAddTagMenuOpen = () => {
        setTagGroupIdx(-1);
        setTagChipIdx(-1);
        setTagMenuOpen(true);
    };

    const anchorEl =
        (tagChipIdx === -1 ? addTagButtonRef.current : tagChipsRef.current[tagChipIdx]) ??
        undefined;

    const sortedTags = useMemo(() => {
        const tags: HydratedBrandProductTag[] = [];
        for (let i = 0; i < tagGroups.length; i += 1) {
            tags.push(...selectedTags.filter((tag) => tag.groupName === tagGroups[i].name));
        }
        return tags;
    }, [selectedTags]);

    return (
        <div className="tags-wrapper">
            <Dropdown className="tag-selector-dropdown">
                {isLoading ? (
                    <LoadingChip />
                ) : (
                    <>
                        {sortedTags.map((tag, currentTagChipIdx) => {
                            // get index of tag group that contains the tag
                            const currentTagGroupIdx = tagGroups.findIndex((tg) =>
                                tg.tags.some((tgTag) => tgTag._id === tag._id),
                            );

                            return (
                                <TagChip
                                    key={`tag-chip-${tag._id}`}
                                    ref={(el) => {
                                        tagChipsRef.current[currentTagChipIdx] = el;
                                    }}
                                    tag={tag}
                                    aiPredicted={tag.isAiTagged}
                                    onClick={() =>
                                        handleTagMenuOpen(currentTagGroupIdx, currentTagChipIdx)
                                    }
                                    onRemove={() => handleDeleteTag(tag)}
                                />
                            );
                        })}
                    </>
                )}
                {addTagAllowed && (
                    <div className="chip-list-item">
                        <Button
                            className="single-product-tag-selector_add-tag-button"
                            ref={addTagButtonRef}
                            variant="flat"
                            color="neutral"
                            disabled={!addTagAllowed || isLoading}
                            startIcon={<PlusIcon />}
                            aria-controls="tag-selector-menu"
                            aria-haspopup="true"
                            onClick={handleAddTagMenuOpen}
                        >
                            Add Tag
                        </Button>
                    </div>
                )}

                {tagMenuOpen && (
                    <TagSelectorDropdownMenu
                        tagGroups={tagChipIdx === -1 ? tagGroups : [tagGroups[tagGroupIdx]]}
                        selectedTags={selectedTags}
                        isVisible={tagMenuOpen}
                        onClickTag={handleClickTag}
                        onClose={() => setTagMenuOpen(false)}
                        anchorEl={anchorEl}
                    />
                )}
            </Dropdown>
        </div>
    );
};

export default SingleProductTagSelector;
