import { FC, ReactNode, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { BrandProductTag, BrandProductTagGroup, HydratedBrandProductTag } from '@sparkplug/lib';

import GroupTagPairMenuItemContent from '@features/product-tags/components/GroupTagPairMenuItemContent';

import Dropdown from '@components/dropdown/Dropdown';
import { useInternalMenuContext } from '@components/dropdown/DropdownContext';
import { ControlledDropdownMenu } from '@components/dropdown/DropdownMenu';
import { Check, CheckBoxOutlineBlank, Dot, Launch } from '@components/icons';
import TextField from '@components/inputs/TextField';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { InputEventOnChange } from '@app/types/UITypes';

import './TagSelectorDropdownMenu.scss';

const SearchMenuItem: FC<{ handleSearchChange: InputEventOnChange }> = ({ handleSearchChange }) => {
    const textFieldRef = useRef<HTMLInputElement>();

    useLayoutEffect(() => {
        textFieldRef.current?.focus();
    }, []);

    return (
        <Dropdown.MenuItem className="product-tag-search-menu-item" disableHover noPadding>
            <TextField
                inputRef={textFieldRef}
                className="product-tag-menu-search"
                variant="raised"
                autoComplete="off"
                onChange={handleSearchChange}
                name="search"
                placeholder="Search tags..."
            />
        </Dropdown.MenuItem>
    );
};

const NoTagsMenuItem: FC<{ search: string }> = ({ search }) => (
    <Dropdown.MenuItem
        className="no-tags-menu-item"
        align="center"
    >{`No tags for "${search}"`}</Dropdown.MenuItem>
);

const ProductTagMenuItemContent: FC<{ tag: BrandProductTag }> = ({ tag }) => (
    <>
        <Dot className="product-tag-row_dot" style={{ color: tag.color }} />
        <span className="product-tag-row_name" title={tag.name}>
            {tag.name}
        </span>
    </>
);

interface SelectorMenuItemProps {
    tag: BrandProductTag;
    checked?: boolean;
    handleClickTag: ({ tag, closeMenu }: { tag: BrandProductTag; closeMenu?: boolean }) => void;
}
const ProductTagMenuItem: FC<SelectorMenuItemProps> = ({ checked, tag, handleClickTag }) => {
    const { updateActiveMenuItemId } = useInternalMenuContext();

    const onCheckClick = () => handleClickTag({ tag, closeMenu: false });

    const handleCheckClick = () => {
        onCheckClick();
    };

    return (
        <Dropdown.MenuItem
            className="product-tag-menu-item"
            key={tag._id}
            onClick={() => handleClickTag({ tag })}
            StartIcon={() =>
                checked ? (
                    <Check
                        className="product-tag-row_check"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCheckClick();
                            updateActiveMenuItemId(undefined);
                        }}
                    />
                ) : (
                    <CheckBoxOutlineBlank
                        className="product-tag-row_uncheck"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCheckClick();
                            updateActiveMenuItemId(undefined);
                        }}
                    />
                )
            }
        >
            <ProductTagMenuItemContent tag={tag} />
        </Dropdown.MenuItem>
    );
};

const ProductTagGroupMenuItem: FC<{
    tagGroup: BrandProductTagGroup;
    selectedTags: BrandProductTag[];
    handleClickTag: ({ tag, closeMenu }: { tag: BrandProductTag; closeMenu?: boolean }) => void;
}> = ({ tagGroup, selectedTags, handleClickTag }) => {
    // group is checked if just one of its tags is selected
    const groupChecked = tagGroup.tags.some((tag) =>
        selectedTags.some((selectedTag) => selectedTag._id === tag._id),
    );

    return (
        <Dropdown.MenuItem
            className="product-tag-group-menu-item"
            StartIcon={() => (
                <Check
                    className="product-tag-group-row_check"
                    style={{ visibility: groupChecked ? 'visible' : 'hidden' }}
                />
            )}
            nestedChildren={
                <>
                    {tagGroup.tags.map((tag: BrandProductTag) => {
                        const tagChecked = selectedTags.some(
                            (selectedTag) => selectedTag._id === tag._id,
                        );

                        return (
                            <ProductTagMenuItem
                                key={tag._id}
                                checked={tagChecked}
                                tag={tag}
                                handleClickTag={handleClickTag}
                            />
                        );
                    })}
                </>
            }
        >
            <span className="product-tag-group-row_name">{tagGroup.name}</span>
        </Dropdown.MenuItem>
    );
};

const GroupTagPairMenuItem: FC<{
    productTag: HydratedBrandProductTag;
    checked: boolean;
    handleClickTag: ({ tag, closeMenu }: { tag: BrandProductTag; closeMenu?: boolean }) => void;
}> = ({ productTag, checked, handleClickTag }) => (
    <Dropdown.MenuItem
        className="product-tag-menu-item"
        onClick={() => handleClickTag({ tag: productTag, closeMenu: true })}
        StartIcon={() =>
            checked ? (
                <Check className="product-tag-row_check" />
            ) : (
                <CheckBoxOutlineBlank
                    className="product-tag-row_uncheck"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClickTag({ tag: productTag, closeMenu: true });
                    }}
                />
            )
        }
    >
        <GroupTagPairMenuItemContent productTag={productTag} />
    </Dropdown.MenuItem>
);

interface MultiGroupTagSelectorMenuProps {
    search: string;
    tagGroups: BrandProductTagGroup[];
    selectedTags: BrandProductTag[];
    handleClickTag: ({ tag, closeMenu }: { tag: BrandProductTag; closeMenu?: boolean }) => void;
    handleSearchChange: InputEventOnChange;
    manageTagsBtn: ReactNode;
}

const MultiGroupTagSelectorMenu: FC<MultiGroupTagSelectorMenuProps> = ({
    search,
    tagGroups,
    selectedTags,
    handleClickTag,
    handleSearchChange,
    manageTagsBtn,
}) => {
    const filteredTags = useMemo<HydratedBrandProductTag[]>(() => {
        if (search) {
            const searchValue = search.toLowerCase();

            return tagGroups.reduce<HydratedBrandProductTag[]>((res, tagGroup) => {
                // If group name matches the search
                if (tagGroup.name.toLowerCase().includes(searchValue)) {
                    tagGroup.tags.forEach((tagGroupTag) => {
                        res.push({
                            ...tagGroupTag,
                            groupName: tagGroup.name,
                        });
                    });
                } else {
                    tagGroup.tags.forEach((tagGroupTag) => {
                        // If tag name matches the search
                        if (tagGroupTag.name.toLowerCase().includes(searchValue)) {
                            res.push({
                                ...tagGroupTag,
                                groupName: tagGroup.name,
                            });
                        }
                    });
                }

                return res;
            }, []);
        }

        return [];
    }, [tagGroups, search]);

    const nonSearchMenuItems = tagGroups.map((tagGroup) => (
        <ProductTagGroupMenuItem
            key={tagGroup._id}
            tagGroup={tagGroup}
            selectedTags={selectedTags}
            handleClickTag={handleClickTag}
        />
    ));

    const searchMenuItems =
        search && filteredTags.length ? (
            filteredTags.map((productTag) => {
                const checked = selectedTags.some(
                    (selectedTag) => selectedTag._id === productTag._id,
                );
                return (
                    <GroupTagPairMenuItem
                        key={productTag._id}
                        productTag={productTag}
                        checked={checked}
                        handleClickTag={handleClickTag}
                    />
                );
            })
        ) : (
            <NoTagsMenuItem search={search} />
        );

    return (
        <>
            <SearchMenuItem handleSearchChange={handleSearchChange} />
            {search ? searchMenuItems : nonSearchMenuItems}
            {manageTagsBtn}
        </>
    );
};

interface SingleGroupTagSelectorMenuProps {
    search: string;
    tagGroup: BrandProductTagGroup;
    selectedTags: BrandProductTag[];
    handleClickTag: ({ tag, closeMenu }: { tag: BrandProductTag; closeMenu?: boolean }) => void;
    handleSearchChange: InputEventOnChange;
    manageTagsBtn: ReactNode;
}

const SingleGroupTagSelectorMenu: FC<SingleGroupTagSelectorMenuProps> = ({
    search,
    tagGroup,
    selectedTags,
    handleClickTag,
    handleSearchChange,
    manageTagsBtn,
}) => {
    const filteredTags = useMemo<HydratedBrandProductTag[]>(() => {
        if (search) {
            const searchValue = search.toLowerCase();

            return tagGroup.tags
                .filter((tagGroupTag) => tagGroupTag.name.toLowerCase().includes(searchValue))
                .map((tagGroupTag) => ({
                    ...tagGroupTag,
                    groupName: tagGroup.name,
                }));
        }

        return [];
    }, [tagGroup, search]);

    const nonSearchMenuItems = tagGroup.tags.map((tag: BrandProductTag) => {
        const tagChecked = selectedTags.some((selectedTag) => selectedTag._id === tag._id);

        return (
            <ProductTagMenuItem
                key={tag._id}
                checked={tagChecked}
                tag={tag}
                handleClickTag={handleClickTag}
            />
        );
    });

    const searchMenuItems =
        search && filteredTags.length ? (
            filteredTags.map((tag: BrandProductTag) => {
                const tagChecked = selectedTags.some((selectedTag) => selectedTag._id === tag._id);

                return (
                    <ProductTagMenuItem
                        key={tag._id}
                        checked={tagChecked}
                        tag={tag}
                        handleClickTag={handleClickTag}
                    />
                );
            })
        ) : (
            <NoTagsMenuItem search={search} />
        );

    return (
        <>
            <SearchMenuItem handleSearchChange={handleSearchChange} />
            {search ? searchMenuItems : nonSearchMenuItems}
            {manageTagsBtn}
        </>
    );
};

interface TagSelectorDropdownMenuProps {
    tagGroups: BrandProductTagGroup[];
    selectedTags?: BrandProductTag[];
    isVisible: boolean;
    onClickTag: (tag: BrandProductTag) => void;
    onClose: () => void;
    anchorEl?: HTMLElement;
}

const TagSelectorDropdownMenu: FC<TagSelectorDropdownMenuProps> = ({
    tagGroups,
    selectedTags = [],
    isVisible,
    onClickTag,
    onClose: parentOnClose,
    anchorEl,
}) => {
    const { history } = useApp();
    const { account } = useSparkplugAccount();

    const [search, setSearch] = useState<string>('');

    const onClose = () => {
        setSearch('');
        parentOnClose();
    };

    const handleClickTag = ({
        tag,
        closeMenu = true,
    }: {
        tag: BrandProductTag;
        closeMenu?: boolean;
    }) => {
        if (closeMenu) {
            onClose();
        }
        onClickTag(tag);
    };

    const handleSearchChange: InputEventOnChange = (e) => {
        setSearch(e.target.value);
    };

    const manageTagsBtn = (
        <Dropdown.MenuItem
            className="product-tag-manage-tags-menu-item"
            color="blue"
            endIcon={<Launch />}
            onClick={() => window.open(`/${account?._id}/settings/product-tags`, '_blank')}
            align="center"
        >
            <span className="product-tag-menu-manage-tags_text">Manage Tags</span>
        </Dropdown.MenuItem>
    );

    return (
        <ControlledDropdownMenu
            id="tag-selector-menu"
            className="product-tag-menu-dropdown"
            anchorEl={anchorEl}
            container={anchorEl}
            isVisible={isVisible}
            onClose={onClose}
        >
            <div className="product-tag-menu-container">
                {tagGroups.length === 1 ? (
                    <SingleGroupTagSelectorMenu
                        search={search}
                        tagGroup={tagGroups[0]}
                        selectedTags={selectedTags}
                        handleClickTag={handleClickTag}
                        handleSearchChange={handleSearchChange}
                        manageTagsBtn={manageTagsBtn}
                    />
                ) : (
                    <MultiGroupTagSelectorMenu
                        search={search}
                        tagGroups={tagGroups}
                        selectedTags={selectedTags}
                        handleClickTag={handleClickTag}
                        handleSearchChange={handleSearchChange}
                        manageTagsBtn={manageTagsBtn}
                    />
                )}
            </div>
        </ControlledDropdownMenu>
    );
};

export default TagSelectorDropdownMenu;
