import { formatPhoneNumber } from 'react-phone-number-input';

import TransactionsAPI from '@api/TransactionsAPI';
import axios from 'axios';
import { isEmpty } from 'lodash';

import {
    GetUserPathParams,
    GetUserResponseBody,
    IPublicAccount,
    IPublicUser,
    ListUsersByIdRequestBody,
    ListUsersByIdResponseBody,
    ListUsersQueryParams,
    ListUsersResponseBody,
    UserRole,
    UserTransaction,
} from '@sparkplug/lib';

import { fetchGroup } from '@core/accounts';

import { useAdvancedQuery, useQueries } from '@hooks/QueryHooks';

import { fetchBatchedData } from '@helpers/api';
import { sortByString } from '@helpers/ui';

import { AccountUsersAndProfiles, IAccountUser } from '@app/types/UsersTypes';

import { formatLastTransactionData } from '../utils';

const API = {
    getUserById: async ({ userId }: GetUserPathParams): Promise<GetUserResponseBody> => {
        return (await axios.get<GetUserResponseBody>(`/api/v1/users/${userId}`)).data;
    },
    listUsers: async (params: ListUsersQueryParams): Promise<ListUsersResponseBody> => {
        return (await axios.get<ListUsersResponseBody>('/api/v1/users', { params })).data;
    },
    listUsersById: async (body: ListUsersByIdRequestBody): Promise<ListUsersByIdResponseBody> => {
        return (await axios.post<ListUsersByIdResponseBody>('/api/v1/users/multi/get', body)).data;
    },
    getAllAccountUsers: async ({
        groupId,
        includeDisabledLocations,
    }: {
        groupId: string;
        includeDisabledLocations: boolean;
    }) => {
        return (
            await axios.get(
                `/api/v1/sparkplug/users?group_id=${groupId}&include_disabled_locations=${includeDisabledLocations}`,
            )
        ).data;
    },
};

const fetchUsers = async ({ posUserId, role }: { posUserId?: string; role?: UserRole }) => {
    return fetchBatchedData((offset, limit) => {
        return API.listUsers({ offset, limit, role, pos_employee_profile_id: posUserId });
    });
};

const getAllSparkplugUsers = async (queryParameters?: {
    posUserId?: string;
    role?: UserRole;
}): Promise<(IPublicUser & { phoneNumberFormatted: string; key: string })[]> => {
    const { data } = await fetchUsers(queryParameters ?? {});

    return data
        .map((user) => {
            const phoneNumberFormatted =
                user?.phoneNumber != null && user?.phoneNumber.length > 0
                    ? formatPhoneNumber(user?.phoneNumber)
                    : '--';

            return {
                ...user,
                key: user._id,
                phoneNumberFormatted,
            };
        })
        .sort(sortByString('firstName', 'asc'));
};

/**
 * export query key for invaliding mutations using the `updateQuery` option
 * with a `useAdvancedMutation` in UserMutations
 *
 * Example from `useSaveUserProfileMutation` in `UserMutations.tsx`:
 *
 * `{ updateQuery: { queryKey: getSparkplugUsersQueryKey() } }
 */
export const getSparkplugUsersQueryKey = (queryParameters?: {
    posUserId?: string;
    role?: UserRole;
}) => (queryParameters ? ['sparkplugUsers', JSON.stringify(queryParameters)] : ['sparkplugUsers']);

export const useSparkplugUsers = (queryParameters?: { posUserId?: string; role?: UserRole }) => {
    const {
        isFetched: isSparkplugUsersReady,
        data: sparkplugUsers,
        refetch: refetchSparkplugUsers,
    } = useAdvancedQuery(getSparkplugUsersQueryKey(queryParameters), () =>
        getAllSparkplugUsers(queryParameters),
    );

    return {
        isSparkplugUsersReady,
        sparkplugUsers,
        refetchSparkplugUsers,
    };
};

export const getSparkplugUserQueryKey = (userId: string) => ['sparkplugUsers', userId];
export const useSparkplugUser = (userId: string) => {
    const {
        isFetched: userIsReady,
        status: userLoadingStatus,
        data: user,
        refetch: refetchUser,
        error: userLoadingError,
    } = useAdvancedQuery(getSparkplugUserQueryKey(userId), () => API.getUserById({ userId }));

    return {
        userIsReady,
        user,
        refetchUser,
        userLoadingStatus,
        userLoadingError,
    };
};

export const fetchUsersById = async ({ userIds }: { userIds: string[] }) => {
    return API.listUsersById({ userIds });
};

export const useSparkplugUsersById = ({ userIds }: { userIds: string[] }) => {
    const queryResults = useQueries(
        userIds.map((userId) => ({
            queryKey: getSparkplugUserQueryKey(userId),
            queryFn: () => API.getUserById({ userId }),
        })),
    );

    return {
        usersAreReady: queryResults.every(({ isFetched }) => isFetched),
        users: queryResults.map(({ data }) => data),
        refetchUsers: Promise.all(queryResults.map(({ refetch }) => refetch)),
    };
};

/**
 * @deprecated Remove this export and refactor usage with `useSparkplugUser`
 */
export const getUserData = async (userId: string): Promise<IPublicUser & { fullName: string }> => {
    const user = await API.getUserById({ userId });

    return {
        ...user,
        fullName: `${user?.firstName} ${user?.lastName}`.trim(),
    };
};
export const getUserTransactions = async (
    sparkId: string,
    userId: string,
): Promise<UserTransaction[]> => {
    const data = await TransactionsAPI.getSparkTransactionsByUserId({
        sparkId,
        userId,
    });

    return data.transactions;
};

/**
 * @deprecated Remove this export and refactor usage with `useAccountUsersQuery`
 */
export const getAccountUsers = async ({
    groupId,
    showDisabledLocations = false,
}: {
    groupId?: string;
    showDisabledLocations?: boolean;
}): Promise<IAccountUser[]> => {
    if (!groupId) {
        return [];
    }

    const { usersInGroup, unattachedPosEmployeeProfiles } = (await API.getAllAccountUsers({
        groupId,
        includeDisabledLocations: showDisabledLocations,
    })) as AccountUsersAndProfiles;

    return [...usersInGroup, ...unattachedPosEmployeeProfiles]
        .map((user) => {
            const { lastTransactionDaysAgo, lastTransactionDaysAgoStr } =
                formatLastTransactionData(user);

            return {
                ...user,
                flexibleEmployeeId: user.userId ?? user.posEmployeeProfileIds?.[0],
                key: [user.userId, user.posEmployeeProfileIds.join('.')].join('|'),
                label: user.fullName,
                lastTransactionDaysAgo,
                lastTransactionDaysAgoStr,
                locationStr: user.locationNames?.sort().join(', '),
                phoneNumberFormatted: user.phoneNumber
                    ? formatPhoneNumber(user.phoneNumber) || undefined
                    : undefined,
                role: user.groupRole,
            };
        })
        .sort(sortByString('label', 'asc'));
};

/**
 * The `useAccountUsersQuery` implements the logic for fetching/getting/merging
 * all the associated pos/account/user data into the `IAccountUser` objects expectet
 * by the UI.
 *
 * NOTE: Ideally, all this logic should move to it's own endpoint on the backend. First,
 * for removing the load from the frontend, but even more importantly, brands have no
 * visibility into the active/inactive users for a retailer. This leads to issues with
 * inactive users showing in the UI for brands. An endpoint returning the correctly filtered
 * data for an retailer to a brand would greatly clean this up
 */
export const getAccountUsersQueryKey = (accountId: string, showDisabledLocations?: boolean) => [
    'accountUsers',
    accountId,
    showDisabledLocations ?? false,
];
export const useAccountUsersQuery = (
    accountId?: string,
    showDisabledLocations: boolean = false,
) => {
    const {
        data: accountUsers = [],
        isFetched: accountUsersAreReady,
        refetch: refetchAccountUsers,
    } = useAdvancedQuery(
        getAccountUsersQueryKey(accountId!, showDisabledLocations),
        () => getAccountUsers({ groupId: accountId, showDisabledLocations }),
        {
            enabled: !!accountId,
        },
    );

    return {
        accountUsers,
        accountUsersAreReady,
        refetchAccountUsers,
    };
};

const getSparkplugUsers = async (account?: IPublicAccount): Promise<IPublicUser[]> => {
    if (isEmpty(account?.members)) {
        return [];
    }

    const accountMembers = account?.members || [];
    const accountMemberIds = accountMembers.map((member) => member?.userId);

    const usersResponse = await fetchUsersById({ userIds: accountMemberIds });
    const users = usersResponse?.data || [];
    return users;
};

const getSparkplugUsersByAccountId = async (accountId?: string) => {
    if (accountId != null) {
        const group = await fetchGroup(accountId);
        return getSparkplugUsers(group);
    }

    return [];
};

export const useAccountSparkplugUsersQueryByGroupId = (accountId?: string) => {
    const { isLoading, data, refetch } = useAdvancedQuery(
        getAccountUsersQueryKey(accountId!),
        () => getSparkplugUsersByAccountId(accountId),
        { enabled: accountId != null },
    );

    return {
        accountSparkplugUsersAreReady: !isLoading,
        accountSparkplugUsers: data,
        refetchAccountSparkplugUsers: refetch,
    };
};
