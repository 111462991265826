import React, { FC } from 'react';

import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import moment from 'moment';

import { AccountMarket } from '@sparkplug/lib';

import { useFetchPosConfigsByGroupId } from '@core/accounts/queries/AccountQueries';

import { CircleCheck, CircleXIcon } from '@components/icons';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';

import { sortTypes } from '@hooks/TableHooks';

import { cn } from '@app/componentLibrary/utils';

const BooleanIconWithLabel: FC<{ value: boolean; label: string }> = ({ value, label }) => {
    return (
        <div className="flex items-center pb-2">
            {value ? (
                <CircleCheck className="mr-2 h-[14px] w-[14px] [&_path]:fill-aqua-800" />
            ) : (
                <CircleXIcon className="mr-2 h-[14px] w-[14px] [&_path]:fill-sienna-800" />
            )}
            <span>{label}</span>
        </div>
    );
};

interface SuperAdminPosConfigsDisplayProps {
    retailerAccountId: string;
    userIsSuperAdmin: boolean;
    activePosConfigId: string;
    retailerInventoryEnabledIsReady: boolean;
    stripeCustomerId?: string;
    vendorName?: string;
    retailerName?: string;
    hasInventoryEntitlement: boolean;
    inventoryEntitlementMarkets: AccountMarket[];
}

export const SuperAdminPosConfigsDisplay: React.FC<SuperAdminPosConfigsDisplayProps> = ({
    retailerAccountId,
    userIsSuperAdmin,
    activePosConfigId,
    retailerInventoryEnabledIsReady,
    stripeCustomerId = '',
    vendorName = '',
    retailerName = '',
    hasInventoryEntitlement,
    inventoryEntitlementMarkets,
}) => {
    const { posConfigs, posConfigsAreReady } = useFetchPosConfigsByGroupId(
        retailerAccountId,
        userIsSuperAdmin,
    );

    const sortFunction = sortTypes.date('lookback', 'desc');
    const sortedPosConfigs = posConfigs?.sort(sortFunction) || [];

    const isLoading = !posConfigsAreReady || !retailerInventoryEnabledIsReady;

    if (isLoading) {
        return <Skeleton height={200} />;
    }

    if (sortedPosConfigs.length === 0) {
        return null;
    }

    const stripeUrl = `https://dashboard.stripe.com/${
        process.env.REACT_APP_ENV === 'prod' ? '' : 'test/'
    }customers/${stripeCustomerId}`;

    return (
        <Paper className="p-4 mt-4 flex flex-col" data-testid="super-admin-pos-configs-display">
            <span className="font-bold pb-4">For Super-Admins Only:</span>

            {hasInventoryEntitlement && (
                <span className="mt-2 mb-2">
                    Inventory Enabled for Markets:&nbsp;
                    {inventoryEntitlementMarkets.length > 0
                        ? inventoryEntitlementMarkets.map((market) => market).join(', ')
                        : 'All'}
                </span>
            )}
            <a
                href={stripeUrl}
                target="_blank"
                rel="noreferrer"
                className="text-cerulean-60 hover:text-cerulean-80 hover:underline mt-2"
            >
                Go to {vendorName} Stripe Account
            </a>
            <a
                href={`/control-center/accounts/${retailerAccountId}/pos-config`}
                className="text-cerulean-60 hover:text-cerulean-80 hover:underline mt-2 mb-2"
                target="_blank"
                rel="noreferrer"
            >
                Go to {retailerName} POS Config Details
            </a>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-2 auto-rows-fr [&_.MuiFormControlLabel-label]:!ml-0">
                {sortedPosConfigs.map((config: any) => {
                    const isActiveConfig = config._id === activePosConfigId;
                    return (
                        <Paper
                            key={config._id}
                            data-testid={
                                config._id === activePosConfigId ? 'active-pos-config' : undefined
                            }
                            className={cn(
                                'flex flex-col !mt-0 h-full',
                                isActiveConfig && 'border-solid !border-2 border-aqua-800',
                            )}
                        >
                            <div className="flex flex-col flex-grow">
                                <span className="font-bold pb-1">{config.type}</span>
                                <span className="pb-2">
                                    Lookback:&nbsp;
                                    {moment(config.lookback).format(DATE_DISPLAY_FORMAT)}
                                </span>
                                <span>
                                    <BooleanIconWithLabel
                                        label="Supports Inventory"
                                        value={config.posInventoryEnabled ?? false}
                                    />
                                </span>
                                <span>
                                    <BooleanIconWithLabel label="Enabled" value={config.enabled} />
                                </span>
                                {config.credentials?.locations && (
                                    <span className="pt-2 pb-2 flex flex-wrap">
                                        Locations:&nbsp;
                                        {config.credentials?.locations
                                            ?.map((location: any) => location.name)
                                            .join(', ') ?? ''}
                                    </span>
                                )}
                            </div>
                            {isActiveConfig && (
                                <span className="self-center p-2 mt-auto">Active</span>
                            )}
                        </Paper>
                    );
                })}
            </div>
        </Paper>
    );
};

export default SuperAdminPosConfigsDisplay;
