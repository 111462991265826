import { useState } from 'react';

import TrainingCourseView from '@views/training/TrainingCourseView';

import { TrainingCompleteIcon } from '@components/icons';
import PanelField from '@components/layout/PanelField';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';
import InfoModal from '@components/overlays/InfoModal';
import { TrainingStatusTable } from '@components/tables/TrainingStatusTable/TrainingStatusTable';

import { useSpark } from '@hooks/SparksHooks';

import { getSelectedParticipantsFromSparkSubGroups } from '@helpers/sparks';

import { SparkParticipant } from '@app/types/SparksTypes';

import './SparkTrainingPanel.scss';

const SparkTrainingPanel = () => {
    const {
        spark: { trainingEnabled, detailedSparkType, courseData, trainingCourseId },
        sparkPosData: { participants },
        sparkSubGroups,
        sparkIsReady,
        sparkPosDataIsReady,
    } = useSpark();

    const [isTrainingStatusModalVisible, setIsTrainingStatusModalVisible] =
        useState<boolean>(false);
    const [isTrainingPreviewModalVisible, setIsTrainingPreviewModalVisible] =
        useState<boolean>(false);

    const subGroupsParticipants = getSelectedParticipantsFromSparkSubGroups(sparkSubGroups);
    const participantsForSpark =
        detailedSparkType === 'leaderboardMulti' ? subGroupsParticipants : participants;
    const participantCount = participantsForSpark.length;

    const finishedParticipantsObj =
        courseData?.completedUsersByCourseId?.[courseData?.courseId] || {};
    const finishedParticipantCount = Object.keys(finishedParticipantsObj).filter((key) => {
        return participantsForSpark.some(
            ({ flexibleEmployeeId }: SparkParticipant) => flexibleEmployeeId === key,
        );
    }).length;

    const completedTotalPct = sparkPosDataIsReady
        ? `${((finishedParticipantCount / participantCount) * 100).toFixed(0)}%`
        : '--';

    const courseLengthStr = courseData?.courseLength ? `${courseData.courseLength} min` : '--';

    const trainingCourseType = courseData?.courseType;
    const externalTrainingCourseId = courseData!.courseId;

    const isRequiredTraining = !!trainingEnabled && !!courseData?.required;
    const courseType = isRequiredTraining ? 'Required' : 'Optional';

    return (
        <Paper className="spark-training-panel">
            <div className="spark-training-panel__header">Training</div>

            {sparkIsReady && sparkPosDataIsReady ? (
                <div className="training-details__wrapper">
                    <section className="left">
                        <div className="training-stats">
                            <TrainingCompleteIcon fill="#62676E" />
                            <span>{completedTotalPct}</span>
                        </div>
                        <PanelField
                            label="Course Completion"
                            value={`${finishedParticipantCount} of ${participantCount} employees`}
                            onClick={() => setIsTrainingStatusModalVisible(true)}
                        />
                    </section>
                    <section className="right">
                        <div>
                            <PanelField
                                className="course-description"
                                label="Course Description"
                                value={courseData?.courseDescription || ''}
                            />
                        </div>

                        <div className="row-two">
                            <PanelField
                                className="course-length"
                                label="Course Length"
                                value={courseLengthStr}
                            />

                            <PanelField
                                className="course-type"
                                label="Course Type"
                                value={courseType}
                            />

                            <PanelField
                                label="Course Link"
                                value={courseData?.courseTitle || 'View Training'}
                                onClick={() => setIsTrainingPreviewModalVisible(true)}
                            />
                        </div>
                    </section>
                </div>
            ) : (
                <Skeleton count={1} height={125} />
            )}
            {isTrainingStatusModalVisible && (
                <InfoModal
                    className="wide"
                    isVisible={isTrainingStatusModalVisible}
                    title="Participating Employees"
                    onClose={() => setIsTrainingStatusModalVisible(false)}
                >
                    <TrainingStatusTable />
                </InfoModal>
            )}
            {isTrainingPreviewModalVisible && (
                <InfoModal
                    className="training-preview-modal"
                    size="extra-narrow"
                    isVisible={isTrainingPreviewModalVisible}
                    onClose={() => setIsTrainingPreviewModalVisible(false)}
                >
                    <TrainingCourseView
                        trainingCourseId={trainingCourseId}
                        trainingCourseType={trainingCourseType}
                        externalTrainingCourseId={externalTrainingCourseId}
                        previewModal
                    />
                </InfoModal>
            )}
        </Paper>
    );
};

export default SparkTrainingPanel;
