import { z } from 'zod';

import { AccountMarkets, AccountMarket } from '..';

export interface ILearningResource {
  learningResourceId: string;
  status: LearningResourceStatus;
  accountId: string;
  accountName?: string;
  accountType?: string;

  surveyId?: string | null;
  surveyParticipants?: number; // count of survey responses

  brandId?: string | null;
  brandName?: string;
  brandPhoto?: string | null;

  markets: AccountMarket[];
  description: string;
  reward: number;
  videoUrl?: string | null;
  imageUrl?: string | null;
  createdBy: string;
  createdByFirstName?: string;
  createdByLastName?: string;
  createdAt?: Date | null;
  updatedAt?: Date | null;
  launchedAt?: Date | null;
  removedAt?: Date | null;

  surveyData?: Record<string, unknown> | null;
  themeData?: Record<string, unknown> | null;
}
export interface IEmployeeLearningResource extends ILearningResource {
  surveyResponse?: Record<string, unknown>;
}

export const updateLearningResourceSchema = z.object({
  markets: z.array(z.enum(AccountMarkets as [string, ...string[]])),
  description: z.string().optional(),
  reward: z.number(),
  videoUrl: z.string().optional(),
  imageUrl: z.string().optional(),
  accountId: z.string().optional(),
  brandId: z.string().optional(),
  launchedAt: z
    .string()
    .optional()
    .transform((str) => (str ? new Date(str) : null)),
  removedAt: z
    .string()
    .optional()
    .transform((str) => (str ? new Date(str) : null)),
  surveyData: z.record(z.string(), z.unknown()).optional(),
  themeData: z.record(z.string(), z.unknown()).optional(),
});

export const createLearningResourceSchema = z.object({
  accountId: z.string(),
  surveyId: z.string().optional(),
  brandId: z.string().optional(),
  markets: z.array(z.enum(AccountMarkets as [string, ...string[]])),
  description: z.string().optional(),
  reward: z.number(),
  videoUrl: z.string().optional(),
  imageUrl: z.string().optional(),
  launchedAt: z
    .string()
    .optional()
    .transform((str) => (str ? new Date(str) : null)),
  surveyData: z.record(z.string(), z.unknown()).optional(),
  themeData: z.record(z.string(), z.unknown()).optional(),
});

export type CreateLearningResourceRequestBody = z.infer<typeof createLearningResourceSchema>;
export type UpdateLearningResourceRequestBody = z.infer<typeof updateLearningResourceSchema>;
export type CreateLearningResourceResponseBody = ILearningResource;
export type GetLearningResourceResponseBody = ILearningResource;

export interface DeleteLearningResourcePathParams {
  learningResourceId: string;
}

export interface GetLearningResourcePathParams {
  learningResourceId: string;
}

export interface UpdateLearningResourcePathParams {
  learningResourceId: string;
}

export const LearningResourceStatuses = ['created', 'live', 'removed'] as const;
export type LearningResourceStatus = typeof LearningResourceStatuses[number];

export const listLearningResourceRequestParamsSchema = z.object({
  brandId: z.union([z.string(), z.array(z.string())]).optional(),
  accountId: z.union([z.string(), z.array(z.string())]).optional(),
  status: z.enum(LearningResourceStatuses).optional(),
  limit: z.string().optional(),
  offset: z.string().optional(),
  sort: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
});

export const listLearningResourceResponseRequestParamsSchema = z.object({
  limit: z.string().optional(),
  offset: z.string().optional(),
  sort: z.string().optional(),
  order: z.enum(['asc', 'desc']).optional(),
});

export type GetAllLearningResourceQueryParams = z.infer<
  typeof listLearningResourceRequestParamsSchema
>;
