import { ReactElement } from 'react';

import { Box } from '@mui/material';

import { AccountLink, DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import { isLinkPending, isLinkUnmapped } from '@features/account-links/utils';
import { RequestSparkButton } from '@features/request-for-spark/components/RequestSparkButton';

import Button from '@components/buttons/Button';
import { CircleCheck, CircleXIcon, GeneralSettingsIcon, ResetIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import Table from '@components/table/Table';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { ITableRow } from '@app/types/TableTypes';

import { useAccountLinksTableActions } from './useAccountLinksTableActions';

export const useLegacyAccountLinkActionCell = () => {
    const { appIsMobile } = useApp();
    const { account, userCan } = useSparkplugAccount();
    const userCanManageLinks = userCan('manageLinks');

    const { isRespondingToAccountLinkRequest, onRespondToAccountLinkRequest, onManageAccountLink } =
        useAccountLinksTableActions();

    const renderLegacyActionCell = (
        row: ITableRow<AccountLink & { key: string }>,
        updateRow: any,
    ) => {
        let actionElement: ReactElement = <></>;

        if (account?.type === 'retailer') {
            switch (row.status) {
                case 'pending':
                    actionElement = (
                        <div className="actions-wrapper">
                            <Button
                                disabled={isRespondingToAccountLinkRequest || !userCanManageLinks}
                                tooltip={
                                    !userCanManageLinks
                                        ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                        : undefined
                                }
                                variant="smooth"
                                color="red"
                                onClick={() =>
                                    onRespondToAccountLinkRequest(row.accountId, false, updateRow)
                                }
                            >
                                {appIsMobile ? <CircleXIcon className="decline" /> : 'Decline'}
                            </Button>
                            <Button
                                disabled={isRespondingToAccountLinkRequest || !userCanManageLinks}
                                tooltip={
                                    !userCanManageLinks
                                        ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                        : undefined
                                }
                                variant="smooth"
                                color="green"
                                onClick={() =>
                                    onRespondToAccountLinkRequest(row.accountId, true, updateRow)
                                }
                            >
                                {appIsMobile ? <CircleCheck className="accept" /> : 'Accept'}
                            </Button>
                        </div>
                    );
                    break;
                case 'rejected':
                    actionElement = (
                        <Tooltip
                            title={
                                !userCanManageLinks
                                    ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                    : 'Reopen link request'
                            }
                        >
                            <ResetIcon
                                className="reset-icon"
                                onClick={() => {
                                    if (!userCanManageLinks) return;
                                    updateRow((prevRow: any) => {
                                        return {
                                            ...prevRow,
                                            status: 'pending',
                                        } as any;
                                    });
                                }}
                            />
                        </Tooltip>
                    );
                    break;
                default:
                    actionElement = (
                        <Box
                            sx={{
                                display: 'flex',
                                gap: '1.5rem',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            {!isLinkUnmapped(row) && row.status !== 'disabled' && !appIsMobile && (
                                <RequestSparkButton variant="icon-only" accountLink={row} />
                            )}
                            <Button
                                startIcon={<GeneralSettingsIcon />}
                                onClick={() => onManageAccountLink(row)}
                                variant="outlined"
                                color="blue"
                                disabled={!userCanManageLinks}
                                tooltip={
                                    !userCanManageLinks
                                        ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                                        : undefined
                                }
                            >
                                {appIsMobile ? undefined : 'Manage'}
                            </Button>
                        </Box>
                    );
                    break;
            }
        }
        if (account?.type === 'brand' && row.status === 'enabled' && !isLinkPending(row)) {
            actionElement = appIsMobile ? (
                <></>
            ) : (
                <Button
                    startIcon={<GeneralSettingsIcon />}
                    onClick={() => onManageAccountLink(row)}
                    variant="outlined"
                    color="blue"
                    disabled={!userCanManageLinks}
                    tooltip={!userCanManageLinks ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined}
                >
                    Manage
                </Button>
            );
        }

        return <Table.Cell align="right">{actionElement}</Table.Cell>;
    };

    return {
        renderLegacyActionCell,
    };
};
