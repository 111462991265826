import { FC } from 'react';

import { useAdminAccountUpdate } from '@contexts/AccountContext/AccountAdminMutations';

import Button from '@components/buttons/Button';
import { AnimatedLoadingFilesGraphic } from '@components/graphics';
import { TagAssistIcon } from '@components/icons';
import Paper from '@components/layout/Paper';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { PoweredByOpenAI } from '../OpenAIBranding/OpenAIBranding';

import './TagAssistCallout.scss';

interface TagAssistCalloutProps {
    showModal: () => void;
    accountId?: string;
    tagAssistantEnabledAt?: string;
    autoplayAnimation?: boolean;
    loopAnimation?: boolean;
}

const TagAssistCallout: FC<TagAssistCalloutProps> = ({
    showModal,
    accountId,
    tagAssistantEnabledAt,
    autoplayAnimation = true,
    loopAnimation = true,
}) => {
    const { refetchAccount, isRefetching } = useSparkplugAccount();
    const { isAdminUpdatingAccount, adminUpdateAccountAsync } = useAdminAccountUpdate(
        accountId ?? '',
    );

    const dismissAssistantCallout = () => {
        adminUpdateAccountAsync(
            { tagAssistantMessageDismissed: true },
            {
                onSuccess: () => {
                    refetchAccount();
                },
            },
        );
    };

    return (
        <Paper
            className={`tag-assist-callout ${
                isAdminUpdatingAccount || isRefetching ? 'processing' : ''
            }`}
        >
            <div>
                <h1>
                    Tag Assistant <PoweredByOpenAI />
                </h1>
                <div className="details">
                    {tagAssistantEnabledAt ? (
                        <span>
                            You&apos;re off and running! The Tag Assistant will take 4-6 hours to
                            tag all products across your linked Retailers.
                        </span>
                    ) : (
                        <span>
                            The SparkPlug Tag Assistant automatically applies your products tags
                            across your linked Retailers.
                        </span>
                    )}
                </div>
            </div>
            {
                // If we display this view and the tag assistant is enabled, we know we're in the initial tagging period and can show the animation
                tagAssistantEnabledAt ? (
                    <AnimatedLoadingFilesGraphic
                        autoplay={autoplayAnimation}
                        loop={loopAnimation}
                    />
                ) : (
                    <div className="buttons">
                        <Button
                            disabled={isAdminUpdatingAccount || isRefetching}
                            onClick={showModal}
                            variant="filled"
                            color="purple"
                            startIcon={<TagAssistIcon />}
                        >
                            Enable
                        </Button>
                        <Button
                            disabled={isAdminUpdatingAccount || isRefetching}
                            onClick={dismissAssistantCallout}
                            color="neutral"
                        >
                            Dismiss
                        </Button>
                    </div>
                )
            }
        </Paper>
    );
};

export default TagAssistCallout;
