import { FC, useState } from 'react';
import { UseMutateFunction } from 'react-query';

import { CardActionArea } from '@mui/material';
import moment, { Moment } from 'moment';

import {
    deactivatePointOfSale,
    fetchPosConfigsByGroupId,
    getLocationImportConfigs,
    getShopifyAuthURL,
    getSquareAuthURL,
} from '@core/accounts';

import Button from '@components/buttons/Button';
import Dropdown from '@components/dropdown/Dropdown';
import Form from '@components/form/Form';
import {
    CheckCircle,
    CloudOff,
    CloverLogoIcon,
    ShopifyLogoIcon,
    SquareLogoIcon,
    WarningRounded,
} from '@components/icons';
import AppView from '@components/layout/AppView';
import Paper from '@components/layout/Paper';
import Spinner from '@components/layout/Spinner';
import IntegrationSubdomainModal from '@components/overlays/IntegrationSubdomainModal';

import { useAdvancedMutation, useAdvancedQuery } from '@hooks/QueryHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { uuid } from '@helpers/ui';

import './AdminIntegrationsView.scss';

interface IntegrationDetails {
    icon: any;
    navType: string;
    getAuthUrl: Function;
}

interface LocationImportConfig {
    error?: string;
    value?: {
        currentLookback?: string;
        currentLookBackDate?: string;
    };
}

const integrationDetailsMap: {
    [key: string]: IntegrationDetails;
} = {
    square: {
        icon: <SquareLogoIcon className="integration-logo" />,
        navType: 'href',
        getAuthUrl: getSquareAuthURL,
    },
    shopify: {
        icon: <ShopifyLogoIcon className="integration-logo" />,
        navType: 'modal',
        getAuthUrl: getShopifyAuthURL,
    },
};

export const IntegrationStatusIcon: FC<{ config: any }> = ({ config }) => {
    if (config.credentials?.isDisconnected) {
        return <CloudOff className="pos-status-icon icon-color-neutral" />;
    }

    const isOk =
        config.locations?.length === 0 ||
        config.locations?.every(
            (locationImportConfig: LocationImportConfig) => !locationImportConfig.error,
        );

    if (isOk) {
        return <CheckCircle className="pos-status-icon icon-color-green" />;
    }

    return <WarningRounded className="pos-status-icon icon-color-yellow" />;
};

export const IntegrationCard: FC<{
    config: any;
    deactivate: UseMutateFunction<void, unknown, string, unknown>;
    deactivateIsLoading: boolean;
    authUrl: string;
}> = ({ config, deactivate, deactivateIsLoading, authUrl }) => {
    const isDisconnected = config.credentials?.isDisconnected;
    const dataUntil = config.locations
        .flat() // There may be multiple location configs here (import, summary)
        .reduce((acc: Moment, location: any) => {
            const current = moment(
                location?.value?.currentLookBackDate ||
                    location?.currentLookback ||
                    config?.lookback,
            );
            if (current.isAfter(acc)) {
                return current;
            }
            return acc;
        }, moment(config.lookback))
        .format('MM/DD/YYYY');

    let integrationSpecificDetails = <></>;
    switch (config.type) {
        case 'square':
            integrationSpecificDetails = (
                <Form.TextField
                    key={config.credentials?.merchantName}
                    name="merchantName"
                    label="Merchant Name"
                    defaultValue={config.credentials?.merchantName}
                    disabled
                />
            );
            break;
        case 'shopify':
            integrationSpecificDetails = (
                <Form.TextField
                    key={config.credentials?.shop}
                    name="shopUrl"
                    label="Shop Subdomain"
                    defaultValue={config.credentials?.shop}
                    disabled
                />
            );
            break;
        case 'clover':
            integrationSpecificDetails = config.credentials?.locations?.map(
                (location: { merchantId: string; region: string }) => {
                    const { merchantId, region } = location ?? {};
                    return (
                        <>
                            <Form.TextField
                                key={merchantId}
                                name="merchantId"
                                label="Merchant Id"
                                defaultValue={merchantId}
                                disabled
                            />
                            <Form.TextField
                                key={`${merchantId}-${region}`}
                                name="region"
                                label="Region"
                                defaultValue={region}
                                disabled
                            />
                        </>
                    );
                },
            );
            break;
        default:
            integrationSpecificDetails = <></>;
            break;
    }

    const integrationIcon =
        config.type === 'clover' ? (
            <CloverLogoIcon className="integration-logo" />
        ) : (
            integrationDetailsMap[config.type]?.icon
        );

    return (
        <Paper className="pos-card">
            <IntegrationStatusIcon config={config} />
            <div className="pos-card-title">
                {integrationIcon}
                <span className="capitalize">{config.type}</span>
            </div>
            <div className="pos-card-content">
                <Form>
                    {integrationSpecificDetails}
                    <Form.TextField
                        key={dataUntil}
                        name="dataSince"
                        label="Current data through"
                        value={dataUntil}
                        disabled
                    />
                    {!isDisconnected && (
                        <Form.Switch
                            name="configEnabled"
                            label="Enabled"
                            value={config.enabled}
                            disabled
                        />
                    )}
                </Form>
            </div>
            {isDisconnected ? (
                <a href={authUrl}>
                    <Button color="blue" variant="flat">
                        Reconnect
                    </Button>
                </a>
            ) : (
                <Button
                    color="neutral"
                    variant="flat"
                    onClick={() => deactivate(config._id)}
                    showSpinner={deactivateIsLoading}
                    disabled={deactivateIsLoading}
                >
                    Disconnect
                </Button>
            )}
        </Paper>
    );
};

const AvailableIntegrationCard: FC<{ accountId: string; type: string; navType: string }> = ({
    accountId,
    type,
    navType,
}) => {
    return (
        <Paper className="add-integration-card">
            <CardActionArea
                className="add-integration-card-action"
                onClick={
                    navType === 'modal'
                        ? () => integrationDetailsMap[type]?.getAuthUrl(accountId)
                        : () => {
                              window.location = integrationDetailsMap[type]?.getAuthUrl(accountId);
                          }
                }
            >
                {integrationDetailsMap[type].icon}
                <span>
                    <Paper.Title>{type}</Paper.Title>
                    Connect SparkPlug with your <span className="capitalize">{type}</span> POS
                </span>
            </CardActionArea>
        </Paper>
    );
};

const AddIntegrationDropdown: FC<{ accountId: string; integrationsToAdd: any[] }> = ({
    accountId,
    integrationsToAdd,
}) => {
    return (
        <Dropdown>
            <Dropdown.Button>Add</Dropdown.Button>
            <Dropdown.Menu>
                {integrationsToAdd.map((type) => {
                    const { navType } = integrationDetailsMap[type];
                    const clickHandler = () => {
                        if (navType === 'href') {
                            const url = integrationDetailsMap[type]?.getAuthUrl(accountId);
                            window.location.assign(url);
                        } else {
                            integrationDetailsMap[type]?.getAuthUrl(accountId);
                        }
                    };
                    return (
                        <Dropdown.MenuItem onClick={clickHandler} key={uuid()}>
                            <span className="capitalize">{type}</span>
                        </Dropdown.MenuItem>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const MainContent: FC<{ posConfigs: any[]; accountId: string }> = ({ posConfigs, accountId }) => {
    const integrationCards = posConfigs.map((config: any) => {
        const { mutate: deactivate, isLoading: deactivateIsLoading } = useAdvancedMutation(
            async (posConfigId: string) => {
                await deactivatePointOfSale(posConfigId);
            },
            {
                updateQuery: {
                    queryKey: ['account', accountId, 'posConfigs'],
                },
            },
        );
        return (
            <IntegrationCard
                key={config._id || uuid()}
                config={config}
                deactivate={deactivate}
                deactivateIsLoading={deactivateIsLoading}
                authUrl={
                    config.type === 'square'
                        ? integrationDetailsMap[config.type]?.getAuthUrl(accountId)
                        : undefined
                }
            />
        );
    });

    if (!posConfigs.length) {
        return (
            <div className="possible-integrations-wrapper">
                {Object.keys(integrationDetailsMap)
                    .filter((integration) => integration !== 'shopify')
                    .map((type: string) => {
                        const { navType } = integrationDetailsMap[type];
                        return (
                            <AvailableIntegrationCard
                                key={uuid()}
                                type={type}
                                accountId={accountId}
                                navType={navType}
                            />
                        );
                    })}
            </div>
        );
    }

    return <>{integrationCards}</>;
};

const AdminIntegrationsView: FC = () => {
    const { accountIsReady, account } = useSparkplugAccount();
    const [showIntegrationSubdomainModal, setShowIntegrationSubdomainModal] = useState(false);
    if (integrationDetailsMap.shopify) {
        integrationDetailsMap.shopify.getAuthUrl = () => {
            setShowIntegrationSubdomainModal(true);
        };
    }

    const { isLoading, data: posConfigs } = useAdvancedQuery(
        ['account', account?._id, 'posConfigs'],
        async () => {
            const posConfigList = await fetchPosConfigsByGroupId(account!._id);
            const locationImportConfigEntries = await Promise.all(
                posConfigList.map(async ({ _id }) => [_id, await getLocationImportConfigs(_id)]),
            );

            const locationImportConfigMap = new Map<string, any[]>(
                locationImportConfigEntries as any,
            );
            return posConfigList.map((posConfig) => ({
                ...posConfig,
                locations: locationImportConfigMap.get(posConfig._id) ?? [],
            }));
        },
        {
            enabled: !!(accountIsReady && account?._id),
        },
    );

    if (isLoading || !posConfigs) {
        return (
            <AppView.Content title="POS Integrations">
                <Spinner />
            </AppView.Content>
        );
    }

    return (
        <AppView.Content title="POS Integrations">
            <Paper className="manage-pos-integrations">
                <div className="header-wrapper">
                    <Paper.Title className="inner-title">Manage POS Integrations</Paper.Title>
                    {!!posConfigs.length && (
                        <AddIntegrationDropdown
                            integrationsToAdd={Object.keys(integrationDetailsMap).filter(
                                (integration) => integration !== 'shopify',
                            )}
                            accountId={account!._id}
                        />
                    )}
                </div>
                <MainContent posConfigs={posConfigs} accountId={account!._id} />
            </Paper>
            <IntegrationSubdomainModal
                isVisible={showIntegrationSubdomainModal}
                onClose={() => setShowIntegrationSubdomainModal(false)}
            />
        </AppView.Content>
    );
};

export default AdminIntegrationsView;
