import { formatCurrency } from '@sparkplug/lib';

import { useGetEmployeeLearningResources } from '@features/learn-and-earn/queries/useGetEmployeeLearningResources';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';

import { useApp } from '@hooks/AppHooks';

import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';

const PreLaunchLearnAndEarn = () => {
    return (
        <div className="flex flex-col items-center justify-center p-4">
            <div className="text-xl font-bold mb-2">Courses are coming soon!</div>
            <div className="text-base text-gray-600">
                Get ready for more ways to earn $$ through SparkPlug.
            </div>
        </div>
    );
};

const LearnAndEarn = () => {
    const { history } = useApp();
    const { learningResources } = useGetEmployeeLearningResources();

    return (
        <>
            {learningResources
                .filter((resource) => resource.surveyResponse?.status !== 'completed')
                .map((resource) => (
                    <Card key={resource.learningResourceId} className="mb-4">
                        <CardHeader className="mb-3">
                            <CardTitle className="flex flex-row items-center justify-between">
                                <img
                                    src={resource.brandPhoto ?? ''}
                                    alt={resource.brandName ?? ''}
                                    className="w-10 h-10 rounded-full mr-2"
                                />
                                <Chip
                                    color="green"
                                    label={`+ ${
                                        resource.reward ? formatCurrency(resource.reward / 100) : 0
                                    }`}
                                />
                            </CardTitle>
                        </CardHeader>
                        <CardContent>
                            <Typography variant="sm" className="font-semibold uppercase mb-1">
                                {resource.brandName} Course
                            </Typography>
                            <Typography variant="base" className="text-gray-900">
                                {resource.description}
                            </Typography>
                        </CardContent>
                        <CardFooter>
                            <Button
                                variant="outlined"
                                color="blue"
                                className="w-full"
                                onClick={() => {
                                    history.push(
                                        `/user/learn-and-earn/${resource.learningResourceId}/${resource.surveyId}`,
                                    );
                                }}
                            >
                                {resource.surveyResponse ? 'Continue Course' : 'Start Course'}
                            </Button>
                        </CardFooter>
                    </Card>
                ))}
        </>
    );
};

const LearnAndEarnTab = () => {
    const { REACT_APP_LEARNING_RESOURCES = false } = process.env;
    const isPreLaunch = REACT_APP_LEARNING_RESOURCES !== 'true';

    return isPreLaunch ? <PreLaunchLearnAndEarn /> : <LearnAndEarn />;
};

export default LearnAndEarnTab;
