import { ComponentProps, FC } from 'react';

import Button from '@components/buttons/Button';
import { Add } from '@components/icons';

interface CreateNewGroupButtonProps
    extends Pick<ComponentProps<typeof Button>, 'disabled' | 'tooltip'> {
    setIsCreating: (value: boolean) => void;
}

export const CreateNewGroupButton: FC<CreateNewGroupButtonProps> = ({
    setIsCreating,
    ...props
}) => {
    return (
        <Button
            className="tag-group-create-button-wrapper"
            startIcon={<Add />}
            onClick={() => setIsCreating(true)}
            {...props}
        >
            Create New Group
        </Button>
    );
};
