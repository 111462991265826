import { z } from 'zod';
import { BrandProductTag, BrandProductTagGroup, TAG_COLOR_OPTIONS } from '../product-tags';

export interface CreateBrandTagGroupPathParams {
  brandId: string;
}

export const createBrandTagGroupSchema = z.object({
  name: z.string().min(1),
});

export const createBrandTagSchema = z.object({
  name: z.string().min(1),
  color: z.enum(TAG_COLOR_OPTIONS),
});

export type CreateBrandTagGroupRequestBody = z.infer<typeof createBrandTagGroupSchema>;
export type CreateBrandTagGroupResponseBody = { data: BrandProductTagGroup };

export const updateBrandTagGroupSchema = z.object({
  name: z.string().min(1),
});
export type UpdateBrandTagGroupRequestBody = z.infer<typeof updateBrandTagGroupSchema>;
export interface UpdateBrandTagGroupPathParams {
  brandId: string;
  groupId: string;
}
export interface CopyBrandTagGroupPathParams {
  brandId: string;
  groupId: string;
}
export const copyBrandTagGroupSchema = z.object({
  targetBrandId: z.string().min(1),
});

export type CopyBrandTagGroupRequestBody = z.infer<typeof copyBrandTagGroupSchema>;
export type CopyBrandTagGroupResponseBody = { data: BrandProductTagGroup };

export interface DeleteBrandTagGroupPathParams {
  brandId: string;
  groupId: string;
}

export interface CreateBrandTagPathParams {
  brandId: string;
  groupId: string;
}
export type CreateBrandTagResponseBody = { data: BrandProductTag };
export type CreateBrandTagRequestBody = z.infer<typeof createBrandTagSchema>;

export const updateBrandTagSchema = createBrandTagSchema;
export type UpdateBrandTagRequestBody = CreateBrandTagRequestBody;

export interface UpdateBrandTagPathParams {
  brandId: string;
  groupId: string;
  tagId: string;
}

export interface DeleteBrandTagPathParams {
  brandId: string;
  groupId: string;
  tagId: string;
}
export type UpdateBrandTagResponseBody = { data: BrandProductTag };
