import { FC } from 'react';

import { TAG_ASSISTANT_LEARN_MORE_LINK } from '@sparkplug/lib';

import { useAdminAccountUpdate } from '@contexts/AccountContext/AccountAdminMutations';

import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal from '@components/overlays/Modal';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import './TagAssistDisableModal.scss';

interface TagAssistDisableModalProps {
    isVisible: boolean;
    accountId?: string;
    onClose: () => void;
}

const TagAssistDisableModal: FC<TagAssistDisableModalProps> = ({
    isVisible,
    accountId,
    onClose,
}) => {
    const { refetchAccount } = useSparkplugAccount();
    const { isAdminUpdatingAccount, adminUpdateAccountAsync } = useAdminAccountUpdate(
        accountId ?? '',
    );

    const disableTagAssistant = () => {
        toast.promise(
            adminUpdateAccountAsync(
                {
                    tagAssistantEnabled: false,
                },
                {
                    onSuccess: () => {
                        refetchAccount();
                    },
                },
            ),
            {
                loading: 'Disabling Tag Assistant...',
                success: 'Tag Assistant disabled',
                error: 'Something went wrong - please try again and contact support if this issue persists.',
            },
        );
    };

    return (
        <Modal className="tag-assist-disable-modal" isVisible={isVisible} onClose={onClose}>
            <Modal.Title onClose={onClose}>Disable Tag Assistant</Modal.Title>

            <Modal.Content>
                <CalloutMessage
                    message={
                        <>
                            When the Tag Assistant is disabled, you will be responsible for manually
                            applying tags to products missing tags.{' '}
                            <a
                                href={TAG_ASSISTANT_LEARN_MORE_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Learn more
                            </a>
                        </>
                    }
                />
            </Modal.Content>

            <Modal.Content className="re-enable-reminder">
                After making updates to tags & tag groups, remember to re-enable the Tag Assistant
                when those are ready to be applied.
            </Modal.Content>

            <Modal.Actions>
                <div className="actions">
                    <Button color="neutral" variant="flat" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        color="purple"
                        variant="filled"
                        onClick={disableTagAssistant}
                        disabled={isAdminUpdatingAccount}
                    >
                        Disable
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default TagAssistDisableModal;
