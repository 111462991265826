import { FC } from 'react';

import { AccountSparkBrand } from '@sparkplug/lib';

import { UseProductTagsTableFilters } from '@features/product-tags/views/AssigningProductTagsView/useProductTagsTableFilters';

import Button from '@components/buttons/Button';
import CalloutMessage from '@components/layout/CalloutMessage';

import './UntaggedProductsCallout.scss';

interface UntaggedProductsCalloutProps {
    productType: 'active' | 'invalid';
    selectedBrand?: AccountSparkBrand;
    tableFilters: UseProductTagsTableFilters;
}

const UntaggedProductsCallout: FC<UntaggedProductsCalloutProps> = ({
    productType,
    selectedBrand,
    tableFilters,
}) => {
    const { missingTagCount = 0 } = selectedBrand?.tagState ?? {};

    if (
        productType !== 'active' ||
        !missingTagCount ||
        tableFilters.filters.tagIds.includes('none')
    ) {
        return <></>;
    }

    const isOrAre = missingTagCount === 1 ? 'is' : 'are';
    const plurality = missingTagCount === 1 ? '' : 's';

    const UntaggedProductsMessage = () => {
        return (
            <>
                <span>
                    There {isOrAre} {missingTagCount} product{plurality} mapped to{' '}
                    {selectedBrand?.name} that {isOrAre} missing tags.
                </span>
                <Button
                    variant="raised"
                    onClick={() =>
                        tableFilters.setFilters((prevValue) => ({
                            ...prevValue,
                            tagIds: ['none'],
                            hideOldProducts: true,
                        }))
                    }
                >
                    View Products
                </Button>
            </>
        );
    };

    return (
        <CalloutMessage
            className="untagged-products"
            message={<UntaggedProductsMessage />}
            color="red"
            customIcon="alert"
        />
    );
};

export default UntaggedProductsCallout;
