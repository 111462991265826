import UploadsAPI from '@api/UploadsAPI';
import axios from 'axios';

import {
    CreateAccountSparkBrandRequestBody,
    CreateSparkBrandAndUploadPhotoParams,
    UPLOAD_FOLDERS,
    UpdateAccountSparkBrandRequestBody,
    UpdateSparkBrandAndUploadPhotoParams,
} from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { getAccountSparkBrandsQueryKey } from '../queries/SparkBrandQueries';

const API = {
    createSparkBrand: async (groupId: string, payload: CreateAccountSparkBrandRequestBody) => {
        return axios.post<{}>(`/api/v1/accounts/${groupId}/spark-brands`, payload);
    },
    updateSparkBrand: async (
        groupId: string,
        sparkBrandId: string,
        payload: UpdateAccountSparkBrandRequestBody,
    ) => {
        return axios.put<{}>(`/api/v1/accounts/${groupId}/spark-brands/${sparkBrandId}`, payload);
    },
    deleteSparkBrand: async (groupId: string, sparkBrandId: string) => {
        return axios.delete<void>(`/api/v1/accounts/${groupId}/spark-brands/${sparkBrandId}`);
    },
};

const uploadSparkBrand = async ({ groupId, file }: { groupId: string; file: any }) => {
    const data = await UploadsAPI.getPresignedUploadUrl({
        groupId,
        folder: UPLOAD_FOLDERS.SPARK_BRANDS,
    });

    return UploadsAPI.upload(data, file);
};

const createSparkBrandAndUploadPhoto = async (
    accountId: string,
    { photo, ...otherParams }: CreateSparkBrandAndUploadPhotoParams,
) => {
    if (!photo) {
        throw new Error('Logo is required');
    }

    const { key } = await uploadSparkBrand({ groupId: accountId, file: photo });

    await API.createSparkBrand(accountId, {
        ...otherParams,
        photo: key,
    });
};

const updateSparkBrandAndUploadPhoto = async (
    accountId: string,
    sparkBrandId: string,
    { photo, ...otherParams }: UpdateSparkBrandAndUploadPhotoParams,
) => {
    const payload: UpdateAccountSparkBrandRequestBody = otherParams ?? {};

    if (photo) {
        const { key } = await uploadSparkBrand({ groupId: accountId, file: photo });
        payload.photo = key;
    }

    await API.updateSparkBrand(accountId, sparkBrandId, payload);
};

export const useSparkBrandMutations = (accountId: string) => {
    const { mutate: createSparkBrand } = useAdvancedMutation(
        (payload: CreateSparkBrandAndUploadPhotoParams) =>
            createSparkBrandAndUploadPhoto(accountId, payload),
        {
            updateQuery: { queryKey: getAccountSparkBrandsQueryKey(accountId) },
            toastOptions: {
                loading: 'Creating Spark Brand...',
                success: 'Spark Brand created',
                error: 'Something went wrong',
            },
        },
    );

    const { mutate: updateSparkBrand } = useAdvancedMutation(
        ({
            sparkBrandId,
            payload,
        }: {
            sparkBrandId: string;
            payload: UpdateSparkBrandAndUploadPhotoParams;
        }) => updateSparkBrandAndUploadPhoto(accountId, sparkBrandId, payload),
        {
            updateQuery: { queryKey: getAccountSparkBrandsQueryKey(accountId) },
            toastOptions: {
                loading: 'Updating Spark Brand...',
                success: 'Spark Brand updated',
                error: 'Something went wrong',
            },
        },
    );

    const { mutate: deleteSparkBrand } = useAdvancedMutation(
        (sparkBrandId: string) => API.deleteSparkBrand(accountId, sparkBrandId),
        {
            updateQuery: { queryKey: getAccountSparkBrandsQueryKey(accountId) },
            toastOptions: {
                loading: 'Deleting Spark Brand...',
                success: 'Spark Brand deleted',
                error: 'Something went wrong',
            },
        },
    );

    return { createSparkBrand, updateSparkBrand, deleteSparkBrand };
};
