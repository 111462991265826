import { FC, useMemo, useState } from 'react';
import { UseMutateFunction } from 'react-query';

import { AxiosResponse } from 'axios';
import { difference } from 'lodash';

import {
    ACCOUNT_MARKETS_RECORD,
    AccountMarket,
    AccountSparkBrand,
    CreateSparkBrandAndUploadPhotoParams,
} from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { DeleteForever as DeleteIcon } from '@components/icons';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import './CreateEditSparkBrandsModal.scss';

interface CreateEditSparkBrandsModalProps extends IDefaultModalProps {
    accountId: string;
    sparkBrand?: AccountSparkBrand;
    markets: AccountMarket[];
    createSparkBrand: UseMutateFunction<
        void,
        unknown,
        CreateSparkBrandAndUploadPhotoParams,
        unknown
    >;
    updateSparkBrand: UseMutateFunction<
        void,
        unknown,
        { sparkBrandId: string; payload: Partial<CreateSparkBrandAndUploadPhotoParams> },
        unknown
    >;
    deleteSparkBrand: UseMutateFunction<AxiosResponse<void>, unknown, string, unknown>;
}

interface CreateSavePayload {
    photo: File;
    name: string;
    isEnabled: boolean;
}

export const getAccountMarketOptions = (accountMarkets: AccountMarket[]) => {
    const options = accountMarkets.map((market) => ({
        value: market,
        label: ACCOUNT_MARKETS_RECORD[market as keyof typeof ACCOUNT_MARKETS_RECORD],
    }));

    return [
        {
            value: 'all',
            label: 'All Markets',
            expanded: true,
            children: options,
        },
    ];
};

const CreateEditSparkBrandsModal: FC<CreateEditSparkBrandsModalProps> = ({
    isVisible,
    onClose,
    sparkBrand,
    markets,
    createSparkBrand,
    updateSparkBrand,
    deleteSparkBrand,
}) => {
    const isExistingSparkBrand = !!sparkBrand?._id;
    const [selectedMarkets, setSelectedMarkets] = useState<string[]>(() => {
        return sparkBrand?.excludedMarkets
            ? difference(markets, sparkBrand.excludedMarkets)
            : markets;
    });

    const MarketOptions = getAccountMarketOptions(markets);

    const [payload, setPayload] = useState<Partial<CreateSavePayload>>(
        !isExistingSparkBrand ? { isEnabled: true } : {},
    );

    const isEnabled = useMemo(
        () => payload.isEnabled ?? sparkBrand?.isEnabled ?? true,
        [payload.isEnabled, sparkBrand?.isEnabled],
    );

    const handleSave = () => {
        const onSuccess = () => {
            onClose(true);
        };

        if (isExistingSparkBrand) {
            updateSparkBrand({ sparkBrandId: sparkBrand?._id, payload }, { onSuccess });
        } else {
            createSparkBrand(payload as CreateSavePayload, { onSuccess });
        }
    };

    const handleDelete = () => {
        if (isExistingSparkBrand) {
            deleteSparkBrand(sparkBrand?._id, { onSuccess: () => onClose(true) });
        }
    };

    return (
        <Modal isVisible={isVisible} onClose={onClose} maxWidth={400}>
            <Form>
                <Modal.Title onClose={() => onClose(false)}>Manage Brand</Modal.Title>
                <Modal.Content>
                    <Form.ImageField
                        required
                        label="Brand Logo"
                        btnLabel="Upload Image"
                        btnLocation="bottom"
                        variant="smooth"
                        defaultValue={sparkBrand?.photo}
                        onChange={(updatedValue) =>
                            setPayload((prevValue) => ({
                                ...prevValue,
                                photo: updatedValue,
                            }))
                        }
                    />
                    <Form.TextField
                        required
                        label="Brand Name"
                        name="name"
                        defaultValue={sparkBrand?.name}
                        onChange={(event) =>
                            setPayload((prevValue) => ({
                                ...prevValue,
                                name: event.target.value,
                            }))
                        }
                    />

                    <Form.TreeSelect
                        required
                        label="Markets"
                        name="markets"
                        className="hide-toggles hide-search"
                        selected={selectedMarkets}
                        onChange={(values: string[]) => {
                            setSelectedMarkets(values);
                            const newExcludedMarkets = markets.filter(
                                (market) => !values.includes(market),
                            );
                            setPayload((prevPayload) => ({
                                ...prevPayload,
                                excludedMarkets: newExcludedMarkets,
                            }));
                        }}
                        options={MarketOptions}
                        error={!selectedMarkets?.length}
                    />
                    <Form.Label>Status</Form.Label>
                    <Form.Switch
                        className="[&_label]:!ml-0"
                        label={isEnabled ? 'Active' : 'Disabled'}
                        name="isEnabled"
                        value={isEnabled}
                        onChange={() =>
                            setPayload((prevValue) => ({
                                ...prevValue,
                                isEnabled: !isEnabled,
                            }))
                        }
                    />
                    <Form.Description>
                        <p>
                            {isEnabled ? (
                                'This account can create new Sparks with this brand name and logo.'
                            ) : (
                                <>
                                    {'This account '}
                                    <strong>cannot</strong>
                                    {
                                        ' create new Sparks with this brand name and logo. Active and completed Sparks will remain associated with this brand name and logo.'
                                    }
                                </>
                            )}
                        </p>
                    </Form.Description>
                </Modal.Content>
                <Modal.Actions>
                    {isExistingSparkBrand && (
                        <Button
                            color="red"
                            variant="flat"
                            startIcon={<DeleteIcon />}
                            onClick={handleDelete}
                            className="spark-brand-modal_delete-button"
                        >
                            Delete
                        </Button>
                    )}
                    <Button color="neutral" variant="flat" onClick={() => onClose(false)}>
                        Cancel
                    </Button>
                    <Form.Button color="blue" variant="smooth" onClick={handleSave}>
                        Save
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default CreateEditSparkBrandsModal;
