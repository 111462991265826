import { FC, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { ColumnSettingsOption } from '@features/spark-dashboard/types';

import Form from '@components/form/Form';
import { Search as SearchIcon } from '@components/icons';
import Checkbox from '@components/inputs/Checkbox';

import { appendClasses } from '@helpers/ui';

import { UpdateColumnOrderParams, UpdateColumnVisibilityParams } from '../useColumnSettingsState';
import { ReactComponent as DraggableIcon } from './DraggableIcon.svg';

import './ColumnSettingsList.scss';

interface ColumnSettingsListProps {
    interactiveColumnSettings: ColumnSettingsOption[];
    staticColumnSettings?: ColumnSettingsOption[];
    onVisibilityChange: (params: UpdateColumnVisibilityParams) => any;
    onOrderChange: (params: UpdateColumnOrderParams) => any;
}
export const ColumnSettingsList: FC<ColumnSettingsListProps> = ({
    interactiveColumnSettings = [],
    staticColumnSettings = [],
    onVisibilityChange,
    onOrderChange,
}) => {
    const [searchQuery, setSearchQuery] = useState<string>('');

    const filteredInteractiveColumnSettings = useMemo(() => {
        return interactiveColumnSettings.filter(({ label }) => {
            return label.toLowerCase().includes(searchQuery.toLowerCase());
        });
    }, [searchQuery, interactiveColumnSettings]);

    return (
        <div className="column-manager-list">
            <Form.TextField
                className="column-manager-list_search-bar"
                name="column-manager-list_search-bar"
                startIcon={<SearchIcon />}
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                placeholder="Search for a column"
            />

            <section className="static-column-settings">
                {staticColumnSettings.map(({ label, value, isChecked }) => (
                    <div key={value} className="column-manager-list_item">
                        <Checkbox
                            disabled
                            name={value}
                            label={label}
                            value={isChecked}
                            onChange={(_, checkedValue) =>
                                onVisibilityChange({ value, isChecked: !!checkedValue })
                            }
                        />
                    </div>
                ))}
            </section>

            <DragDropContext
                onDragEnd={({ source, destination }) => {
                    if (source && destination) {
                        onOrderChange({
                            sourceIndex: source.index,
                            destinationIndex: destination.index,
                        });
                    }
                }}
            >
                <Droppable droppableId="column-settings-drop-zone">
                    {(dropZone) => (
                        <section {...dropZone.droppableProps} ref={dropZone.innerRef}>
                            {filteredInteractiveColumnSettings.map(
                                ({ label, value, isChecked, isHidden, disabled }, i) => (
                                    <Draggable
                                        key={value}
                                        draggableId={value}
                                        index={i}
                                        isDragDisabled={disabled}
                                    >
                                        {(draggable, { isDragging }) => (
                                            <div
                                                ref={draggable.innerRef}
                                                {...draggable.draggableProps}
                                                {...draggable.dragHandleProps}
                                                className={appendClasses([
                                                    'column-manager-list_item',
                                                    isHidden
                                                        ? 'column-manager-list_item_hidden'
                                                        : '',
                                                    isDragging
                                                        ? 'column-manager-list_item_dragging'
                                                        : '',
                                                ])}
                                            >
                                                <Checkbox
                                                    name={value}
                                                    label={label}
                                                    value={isChecked}
                                                    disabled={disabled}
                                                    onChange={(_, checkedValue) =>
                                                        onVisibilityChange({
                                                            value,
                                                            isChecked: !!checkedValue,
                                                        })
                                                    }
                                                />
                                                <DraggableIcon className="draggable-icon" />
                                            </div>
                                        )}
                                    </Draggable>
                                ),
                            )}
                            {dropZone.placeholder}
                        </section>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};
