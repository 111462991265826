import Form from '@components/form/Form';
import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import './SwitchAccountModal.scss';

const SwitchAccountModal = ({
    isVisible,
    onClose,
}: {
    isVisible: boolean;
    onClose: () => void;
}) => {
    const { user, history } = useApp();

    const { account } = useSparkplugAccount();

    const onSwitch = (newAccountId: string) => {
        const path = window.location.pathname;

        const newUrl =
            account && path.includes(account?._id) && !path.includes('control-center')
                ? path.replace(account?._id, newAccountId)
                : `/${newAccountId}/dashboard`;

        history.push(newUrl);
        onClose();
    };

    if (user?.role !== 'super-admin') {
        if (user?.accounts == null || user?.accounts.length < 2) {
            return null;
        }
    }

    return (
        <Modal className="switch-account-modal" isVisible={isVisible} onClose={onClose}>
            <Modal.Title onClose={onClose}>Switch Account</Modal.Title>

            <Modal.Content>
                <Form>
                    <Form.Select
                        searchable
                        label="Accounts"
                        value={account?._id || ''}
                        options={user.accounts}
                        onChange={(event) => {
                            onSwitch(event.target.value);
                        }}
                    />
                </Form>
            </Modal.Content>

            <Modal.Actions />
        </Modal>
    );
};

export default SwitchAccountModal;
