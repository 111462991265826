import { FC, useState } from 'react';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import { MarketsFilter } from '@features/account-links/components/MarketsFilter';
import TagAssistCallout from '@features/account-links/components/TagAssistCallout';
import TagAssistDisableModal from '@features/account-links/components/TagAssistDisableModal';
import TagAssistEnableModal from '@features/account-links/components/TagAssistEnableModal';

import { useAdminAccountUpdate } from '@contexts/AccountContext/AccountAdminMutations';

import Form from '@components/form/Form';
import toast from '@components/toast';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { InputEventOnChange } from '@app/types/UITypes';

import { useAccountLinkTableFilters } from '../../hooks/useAccountLinkTableFilters';
import { useShowTagAssistantUI } from './useShowTagAssistantUI';

import './AccountLinksTableHeader.scss';

const getStatusFilterOptions = () => {
    const autoLinkingFeatureEnabled = process.env.REACT_APP_AUTO_LINKING === 'true';

    if (autoLinkingFeatureEnabled) {
        return [
            { label: 'All Statuses', value: 'all' },
            { label: 'Enabled', value: 'enabled' },
            { label: 'Disabled', value: 'disabled' },
        ];
    }

    return [
        { label: 'All Statuses', value: 'all' },
        { label: 'Requested', value: 'pending' },
        { label: 'Disabled', value: 'disabled' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Enabled', value: 'enabled' },
        { label: 'Unmapped', value: 'unmapped' },
    ];
};

export interface AccountLinksTableHeaderProps {
    searchFilter?: string;
    onChangeSearchFilter: InputEventOnChange;
    isInboxView?: boolean;
}
export const AccountLinksTableHeader: FC<AccountLinksTableHeaderProps> = ({
    searchFilter,
    onChangeSearchFilter,
    isInboxView = false,
}) => {
    const { account, refetchAccount, userCan } = useSparkplugAccount();
    const { appIsMobile } = useApp();
    const [showEnableTagAssistantModal, setShowEnableTagAssistantModal] = useState<boolean>(false);
    const [showDisableTagAssistantModal, setShowDisableTagAssistantModal] =
        useState<boolean>(false);

    const { statusFilter, setStatusFilter } = useAccountLinkTableFilters();

    const { adminUpdateAccountAsync } = useAdminAccountUpdate(account?._id ?? '');
    const enableAssistant = () => {
        toast.promise(
            adminUpdateAccountAsync(
                {
                    tagAssistantEnabled: true,
                },
                {
                    onSuccess: () => {
                        refetchAccount();
                    },
                    onSettled: () => {
                        setShowEnableTagAssistantModal(false);
                    },
                },
            ),
            {
                loading: 'Enabling Tag Assistant...',
                success: 'Tag Assistant enabled',
                error: 'Something went wrong - please try again and contact support if this issue persists.',
            },
        );
    };
    const userCanManageProductTags = userCan('manageProductTags');

    const { showTagAssistantToggle, showTagAssistantCallout } = useShowTagAssistantUI();

    return (
        <>
            {showTagAssistantCallout && (
                <TagAssistCallout
                    showModal={() => setShowEnableTagAssistantModal(true)}
                    autoplayAnimation
                    accountId={account?._id}
                    tagAssistantEnabledAt={account?.tagAssistantEnabledAt}
                />
            )}
            <div className="account-links-header">
                <Toolbar className="account-links-toolbar" scrollOnMobile>
                    <Toolbar.Search
                        name="search"
                        defaultValue={searchFilter}
                        onChange={onChangeSearchFilter}
                    />
                    <Toolbar.Text className="account-markets toolbar-group-start">
                        <MarketsFilter />
                    </Toolbar.Text>
                    {!isInboxView && (
                        <Toolbar.Dropdown
                            className="status-dropdown"
                            label={null}
                            options={getStatusFilterOptions()}
                            value={statusFilter}
                            onSelect={(value) => setStatusFilter(value)}
                        />
                    )}
                </Toolbar>

                {showTagAssistantToggle && !appIsMobile && (
                    <Form.Switch
                        label={
                            <span
                                className={`tag-assistant-switch ${
                                    !account?.tagAssistantEnabled ? 'disabled' : ''
                                }`}
                            >
                                Tag Assistant
                            </span>
                        }
                        value={Boolean(account?.tagAssistantEnabled)}
                        color="purple"
                        showIcon
                        disabled={!userCanManageProductTags}
                        tooltip={
                            !userCanManageProductTags ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined
                        }
                        onChange={() => {
                            if (account?.tagAssistantEnabled) {
                                setShowDisableTagAssistantModal(true);
                            } else {
                                setShowEnableTagAssistantModal(true);
                            }
                        }}
                    />
                )}
                <TagAssistDisableModal
                    accountId={account?._id}
                    isVisible={showDisableTagAssistantModal}
                    onClose={() => setShowDisableTagAssistantModal(false)}
                />
                <TagAssistEnableModal
                    onClose={() => setShowEnableTagAssistantModal(false)}
                    onConfirm={enableAssistant}
                    isVisible={showEnableTagAssistantModal}
                />
            </div>
        </>
    );
};
