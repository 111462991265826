const ErrorWarningLogger = (name) => {
    return {
        e() {
            // eslint-disable-next-line
            this.error(...arguments);
        },
        error() {
            if (arguments.length === 1) {
                // eslint-disable-next-line
                console.log(`${name}|error: ${arguments[0]}`);
            } else {
                // eslint-disable-next-line
                // console.log(...[`${name}|error:`, ...arguments]);
            }
        },
        w() {
            // eslint-disable-next-line
            this.warn(...arguments);
        },
        warn() {
            if (arguments.length === 1) {
                // eslint-disable-next-line
                console.log(`${name}|warning: ${arguments[0]}`);
            } else {
                // eslint-disable-next-line
                console.log(...[`${name}|warning:`, ...arguments]);
            }
        },
    };
};

const DebugInfoLogger = (name) => {
    return {
        d() {
            // eslint-disable-next-line
            this.debug(...arguments);
        },
        debug() {
            // eslint-disable-next-line
            if (arguments.length === 1) {
                // eslint-disable-next-line
                console.log(`${name}|debug: ${arguments[0]}`);
            } else {
                // eslint-disable-next-line
                console.log(...[`${name}|debug:`, ...arguments]);
            }
        },
        i() {
            // eslint-disable-next-line
            this.info(...arguments);
        },
        info() {
            // eslint-disable-next-line
            if (arguments.length === 1) {
                // eslint-disable-next-line
                console.log(`${name}|info: ${arguments[0]}`);
            } else {
                // eslint-disable-next-line
                console.log(...[`${name}|info:`, ...arguments]);
            }
        },
    };
};

const DebugInfoNoop = () => {
    return {
        i() {},
        d() {},
    };
};

const factory = (name) => {
    if (process.env.REACT_APP_ENV === 'prod') {
        return Object.assign(ErrorWarningLogger(name), DebugInfoNoop());
    } else {
        return Object.assign(ErrorWarningLogger(name), DebugInfoLogger(name));
    }
};

module.exports = factory;
