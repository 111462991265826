import { FC, useMemo, useState } from 'react';

import { uniq } from 'lodash';

import { RetailerSparkFilters, Spark, SparkCommission } from '@sparkplug/lib';

import { useVendorRetailerDataPermissions } from '@features/account-links';

import toast from '@components/toast';

import { useModal } from '@hooks/ModalHooks';
import { useSpark } from '@hooks/SparksHooks';

import { IPosProduct } from '@app/types/PosTypes';

import {
    PosRulesBasedProductSelectorTable,
    usePosRulesBasedProductTableFilters,
} from '../../../PosRulesBasedSparkProductSelectorTable';
import { SINGLE_RETAILER_INTERCOM_CALLOUT_MESSAGE } from '../../../constants';

import './SingleRetailerPosRulesBasedProductSelector.scss';

const ModalNext: FC<{
    vendorPosFilters: RetailerSparkFilters;
    selectedProducts: IPosProduct[];
    allProducts: IPosProduct[];
}> = ({ vendorPosFilters, selectedProducts, allProducts }) => {
    const { spark, sparkCommissionMap, updateSpark } = useSpark();
    const { updateValidationFn, updateBackFn } = useModal();

    const applySparkProducts = () => {
        const selectedProductIds = selectedProducts.map(({ _id }) => _id);
        let retailerFilters = vendorPosFilters;
        if (vendorPosFilters.posBrandIds.length < 1) {
            retailerFilters = {
                ...vendorPosFilters,
                posBrandIds: uniq(allProducts.map(({ brandId }) => brandId)),
            };
        }

        const updatedSparkProperties: Pick<
            Spark,
            | 'posBrandIds'
            | 'posCategoryIds'
            | 'posProductIds'
            | 'productFilters'
            | 'commissions'
            | 'vendorFilters'
            | 'retailerFilters'
        > = {
            posBrandIds: [],
            posCategoryIds: [],
            posProductIds: selectedProductIds,
            productFilters: {},
            commissions: [],
            retailerFilters,
            vendorFilters: undefined,
        };

        if (spark.type === 'commission') {
            updatedSparkProperties.commissions = selectedProductIds.map((productId) => {
                const commissionItem = sparkCommissionMap?.get(productId);

                return {
                    posProductId: productId,
                    type: 'flat',
                    value: commissionItem ? commissionItem.value : undefined,
                } as SparkCommission;
            });
        }

        updateSpark(updatedSparkProperties);
    };

    updateValidationFn(() => {
        const validation = [
            () => {
                if (
                    !vendorPosFilters.posBrandIds.length &&
                    !vendorPosFilters.posCategoryIds.length &&
                    !vendorPosFilters.productNameFilters.length &&
                    !vendorPosFilters.productNameContains.length &&
                    !vendorPosFilters.productNameDoesNotContain.length
                ) {
                    toast.error('Select at least one product rule to proceed.');
                    return false;
                }

                return true;
            },
            () => {
                if (!selectedProducts.length) {
                    toast.error('Select products to proceed.');
                    return false;
                }

                return true;
            },
        ];

        const allValid = validation.every((fn) => fn());

        if (allValid) {
            requestAnimationFrame(() => {
                applySparkProducts();
            });
        }

        return allValid;
    });
    updateBackFn(() => {
        requestAnimationFrame(() => {
            applySparkProducts();
        });
        return true;
    });
    return <></>;
};

interface SingleRetailerPosRulesBasedProductSelectorProps {
    products: IPosProduct[];
}

const SingleRetailerPosRulesBasedProductSelector: FC<
    SingleRetailerPosRulesBasedProductSelectorProps
> = ({ products }) => {
    const { spark } = useSpark();

    const initialFilters = useMemo(() => {
        return (() => {
            if (spark.retailerFilters) return spark.retailerFilters;
            return {};
        })();
    }, [spark.retailerFilters, products, spark._id]);

    const { shareSalesData } = useVendorRetailerDataPermissions({
        vendorAccountId: spark.originatorGroupId,
        retailerAccountId: spark.groupId,
    });

    const tableFilters = usePosRulesBasedProductTableFilters({
        isEditingExistingSpark: !!spark._id,
        initialFilters,
        sparkStartDate: spark.startDate,
    });

    const [formHasErrorState, setFormHasErrorState] = useState(false);
    const resetFormErrorState = () => {
        setFormHasErrorState(false);
    };

    const selectedProducts = useMemo(
        () => tableFilters.getRulesBasedFilteredProducts(products),
        [products, tableFilters],
    );

    const vendorPosFilters = useMemo<RetailerSparkFilters>(() => {
        const {
            brands,
            categories,
            lastSoldAt,
            hideSampleProducts,
            productNameFilters,
            productNameContains,
            productNameDoesNotContain,
            excludedProductIds,
        } = tableFilters.filters;

        return {
            primaryFilter: 'brands',
            posBrandIds: brands.map(({ value }) => value),
            posCategoryIds: categories.map(({ value }) => value),
            lastSoldAt,
            hideSampleProducts,
            productNameFilters,
            productNameContains,
            productNameDoesNotContain,
            excludedProductIds,
        };
    }, [tableFilters.filters]);

    const learnMoreUrl =
        'https://help.sparkplug.app/en/articles/8183275-using-smart-filters-to-build-sparks';

    return (
        <PosRulesBasedProductSelectorTable
            calloutProps={{
                className: 'filters-callout',
                message: (
                    <>
                        {`${SINGLE_RETAILER_INTERCOM_CALLOUT_MESSAGE} `}
                        <a href={learnMoreUrl} target="_blank" rel="noreferrer">
                            Learn more
                        </a>
                    </>
                ),
            }}
            showPricesColumn={shareSalesData}
            products={products}
            tableFilters={tableFilters}
            ModalNext={() => (
                <ModalNext
                    vendorPosFilters={vendorPosFilters}
                    selectedProducts={selectedProducts}
                    allProducts={products}
                />
            )}
            formHasErrorState={formHasErrorState}
            resetFormErrorState={resetFormErrorState}
        />
    );
};

export default SingleRetailerPosRulesBasedProductSelector;
