import { FC, ReactNode, createContext, useContext, useMemo } from 'react';

import { keyBy } from 'lodash';

import { BucketsKeyedByLabel, FullChartResponseBody, HydratedPosProduct } from '@sparkplug/lib';

import { useChartFactories } from '@core/charts/hooks/ChartsHooks';

import { useVendorRetailerPosData } from '@features/product-tags';

import { useChartSettings, useCloudChartDataQuery } from '@hooks/ChartDataHooks';
import { useAccountPosDataQuery, useAppAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import BucketFactory from '@helpers/visualization/buckets/BucketFactory';
import DayBucketFactory from '@helpers/visualization/buckets/DayBucketFactory';

import { IChartDataSettings } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

export interface ChartContextValue extends ReturnType<typeof useChartSettings> {
    chartIsReady: boolean;
    cloudChartData: FullChartResponseBody | Partial<FullChartResponseBody>;
    chartLocations: IPosLocation[];
    chartProducts: HydratedPosProduct[];
    chartValueFormatter: (value: number) => string;
    chartBucketFactory: BucketFactory;
    bucketsKeyedByLabel?: BucketsKeyedByLabel;
}

export const DEFAULT_CHART_CONTEXT_VALUE: ChartContextValue = {
    chartIsReady: true,
    cloudChartData: {},
    updateChartSettings: () => {},
    onUpdateChartSetting: () => () => {},
    chartSettings: {} as IChartDataSettings,
    chartLocations: [],
    chartProducts: [],
    chartValueFormatter: () => '',
    chartBucketFactory: new DayBucketFactory(),
};
export const ChartContext = createContext<ChartContextValue>(DEFAULT_CHART_CONTEXT_VALUE);

export const useChartContext = () => {
    const context = useContext(ChartContext);

    if (!context) {
        throw new Error('useChartContext must be used within a ChartProvider');
    }

    return context;
};

export const useSharedChartContextValues = ({
    initialSettings,
}: {
    initialSettings: IChartDataSettings;
}) => {
    const chartSettingsValues = useChartSettings(initialSettings);
    const chartFactoriesValues = useChartFactories(chartSettingsValues.chartSettings);

    return {
        ...chartSettingsValues,
        ...chartFactoriesValues,
    };
};

/**
 * @description
 *
 * We need to fetch different products depending on whether the current account is a vendor account or not.
 */
const useChartProducts = ({
    retailerAccountId,
    isEnabled,
}: {
    retailerAccountId: string;
    isEnabled: boolean;
}) => {
    const { account } = useAppAccount();
    const appAccountIsVendorAccount = account?.type === 'brand';
    const { vendorRetailerPosData, vendorRetailerPosDataIsReady } = useVendorRetailerPosData(
        (appAccountIsVendorAccount ? account?._id : undefined) ?? '',
        retailerAccountId,
        isEnabled && appAccountIsVendorAccount,
    );
    // if this is a spark chart, we only need the products that are in the spark and can avoid loading _all_ products for the retailer
    const { spark } = useSpark();
    const { accountPosProducts, accountPosProductsDataIsReady } = useAccountPosDataQuery(
        retailerAccountId,
        {
            isEnabled: isEnabled && !appAccountIsVendorAccount,
            includedDatasets: ['products'],
        },
        spark?.posProductIds,
    );

    const chartProductsAreReady = appAccountIsVendorAccount
        ? vendorRetailerPosDataIsReady
        : accountPosProductsDataIsReady;

    const chartProducts = useMemo(() => {
        if (appAccountIsVendorAccount) {
            return Object.values(vendorRetailerPosData?.productsVendorBrandMap ?? {}).flatMap(
                ({ products }) => products,
            );
        }

        return accountPosProducts;
    }, [chartProductsAreReady]);

    return {
        chartProductsAreReady,
        chartProducts,
    };
};

interface ChartProviderProps {
    retailerAccountId: string;
    initialSettings: IChartDataSettings;
    children: ReactNode;
}
export const ChartProvider: FC<ChartProviderProps> = ({
    retailerAccountId,
    initialSettings,
    children,
}) => {
    const {
        onUpdateChartSetting,
        updateChartSettings,
        chartSettings,
        chartValueFormatter,
        chartBucketFactory,
    } = useSharedChartContextValues({ initialSettings });
    // TODO: new chart endpoints currently do not support sparks, or some metrics yet
    const metricNotV2Supoorted = !['total_units', 'total_sales'].includes(chartSettings.metric);
    const breakdownType =
        chartSettings.sparkId || metricNotV2Supoorted ? undefined : chartSettings.breakdown;
    const { isFetched, data } = useCloudChartDataQuery(
        retailerAccountId,
        chartSettings,
        true,
        breakdownType,
    );
    const { accountAllPosLocations, accountPosLocationsDataIsReady } = useAccountPosDataQuery(
        retailerAccountId,
        {
            isEnabled: true,
            includedDatasets: ['locations'],
        },
    );
    const breakdownRequiresProducts = ['product', 'brand', 'category'].includes(
        chartSettings.breakdown,
    );
    const { chartProducts, chartProductsAreReady } = useChartProducts({
        retailerAccountId,
        isEnabled: breakdownRequiresProducts,
    });

    const breakdownDataIsReady = breakdownRequiresProducts
        ? chartProductsAreReady
        : accountPosLocationsDataIsReady;

    const chartIsReady = Boolean(isFetched && !!data && breakdownDataIsReady);

    const value = useMemo<ChartContextValue>(() => {
        const accountLocationsById = keyBy(accountAllPosLocations, '_id');

        return {
            onUpdateChartSetting,
            updateChartSettings,
            chartSettings,
            chartIsReady,
            cloudChartData: (data as FullChartResponseBody) ?? {},
            chartLocations: chartSettings.locationIds
                .filter((locationId) => accountLocationsById[locationId])
                .map((locationId) => accountLocationsById[locationId]),
            chartProducts,
            chartValueFormatter,
            chartBucketFactory,
        };
    }, [
        chartIsReady,
        chartSettings,
        onUpdateChartSetting,
        data,
        accountAllPosLocations,
        chartProducts,
    ]);

    return <ChartContext.Provider value={value}>{children}</ChartContext.Provider>;
};
