import React, { ReactElement } from 'react';

import { ReactComponent as Alabama } from './mdi_alabama.svg';
import { ReactComponent as Alaska } from './mdi_alaska.svg';
import { ReactComponent as Arizona } from './mdi_arizona.svg';
import { ReactComponent as Arkansas } from './mdi_arkansas.svg';
import { ReactComponent as California } from './mdi_california.svg';
import { ReactComponent as Colorado } from './mdi_colorado.svg';
import { ReactComponent as Connecticut } from './mdi_connecticut.svg';
import { ReactComponent as Delaware } from './mdi_delaware.svg';
import { ReactComponent as Florida } from './mdi_florida.svg';
import { ReactComponent as Georgia } from './mdi_georgia.svg';
import { ReactComponent as Hawaii } from './mdi_hawaii.svg';
import { ReactComponent as Idaho } from './mdi_idaho.svg';
import { ReactComponent as Illinois } from './mdi_illinois.svg';
import { ReactComponent as Indiana } from './mdi_indiana.svg';
import { ReactComponent as Iowa } from './mdi_iowa.svg';
import { ReactComponent as Kansas } from './mdi_kansas.svg';
import { ReactComponent as Kentucky } from './mdi_kentucky.svg';
import { ReactComponent as Louisiana } from './mdi_louisiana.svg';
import { ReactComponent as Maine } from './mdi_maine.svg';
import { ReactComponent as Maryland } from './mdi_maryland.svg';
import { ReactComponent as Massachusetts } from './mdi_massachusetts.svg';
import { ReactComponent as Michigan } from './mdi_michigan.svg';
import { ReactComponent as Minnesota } from './mdi_minnesota.svg';
import { ReactComponent as Mississippi } from './mdi_mississippi.svg';
import { ReactComponent as Missouri } from './mdi_missouri.svg';
import { ReactComponent as Montana } from './mdi_montana.svg';
import { ReactComponent as Nebraska } from './mdi_nebraska.svg';
import { ReactComponent as Nevada } from './mdi_nevada.svg';
import { ReactComponent as NewHampshire } from './mdi_new_hampshire.svg';
import { ReactComponent as NewJersey } from './mdi_new_jersey.svg';
import { ReactComponent as NewMexico } from './mdi_new_mexico.svg';
import { ReactComponent as NewYork } from './mdi_new_york.svg';
import { ReactComponent as NorthCarolina } from './mdi_north_carolina.svg';
import { ReactComponent as NorthDakota } from './mdi_north_dakota.svg';
import { ReactComponent as Ohio } from './mdi_ohio.svg';
import { ReactComponent as Oklahoma } from './mdi_oklahoma.svg';
import { ReactComponent as Oregon } from './mdi_oregon.svg';
import { ReactComponent as Pennsylvania } from './mdi_pennsylvania.svg';
import { ReactComponent as RhodeIsland } from './mdi_rhode_island.svg';
import { ReactComponent as SouthCarolina } from './mdi_south_carolina.svg';
import { ReactComponent as SouthDakota } from './mdi_south_dakota.svg';
import { ReactComponent as Tennessee } from './mdi_tennessee.svg';
import { ReactComponent as Texas } from './mdi_texas.svg';
import { ReactComponent as Utah } from './mdi_utah.svg';
import { ReactComponent as Vermont } from './mdi_vermont.svg';
import { ReactComponent as Virginia } from './mdi_virginia.svg';
import { ReactComponent as Washington } from './mdi_washington.svg';
import { ReactComponent as WestVirginia } from './mdi_west_virginia.svg';
import { ReactComponent as Wisconsin } from './mdi_wisconsin.svg';
import { ReactComponent as Wyoming } from './mdi_wyoming.svg';

const icons: { [key: string]: ReactElement } = {
    Alabama: <Alabama />,
    Alaska: <Alaska />,
    Arizona: <Arizona />,
    Arkansas: <Arkansas />,
    California: <California />,
    Colorado: <Colorado />,
    Connecticut: <Connecticut />,
    Delaware: <Delaware />,
    Florida: <Florida />,
    Georgia: <Georgia />,
    Hawaii: <Hawaii />,
    Idaho: <Idaho />,
    Illinois: <Illinois />,
    Indiana: <Indiana />,
    Iowa: <Iowa />,
    Kansas: <Kansas />,
    Kentucky: <Kentucky />,
    Louisiana: <Louisiana />,
    Maine: <Maine />,
    Maryland: <Maryland />,
    Massachusetts: <Massachusetts />,
    Michigan: <Michigan />,
    Minnesota: <Minnesota />,
    Mississippi: <Mississippi />,
    Missouri: <Missouri />,
    Montana: <Montana />,
    Nebraska: <Nebraska />,
    Nevada: <Nevada />,
    NewHampshire: <NewHampshire />,
    NewJersey: <NewJersey />,
    NewMexico: <NewMexico />,
    NewYork: <NewYork />,
    NorthCarolina: <NorthCarolina />,
    NorthDakota: <NorthDakota />,
    Ohio: <Ohio />,
    Oklahoma: <Oklahoma />,
    Oregon: <Oregon />,
    Pennsylvania: <Pennsylvania />,
    RhodeIsland: <RhodeIsland />,
    SouthCarolina: <SouthCarolina />,
    SouthDakota: <SouthDakota />,
    Tennessee: <Tennessee />,
    Texas: <Texas />,
    Utah: <Utah />,
    Vermont: <Vermont />,
    Virginia: <Virginia />,
    Washington: <Washington />,
    WestVirginia: <WestVirginia />,
    Wisconsin: <Wisconsin />,
    Wyoming: <Wyoming />,
};
const MarketIcon = ({ marketName }: { marketName: string }) => {
    return icons[marketName.replace(' ', '')] ?? null;
};

export default MarketIcon;
