import { Dispatch, FC, SetStateAction, useMemo, useState } from 'react';

import { ClickAwayListener } from '@mui/material';

import {
    AccountSparkBrand,
    BrandProductTagGroup,
    DO_NOT_HAVE_PERMISSIONS_MESSAGE,
    TAG_ASSISTANT_GROUPS_DISABLED_MESSAGE,
} from '@sparkplug/lib';

import { useCreateBrandTagGroup } from '@features/product-tags/mutations';
import { useBrandTagGroups } from '@features/product-tags/queries';

import { Add as AddIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Skeleton from '@components/layout/Skeleton';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { CreateEditTagGroupForm } from './CreateEditTagGroupForm';
import { CreateNewGroupButton } from './CreateNewGroupButton';
import { TagGroup } from './TagGroup/TagGroup';
import { ReactComponent as EmptyStateGraphic } from './TagGroupEmptyState.svg';

import './TagGroupsWidget.scss';

const TagGroupsEmptyState = ({
    setIsCreating,
    userCanManageProductTags,
}: {
    setIsCreating: Dispatch<SetStateAction<boolean>>;
    userCanManageProductTags?: boolean;
}) => {
    return (
        <div className="tag-groups-empty-state">
            <EmptyStateDisplay
                graphic={<EmptyStateGraphic />}
                label="No Tag Groups Set Up"
                smallText={
                    <span>{`You haven't created any tag groups for organizing product tags yet.`}</span>
                }
                actionButton={{
                    startIcon: <AddIcon />,
                    label: 'Create New Group',
                    onClick: () => setIsCreating(true),
                    disabled: !userCanManageProductTags,
                    tooltip: !userCanManageProductTags
                        ? DO_NOT_HAVE_PERMISSIONS_MESSAGE
                        : undefined,
                }}
            />
        </div>
    );
};

const TagGroupsLoadingState: FC = () => {
    return <Skeleton height={480} />;
};

interface TagGroupsWidgetProps {
    brands: AccountSparkBrand[];
    selectedBrandId: string;
}
export const TagGroupsWidget: FC<TagGroupsWidgetProps> = ({ brands, selectedBrandId }) => {
    const [isCreatingCreatingGroup, setIsCreatingCreatingGroup] = useState(false);
    const [newTagGroupNameValue, setNewTagGroupNameValue] = useState<string>('');
    const { userCan, account, accountIsReady } = useSparkplugAccount();
    const { brandTagGroupsAreReady, brandTagGroups = [] } = useBrandTagGroups(selectedBrandId);
    const { isCreatingBrandTagGroup, createBrandTagGroup } = useCreateBrandTagGroup(
        selectedBrandId ?? '',
    );
    const isLoading = !brandTagGroupsAreReady || !accountIsReady;
    const isEmpty = useMemo(() => !brandTagGroups?.length, [brandTagGroups]);
    const userCanManageProductTags = userCan('manageProductTags');

    const createTagGroupTooltip = useMemo(() => {
        if (!userCanManageProductTags) {
            return DO_NOT_HAVE_PERMISSIONS_MESSAGE;
        }
        if (account?.tagAssistantEnabled) {
            return TAG_ASSISTANT_GROUPS_DISABLED_MESSAGE;
        }
        return undefined;
    }, [userCanManageProductTags, account?.tagAssistantEnabled]);

    if (isLoading) {
        return <TagGroupsLoadingState />;
    }

    const handleCancelCreateNewGroup = () => {
        setNewTagGroupNameValue('');
        setIsCreatingCreatingGroup(false);
    };
    const handleCreateBrandTagGroup = (value: string) => {
        if (isCreatingBrandTagGroup || !newTagGroupNameValue) {
            return;
        }
        createBrandTagGroup(
            {
                body: { name: newTagGroupNameValue },
            },
            {
                onSuccess: () => {
                    toast.success('Group saved');
                    handleCancelCreateNewGroup();
                },
                onError: ({ response }: any) => {
                    toast.error(
                        response.data.details ?? 'Something went wrong - please try again.',
                    );
                    handleCancelCreateNewGroup();
                },
            },
        );
    };

    return (
        <div className="tag-groups-widget_container">
            {isEmpty && !isCreatingCreatingGroup ? (
                <TagGroupsEmptyState
                    userCanManageProductTags={userCanManageProductTags}
                    setIsCreating={setIsCreatingCreatingGroup}
                />
            ) : (
                <>
                    {brandTagGroups.map((tagGroup: BrandProductTagGroup) => (
                        <TagGroup
                            key={tagGroup._id}
                            brands={brands}
                            selectedBrandId={selectedBrandId}
                            userCanManageProductTags={userCanManageProductTags}
                            tagAssistantEnabled={Boolean(account?.tagAssistantEnabled)}
                            {...tagGroup}
                        />
                    ))}
                    <ClickAwayListener onClickAway={() => handleCancelCreateNewGroup()}>
                        <div>
                            {isCreatingCreatingGroup ? (
                                <CreateEditTagGroupForm
                                    selectedBrandId={selectedBrandId || ''}
                                    value={newTagGroupNameValue}
                                    onCancel={() => handleCancelCreateNewGroup()}
                                    onChange={(val) => {
                                        setNewTagGroupNameValue(val);
                                    }}
                                    onSave={(value) => handleCreateBrandTagGroup(value)}
                                />
                            ) : (
                                brandTagGroups.length < 3 && (
                                    <CreateNewGroupButton
                                        disabled={
                                            !userCanManageProductTags ||
                                            Boolean(account?.tagAssistantEnabled)
                                        }
                                        tooltip={createTagGroupTooltip}
                                        setIsCreating={setIsCreatingCreatingGroup}
                                    />
                                )
                            )}
                        </div>
                    </ClickAwayListener>
                </>
            )}
        </div>
    );
};
