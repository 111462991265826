import { FC, useMemo, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { keyBy } from 'lodash';
import moment from 'moment';
import pluralize from 'pluralize';

import {
    BrandProductTagGroup,
    HydratedBrandProduct,
    MultiRetailerSparkParticipantGroup,
} from '@sparkplug/lib';

import {
    useAccountLinks,
    useIsInitializingAllVendorRetailerPosData,
    useVendorBrandRetailersByBrandId,
    useVendorBrandRetailersProductsByBrandId,
} from '@features/account-links';
import RetailerLinkDetails from '@features/account-links/components/RetailerLinkDetails';
import { RetailerAccountLink } from '@features/account-links/components/RetailerLinkDetails/RetailerLinkDetails';
import { getMissingTagsCount } from '@features/product-tags/utils';
import VendorRulesBasedProductSearchBar from '@features/spark-wizard/components/SparkProductSelector/VendorSparkProductSelector/VendorRulesBasedProductSelector/VendorRulesBasedProductSearchBar';

import Divider from '@components/Divider';
import Form from '@components/form/Form';
import { AlertIcon, ExpandMore, KeyboardArrowDown, KeyboardArrowUp } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import ClickableArea from '@components/layout/ClickableArea';
import LazyLoad from '@components/layout/LazyLoad';
import Skeleton from '@components/layout/Skeleton';

import { useSpark } from '@hooks/SparksHooks';

import { IPosLocation } from '@app/types/PosTypes';

import { MULTI_RETAILER_INTERCOM_CALLOUT_MESSAGE } from '../../../constants';
import { MULTI_RETAILER_LEARN_MORE_URL } from '../VendorRulesBasedProductSelectorConstants';
import VendorRulesBasedProductSelectorTableToolbar from '../VendorRulesBasedProductSelectorTableToolbar';
import VendorRulesBasedSparkProductSelectorTable from '../VendorRulesBasedSparkProductSelectorTable';
import { UseVendorRulesBasedSparkProductSelectorTableFilters } from '../useRulesBasedProductTableFilters';
import { ModalNext } from '../vendorRulesBasedModalNext';

import './MultiRetailerRulesBasedProductSelector.scss';

interface RetailerAccordionItemProps {
    isExpanded: boolean;
    matchingProducts: HydratedBrandProduct[];
    recentRetailerProducts: HydratedBrandProduct[];
    filteredProductsMissingTagCount: number;
    accountLinkCopy: RetailerAccountLink;
    handleAccordionChange: () => void;
    tableFilters: UseVendorRulesBasedSparkProductSelectorTableFilters;
}
const RetailerAccordionItem = ({
    isExpanded,
    matchingProducts,
    recentRetailerProducts,
    filteredProductsMissingTagCount,
    accountLinkCopy,
    handleAccordionChange,
    tableFilters,
}: RetailerAccordionItemProps) => {
    const { accountId: retailerAccountId } = accountLinkCopy;
    return (
        <LazyLoad
            className="lazy-load-wrapper_retailer-products-accordion"
            key={`${retailerAccountId}-${isExpanded}`}
            height={64}
            offset={100}
        >
            <Accordion
                className="retailer-products-accordion"
                key={retailerAccountId}
                id={`retailer-products-${retailerAccountId}`}
                onChange={handleAccordionChange}
                expanded={isExpanded}
            >
                <AccordionSummary expandIcon={<ExpandMore />}>
                    <RetailerLinkDetails accountLink={accountLinkCopy} />
                    <div data-testid="matching-products">
                        <span
                            className={`matching-products-count ${
                                matchingProducts.length === 0 ? ' no-products' : ''
                            }`}
                        >
                            {matchingProducts.length}{' '}
                            {pluralize('product', matchingProducts.length)} qualify
                        </span>{' '}
                        of {recentRetailerProducts.length} total
                        {filteredProductsMissingTagCount > 0 && (
                            <div className="missing-tag-warning">
                                <AlertIcon /> {filteredProductsMissingTagCount} missing tags
                            </div>
                        )}
                    </div>
                </AccordionSummary>
                <AccordionDetails data-testid={`multi-retailer-table-${retailerAccountId}`}>
                    <VendorRulesBasedSparkProductSelectorTable
                        isCreatingMultiRetailerSpark
                        retailerAccountId={retailerAccountId}
                        products={matchingProducts}
                        tableFilters={tableFilters}
                        filteredProductsMissingTagCount={filteredProductsMissingTagCount}
                    />
                </AccordionDetails>
            </Accordion>
        </LazyLoad>
    );
};

interface MultiRetailerRulesBasedProductSelectorProps {
    tableFilters: UseVendorRulesBasedSparkProductSelectorTableFilters;
    brandId: string;
    vendorAccountId: string;
    brandTagGroups?: BrandProductTagGroup[];
}

const MultiRetailerRulesBasedProductSelector: FC<MultiRetailerRulesBasedProductSelectorProps> = ({
    tableFilters,
    brandId,
    vendorAccountId,
    brandTagGroups,
}) => {
    const [accordionsExpanded, setAccordionsExpanded] = useState<Record<string, boolean>>({});
    const someAccordionsExpanded = Object.values(accordionsExpanded).some(
        (isExpanded) => isExpanded,
    );
    const { multiRetailerParticipantGroups, originalMultiRetailerParticipantGroups, spark } =
        useSpark();
    const { accountLinksAreReady, accountLinks } = useAccountLinks(vendorAccountId);
    const { filters, getRulesBasedFilteredProducts } = tableFilters;

    const isInitializingAllVendorRetailerPosData = useIsInitializingAllVendorRetailerPosData({
        vendorAccountId,
    });
    const {
        vendorBrandRetailerProductsAreReady: vendorBrandRetailerProductsAreInitialized,
        vendorBrandRetailerProducts,
    } = useVendorBrandRetailersProductsByBrandId({
        brandId,
        vendorAccountId,
        vendorBrandRetailerIds: (originalMultiRetailerParticipantGroups?.length > 0
            ? originalMultiRetailerParticipantGroups
            : multiRetailerParticipantGroups
        ).map(({ retailerAccountId }) => retailerAccountId ?? ''),
    });
    const vendorBrandRetailerProductsAreReady =
        vendorBrandRetailerProductsAreInitialized && !isInitializingAllVendorRetailerPosData;

    const { vendorBrandRetailers, vendorBrandRetailersAreReady } = useVendorBrandRetailersByBrandId(
        {
            brandId,
            vendorAccountId,
        },
    );

    const tableFiltersWithoutSearch = {
        ...tableFilters,
        applySearch: undefined,
    };

    const handleAccordionChange = (panel: string) => () => {
        setAccordionsExpanded((prevValue) => {
            const currentPanelValue = prevValue[panel] ?? false;

            return {
                ...prevValue,
                [panel]: !currentPanelValue,
            };
        });
    };

    const handleShowHideAll = () => {
        setAccordionsExpanded((prevValue) => {
            if (Object.values(prevValue).some((isExpanded) => isExpanded)) {
                return {};
            }

            return Object.fromEntries(
                Object.keys(vendorBrandRetailerProducts).map((id) => [id, true]),
            );
        });
    };

    const {
        withMatchingProducts: retailersWithMatchingProducts,
        withoutMatchingProducts: retailersWithoutMatchingProducts,
        updatedParticipantGroups,
        selectedProducts,
    } = useMemo(() => {
        const accountLinksByRetailerAccountId = keyBy(accountLinks, 'accountId');
        const multiRetailerParticipantGroupsByRetailerAccountId = keyBy(
            multiRetailerParticipantGroups,
            'retailerAccountId',
        );
        const vendorBrandRetailersByRetailerAccountId = keyBy(
            vendorBrandRetailers,
            'retailerAccountId',
        );

        const allRetailerProductTables = Object.entries(vendorBrandRetailerProducts).map(
            ([retailerAccountId, retailerProducts]) => {
                const recentRetailerProducts = retailerProducts.filter(({ lastSoldAt }) =>
                    moment(lastSoldAt).isSameOrAfter(
                        (moment(spark?.startDate).isSameOrBefore(moment())
                            ? moment(spark?.startDate)
                            : moment()
                        ).subtract(60, 'days'),
                        'day',
                    ),
                );
                const filteredProductsMissingTagCount = getMissingTagsCount(retailerProducts);
                const matchingProducts = getRulesBasedFilteredProducts(recentRetailerProducts);
                const accountLink = accountLinksByRetailerAccountId[retailerAccountId] ?? {};
                // The user can select individual locations from each retailer
                const currentRetailerParticipantGroup =
                    multiRetailerParticipantGroupsByRetailerAccountId[retailerAccountId];
                // But if we alter the original AccountLink, it will affect the retailer/location selector if we go "back", so we make a copy with the correct location count for display
                const accountLinkCopy: RetailerAccountLink = {
                    ...accountLink,
                    locations:
                        currentRetailerParticipantGroup?.participantGroups?.flatMap(
                            ({ locationIds }) =>
                                locationIds.map(
                                    (locationId) =>
                                        ({ _id: locationId, value: locationId } as IPosLocation),
                                ),
                        ) ?? [],
                    // we only want to count "active" locations for this prop
                    allLocations:
                        vendorBrandRetailersByRetailerAccountId?.[retailerAccountId]
                            ?.activeLocations?.length ?? 0,
                };

                return {
                    matchingProducts,
                    myGroup: currentRetailerParticipantGroup,
                    accountLinkCopy,
                    filteredProductsMissingTagCount,
                    recentRetailerProducts,
                };
            },
        );

        const withMatchingProducts = allRetailerProductTables.filter(({ matchingProducts }) => {
            return matchingProducts.length > 0;
        });

        const participantGroupsWithMatchingProducts = withMatchingProducts.map(
            ({ myGroup }) => myGroup,
        );

        return {
            updatedParticipantGroups: participantGroupsWithMatchingProducts,
            withMatchingProducts,
            withoutMatchingProducts: allRetailerProductTables.filter(({ matchingProducts }) => {
                return matchingProducts.length === 0;
            }),
            selectedProducts: allRetailerProductTables.flatMap(({ matchingProducts }) => {
                return matchingProducts;
            }),
        };
    }, [vendorBrandRetailerProducts, filters, accountLinksAreReady, vendorBrandRetailersAreReady]);

    return accountLinksAreReady && vendorBrandRetailerProductsAreReady ? (
        <div className="multi-retailer-product-selector">
            <CalloutMessage
                message={
                    <>
                        {`${MULTI_RETAILER_INTERCOM_CALLOUT_MESSAGE} `}
                        <a href={MULTI_RETAILER_LEARN_MORE_URL} target="_blank" rel="noreferrer">
                            Learn more
                        </a>
                    </>
                }
            />
            <VendorRulesBasedProductSelectorTableToolbar
                brandTagGroups={brandTagGroups}
                tableFilters={tableFiltersWithoutSearch}
                customClassName="multi-retailer-toolbar"
            />
            <div className="label-wrapper">
                <Form.Label className="no-margin">Qualifying Products (Last 60 days):</Form.Label>
                <ClickableArea className="action" onClick={handleShowHideAll}>
                    {someAccordionsExpanded ? 'Collapse' : 'Expand'} all
                    <div className={`icons ${someAccordionsExpanded ? 'collapse' : ''}`}>
                        <KeyboardArrowUp />
                        <KeyboardArrowDown />
                    </div>
                </ClickableArea>
            </div>
            <VendorRulesBasedProductSearchBar
                tableFilters={tableFiltersWithoutSearch}
                numRetailers={retailersWithMatchingProducts.length}
            />
            <div className="matching-products-wrapper">
                {retailersWithMatchingProducts.map(({ accountLinkCopy, ...sharedProps }) => (
                    <RetailerAccordionItem
                        key={accountLinkCopy.accountId}
                        isExpanded={accordionsExpanded[accountLinkCopy.accountId ?? ''] ?? false}
                        tableFilters={tableFilters}
                        accountLinkCopy={accountLinkCopy}
                        handleAccordionChange={handleAccordionChange(accountLinkCopy.accountId)}
                        {...sharedProps}
                    />
                ))}
            </div>
            {retailersWithoutMatchingProducts.length > 0 && (
                <>
                    <Divider
                        variant="error"
                        text="0 products qualify"
                        tooltipText="Retailers must have at least 1 qualifying product to participate. The following selected Retailers will not be included in this Spark based on the current rules applied."
                    />

                    <div className="non-matching-products-wrapper">
                        {retailersWithoutMatchingProducts.map(
                            ({ accountLinkCopy, ...sharedProps }) => (
                                <RetailerAccordionItem
                                    key={accountLinkCopy.accountId}
                                    isExpanded={
                                        accordionsExpanded[accountLinkCopy.accountId ?? ''] ?? false
                                    }
                                    tableFilters={tableFilters}
                                    accountLinkCopy={accountLinkCopy}
                                    handleAccordionChange={handleAccordionChange(
                                        accountLinkCopy.accountId,
                                    )}
                                    {...sharedProps}
                                />
                            ),
                        )}
                    </div>
                </>
            )}
            <ModalNext
                vendorFilters={tableFilters.filters}
                selectedProducts={selectedProducts}
                updatedParticipantGroups={
                    updatedParticipantGroups as MultiRetailerSparkParticipantGroup[]
                }
            />
        </div>
    ) : (
        <Skeleton height={400} />
    );
};

export default MultiRetailerRulesBasedProductSelector;
