import { ComponentType, ReactNode, createContext, useCallback, useContext, useMemo } from 'react';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

interface BrandOverrideContextValue {
    brandOverrideIsVisible: boolean;
    brandOverrideIsEnabled: boolean;
    updateBrandOverrideIsEnabled: (value: boolean) => void;
    toggleBrandOverride: () => void;
}
export const BrandOverrideContext = createContext<BrandOverrideContextValue>({
    brandOverrideIsVisible: false,
    brandOverrideIsEnabled: false,
    updateBrandOverrideIsEnabled: () => {},
    toggleBrandOverride: () => {},
});

/**
 * This is a provider for usage in the Spark Wizard for Vendor POS-Rules Based Sparks. Sometimes, the limited
 * products mapped to a particular Spark Brand are not sufficient for the Spark. In these cases, we will allow
 * super-admins to override the Pos Brand options and select from all products available to the Vendor across
 * all their Brands.
 */
export const BrandOverrideProvider = ({ children }: { children: ReactNode }) => {
    const { spark, updateSpark } = useSpark();
    const { account } = useSparkplugAccount();
    const { userIsSuperAdmin } = useApp();

    const isEditingSpark = !!spark?._id;
    const brandOverrideIsEnabled = spark?.brandOverrideIsEnabled ?? false;

    const updateBrandOverrideIsEnabled = (value: boolean) => {
        updateSpark({ brandOverrideIsEnabled: value });
    };

    const toggleBrandOverride = useCallback(() => {
        updateSpark({ brandOverrideIsEnabled: !brandOverrideIsEnabled });
    }, [brandOverrideIsEnabled]);

    const value = useMemo(
        () => ({
            brandOverrideIsVisible: account?.type === 'brand' && userIsSuperAdmin && isEditingSpark,
            brandOverrideIsEnabled,
            updateBrandOverrideIsEnabled,
            toggleBrandOverride,
        }),
        [
            account?.type,
            userIsSuperAdmin,
            isEditingSpark,
            brandOverrideIsEnabled,
            toggleBrandOverride,
        ],
    );

    return <BrandOverrideContext.Provider value={value}>{children}</BrandOverrideContext.Provider>;
};

export const useBrandOverride = () => useContext(BrandOverrideContext);

export const wrapWithBrandOverrideProvider = <T extends {}>(Component: ComponentType<T>) => {
    return (props: T) => (
        <BrandOverrideProvider>
            <Component {...props} />
        </BrandOverrideProvider>
    );
};
