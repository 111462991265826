import { FC } from 'react';

import { Spark } from '@sparkplug/lib';

import {
    MultiRetailerReviewTabs,
    SelectedRetailerPosData,
} from '@features/spark-wizard/components/MultiRetailerReviewTabs';

import SparkDetailCard from '@components/sparks/SparkDetailCard';
import SparkDetailsCard from '@components/sparks/SparkDetailsCard';

import { useSpark } from '@hooks/SparksHooks';

import { ISparkCommissionMap } from '@app/types/SparksTypes';

import SparkWizardSnapPanel from '../SparkWizardSnapPanel';

import './CommissionReview.scss';

interface CommissionReviewProps {
    spark: Spark;
    sparkPosData: SelectedRetailerPosData;
    sparkCommissionMap?: ISparkCommissionMap;
    reviewDataIsReady: boolean;
}
const CommissionReview: FC<CommissionReviewProps> = ({
    spark,
    sparkPosData,
    sparkCommissionMap,
    reviewDataIsReady,
}) => {
    const { locations, participants, brands, categories } = sparkPosData;

    const brandsRows =
        brands != null && brands.length > 0
            ? brands
            : [
                  {
                      label: 'All Brands',
                  },
              ];

    const categoriesRows =
        categories != null && categories.length > 0
            ? categories
            : [
                  {
                      label: 'All Categories',
                  },
              ];

    const commissionItems =
        sparkCommissionMap != null
            ? Array.from(sparkCommissionMap.values())
                  .filter((item: any) => item.enabled)
                  .map((item: any) => {
                      const amount =
                          (item.type === 'flat' ? '$' : '') +
                          item.value +
                          (item.type === 'percentage' ? '%' : '');
                      return {
                          label: `${item.name} - ${amount}`,
                      };
                  })
            : [];

    const isLoading = !reviewDataIsReady;

    return (
        <div className="review-container">
            <SparkDetailsCard spark={spark} />

            <SparkWizardSnapPanel />

            <SparkDetailCard title="Locations" rows={locations} isLoading={isLoading} />

            <SparkDetailCard title="Participants" rows={participants} isLoading={isLoading} />

            <SparkDetailCard title="Brands" rows={brandsRows} isLoading={isLoading} />

            <SparkDetailCard title="Categories" rows={categoriesRows} isLoading={isLoading} />

            <SparkDetailCard title="Products" rows={commissionItems} isLoading={isLoading} />
        </div>
    );
};

export default () => {
    const {
        spark,
        sparkPosData,
        sparkPosDataIsReady,
        sparkCommissionMap,
        isCreatingMultiRetailerSpark,
    } = useSpark();

    if (isCreatingMultiRetailerSpark) {
        return (
            <MultiRetailerReviewTabs>
                {({
                    selectedRetailerPosData,
                    selectedRetailerCommissionMap,
                    reviewDataIsReady,
                }) => (
                    <CommissionReview
                        spark={spark}
                        sparkPosData={selectedRetailerPosData}
                        sparkCommissionMap={selectedRetailerCommissionMap}
                        reviewDataIsReady={reviewDataIsReady}
                    />
                )}
            </MultiRetailerReviewTabs>
        );
    }

    return (
        <CommissionReview
            spark={spark}
            sparkPosData={sparkPosData}
            sparkCommissionMap={sparkCommissionMap}
            reviewDataIsReady={sparkPosDataIsReady}
        />
    );
};
