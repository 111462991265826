import { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import clsx from 'clsx';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';

import { ITrainingCourse, Spark } from '@sparkplug/lib';

import { getCourseTypeByAccount } from '@core/accounts';

import { RequestDetailsCallout } from '@features/request-for-spark/components/RequestDetailsCallout';
import { useApplyRequestForSparkDetails } from '@features/request-for-spark/hooks';
import { useSparkBrandsQuery } from '@features/spark-brands';
import SparkBrandDropdown from '@features/spark-brands/components/SparkBrandDropdown';
import SparkWizardSnapPanel from '@features/spark-wizard/views/SparkWizardView/SparkWizardSnapPanel';

import Form from '@components/form/Form';
import { RecurringSchedulePickerValue } from '@components/inputs/RecurringSchedulePicker';
import CalloutMessage from '@components/layout/CalloutMessage';
import Grid from '@components/layout/Grid';
import Tooltip from '@components/layout/Tooltip';
import RestrictedWordsModal, {
    containsRestrictedWordsByRef,
} from '@components/overlays/RestrictedWordsModal';
import { TrainingCourseForm } from '@components/sparks/TrainingCourseForm';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import { useModal } from '@hooks/ModalHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import { IAccountRetailers } from '@app/types/AccountsTypes';

import './SparkDetailsFormFields.scss';

type SparkScheduleType = 'oneTime' | 'recurring';

const EMPTY_START_DATE_MESSAGE = 'Select a start date to proceed.';
const INVALID_REQUEST_START_DATE_MESSAGE =
    "Sparks can't be started without at least 3 days notice.";

export const isValidOneTimeSparkSchedule = ({
    sparkSchedule,
    startDate,
    endDate,
    isBrandUser,
    isThreeDaysAway,
}: {
    sparkSchedule: SparkScheduleType;
    startDate: string;
    endDate: string;
    isBrandUser: boolean;
    isThreeDaysAway: boolean;
}) => {
    let result = true;

    if (sparkSchedule === 'oneTime') {
        if (!startDate) {
            toast.error(EMPTY_START_DATE_MESSAGE);
            result = false;
        }

        if (!endDate) {
            toast.error('Select an end date to proceed.');
            result = false;
        }

        if (moment(startDate).isAfter(endDate)) {
            toast.error("Your Spark's start date can't be after the end date.");
            result = false;
        }

        if (isBrandUser && !isThreeDaysAway) {
            toast.error(INVALID_REQUEST_START_DATE_MESSAGE);
            result = false;
        }
    }

    return result;
};

export const isValidRecurringSparkSchedule = ({
    sparkSchedule,
    recurringSparkSchedule,
    isBrandUser,
    isThreeDaysAway,
}: {
    sparkSchedule: SparkScheduleType;
    recurringSparkSchedule?: RecurringSchedulePickerValue;
    isBrandUser: boolean;
    isThreeDaysAway: boolean;
}) => {
    if (sparkSchedule === 'recurring') {
        if (!recurringSparkSchedule?.interval) {
            toast.error('Select an interval for this Recurring Spark');
            return false;
        }
        if (!recurringSparkSchedule?.startDate) {
            toast.error(EMPTY_START_DATE_MESSAGE);
            return false;
        }
        if (
            recurringSparkSchedule?.interval === 'daily' &&
            !recurringSparkSchedule?.daysOfTheWeek?.length
        ) {
            toast.error('Select days of the week to run this Spark');
            return false;
        }
        if (isBrandUser && !isThreeDaysAway) {
            toast.error(INVALID_REQUEST_START_DATE_MESSAGE);
            return false;
        }
    }

    return true;
};

type IAccountRetailersModified = IAccountRetailers & {
    displayLabel?: ReactElement;
};

const onDateChanged = (value: moment.MomentInput, setFn: any) => {
    if (value) {
        setFn(moment(value).format('YYYY-MM-DD'));
    }
};

const SparkDetailsFormFields = () => {
    const { updateValidationFn } = useModal();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const groupIdFromUrl = queryParams.get('groupId');
    const {
        isRecurringSpark,
        spark,
        spark: {
            name,
            description,
            startDate,
            endDate,
            emoji,
            groupId,
            trainingEnabled,
            courseData = {} as ITrainingCourse,
        },
        updateSpark,
        isCreatingMultiRetailerSpark,
    } = useSpark();

    const requestForSpark = useApplyRequestForSparkDetails();

    const { account } = useSparkplugAccount();

    const { user, userIsSuperAdmin } = useApp();
    const restrictedWordsRef = useRef();

    const isBrandUser = account?.type === 'brand' && !userIsSuperAdmin;
    const requestState = isBrandUser ? 'pending' : 'none';
    const threeDaysAway = moment().add(3, 'days').startOf('day');
    const options = useMemo(() => account?.retailers || [], [account?.retailers]);
    const isEditingVendorRulesBasedSpark = !!spark._id && !isEmpty(spark?.vendorFilters);

    const [sparkSchedule, setSparkSchedule] = useState<'oneTime' | 'recurring'>(
        isRecurringSpark ? 'recurring' : 'oneTime',
    );
    const [recurringSparkSchedule, setRecurringSparkSchedule] =
        useState<RecurringSchedulePickerValue>(spark.recurringSchedule ?? {});

    const { sparkBrandsAreReady, sparkBrands } = useSparkBrandsQuery(
        spark.originatorGroupId ?? '',
        !!spark.originatorGroupId,
        '',
    );

    const checkValidation = useCallback(() => {
        const isThreeDaysAway =
            sparkSchedule === 'oneTime'
                ? !moment(startDate).isBefore(threeDaysAway)
                : !!(
                      recurringSparkSchedule?.startDate &&
                      !moment(recurringSparkSchedule.startDate).isBefore(threeDaysAway)
                  );

        const validation: (() => boolean)[] = [
            () =>
                isValidOneTimeSparkSchedule({
                    sparkSchedule,
                    startDate,
                    endDate,
                    isBrandUser,
                    isThreeDaysAway,
                }),
            () =>
                isValidRecurringSparkSchedule({
                    sparkSchedule,
                    recurringSparkSchedule,
                    isBrandUser,
                    isThreeDaysAway,
                }),
            () => containsRestrictedWordsByRef(restrictedWordsRef, [name, description].join('|')),
            () => {
                if (account?.type === 'brand' && !spark.sparkBrandId) {
                    toast.error('Select Spark Brand');
                    return false;
                }
                return true;
            },
            () => {
                if (
                    account?.type === 'brand' &&
                    !options.find((o) => o?._id === groupId) &&
                    !isCreatingMultiRetailerSpark
                ) {
                    toast.error('Select a retailer.');
                    return false;
                }
                return true;
            },
            () => {
                if (spark.trainingEnabled && spark.courseData?.required === undefined) {
                    toast.error('Select ZolTrain course type before proceeding');
                    return false;
                }
                return true;
            },
        ];

        const allValid = validation.every((fn) => fn());

        if (allValid && sparkSchedule === 'recurring') {
            const {
                interval,
                startDate: scheduleStartDate,
                daysOfTheWeek,
            } = recurringSparkSchedule ?? {};

            if (interval && scheduleStartDate) {
                updateSpark({
                    requestState,
                    recurringSchedule: {
                        interval,
                        startDate: scheduleStartDate,
                        daysOfTheWeek,
                    },
                });
            }
        } else {
            updateSpark({
                requestState,
                recurringSchedule: undefined,
            });
        }

        return allValid;
    }, [
        spark,
        name,
        description,
        startDate,
        endDate,
        isBrandUser,
        account?.type,
        options,
        groupId,
        sparkSchedule,
        recurringSparkSchedule,
        requestState,
    ]);

    useEffect(() => {
        updateValidationFn(checkValidation);
    }, [checkValidation]);

    const onUpdateSpark = <SparkKey extends keyof Spark>(key: SparkKey) => {
        return (value: Spark[SparkKey]) => {
            updateSpark({
                [key]: value,
            });
        };
    };

    useEffect(() => {
        if (isBrandUser) {
            onDateChanged(threeDaysAway, onUpdateSpark('startDate'));
        }
    }, []);

    useEffect(() => {
        if (groupIdFromUrl) {
            updateSpark({
                groupId: groupIdFromUrl,
            });
        }
    }, [groupIdFromUrl]);

    const sparkLength = useMemo(() => {
        // added +1 to include the first day of the spark
        return moment(endDate).diff(startDate, 'days') + 1;
    }, [startDate, endDate]);

    const oneTimeSparkDateRangePicker = (
        <>
            <Form.DateRangePicker
                id="spark-create-edit-date-range"
                label={
                    account?.type === 'brand'
                        ? 'Spark Dates (Retailers require 3 days notice to schedule a Spark)'
                        : 'Spark Dates'
                }
                dateStart={startDate}
                dateEnd={endDate}
                required
                onApply={(newDateStart, newDateEnd) => {
                    onDateChanged(newDateStart, onUpdateSpark('startDate'));
                    onDateChanged(newDateEnd, onUpdateSpark('endDate'));
                }}
                isOutsideRange={(day) => {
                    if (isBrandUser) {
                        return day.isBefore(threeDaysAway);
                    }
                    return false;
                }}
                variant="outlined"
                color="neutral"
            />
            <Form.InputHelperText>
                {sparkLength > 0 ? (
                    <em>
                        <span>Spark will run for </span>
                        <u>{`${sparkLength} days`}</u>
                        <span>.</span>
                    </em>
                ) : (
                    <em>Choose start and end dates.</em>
                )}
            </Form.InputHelperText>
        </>
    );

    const recurringSchedulePickerDisabled = !!spark.recurringSparkScheduleId;

    const sparkDateRangePicker = (
        <>
            {recurringSchedulePickerDisabled && (
                <CalloutMessage
                    className="recurring-schedule-disabled-callout-message"
                    color="neutral"
                    message="Spark schedule cannot be modified for an existing recurring Spark."
                />
            )}
            <Form.RadioGroup
                required
                color="blue"
                variant="none"
                className="interval-radio-group"
                disabled={recurringSchedulePickerDisabled}
                label="Spark Schedule"
                value={sparkSchedule}
                options={[
                    { value: 'oneTime', label: 'One-time Spark' },
                    { value: 'recurring', label: 'Recurring Spark' },
                ]}
                onChange={(event) => {
                    const updatedSchedule = event.target.value;

                    if (updatedSchedule === 'recurring') {
                        updateSpark({
                            startDate: undefined,
                            endDate: undefined,
                        });
                    }

                    setSparkSchedule(updatedSchedule);
                }}
            />
            {sparkSchedule === 'oneTime' && oneTimeSparkDateRangePicker}
            {sparkSchedule === 'recurring' && (
                <Form.RecurringSchedulePicker
                    required
                    value={recurringSparkSchedule}
                    onChange={setRecurringSparkSchedule}
                    minimumDaysOffset={
                        account?.type === 'brand' && user?.role !== 'super-admin' ? 3 : 0
                    }
                    name="recurringSchedule"
                    label={
                        account?.type === 'brand'
                            ? 'Spark Dates (Retailers require 3 days notice to schedule a Spark)'
                            : 'Spark Dates'
                    }
                    disabled={recurringSchedulePickerDisabled}
                    calendarInfo={{
                        daily: 'Select a day of the week to begin the recurring Spark.',
                        weekly: 'Select an upcoming Monday to begin a weekly recurring Spark.',
                        twice_monthly:
                            'Select an upcoming 1st or 15th of the month to begin a twice-monthly recurring Spark.',
                        monthly:
                            'Select an upcoming 1st of the month to begin a monthly recurring Spark.',
                    }}
                    helperText={{
                        daily: () => (
                            <>
                                Spark will run for{' '}
                                <u>
                                    <em>1 day</em>
                                </u>
                                , and recur on the selected days of the week.
                            </>
                        ),
                        weekly: () => (
                            <>
                                Spark will run for{' '}
                                <u>
                                    <em>7 days</em>
                                </u>
                                , and recur each week, Monday through Sunday.
                            </>
                        ),
                        twice_monthly: () => (
                            <>
                                Spark will run for{' '}
                                <u>
                                    <em>14 days</em>
                                </u>{' '}
                                twice a month, starting on the 1st and the 15th.
                            </>
                        ),
                        monthly: () => (
                            <>
                                Spark will run for a{' '}
                                <u>
                                    <em>full month</em>
                                </u>
                                , and recur on the 1st of each month.
                            </>
                        ),
                    }}
                />
            )}
        </>
    );

    const accountRetailerOptions: IAccountRetailersModified[] = useMemo(() => {
        if (
            spark.sparkBrandId &&
            account?.retailers &&
            sparkBrandsAreReady &&
            sparkBrands?.length > 1
        ) {
            return account.retailers.map((retailer) => {
                const brandLink = sparkBrands.find((brand) => brand._id === spark.sparkBrandId);
                const productsByRetailer = brandLink?.productsAvailableByRetailer;
                const productsMapped = productsByRetailer?.[retailer._id] ?? false;
                return {
                    ...retailer,
                    displayLabel: (
                        <Tooltip
                            title={
                                !productsMapped
                                    ? `${retailer.label} does not have any products mapped to the selected Brand (${spark?.sparkBrand?.name})`
                                    : ''
                            }
                        >
                            <div className={clsx(!productsMapped && 'none-mapped')}>
                                {retailer.label}
                            </div>
                        </Tooltip>
                    ),
                };
            });
        } else {
            return account?.retailers || [];
        }
    }, [account?.retailers, sparkBrands, spark.sparkBrandId, sparkBrandsAreReady]);

    return (
        <>
            <div className="form-container">
                <Grid className="details-container">
                    <Grid.Item xs={12} lg={6}>
                        {requestForSpark && (
                            <RequestDetailsCallout
                                requestForSpark={requestForSpark}
                                sx={{ marginBottom: '8px' }}
                            />
                        )}
                        {spark.originatorGroupId && !isCreatingMultiRetailerSpark && (
                            <Form.SearchSelect
                                optionLabelComponent={(option: IAccountRetailersModified) => (
                                    <>{option.displayLabel ? option.displayLabel : option.label}</>
                                )}
                                label="Retailer"
                                placeholder=""
                                name="name"
                                required
                                value={groupId}
                                options={accountRetailerOptions}
                                disabled={isEditingVendorRulesBasedSpark || !!requestForSpark}
                                onChange={(option) => {
                                    onUpdateSpark('groupId')(option.value);
                                }}
                            />
                        )}

                        {spark.originatorGroupId && (
                            <SparkBrandDropdown
                                accountId={spark.originatorGroupId ?? ''}
                                retailerAccount={{
                                    id: spark.groupId ?? '',
                                    name:
                                        account?.retailers?.find(
                                            (retailer) => retailer._id === spark.groupId,
                                        )?.name ?? '',
                                }}
                                defaultValue={spark.sparkBrandId}
                                disabled={
                                    (account?.type === 'retailer' && !userIsSuperAdmin) ||
                                    isEditingVendorRulesBasedSpark
                                }
                                onChange={(updatedSparkBrandId, updatedSparkBrandName) =>
                                    updateSpark({
                                        sparkBrandId: updatedSparkBrandId,
                                        sparkBrand: {
                                            name: updatedSparkBrandName,
                                        },
                                    } as any)
                                }
                                sparkBrands={sparkBrands}
                                sparkBrandsAreReady={sparkBrandsAreReady}
                                isCreatingMultiRetailerSpark={isCreatingMultiRetailerSpark}
                            />
                        )}

                        <Form.TextField
                            label="Spark Name"
                            name="name"
                            helperText={`${name?.length || 0} / 32`}
                            value={name}
                            required
                            onChange={(event) =>
                                onUpdateSpark('name')(event.target.value.substr(0, 32))
                            }
                        />
                        <Form.TextField
                            label="Description"
                            name="description"
                            helperText={`${description?.length || 0} / 90`}
                            required
                            value={description}
                            onChange={(event) =>
                                onUpdateSpark('description')(event.target.value.substr(0, 90))
                            }
                        />

                        {!spark.originatorGroupId && (
                            <Form.EmojiPicker
                                label="Spark Emoji"
                                required
                                value={emoji}
                                onChange={(value) => onUpdateSpark('emoji')(value)}
                            />
                        )}
                    </Grid.Item>

                    <Grid.Item xs={12} lg={6}>
                        {sparkDateRangePicker}
                        {sparkSchedule === 'oneTime' && (
                            <TrainingCourseForm
                                accountCanAddCourse={
                                    account?.zoltrainEnabled || account?.seedTalentEnabled
                                }
                                accountCourseType={getCourseTypeByAccount(account)}
                                trainingEnabled={trainingEnabled}
                                courseData={courseData}
                                onUpdate={updateSpark}
                            />
                        )}
                        <SparkWizardSnapPanel className="mt-5" labelPosition="above-panel" />
                    </Grid.Item>
                </Grid>
            </div>

            <RestrictedWordsModal ref={restrictedWordsRef} />
        </>
    );
};

export default SparkDetailsFormFields;
