import moment from 'moment';

import { CustomIntercomUserData } from '@sparkplug/lib';

import { isPWA } from './isPWA';
import { objectFilterNull } from './ui';

const { window }: any = global;

const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
interface IIntercomSettings {
    firstName?: string;
    lastName?: string;
    fullName?: string;
    email?: string;
    phone?: string;
    userId?: string;
    userType?: string;
    accountType?: string;
    accountName?: string;
    accountId?: string;
    isSuperAdmin?: boolean;
}

interface IntercomUser extends CustomIntercomUserData {
    name?: string;
    email?: string;
    phone?: string;
    user_id?: string;
    company?: {
        id: string;
        name: string;
    };
}

function mapAndFilterData({
    fullName,
    email,
    phone,
    userId,
    userType,
    accountType,
    accountName,
    accountId,
    isSuperAdmin = false,
}: IIntercomSettings): IntercomUser {
    return objectFilterNull({
        name: fullName,
        email,
        phone,
        user_id: userId,
        user_type: userType,
        account_type: accountType,
        account_name: accountName,
        ...(accountId &&
            !isSuperAdmin && {
                company: { id: accountId, name: accountName },
                app_mode: isPWA(),
            }),
    });
}

class Intercom {
    _isReady: boolean;

    constructor() {
        this._isReady = false;
    }

    get isReady() {
        if (!this._isReady) {
            // eslint-disable-next-line no-console
            console.warn('The Intercom is not ready');
        }
        return this._isReady;
    }

    init() {
        // @ts-expect-error
        // prettier-ignore
        // eslint-disable-next-line
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    }

    boot(settings: IIntercomSettings = {}) {
        if (settings.isSuperAdmin) return;
        if (APP_ID != null) {
            const data = {
                ...mapAndFilterData(settings),
                app_id: APP_ID,
                created_at: moment().unix(),
            };

            window.Intercom('boot', data);
            this._isReady = true;
        }
    }

    update(settings: IIntercomSettings = {}) {
        const data = mapAndFilterData(settings);
        window.Intercom('update', data);
    }

    open() {
        if (this.isReady) {
            window.Intercom('show');
        }
    }

    shutdown() {
        window.Intercom('shutdown');
        this._isReady = false;
    }
}

export default new Intercom();
