import { ComponentProps, FC, useMemo, useState } from 'react';

import { chunk } from 'lodash';

import { TAG_COLOR_OPTIONS, TagColorOption } from '@sparkplug/lib';

import IconButton from '@components/buttons/IconButton';
import Dropdown from '@components/dropdown/Dropdown';
import { useDropdown } from '@components/dropdown/DropdownContext';
import { Dot } from '@components/icons';

import { appendClasses } from '@helpers/ui';

import './ColorSelector.scss';

interface ColorOptionProps {
    selectedValue: TagColorOption;
    color: TagColorOption;
    updateValue: (updatedValue: TagColorOption) => void;
}
const ColorOptionButton: FC<ColorOptionProps> = ({ selectedValue, color, updateValue }) => {
    const { handleClose } = useDropdown();
    const isSelected = selectedValue === color;

    const handleClick = () => {
        updateValue(color);
        handleClose();
    };

    const classNamesAppended = appendClasses([
        'color-option-button',
        isSelected ? 'is-selected' : '',
    ]);

    return (
        <IconButton className={classNamesAppended} onClick={handleClick}>
            <Dot style={{ color }} />
        </IconButton>
    );
};

interface PopoverContentProps {
    value: TagColorOption;
    updateValue: (updatedValue: TagColorOption) => void;
}
export const PopoverContent: FC<PopoverContentProps> = ({ value, updateValue }) => {
    const colorColumns = chunk(TAG_COLOR_OPTIONS, 4);

    return (
        <div className="color-selector_grid">
            {colorColumns.map((colorGroup, i) => (
                <div className="color-selector_color-group" key={i}>
                    {colorGroup.map((color) => (
                        <ColorOptionButton
                            key={color}
                            selectedValue={value}
                            color={color}
                            updateValue={updateValue}
                        />
                    ))}
                </div>
            ))}
        </div>
    );
};

interface ColorSelectorProps {
    id?: string;
    value?: TagColorOption;
    defaultValue?: TagColorOption;
    variant?: ComponentProps<typeof Dropdown.IconButton>['variant'];
    onChange: (updatedValue: TagColorOption) => void;
}

const ColorSelector: FC<ColorSelectorProps> = ({
    id,
    value: controlledValue,
    defaultValue = TAG_COLOR_OPTIONS[0],
    onChange,
}) => {
    const [uncontrolledValue, setUncontrolledValue] = useState(defaultValue);

    const value = useMemo(
        () => controlledValue ?? uncontrolledValue,
        [controlledValue, uncontrolledValue],
    );

    const updateValue = (updatedValue: TagColorOption) => {
        setUncontrolledValue(updatedValue);
        onChange(updatedValue);
    };

    return (
        <Dropdown>
            <Dropdown.IconButton
                className="color-selector_icon-button"
                color="neutral"
                variant="outlined"
                data-testid={id ? `color-selector_icon-button-${id}` : undefined}
            >
                <Dot style={{ color: value }} />
            </Dropdown.IconButton>
            <Dropdown.Popover>
                <PopoverContent value={value} updateValue={updateValue} />
            </Dropdown.Popover>
        </Dropdown>
    );
};

export default ColorSelector;
