import { FC, useCallback, useMemo, useRef } from 'react';

import { AccountMarket } from '@sparkplug/lib';

import { useVendorRetailerDataPermissions } from '@features/account-links';
import {
    InventoryDisabledEmptyState,
    InventoryNotAvailableEmptyState,
} from '@features/inventory/components/InventoryEmptyStates';
import { NoActiveProductsEmptyState } from '@features/inventory/components/InventoryEmptyStates/InventoryEmptyStates';
import ProductInventoryTable from '@features/inventory/components/ProductInventoryTable';
import { useProductInventoryTableFilters } from '@features/inventory/components/ProductInventoryTable/useProductInventoryTableFilters';
import { RetailerDashboardInventoryToolbar } from '@features/inventory/components/RetailerDashboardInventoryToolbar/RetailerDashboardInventoryToolbar';
import { SuperAdminPosConfigsDisplay } from '@features/inventory/components/SuperAdminPosConfigsDisplay/SuperAdminPosConfigsDisplay';
import {
    useFetchRetailerInventoryEnabled,
    useRetailerProductsMappedToVendor,
} from '@features/inventory/queries';
import {
    getLocationInventoryRows,
    prepareInventoryData,
} from '@features/inventory/utils/inventoryUtils';

import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';
import { ExpandableTable } from '@components/table/ExpandableTable';

import { useSparkplugBrandRetailer } from '@hooks/SparkplugAccountsHooks';

import { cn } from '@app/componentLibrary/utils';
import { IAccount } from '@app/types/AccountsTypes';

import {
    LocationInventoryRow,
    geLocationInventoryHeadCells,
} from './getRetailerInventoryHeadCells';

import './RetailerInventoryView.scss';

export interface RetailerInventoryViewProps {
    account: IAccount;
    userIsSuperAdmin: boolean;
    hasInventoryEntitlement: boolean;
    inventoryEntitlementMarkets: AccountMarket[];
}
export const RetailerInventoryView: FC<RetailerInventoryViewProps> = ({
    account,
    userIsSuperAdmin,
    hasInventoryEntitlement,
    inventoryEntitlementMarkets,
}) => {
    const { brandRetailer, brandRetailerId } = useSparkplugBrandRetailer();
    const { shareInventoryData } = useVendorRetailerDataPermissions({
        vendorAccountId: account?._id,
        retailerAccountId: brandRetailerId,
        isEnabled: account?.type === 'brand',
    });
    const {
        inventoryEnabled: retailerInventoryEnabled,
        posConfigId: activePosConfigId,
        retailerInventoryEnabledIsReady,
    } = useFetchRetailerInventoryEnabled(brandRetailerId);

    const { retailerProducts, retailerProductsAreReady } = useRetailerProductsMappedToVendor({
        vendorAccountId: account?._id ?? '',
        retailerAccountId: brandRetailer?._id ?? '',
    });

    const locations = brandRetailer?.locations ?? [];

    const {
        numProductsMissingInventory,
        productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
        locationOptions,
    } = useMemo(
        () => prepareInventoryData(retailerProducts, locations),
        [retailerProducts, locations],
    );

    const locationInventoryRows = useMemo(
        () => getLocationInventoryRows(productsByLocation),
        [productsByLocation],
    );

    const {
        searchFilter,
        applySearch,
        filters,
        tableFilters,
        updateFilters,
        onChangeSearchFilter,
        visibleBrandOptions,
        visibleCategoryOptions,
        selectedBrandCountProps,
        selectedCategoryCountProps,
        visibleLocationOptions,
        selectedLocationCountProps,
        visibleProductOptions,
        selectedProductCountProps,
    } = useProductInventoryTableFilters({
        initialFilters: {},
        isRetailerDashboard: true,
        initialProducts: productsByLocation,
        brandOptions,
        categoryOptions,
        productOptions,
        locationOptions,
    });

    const showLocationsDropdown = useMemo(
        () => locationOptions.length > 1,
        [locationOptions.length],
    );

    const applyInventoryLocationFilter = useCallback(
        (rows: LocationInventoryRow[]) => {
            if (!filters.locations.length) return rows;
            const filteredRows = rows.filter((row) =>
                filters.locations.some((filter) => filter.value === row.locationId),
            );
            return filteredRows;
        },
        [filters.locations],
    );

    const getCollapsibleContent = useCallback(
        (row: LocationInventoryRow) => {
            const containerRef = useRef(null);
            return (
                <div className="overflow-y-auto" ref={containerRef}>
                    <ProductInventoryTable
                        accountType="brand"
                        products={row.productData}
                        filters={[...tableFilters, applySearch]}
                        inventoryEnabled={shareInventoryData}
                        retailerAccountId={brandRetailer?._id ?? ''}
                        isLoading={!retailerProductsAreReady}
                        isRetailerDashboard
                        numProductsMissingInventory={row.numProductsMissingInventory}
                        containerRef={containerRef}
                    />
                </div>
            );
        },
        [
            tableFilters,
            applySearch,
            shareInventoryData,
            brandRetailer,
            retailerProductsAreReady,
            numProductsMissingInventory,
        ],
    );

    const retailerInventoryDataSharingNotEnabled =
        ((productsByLocation.length > 0 &&
            numProductsMissingInventory === productsByLocation.length) ||
            !retailerInventoryEnabled) &&
        retailerProductsAreReady &&
        retailerInventoryEnabledIsReady;

    const emptyState = useMemo(() => {
        if (!shareInventoryData) {
            return <InventoryDisabledEmptyState />;
        } else if (retailerInventoryDataSharingNotEnabled) {
            return <InventoryNotAvailableEmptyState />;
        } else if (productsByLocation.length === 0) {
            return <NoActiveProductsEmptyState />;
        }
        return null;
    }, [
        shareInventoryData,
        productsByLocation.length,
        numProductsMissingInventory,
        retailerInventoryEnabled,
        retailerProductsAreReady,
        retailerInventoryEnabledIsReady,
    ]);

    const headCells = useMemo(() => geLocationInventoryHeadCells(), []);

    return (
        <>
            {!retailerInventoryEnabledIsReady || !retailerProductsAreReady ? (
                <Paper className="section mt-4">
                    <Skeleton height={400} />
                </Paper>
            ) : (
                <div className={`dashboard-view dashboard-${account?.type}-view`}>
                    {!emptyState ? (
                        <div className={cn('product-inventory-table-container retailer-dashboard')}>
                            <RetailerDashboardInventoryToolbar
                                {...{
                                    showLocationsDropdown:
                                        showLocationsDropdown || numProductsMissingInventory > 0,
                                    filters,
                                    visibleBrandOptions,
                                    visibleProductOptions,
                                    visibleCategoryOptions,
                                    updateFilters,
                                    locationOptions,
                                    brandOptions,
                                    productOptions,
                                    categoryOptions,
                                    selectedBrandCountProps,
                                    selectedProductCountProps,
                                    selectedCategoryCountProps,
                                    visibleLocationOptions,
                                    selectedLocationCountProps,
                                    searchFilter,
                                    onChangeSearchFilter,
                                }}
                            />
                            <Paper className="section">
                                <ExpandableTable
                                    className="min-h-[475px] retailer-inventory-view"
                                    filters={[applyInventoryLocationFilter]}
                                    useExternalProvider={false}
                                    variant="raised"
                                    rows={locationInventoryRows}
                                    headCells={headCells}
                                    collapse={getCollapsibleContent}
                                    useInfiniteScrollStyling
                                    showPagination={false}
                                    emptyStateText="No products with these filters"
                                />
                            </Paper>
                        </div>
                    ) : (
                        <Paper className="section">{emptyState}</Paper>
                    )}
                    {userIsSuperAdmin && (
                        <SuperAdminPosConfigsDisplay
                            activePosConfigId={activePosConfigId ?? ''}
                            retailerInventoryEnabledIsReady={retailerInventoryEnabledIsReady}
                            retailerAccountId={brandRetailer?._id ?? ''}
                            userIsSuperAdmin={userIsSuperAdmin}
                            vendorName={account?.name}
                            retailerName={brandRetailer?.name ?? ''}
                            stripeCustomerId={account?.stripeCustomerId}
                            hasInventoryEntitlement={hasInventoryEntitlement}
                            inventoryEntitlementMarkets={inventoryEntitlementMarkets}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default RetailerInventoryView;
