import { FC, ReactElement, useMemo, useState } from 'react';

import { has } from 'lodash';
import moment from 'moment-timezone';
import pluralize from 'pluralize';

import Button, { ButtonColor, ButtonVariant } from '@components/buttons/Button';
import IntercomContactMessage from '@components/buttons/IntercomContactMessage';
import SplitButton from '@components/buttons/SplitButton';
import Form from '@components/form/Form';
import { HelpOutline, Settings } from '@components/icons';
import ConfirmProductSelectionMethod from '@components/overlays/ConfirmProductSelectionMethod';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import { useModal, withModal } from '@hooks/ModalHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import { validateZoltrainCourse } from '@helpers/training';
import { appendClasses } from '@helpers/ui';

import { SparkCreateEditAction } from '@app/types/SparksTypes';

import './SparkModalActionButtons.scss';

interface SparkWizardNextButtonProps {
    className?: string;
    color?: ButtonColor;
    variant?: ButtonVariant;
    endIcon?: ReactElement;
    onComplete: () => void;
}

export const SparkWizardNextButton: FC<SparkWizardNextButtonProps> = ({
    className,
    color = 'blue',
    variant,
    endIcon,
    onComplete,
}) => {
    const { currentStage, next } = useModal();
    const {
        sparkIsReady,
        saveSpark,
        sendSparkRequest,
        spark,
        isCreatingMultiRetailerSpark,
        multiRetailerParticipantGroups = [],
    } = useSpark();
    const [submitted, setSubmitted] = useState<boolean>(false);
    const isEditingSpark = !!spark?._id;
    const classNamesAppended = appendClasses(['spark-wizard-next-button', className]);

    const { account } = useSparkplugAccount();

    const { userIsSuperAdmin } = useApp();

    const nextButtonText = useMemo(() => {
        if (currentStage === 4) {
            if (isEditingSpark) {
                return submitted ? 'Updating...' : 'Update Spark';
            }

            if (account?.type === 'brand' && !userIsSuperAdmin) {
                const countText = isCreatingMultiRetailerSpark
                    ? `${multiRetailerParticipantGroups.length} ${pluralize('request')}`
                    : 'request';
                return submitted ? `Sending ${countText}...` : `Send ${countText}`;
            }

            const countText = isCreatingMultiRetailerSpark
                ? `${multiRetailerParticipantGroups.length} ${pluralize('Spark')}`
                : 'Spark';

            return submitted ? `Launching ${countText}...` : `Launch ${countText}`;
        }

        return 'Next';
    }, [
        currentStage,
        isEditingSpark,
        userIsSuperAdmin,
        account,
        submitted,
        multiRetailerParticipantGroups.length,
    ]);

    const onNext = () => {
        switch (currentStage) {
            case 0:
                if (spark.trainingEnabled) {
                    if (has(spark, 'courseData')) {
                        if (
                            // Because the ZolTrain courses might have a course type undefined
                            !spark.courseData?.courseType ||
                            spark.courseData?.courseType === 'zoltrain'
                        ) {
                            validateZoltrainCourse(spark.courseData!)
                                .then((courseTitle: string) => {
                                    spark.courseData!.courseTitle = courseTitle;
                                })
                                .then(() => next());
                        } else {
                            next();
                        }
                    } else {
                        toast.error('Training Course data is required if training is enabled');
                    }
                } else {
                    next();
                }
                break;
            case 4:
                if (!isEditingSpark && account?.type === 'brand' && !userIsSuperAdmin) {
                    setSubmitted(true);
                    sendSparkRequest()
                        .then(() => {
                            setSubmitted(false);
                            onComplete();
                        })
                        .catch(() => {
                            setSubmitted(false);
                        });
                    return;
                }

                setSubmitted(true);
                saveSpark(isEditingSpark ? 'edit' : 'create')
                    .then(() => {
                        setSubmitted(false);
                        onComplete();
                    })
                    .catch(() => {
                        setSubmitted(false);
                    });
                break;
            default:
                next();
                break;
        }
    };

    if (
        !isEditingSpark &&
        account?.type === 'brand' &&
        userIsSuperAdmin &&
        currentStage === 4 &&
        (moment(spark.startDate).isAfter(moment()) ||
            (spark.recurringSchedule &&
                moment(spark.recurringSchedule.startDate).isAfter(moment())))
    ) {
        // a super-admin should have the option to request a spark (as a vendor admin would) as long as the start date is later than the current date
        const options = [
            {
                text: submitted ? 'Sending Request' : 'Send Request',
                details: 'Requires retailer approval',
                action: () => {
                    setSubmitted(true);
                    sendSparkRequest()
                        .then(() => {
                            setSubmitted(false);
                            onComplete();
                        })
                        .catch(() => {
                            setSubmitted(false);
                        });
                },
            },
            {
                text: submitted ? 'Launching Spark' : 'Launch Spark',
                details: 'Does NOT require retailer approval',
                action: () => {
                    setSubmitted(true);
                    saveSpark('create')
                        .then(() => {
                            setSubmitted(false);
                            onComplete();
                        })
                        .catch(() => {
                            setSubmitted(false);
                        });
                },
            },
        ];
        return <SplitButton disabled={submitted} options={options} />;
    }
    return (
        <Form.Button
            className={classNamesAppended}
            endIcon={currentStage !== 4 ? endIcon : undefined}
            color={color}
            variant={variant}
            onClick={onNext}
            disabled={submitted || !sparkIsReady}
        >
            {nextButtonText}
        </Form.Button>
    );
};

interface SparkModalActionButtonsParams {
    action: SparkCreateEditAction;
    back: Function;
    currentStage: 0 | 1 | 2 | 3 | 4;
    next: Function;
    onClose: Function;
}

const SparkModalActionButtons = withModal(
    ({ currentStage, back, onClose }: SparkModalActionButtonsParams) => {
        const { spark, isRulesBasedSpark } = useSpark();
        const [manualSwitchIsVisible, setManualSwitchIsVisible] = useState<boolean>(false);

        const { user, userIsSuperAdmin } = useApp();

        const rulesBasedElement = useMemo(() => {
            // stage 2 encompasses both the metric selection and the product selection, but we only want to display the manual product help message (retailer-admin) or change option (super-admin) for the product selection part
            if (currentStage !== 2 || !spark.metric || user?.role === 'brand-admin') {
                return null;
            }

            if (userIsSuperAdmin && isRulesBasedSpark) {
                return (
                    <Button
                        startIcon={<Settings />}
                        color="neutral"
                        onClick={() => setManualSwitchIsVisible(true)}
                    >
                        Change to Manually Managed Spark
                    </Button>
                );
            }
            if (userIsSuperAdmin && !isRulesBasedSpark) {
                return (
                    <Button
                        startIcon={<Settings />}
                        color="neutral"
                        onClick={() => setManualSwitchIsVisible(true)}
                    >
                        Change to Rule-Based Spark
                    </Button>
                );
            }

            return (
                <div className="manual-selection-operations request">
                    <HelpOutline />
                    <IntercomContactMessage prompt="Having trouble selecting the products you want?" />
                </div>
            );
        }, [userIsSuperAdmin, spark, currentStage]);

        return (
            <div className={rulesBasedElement ? 'button-container' : ''}>
                {rulesBasedElement}
                <div className="action-buttons-right">
                    {currentStage > 0 && (
                        <Button color="neutral" className="ml-1 mr-1" onClick={() => back()}>
                            Back
                        </Button>
                    )}
                    <SparkWizardNextButton className="ml-1 mr-2" onComplete={() => onClose(true)} />
                </div>
                <ConfirmProductSelectionMethod
                    isVisible={manualSwitchIsVisible}
                    onClose={() => setManualSwitchIsVisible(false)}
                />
            </div>
        );
    },
);

export default SparkModalActionButtons;
