import { useEffect, useMemo, useState } from 'react';

import { SidebarLayout, Tabs, TabsContent, TabsList, TabsTrigger } from '@componentLibrary';
import clsx from 'clsx';

import { ACCOUNT_MARKETS_RECORD, CreateAccountSnapRequestBody } from '@sparkplug/lib';

import { getMarketOptions } from '@core/utils/getMarketOptions';

import { useSparkBrandsQuery } from '@features/spark-brands';
import { SnapTypeModal } from '@features/spark-snaps/components/SnapTypeModal';
import { SnapTypeOption } from '@features/spark-snaps/components/SnapTypeModal/SnapTypeModal';
import CreateEditSnapDrawer from '@features/spark-snaps/components/SparkSnapsPanel/CreateEditSnapDrawer';
import { CreateSnapDrawerContent } from '@features/spark-snaps/components/SparkSnapsPanel/CreateEditSnapDrawer/CreateEditSnapDrawer';
import { useSnapEditorSdk } from '@features/spark-snaps/hooks';
import { useCreateSnapMutation } from '@features/spark-snaps/mutations';
import { useFeaturedSnaps } from '@features/spark-snaps/queries/FeaturedSnapQueries';
import SparkSnapsTab from '@features/spark-snaps/views/AdminAccountSnapsView/SparkSnapsDashboardView';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Add as AddIcon } from '@components/icons';
import PageHeader from '@components/layout/PageHeader';
import { useHistory, useQueryParams } from '@components/router';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import Typography from '@app/componentLibrary/Typography';

import SnapsFeatureSidebar from '../../components/SnapsFeatureSidebar';
import FeaturedSnapsTab from '../FeaturedSnapsTab';
import { FeaturedSnapsBrandSelector } from '../FeaturedSnapsTab/components/FeaturedSnapsBrandSelector';

import './SparkSnapsView.scss';

const allMarkets = Object.keys(ACCOUNT_MARKETS_RECORD);

export const RetailerSnapNameField = ({
    name,
    hasActiveSnap,
}: {
    name: string;
    hasActiveSnap?: boolean;
}) => {
    return (
        <Form.TextField
            required
            name="snapName"
            label="Name"
            disabled
            defaultValue={name || ''}
            error={hasActiveSnap}
            helperText={
                hasActiveSnap
                    ? 'You cannot create a new featured Snap because one is currently live.'
                    : 'Featured Snap name will display as Retailer name.'
            }
        />
    );
};

const SparkSnapsView = () => {
    const { tab, create } = useQueryParams();
    const [createEditFormError, setCreateEditFormError] = useState<string | undefined>(undefined);
    const { account, hasSnapsEntitlement: snapsEnabled } = useSparkplugAccount();
    const { createSnapAsync } = useCreateSnapMutation(account?._id || '');
    const { sparkBrands, sparkBrandsAreReady } = useSparkBrandsQuery(
        account?._id || '',
        account?.type === 'brand',
    );
    const [snapToCreate, setSnapToCreate] = useState<number>();
    const [snapTypeModalVisible, setSnapTypeModalVisible] = useState(false);
    const [createFeaturedSnapDrawerVisible, setCreateFeaturedSnapDrawerVisible] = useState(false);

    const [selectedBrandId, setSelectedBrandId] = useState<string | undefined>();
    const [markets, setMarkets] = useState<string[]>([]);

    const isFeaturedSnapsMarketTargeted =
        process.env.REACT_APP_FEATURED_SNAPS_MARKET_TARGETED === 'true';

    const { openSnapEditor } = useSnapEditorSdk({
        accountId: account?._id || '',
        snapsEnabled,
        onSnapSaved: ({ snapId }) => {
            setSnapToCreate(snapId);
            return {};
        },
    });

    const tabComponents = [
        {
            value: 'featured-snaps',
            label: 'Featured Snaps',
            content: <FeaturedSnapsTab setSelectedBrandId={setSelectedBrandId} />,
        },
        {
            value: 'spark-snaps',
            label: 'Spark Snaps',
            content: <SparkSnapsTab />,
        },
    ];

    const history = useHistory();
    const { featuredSnaps = [], featuredSnapsAreReady } = useFeaturedSnaps(
        account?._id || '',
        snapsEnabled,
    );

    const handleChange = (newVal: string) => {
        history.push(`?tab=${newVal}`);
    };

    const handleCloseSnapTypeModal = () => {
        setSnapTypeModalVisible(false);
        setCreateEditFormError(undefined);
        setCreateFeaturedSnapDrawerVisible(false);
    };

    const resetFormFields = () => {
        setSelectedBrandId(undefined);
        setMarkets([]);
    };

    const handleCloseCreateFeaturedSnapDrawer = () => {
        setCreateFeaturedSnapDrawerVisible(false);
        history.replace(history.location.pathname);
    };
    const handleAddSnap = ({ snapName, brandId }: any) => {
        if (account?.type === 'brand' && !brandId) {
            setCreateEditFormError('Please select a brand');
            return;
        }
        if (account?.type === 'brand' && !markets.length && isFeaturedSnapsMarketTargeted) {
            setCreateEditFormError('Please select at least one market');
            return;
        }

        setSelectedBrandId(brandId);
        const name =
            account?.type === 'retailer'
                ? account.name
                : sparkBrands?.find((x) => x._id === brandId)?.name || snapName;
        openSnapEditor({
            name,
        });
        handleCloseCreateFeaturedSnapDrawer();
    };

    const activeFeaturedSnap =
        featuredSnaps.filter((snap) => snap.featuredPeriods?.some((period) => !period?.endDate))
            .length > 0;

    const MarketOptions = useMemo(() => getMarketOptions(account?.type), [account?.type]);

    const SnapDetailsForm = useMemo(() => {
        if (!featuredSnapsAreReady) return <></>;
        if (account?.type === 'retailer') {
            return (
                <RetailerSnapNameField name={account?.name} hasActiveSnap={activeFeaturedSnap} />
            );
        } else {
            const liveFeaturedSnaps = featuredSnaps.filter((snap) =>
                snap.featuredPeriods?.some((period) => !period?.endDate),
            );

            return sparkBrandsAreReady ? (
                <div className="flex flex-col gap-4">
                    <FeaturedSnapsBrandSelector
                        liveFeaturedSnapsAreReady
                        liveFeaturedSnaps={liveFeaturedSnaps}
                        accountBrands={sparkBrands}
                        selectedBrandId={selectedBrandId}
                        onSelectBrand={({ _id }) => setSelectedBrandId(_id)}
                        onRemoveBrand={() => setSelectedBrandId(undefined)}
                    />
                    {isFeaturedSnapsMarketTargeted ? (
                        <div className="flex flex-col gap-1">
                            <Form.TreeSelect
                                required
                                className={clsx([
                                    'hide-toggles hide-search',
                                    markets?.length > 1 && 'all-markets',
                                ])}
                                label="Audience"
                                selected={markets}
                                onChange={(values: string[]) => {
                                    if (values.includes('all')) {
                                        setMarkets(['all', ...allMarkets]);
                                    } else {
                                        setMarkets(values);
                                    }
                                }}
                                options={MarketOptions}
                                controlled
                            />
                            <Typography variant="sm">
                                Target this Snap to employees at Retailers in specific markets.
                            </Typography>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            ) : (
                <></>
            );
        }
    }, [account?.type, sparkBrandsAreReady, selectedBrandId, markets, featuredSnapsAreReady]);

    const createSnapPayload: CreateAccountSnapRequestBody = useMemo(() => {
        const marketsPayload = markets?.filter((x) => x !== 'all');
        return {
            brandId: selectedBrandId,
            featuredPeriod: {
                startDate: new Date().toISOString(),
            },
            markets: marketsPayload,
        };
    }, [selectedBrandId, markets]);

    useEffect(() => {
        if (create === 'featured-snap') {
            setCreateFeaturedSnapDrawerVisible(true);
        }
    }, [create]);

    useEffect(() => {
        // unfortunately, we can't use the openSnapEditor callback to do this because it doesn't get the form updates
        if (snapToCreate) {
            createSnapAsync({ snapId: snapToCreate, payload: createSnapPayload });
        }
        setSnapToCreate(undefined);
        setCreateEditFormError(undefined);
    }, [snapToCreate]);

    return (
        <div className="w-full">
            <SidebarLayout
                header={
                    <div>
                        <PageHeader
                            className="m-0"
                            title={`${account?.name || ''}`.trim()}
                            heading="Snaps"
                        >
                            <div className="flex justify-end flex-1">
                                {snapsEnabled && (
                                    <Button
                                        className="toolbar-group-end"
                                        variant="filled"
                                        onClick={() => {
                                            setSnapTypeModalVisible(true);
                                        }}
                                        startIcon={<AddIcon />}
                                    >
                                        Create Snap
                                    </Button>
                                )}
                            </div>
                        </PageHeader>
                        <SnapTypeModal
                            isVisible={snapTypeModalVisible}
                            onClose={handleCloseSnapTypeModal}
                        >
                            <>
                                <SnapTypeOption
                                    onClick={() => {
                                        history.push(`/${account?._id}/snaps?tab=featured-snaps`);
                                        setSnapTypeModalVisible(false);
                                        setCreateFeaturedSnapDrawerVisible(true);
                                        resetFormFields();
                                    }}
                                    imgSrc="/static/images/featured-snap-preview.jpg"
                                    title="Featured Snap"
                                    description={
                                        account?.type === 'retailer'
                                            ? 'Add a featured Snap viewable by all enrolled employees'
                                            : 'Add a featured Snap viewable by all linked Retailer employees'
                                    }
                                />
                                <SnapTypeOption
                                    onClick={() => {
                                        history.push(`/${account?._id}/sparks/create`);
                                    }}
                                    imgSrc="/static/images/spark-snap-preview.jpg"
                                    title="Spark Snap"
                                    description="Add a Snap to a Spark viewable by Spark participants only"
                                />
                            </>
                        </SnapTypeModal>
                    </div>
                }
                sidebar={<SnapsFeatureSidebar />}
                content={
                    <Tabs
                        value={tab || 'featured-snaps'}
                        onValueChange={handleChange}
                        orientation="vertical"
                    >
                        <TabsList className="mb-[16px]">
                            {tabComponents.map(({ value, label }) => (
                                <TabsTrigger key={value} value={value}>
                                    {label}
                                </TabsTrigger>
                            ))}
                        </TabsList>
                        {tabComponents.map(({ value, content }) => (
                            <TabsContent key={value} value={value}>
                                {content}
                            </TabsContent>
                        ))}
                    </Tabs>
                }
            />

            <CreateEditSnapDrawer
                disabled={account?.type === 'retailer' && activeFeaturedSnap}
                isVisible={createFeaturedSnapDrawerVisible}
                onClose={handleCloseCreateFeaturedSnapDrawer}
                onSave={handleAddSnap}
                drawerTitle="Create Featured Snap"
                snapType="Featured Snap"
                calloutMessage={
                    account?.type === 'retailer'
                        ? 'Create a featured Snap for all locations. This will be available for employees to view anytime at the top of their Sparks list.'
                        : 'Create a featured Snap for a Brand. This will be available for employees to view anytime at the top of their Sparks list.'
                }
                error={createEditFormError}
            >
                <CreateSnapDrawerContent
                    snapType="Spark Snap"
                    nameFieldOverride={SnapDetailsForm}
                />
            </CreateEditSnapDrawer>
        </div>
    );
};

export default SparkSnapsView;
