import { FC, useMemo, useState } from 'react';

import { AccountSparkBrand } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { useSparkBrandMutations } from '../../mutations/SparkBrandMutations';
import { useSparkBrandsQuery } from '../../queries';
import CreateEditSparkBrandsModal from './CreateEditSparkBrandsModal';
import SparkBrandsTable from './SparkBrandsTable';

import './CCSparkBrandsView.scss';

const SparkBrandsCalloutMessage = () => {
    return (
        <CalloutMessage message="These logos and brand names display on Sparks to Retailers and Employees. Brands should be billed based on the number of active Brands." />
    );
};

interface CCSparkBrandsViewProps {}

export const CCSparkBrandsView: FC<CCSparkBrandsViewProps> = () => {
    const { account } = useSparkplugAccount();

    const {
        sparkBrandsAreReady,
        sparkBrands, // refetchSparkBrands
    } = useSparkBrandsQuery(account?._id ?? '');
    const { createSparkBrand, updateSparkBrand, deleteSparkBrand } = useSparkBrandMutations(
        account?._id ?? '',
    );

    const [createEditModalIsVisible, setCreateEditModalIsVisible] = useState(false);
    const [currentSparkBrand, setCurrentSparkBrand] = useState<AccountSparkBrand>();

    const rows = useMemo(
        () => sparkBrands.map((sparkBrand) => ({ ...sparkBrand, key: sparkBrand._id })),
        [sparkBrands],
    );

    const onCreate = () => {
        setCreateEditModalIsVisible(true);
    };

    const onManage = (sparkBrand: AccountSparkBrand) => {
        setCurrentSparkBrand(sparkBrand);
        setCreateEditModalIsVisible(true);
    };

    const onCloseCreateEditModal = () => {
        setCreateEditModalIsVisible(false);
        setCurrentSparkBrand(undefined);
    };

    return (
        <>
            <SparkBrandsCalloutMessage />
            <SparkBrandsTable
                isReady={sparkBrandsAreReady}
                rows={rows}
                onCreate={onCreate}
                onManage={onManage}
                updateSparkBrand={updateSparkBrand}
                markets={account?.metaData?.markets ?? []}
            />
            {createEditModalIsVisible && (
                <CreateEditSparkBrandsModal
                    isVisible
                    onClose={onCloseCreateEditModal}
                    accountId={account?._id ?? ''}
                    sparkBrand={currentSparkBrand}
                    markets={account?.metaData?.markets ?? []}
                    createSparkBrand={createSparkBrand}
                    updateSparkBrand={updateSparkBrand}
                    deleteSparkBrand={deleteSparkBrand}
                />
            )}
        </>
    );
};

export default CCSparkBrandsView;
