import { useMemo } from 'react';

import { sortBy, uniqBy } from 'lodash';

import { useAccountUsersQuery } from '@core/users';

import { VendorBrandRetailer } from '@features/account-links';

import { useSpark } from '@hooks/SparksHooks';

import { ISparkPosData } from '@app/types/SparksTypes';
import { IOption } from '@app/types/UITypes';

export type SelectedRetailerPosData = Omit<ISparkPosData, 'brands' | 'categories'> & {
    brands: IOption[];
    categories: IOption[];
};

export const useSelectedRetailerPosData = (selectedRetailer?: VendorBrandRetailer) => {
    const {
        multiRetailerProductSelection,
        multiRetailerProducts,
        multiRetailerProductsAreReady,
        multiRetailerParticipantGroups = [],
    } = useSpark();

    const { accountUsers = [], accountUsersAreReady } = useAccountUsersQuery(
        selectedRetailer?.retailerAccountId,
        false,
    );

    const selectedParticipantGroups = useMemo(() => {
        const selectedRetailerId = selectedRetailer?.retailerAccountId;
        return (
            multiRetailerParticipantGroups.find(
                ({ retailerAccountId }) =>
                    selectedRetailerId && retailerAccountId === selectedRetailerId,
            )?.participantGroups ?? []
        );
    }, [multiRetailerParticipantGroups, selectedRetailer]);

    const selectedLocationIds = useMemo(() => {
        return new Set(selectedParticipantGroups.flatMap(({ locationIds = [] }) => locationIds));
    }, [selectedParticipantGroups]);
    const selectedPosEPIds = useMemo(() => {
        return new Set(
            selectedParticipantGroups.flatMap(
                ({ posEmployeeProfileIds = [] }) => posEmployeeProfileIds,
            ),
        );
    }, [selectedParticipantGroups]);

    const participants = useMemo(() => {
        return sortBy(
            accountUsers
                .filter(({ locationIds = [], posEmployeeProfileIds = [] }) => {
                    if (selectedPosEPIds.size) {
                        return posEmployeeProfileIds.some((posEpId) =>
                            selectedPosEPIds.has(posEpId),
                        );
                    }
                    return locationIds.some((locationId) => selectedLocationIds.has(locationId));
                })
                .map((user) => ({
                    ...user,
                    label: user.fullName,
                    value: user.flexibleEmployeeId,
                })),
            'label',
        );
    }, [accountUsers, selectedLocationIds, selectedPosEPIds]);

    const locations = useMemo(() => {
        const selectedRetailerLocations = selectedRetailer?.activeLocations ?? [];
        return sortBy(
            selectedRetailerLocations
                .filter(({ _id: locationId }) => {
                    return selectedLocationIds.has(locationId);
                })
                .map((location) => ({
                    ...location,
                    label: location.displayName ?? location.name,
                    value: location._id,
                })),
            'label',
        );
    }, [selectedRetailer, selectedLocationIds]);

    const allAssociatedProducts = useMemo(() => {
        const allSelectedRetailerProducts =
            multiRetailerProducts[selectedRetailer?.retailerAccountId ?? ''] ?? [];
        return sortBy(
            allSelectedRetailerProducts.map((product) => ({
                ...product,
                value: product._id,
                label: product.name,
            })),
            'label',
        );
    }, [multiRetailerProducts, selectedRetailer]);

    const selectedProducts = useMemo(() => {
        const productSelection =
            multiRetailerProductSelection[selectedRetailer?.retailerAccountId ?? ''];
        const selectedProductIds = new Set(productSelection?.posProductIds);
        return sortBy(
            allAssociatedProducts.filter(({ _id: productId }) => selectedProductIds.has(productId)),
            'label',
        );
    }, [allAssociatedProducts, multiRetailerProductSelection, selectedRetailer]);

    const brands = useMemo(() => {
        const selectedBrands = selectedProducts.flatMap(
            ({ brands: productBrands }) => productBrands,
        );
        return sortBy(
            uniqBy(selectedBrands, '_id').map((b) => ({
                ...b,
                label: b.name,
                value: b._id,
            })),
            'label',
        );
    }, [selectedProducts]);

    const categories = useMemo(() => {
        const selectedCategories = selectedProducts.flatMap(
            ({ categories: productCategories }) => productCategories,
        );
        return sortBy(
            uniqBy(selectedCategories, '_id').map((c) => ({
                ...c,
                label: c.name,
                value: c._id,
            })),
            'label',
        );
    }, [selectedProducts]);

    const selectedRetailerPosData: SelectedRetailerPosData = useMemo(
        () => ({
            associatedProducts: allAssociatedProducts,
            participants,
            locations,
            products: selectedProducts,
            brands,
            categories,
        }),
        [participants, locations, allAssociatedProducts, selectedProducts, brands, categories],
    );

    const selectedRetailerPosDataIsReady = accountUsersAreReady && multiRetailerProductsAreReady;

    return {
        selectedRetailerPosData,
        selectedRetailerPosDataIsReady,
    };
};
