import { FC, useMemo } from 'react';

import { RetailerSparkFilters, VendorSparkFilters } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

import './ConfirmProductSelectionMethod.scss';

interface ConfirmProductSelectionMethodProps {
    isVisible: boolean;
    onClose: (val: boolean) => void;
}

const ConfirmProductSelectionMethod: FC<ConfirmProductSelectionMethodProps> = ({
    isVisible,
    onClose,
}) => {
    const { account } = useSparkplugAccount();
    const { updateSpark, spark, isRulesBasedSpark } = useSpark();

    const onConfirm = () => {
        const isChangingToManuallyManaged = isRulesBasedSpark;
        const isChangingToRulesBased = !isRulesBasedSpark;

        if (isChangingToManuallyManaged) {
            updateSpark({
                /**
                 * NOTE: Setting `retailerFilters` and `vendorFilters` to undefined does not accomplish
                 * the goal - the property is just ignored when submitting the payload, so setting
                 * to an empty object instead. This shooould be resolved server-side though in
                 * the Spark update endpoint/service
                 */
                retailerFilters: {} as RetailerSparkFilters,
                vendorFilters: {} as VendorSparkFilters,
                productFilters: {},
                posBrandIds: [],
                posCategoryIds: [],
                posProductIds: [],
            });
        }

        if (isChangingToRulesBased && account?.type === 'brand') {
            if (account?.rulesBasedSparksEnabled) {
                updateSpark({
                    retailerFilters: undefined,
                    vendorFilters: {
                        productTags: [] as VendorSparkFilters['productTags'],
                    } as VendorSparkFilters,
                });
            } else {
                // Use existing Brands on the Spark
                const posBrandIds =
                    [spark?.posBrandIds, spark?.productFilters?.brandIds, []].find(
                        (possiblePosBrandIds) => possiblePosBrandIds?.length,
                    ) ?? [];

                updateSpark({
                    retailerFilters: {
                        posBrandIds,
                    } as RetailerSparkFilters,
                    vendorFilters: undefined,
                });
            }
        }

        onClose(true);
    };

    const message = useMemo(() => {
        if (isRulesBasedSpark) {
            return 'Once this has been changed to a manually managed Spark, individual product selection will be possible but new products available to the POS will not be automatically added.  Currently assigned products will also be cleared.';
        }
        return 'Once changing this to a rule-based Spark, individual product selection will be removed and qualifying will be determined by the product tag filters applied.';
    }, [isRulesBasedSpark]);

    const extraModalContent = useMemo(() => {
        if (isRulesBasedSpark) {
            return 'This action cannot be undone, please confirm with the customer first!';
        }
        return 'All selected products will be cleared, please confirm with the customer first!';
    }, [isRulesBasedSpark]);

    return (
        <ConfirmModal
            title={`Change to ${isRulesBasedSpark ? 'Manually Managed' : 'Rule-based'} Spark?`}
            message={<CalloutMessage customIcon="alert" color="red" message={message} />}
            extraModalContent={<>{extraModalContent}</>}
            confirmText="Continue"
            isVisible={isVisible}
            onConfirm={onConfirm}
            onClose={() => onClose(false)}
        />
    );
};

export default ConfirmProductSelectionMethod;
