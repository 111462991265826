import { useApp } from '@hooks/AppHooks';
import { useSpark } from '@hooks/SparksHooks';

import { getToday } from '@helpers/util';

export const useCanEditCourse = () => {
    const { userIsSuperAdmin } = useApp();
    const { spark } = useSpark();

    const sparkHasStarted = getToday().isSameOrAfter(spark.startDate, 'day');
    return userIsSuperAdmin || !sparkHasStarted;
};
