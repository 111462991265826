import { AccountLinkStatus } from '@sparkplug/lib';

import { useAccountLinks } from '@features/account-links/queries';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

const getMappedLinksStatuses = (
    autoLinkingFeatureEnabled: boolean,
): AccountLinkStatus[] | undefined => {
    if (autoLinkingFeatureEnabled) {
        return ['enabled', 'disabled'];
    }
    return undefined;
};

export const useMappedLinks = () => {
    const autoLinkingFeatureEnabled = process.env.REACT_APP_AUTO_LINKING === 'true';

    const { account } = useSparkplugAccount();

    const {
        accountLinks: mappedLinks = [],
        accountLinksAreReady: mappedLinksAreReady,
        refetchAccountLinks: refetchMappedLinks,
    } = useAccountLinks(
        account?._id ?? '',
        !!account,
        getMappedLinksStatuses(autoLinkingFeatureEnabled),
    );

    return {
        mappedLinks,
        mappedLinkCount: mappedLinks.length,
        mappedLinksAreReady,
        refetchMappedLinks,
    };
};
