import React, { Dispatch, FC, SetStateAction, memo, useCallback, useMemo, useState } from 'react';
import Linkify from 'react-linkify';

import { SparkLeaderboardTableDataRow } from '@views/sparks/SparkManageView/useSparkLeaderboardTableData';
import { clsx } from 'clsx';
import { keyBy } from 'lodash';

import { DetailedSparkType, Spark } from '@sparkplug/lib';

import { TableProvider } from '@contexts/TableContext';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import Form from '@components/form/Form';
import {
    CheckCircleOutline as CheckIcon,
    Close as CloseIcon,
    Edit as EditIcon,
    SaveAlt as SaveIcon,
    Undo as UndoIcon,
} from '@components/icons';
import Grid from '@components/layout/Grid';
import Paper from '@components/layout/Paper';
import SparkAwardsPanel from '@components/sparks/SparkAwardsPanel';
import SparkLeaderboardsPaper from '@components/sparks/SparkLeaderboardsPaper';
import SparkPaymentSummaryPanel from '@components/sparks/SparkPaymentSummaryPanel';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useFormContext } from '@hooks/FormHooks';
import { useAccountPaymentMethods } from '@hooks/SparkplugAccountsHooks';
import { useSpark, useSparkPayouts } from '@hooks/SparksHooks';
import { useTableContext } from '@hooks/TableHooks';
import { useSearch } from '@hooks/UIHooks';

import { resetSparkPayouts } from '@helpers/sparks';
import { formatNumberToCurrency, parseCurrencyToNumber } from '@helpers/ui';
import { downloadStringAsTxt, isEmpty } from '@helpers/util';

import { ChartLeader } from '@app/types/ChartDataTypes';
import { ISparkPayout, ISparkSubGroup } from '@app/types/SparksTypes';
import { THeadCell } from '@app/types/TableTypes';

import SparkCheckoutModal from './SparkCheckoutModal';
import UnfinalizeSparkModal from './UnfinalizeSparkModal';

import './SparkCheckoutView.scss';

const payStatusLabels = {
    belowMinimum: 'Below Min',
    noSales: 'No Sales',
    pending: 'Pending',
    approved: 'Approved',
    claimed: 'Claimed',
    blocked: 'Blocked',
} as const;

interface RewardUpdateFields {
    fulfilledBySparkplug: boolean;
    payout: string;
    claimInstructions: string;
}

interface SparkCheckoutTableRow extends SparkLeaderboardTableDataRow {
    payout?: ISparkPayout;
}

interface TableCellProps {
    row: SparkCheckoutTableRow;
    updateRow: Dispatch<SetStateAction<SparkCheckoutTableRow>>;
}

const SelectAllCheckbox = memo<TableCellProps & { bulkEditorIsVisible: boolean }>(({ row }) => {
    const {
        tableSelected: selected,
        tableCheckUncheckRow,
        tableShowBulkEditor,
    } = useTableContext();

    if (tableShowBulkEditor) {
        return null;
    }

    return (
        <Table.Cell scope="row">
            <Table.Checkbox
                disabled={row?.selectionDisabled}
                value={selected.includes(row.key)}
                onChange={() => tableCheckUncheckRow(row)}
            />
        </Table.Cell>
    );
});

const PayoutField: FC<TableCellProps & { bulkEditorIsVisible: boolean }> = ({
    row,
    updateRow,
    bulkEditorIsVisible,
}) => {
    const { payout } = row;
    const { flexibleEmployeeId } = row;

    const onChange = (event: any) => {
        const amount = event.target.value;

        updateRow((prevRow) => {
            return {
                ...prevRow,
                payout: {
                    ...(prevRow.payout ?? {}),
                    amount,
                    text: amount,
                },
            } as SparkCheckoutTableRow;
        });
    };

    if (bulkEditorIsVisible) {
        return (
            <Table.Cell align="left">
                <Form.TextField
                    isMoneyField
                    className="text-field_payout"
                    moneyFieldIsActive={row.payout?.fulfilledBySparkplug}
                    name={`payouts[${flexibleEmployeeId}][payout]`}
                    placeholder="--"
                    defaultValue={row?.payout?.text}
                    onChange={onChange}
                />
            </Table.Cell>
        );
    }

    const hasPayoutText = !!payout?.text;
    const isModified = payout?.isModified ?? false;
    const isFulfilledBySparkplug = payout?.fulfilledBySparkplug;

    return (
        <Table.Cell align="left">
            <div
                className={clsx(
                    'spark-payout-text',
                    isModified && 'is-modified',
                    isFulfilledBySparkplug ? 'is-fbs' : 'is-not-fbs',
                )}
            >
                <span>{hasPayoutText ? payout?.text : '--'}</span>
            </div>
        </Table.Cell>
    );
};

const ClaimInstructionsField: FC<TableCellProps & { bulkEditorIsVisible: boolean }> = ({
    row,
    updateRow,
    bulkEditorIsVisible,
}) => {
    const { payout } = row;

    const onChange = (event: any) => {
        const value = event.target.value;

        updateRow((prevRow) => {
            return {
                ...prevRow,
                payout: {
                    ...(prevRow?.payout || {}),
                    claimInstructions: value,
                },
            } as SparkCheckoutTableRow;
        });
    };

    const { text: payoutText, claimInstructions } = payout ?? {};

    const hasPayoutText = !!payoutText;
    const hasClaimInstructions = !!claimInstructions;
    const isFulfilledBySparkplug = payout?.fulfilledBySparkplug;
    const { flexibleEmployeeId } = row;

    const placeholder = isFulfilledBySparkplug ? '--' : 'Talk to manager';

    const claimInstructionsClassnames = clsx(
        'spark-payout-claim-instructions',
        hasClaimInstructions ? 'is-modified' : 'is-not-modified',
        isFulfilledBySparkplug ? 'is-fbs' : 'is-not-fbs',
    );

    if (bulkEditorIsVisible) {
        return (
            <Table.Cell align="left">
                {hasPayoutText && !isFulfilledBySparkplug ? (
                    <Form.TextField
                        className="text-field_claim-instructions"
                        name={`payouts[${flexibleEmployeeId}][claimInstructions]`}
                        placeholder={placeholder}
                        defaultValue={claimInstructions}
                        onChange={onChange}
                    />
                ) : (
                    <div className={claimInstructionsClassnames}>
                        <span>--</span>
                    </div>
                )}
            </Table.Cell>
        );
    }

    let text = '--';

    if (hasPayoutText) {
        text = hasClaimInstructions ? claimInstructions : placeholder;
    }

    return (
        <Table.Cell align="left">
            <div className={claimInstructionsClassnames}>
                <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                        <a target="blank" href={decoratedHref} key={key}>
                            {decoratedText}
                        </a>
                    )}
                >
                    <span>{text}</span>
                </Linkify>
            </div>
        </Table.Cell>
    );
};

const CashLinkSwitch: FC<TableCellProps> = ({ row, updateRow }) => {
    const fulfilledBySparkplug = row?.payout?.fulfilledBySparkplug ?? false;
    const { flexibleEmployeeId } = row;

    const onChange = () => {
        const newValue = !fulfilledBySparkplug;

        updateRow((prevRow) => {
            return {
                ...prevRow,
                payout: {
                    ...(prevRow?.payout || {}),
                    fulfilledBySparkplug: newValue,
                },
            } as SparkCheckoutTableRow;
        });
    };

    return (
        <Table.Cell align="left">
            <Form.Switch
                name={`payouts[${flexibleEmployeeId}][fulfilledBySparkplug]`}
                value={fulfilledBySparkplug}
                onChange={onChange}
            />
        </Table.Cell>
    );
};

const PayoutStatusChip = ({ payout }: { payout?: ISparkPayout }) => {
    if (!payout) {
        return <Table.Cell align="left">--</Table.Cell>;
    }

    const { status, text: payoutText, fulfilledBySparkplug } = payout;

    const isBelowMinimum = status === 'belowMinimum';
    const hasNoSales = status === 'noSales';
    const hasPayoutStatus = status != null && payoutText?.length > 0;
    const showPayoutStatus = hasPayoutStatus || isBelowMinimum || hasNoSales;

    return (
        <Table.Cell align="left">
            {showPayoutStatus ? (
                <Chip
                    className={clsx(
                        'payout-status-chip',
                        fulfilledBySparkplug ? 'is-fbs' : 'is-not-fbs',
                        `is-${status}`,
                    )}
                    color="neutral"
                    variant="flat"
                    label={payStatusLabels[status]}
                />
            ) : (
                '--'
            )}
        </Table.Cell>
    );
};

const combineHeadCells = (
    headCells: THeadCell<SparkCheckoutTableRow>[],
    bulkEditorIsVisible: boolean = false,
) => {
    const checkboxCell: THeadCell<SparkCheckoutTableRow> = {
        id: 'select',
        type: 'checkbox',
        render: (row, updateRow) => (
            <SelectAllCheckbox
                row={row}
                updateRow={updateRow}
                bulkEditorIsVisible={bulkEditorIsVisible}
            />
        ),
    };

    const payoutCells: THeadCell<SparkCheckoutTableRow>[] = [
        {
            id: 'payout',
            sortType: 'string',
            label: 'Payout',
            render: (row, updateRow) => (
                <PayoutField
                    row={row}
                    updateRow={updateRow}
                    bulkEditorIsVisible={bulkEditorIsVisible}
                />
            ),
        },
    ];

    const claimInstructionsCell: THeadCell<SparkCheckoutTableRow> = {
        id: 'claimInstructions',
        sortType: 'string',
        label: 'Claim Instructions',
        render: (row, updateRow) => (
            <ClaimInstructionsField
                row={row}
                updateRow={updateRow}
                bulkEditorIsVisible={bulkEditorIsVisible}
            />
        ),
    };

    if (!bulkEditorIsVisible) {
        payoutCells.push(claimInstructionsCell);
        payoutCells.push({
            id: 'payoutStatus',
            sortType: 'string',
            label: 'Pay Status',
            render: (row) => <PayoutStatusChip payout={row.payout} />,
        });
    } else {
        payoutCells.push({
            id: 'payoutStatus',
            sortType: 'string',
            label: 'Cash Link',
            render: (row, updateRow) => <CashLinkSwitch row={row} updateRow={updateRow} />,
        });
        payoutCells.push(claimInstructionsCell);
    }

    return [checkboxCell, ...payoutCells, ...headCells];
};

export const generateCsvContentFromRows = ({
    spark,
    tableFilteredRows,
}: {
    spark: Spark;
    tableFilteredRows: any[];
}) => {
    if (spark.metric !== 'total_units') {
        // eslint-disable-next-line no-alert
        alert(
            'Export CSV is only available for Commission Sparks or Sparks whose metric is "total units"',
        );
    }

    const rows = tableFilteredRows.map(
        ({ standing, firstName, lastName, locationStr, value, unitCount, payout }) =>
            [
                standing,
                firstName,
                lastName,
                locationStr,
                spark.type === 'commission' ? unitCount : value,
                payout?.text ?? '--',
            ].map((col) => String(col).replace(/,/g, '')),
    );

    let csv = `${[
        'standing',
        'first name',
        'last name',
        'location(s)',
        'unit count',
        'payout',
    ].join(',')}\n`;

    rows.forEach((rowArray) => {
        csv += `${rowArray.join(',')}\n`;
    });

    return csv;
};

type TFormData = {
    payouts: {
        [posEmployeeProfileId: string]: RewardUpdateFields;
    };
};

interface ISparkCheckoutTableToolbar {
    rows: SparkLeaderboardTableDataRow[];
    searchFilter: string;
    sparkPayouts: ISparkPayout[];
    sparkPayoutsFinalized: boolean;
    onChangeSearchFilter: (event: any) => void;
    bulkEditorIsVisible: boolean;
    updateSparkPayouts: ReturnType<typeof useSparkPayouts>['updatePayouts'];
    updateBulkEditorIsVisible: (isVisible: boolean) => void;
    updateConfirmModalIsVisible: (isVisible: boolean) => void;
}
const SparkCheckoutTableToolbar = ({
    rows,
    searchFilter,
    sparkPayouts,
    sparkPayoutsFinalized,
    onChangeSearchFilter,
    bulkEditorIsVisible,
    updateSparkPayouts,
    updateBulkEditorIsVisible,
    updateConfirmModalIsVisible,
}: ISparkCheckoutTableToolbar) => {
    const { handleSubmit } = useFormContext();
    const { tableSelected, tableSetSelected, tableFilteredRows } =
        useTableContext<SparkCheckoutTableRow>();

    const { spark, sparkSubGroups, detailedSparkType, refetchSparkData } = useSpark();

    const hasCustomRewards =
        !!spark.finalizedAt || sparkPayouts.some((reward) => reward?._id != null);

    const showSubmitMultiLeaderboardInvoiceBtn =
        detailedSparkType === 'leaderboardMulti' &&
        sparkSubGroups.every(({ finalizedAt }) => !!finalizedAt) &&
        sparkSubGroups.some(({ internalTracking }) => {
            const invoiceHasNotBeenSent =
                internalTracking?.invoiceStatus === 'not-sent' || !internalTracking?.invoiceStatus;

            return invoiceHasNotBeenSent;
        });

    const showUnsentInvoiceBtn =
        detailedSparkType !== 'leaderboardMulti' &&
        sparkPayoutsFinalized &&
        (!spark?.internalTracking?.invoiceStatus ||
            spark?.internalTracking?.invoiceStatus === 'not-sent');

    const showSubmitInvoiceBtn = showSubmitMultiLeaderboardInvoiceBtn || showUnsentInvoiceBtn;

    const rowsByFlexibleEmployeeId = keyBy(rows, 'flexibleEmployeeId');

    const onSave = useCallback(
        (formData: TFormData) => {
            const { payouts = {} } = formData;

            const updatedPayoutPartials = Object.keys(payouts).reduce(
                (result: Partial<ISparkPayout>[], flexibleEmployeeId) => {
                    const { userId, posEmployeeProfileIds } =
                        rowsByFlexibleEmployeeId[flexibleEmployeeId];
                    let { fulfilledBySparkplug, payout, claimInstructions } =
                        payouts[flexibleEmployeeId];

                    if (fulfilledBySparkplug && payout === '') {
                        fulfilledBySparkplug = false;
                        payout = '';
                        claimInstructions = '';
                    }

                    if (fulfilledBySparkplug) {
                        claimInstructions = '';
                    }

                    const amount = fulfilledBySparkplug ? parseCurrencyToNumber(payout) : 0;

                    const text = fulfilledBySparkplug
                        ? formatNumberToCurrency(payout, true)
                        : payout;

                    const partialPayout = {
                        flexibleEmployeeId,
                        userId,
                        posEmployeeProfileId: userId ? undefined : flexibleEmployeeId,
                        posEmployeeProfileIds,
                        amount,
                        text,
                        fulfilledBySparkplug,
                        claimInstructions,
                    };

                    const existingReward = sparkPayouts.find(
                        (reward) => reward?.flexibleEmployeeId === flexibleEmployeeId,
                    );
                    const isEmptyReward =
                        (!fulfilledBySparkplug && isEmpty(payout)) ||
                        (fulfilledBySparkplug && amount === 0);
                    const isEmptyAndNoExistingReward = isEmptyReward && existingReward == null;

                    if (isEmptyAndNoExistingReward) {
                        return result;
                    }

                    if (existingReward) {
                        const rewardWasUpdated = Object.keys(partialPayout).some((key) => {
                            return (
                                partialPayout[key as keyof typeof partialPayout] !==
                                existingReward[key as keyof typeof partialPayout]
                            );
                        });

                        if (!rewardWasUpdated) {
                            return result;
                        }
                    }

                    return [...result, partialPayout];
                },
                [],
            );

            updateSparkPayouts(updatedPayoutPartials);
            updateBulkEditorIsVisible(false);
            tableSetSelected([]);
        },
        [sparkPayouts, rowsByFlexibleEmployeeId],
    );

    const exportPayoutCSV = () => {
        const csv = generateCsvContentFromRows({ spark, tableFilteredRows });

        downloadStringAsTxt(csv, spark.name, '.csv');
    };

    return (
        <Toolbar scrollOnMobile>
            <Toolbar.Search
                className="toolbar-group-start"
                name="search"
                defaultValue={searchFilter}
                onChange={onChangeSearchFilter}
            />
            {bulkEditorIsVisible ? (
                <>
                    <Toolbar.Button
                        startIcon={<SaveIcon />}
                        color="blue"
                        variant="smooth"
                        onClick={handleSubmit(onSave)}
                    >
                        {`Save (${tableSelected.length})`}
                    </Toolbar.Button>
                    <Toolbar.Button
                        startIcon={<CloseIcon />}
                        color="neutral"
                        variant="smooth"
                        onClick={() => {
                            updateBulkEditorIsVisible(false);
                        }}
                    >
                        Cancel
                    </Toolbar.Button>
                </>
            ) : (
                <>
                    {process.env.REACT_APP_ENV === 'development' && hasCustomRewards && (
                        <Toolbar.Button
                            startIcon={<UndoIcon />}
                            color="red"
                            variant="flat"
                            tooltip="This action is only visible to devs for testing purposes"
                            onClick={() => {
                                resetSparkPayouts(spark._id).then(() => {
                                    refetchSparkData();
                                    updateSparkPayouts([]);
                                });
                            }}
                        >
                            Reset Amounts
                        </Toolbar.Button>
                    )}

                    <Toolbar.Button color="blue" variant="flat" onClick={exportPayoutCSV}>
                        Download CSV
                    </Toolbar.Button>

                    <Toolbar.Button
                        startIcon={<EditIcon />}
                        color="blue"
                        variant="smooth"
                        disabled={tableSelected?.length === 0}
                        tooltip={
                            tableSelected?.length === 0 && !sparkPayoutsFinalized
                                ? 'Select payouts in the table to edit.'
                                : null
                        }
                        onClick={() => {
                            updateBulkEditorIsVisible(true);
                        }}
                    >
                        Edit Amounts
                    </Toolbar.Button>

                    {showSubmitInvoiceBtn ? (
                        <Toolbar.Button
                            startIcon={<CheckIcon />}
                            color="blue"
                            variant="filled"
                            onClick={() => updateConfirmModalIsVisible(true)}
                        >
                            Submit Invoice
                        </Toolbar.Button>
                    ) : (
                        <Toolbar.Button
                            startIcon={<CheckIcon />}
                            color="blue"
                            disabled={sparkPayoutsFinalized}
                            variant={!sparkPayoutsFinalized ? 'filled' : 'smooth'}
                            tooltip={
                                sparkPayouts?.length === 0
                                    ? 'Add payouts to users before approving.'
                                    : null
                            }
                            onClick={() => updateConfirmModalIsVisible(true)}
                        >
                            Approve Amounts
                        </Toolbar.Button>
                    )}
                </>
            )}
        </Toolbar>
    );
};

export const mapSparkRewardsToUsers = ({
    rows,
    rewards,
    isLocationLeaderboard,
    isMultiLeaderboard,
    payoutsAreFinalized,
    selectedLocationId,
}: {
    rows: SparkLeaderboardTableDataRow[];
    rewards: ISparkPayout[];
    isLocationLeaderboard: boolean;
    isMultiLeaderboard: boolean;
    payoutsAreFinalized: boolean;
    selectedLocationId: string;
}) => {
    return rows.reduce<SparkLeaderboardTableDataRow[]>((result, row) => {
        const payout = rewards.find(
            ({ userId, flexibleEmployeeId, posEmployeeProfileId, posEmployeeProfileIds = [] }) =>
                [flexibleEmployeeId, userId, posEmployeeProfileId, ...posEmployeeProfileIds]
                    .filter((id) => !!id)
                    .includes(row.flexibleEmployeeId),
        );

        if (isLocationLeaderboard && row.lastTransactionLocationId !== selectedLocationId) {
            return result;
        }

        if (
            isMultiLeaderboard &&
            selectedLocationId &&
            !row.locationIds?.includes(selectedLocationId)
        ) {
            return result;
        }

        const mergedRow = {
            ...row,
            selectionDisabled: payoutsAreFinalized,
            payout,
        };

        return [...result, mergedRow];
    }, []);
};

interface SparkCheckoutTableProps {
    isLoadingTableData: boolean;
    headCells: THeadCell<SparkLeaderboardTableDataRow>[];
    rows: SparkLeaderboardTableDataRow[];
    locationLeaders?: ChartLeader[];
    spark: Spark;
    sparkSubGroups: ISparkSubGroup[];
    detailedSparkType: DetailedSparkType;
    initialTableStates?: {
        bulkEditorIsVisible?: boolean;
        defaultSelected?: string[];
    };
    finalizeCallback?: () => void;
}

export const SparkCheckoutTable: FC<SparkCheckoutTableProps> = ({
    spark,
    sparkSubGroups,
    detailedSparkType,
    isLoadingTableData,
    headCells,
    rows,
    locationLeaders = [],
    initialTableStates,
    finalizeCallback,
}) => {
    const [confirmModalIsVisible, setConfirmModalIsVisible] = useState(false);
    const [unfinalizeModalIsVisible, setUnfinalizeModalIsVisible] = useState(false);
    const [bulkEditorIsVisible, setBulkEditorIsVisible] = useState(
        initialTableStates?.bulkEditorIsVisible ?? false,
    );
    const [selectedLocationId, setSelectedLocationId] = useState<string>(
        locationLeaders?.[0]?.flexibleEmployeeId,
    );

    const isMultiLeaderboard = detailedSparkType === 'leaderboardMulti';
    const isLocationLeaderboard = detailedSparkType === 'leaderboardLocation';

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'firstName',
        'lastName',
        'locationStr',
    ]);

    const payerGroupId = spark?.originatorGroupId || spark?.groupId;
    const { paymentMethods, paymentMethodsAreReady, defaultPaymentMethodId } =
        useAccountPaymentMethods(payerGroupId);

    const {
        isLoadingPayouts,
        payoutsAreFinalized,
        payoutsFinalizedAt,
        multiLeaderboardPayoutsFinalizedAt,
        payouts: sparkPayouts,
        payoutGroups: sparkPayoutGroups,
        updatePayouts: updateSparkPayouts,
        confirmPayouts: confirmSparkPayouts,
        refetchSparkRewards,
    } = useSparkPayouts(spark, sparkSubGroups);

    const payoutRows = useMemo(
        () =>
            mapSparkRewardsToUsers({
                rows,
                rewards: sparkPayouts,
                isLocationLeaderboard,
                isMultiLeaderboard,
                payoutsAreFinalized,
                selectedLocationId: isMultiLeaderboard ? spark.locationIds[0] : selectedLocationId,
            }),
        [
            rows,
            sparkPayouts,
            isLocationLeaderboard,
            isMultiLeaderboard,
            sparkPayoutGroups,
            payoutsAreFinalized,
            selectedLocationId,
            spark.locationIds,
        ],
    );

    // For multi-leaderboards, we only want to calculate the total for those leaderboards that have been finalized
    const sparkPayoutsForSummaryTotal = useMemo(
        () =>
            isMultiLeaderboard
                ? sparkPayoutGroups.flatMap((sparkPayoutGroup) =>
                      sparkPayoutGroup.payoutsAreFinalized ? sparkPayoutGroup.payouts : [],
                  )
                : sparkPayouts,
        [isMultiLeaderboard, sparkPayouts, sparkPayoutGroups],
    );

    const rowRenderKeyFn = (row: SparkLeaderboardTableDataRow & { payout?: ISparkPayout }) => {
        const keys = [
            row.flexibleEmployeeId,
            row.selectionDisabled,
            bulkEditorIsVisible,
            JSON.stringify(row.payout),
        ];
        return keys.join('-');
    };

    const finalizedMultiLeaderboardSparkIds = sparkPayoutGroups
        .filter(({ payoutsAreFinalized: _payoutsAreFinalized }) => _payoutsAreFinalized)
        .map(({ sparkId }) => sparkId);

    const SparkCheckoutForm = (
        <Form>
            <TableProvider
                rows={payoutRows}
                isLoading={isLoadingTableData || isLoadingPayouts}
                headCells={combineHeadCells(headCells, bulkEditorIsVisible)}
                showBulkEditor={bulkEditorIsVisible}
                showPagination={false}
                showCheckboxes={!bulkEditorIsVisible}
                filters={[applySearch]}
                defaultSelected={initialTableStates?.defaultSelected}
                defaultOptions={{
                    orderBy: 'rank',
                }}
            >
                <SparkCheckoutTableToolbar
                    rows={payoutRows}
                    sparkPayoutsFinalized={payoutsAreFinalized}
                    searchFilter={searchFilter}
                    sparkPayouts={sparkPayouts}
                    onChangeSearchFilter={onChangeSearchFilter}
                    bulkEditorIsVisible={bulkEditorIsVisible}
                    updateSparkPayouts={updateSparkPayouts}
                    updateBulkEditorIsVisible={(isVisible: boolean) => {
                        setBulkEditorIsVisible(isVisible);
                    }}
                    updateConfirmModalIsVisible={(isVisible: boolean) => {
                        setConfirmModalIsVisible(isVisible);
                    }}
                />
                <Table useExternalProvider variant="raised">
                    <Table.RenderHead />
                    <Table.RenderBody rowRenderKeyFn={rowRenderKeyFn} />
                </Table>
            </TableProvider>
        </Form>
    );

    return (
        <>
            <SparkPaymentSummaryPanel
                isLoadingPayouts={
                    isMultiLeaderboard ? isLoadingPayouts : isLoadingTableData || isLoadingPayouts
                }
                payoutsFinalizedAt={
                    isMultiLeaderboard ? multiLeaderboardPayoutsFinalizedAt : payoutsFinalizedAt
                }
                payouts={sparkPayoutsForSummaryTotal}
                payoutGroups={sparkPayoutGroups}
            />

            {isMultiLeaderboard || isLocationLeaderboard ? (
                <Grid>
                    <Grid.Item sm={3}>
                        {isMultiLeaderboard && (
                            <SparkLeaderboardsPaper
                                showCheckmarks
                                selectedValue={spark._id}
                                checkedValues={finalizedMultiLeaderboardSparkIds}
                            />
                        )}
                        {isLocationLeaderboard && (
                            <SparkLeaderboardsPaper
                                selectedValue={selectedLocationId}
                                locationOptions={locationLeaders.map((locationLeader, i) => ({
                                    value: locationLeader.flexibleEmployeeId,
                                    label: `#${i + 1} - ${locationLeader.name}`,
                                }))}
                                onChange={(value) => setSelectedLocationId(value)}
                            />
                        )}
                    </Grid.Item>
                    <Grid.Item sm={9}>{SparkCheckoutForm}</Grid.Item>
                </Grid>
            ) : (
                SparkCheckoutForm
            )}
            {!!spark.finalizedAt && (
                <>
                    <Paper className="content unfinalize-spark-box">
                        <h2>Unfinalize Spark</h2>
                        <p>
                            Unfinalize spark removes the ability for employees to cash out . All
                            employees that have already cashed out their earnings will not be
                            unfinalized.{' '}
                        </p>
                        <Button
                            color="red"
                            variant="filled"
                            onClick={() => setUnfinalizeModalIsVisible(true)}
                        >
                            Unfinalize Spark
                        </Button>
                    </Paper>

                    <UnfinalizeSparkModal
                        isVisible={unfinalizeModalIsVisible}
                        onClose={() => {
                            refetchSparkRewards();
                            setUnfinalizeModalIsVisible(false);
                        }}
                        claimedRewardAmount={sparkPayouts.reduce((total, reward) => {
                            return total + (reward?.amount ?? 0);
                        }, 0)}
                    />
                </>
            )}

            <SparkCheckoutModal
                isVisible={confirmModalIsVisible}
                onClose={() => setConfirmModalIsVisible(false)}
                sparkPayoutsAreReady={!isLoadingPayouts}
                sparkPayouts={sparkPayouts}
                sparkPayoutGroups={sparkPayoutGroups}
                confirmSparkPayouts={confirmSparkPayouts}
                finalizeCallback={finalizeCallback}
                paymentMethods={paymentMethods}
                paymentMethodsAreReady={paymentMethodsAreReady}
                defaultPaymentMethodId={defaultPaymentMethodId}
            />
        </>
    );
};

interface ISparkCheckoutViewProps {
    isLoadingTableData: boolean;
    tableHeadCells: THeadCell<SparkLeaderboardTableDataRow>[];
    tableRows: SparkLeaderboardTableDataRow[];
    locationLeaders?: ChartLeader[];
    finalizeCallback?: () => void;
}
const SparkCheckoutView = ({
    isLoadingTableData,
    tableHeadCells: headCells,
    tableRows: rows,
    locationLeaders = [],
    finalizeCallback,
}: ISparkCheckoutViewProps) => {
    const { spark, sparkSubGroups, detailedSparkType } = useSpark();

    return (
        <>
            {spark?.type !== 'commission' && <SparkAwardsPanel spark={spark} />}

            <SparkCheckoutTable
                spark={spark}
                sparkSubGroups={sparkSubGroups}
                detailedSparkType={detailedSparkType!}
                isLoadingTableData={isLoadingTableData}
                headCells={headCells}
                rows={rows}
                locationLeaders={locationLeaders}
                finalizeCallback={finalizeCallback}
            />
        </>
    );
};

export default SparkCheckoutView;
