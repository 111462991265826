import React, { FC, useEffect, useMemo, useState } from 'react';

import SparksAPI from '@api/SparksAPI';
import { DATE_DISPLAY_FORMAT } from '@constants/AppConstants';
import { groupBy, isEmpty, uniq } from 'lodash';
import moment from 'moment';

import { RetailerSparkFilters, VendorSparkFilters } from '@sparkplug/lib';

import { getUserData } from '@core/users';

import { useVendorBrandRetailersProductsByBrandId } from '@features/account-links';
import { usePosRulesBasedProductTableFilters } from '@features/spark-wizard/components/SparkProductSelector/PosRulesBasedSparkProductSelectorTable';
import { useRulesBasedProductTableFilters } from '@features/spark-wizard/components/SparkProductSelector/VendorSparkProductSelector/VendorRulesBasedProductSelector/useRulesBasedProductTableFilters';

import Button from '@components/buttons/Button';
import Select from '@components/inputs/Select';
import CalloutMessage from '@components/layout/CalloutMessage';
import Tooltip from '@components/layout/Tooltip';
import InfoModal from '@components/overlays/InfoModal';
import { IDefaultModalProps } from '@components/overlays/Modal';
import SparkProductsTable from '@components/sparks/SparkProductsTable';

import { useApp } from '@hooks/AppHooks';
import { useAdvancedQuery } from '@hooks/QueryHooks';
import { useAccountPosDataQuery } from '@hooks/SparkplugAccountsHooks';
import { useSpark, useSparkPosProductData } from '@hooks/SparksHooks';

import './SparkProductsModal.scss';

export const sparkHistoryCacheKey = 'spark-history' as const;
export const useSparkHistory = (sparkId: string, enabled: boolean) => {
    const { data, isLoading } = useAdvancedQuery(
        [sparkHistoryCacheKey, sparkId],
        () => SparksAPI.getSparkHistory(sparkId!),
        { enabled },
    );
    return useMemo(
        () => ({
            sparkHistory: data?.data?.snapshots,
            sparkHistoryIsReady: !isLoading,
        }),
        [data?.data?.snapshots?.length, isLoading],
    );
};

const useSparkHistoryUsers = (sparkId: string, uniqueUserIds: string[], enabled: boolean) => {
    const { data, isLoading } = useAdvancedQuery(
        [sparkHistoryCacheKey, sparkId, 'users', uniqueUserIds],
        () =>
            Promise.all(
                uniqueUserIds.filter((userId) => !!userId).map((userId) => getUserData(userId)),
            ),
        { enabled },
    );

    return useMemo(
        () => ({
            sparkHistoryUsers: data,
            sparkHistoryUsersIsReady: !isLoading,
        }),
        [data, isLoading],
    );
};

interface RuleTooltipProps {
    groupName: string;
    groupMembers: string[];
    allBrands?: boolean;
    allProducts?: boolean;
}

const RuleTooltip = React.memo(
    ({ groupName, groupMembers, allBrands, allProducts }: RuleTooltipProps) => {
        return (
            <Tooltip
                className="rules-filter"
                placement="top"
                title={allBrands ? `All (${groupMembers.length}) Brands` : groupMembers.join(', ')}
            >
                <span>
                    ({allBrands || allProducts ? 'All' : groupMembers.length})&nbsp;{groupName}
                </span>
            </Tooltip>
        );
    },
);

interface SparkRulesBasedFilterBannerProps {
    productsTotal: number;
    retailerFilters?: RetailerSparkFilters;
    vendorFilters?: VendorSparkFilters;
    groupId: string;
    isAllProducts?: boolean;
}

const SparkRulesBasedFilterBanner = ({
    productsTotal,
    retailerFilters,
    vendorFilters,
    groupId,
    isAllProducts,
}: SparkRulesBasedFilterBannerProps) => {
    const { accountPosDataIsReady, accountPosBrands, accountPosCategories } =
        useAccountPosDataQuery(groupId, {
            includedDatasets: ['brands', 'categories'],
        });

    const filters = useMemo(() => {
        const tooltips = [];
        if (!isEmpty(vendorFilters)) {
            const productTagFilters = groupBy(vendorFilters.productTags, 'tagGroupName');

            const tagTooltips = Object.entries(productTagFilters).map(([groupName, tagFilters]) => {
                const tags = tagFilters.map(({ tagName }) => tagName);
                return <RuleTooltip key={groupName} groupName={groupName} groupMembers={tags} />;
            });
            if (tagTooltips.length < 1) {
                return [
                    <RuleTooltip key="brands" groupName="Products" groupMembers={[]} allProducts />,
                ];
            }
            return tagTooltips;
        } else {
            const getBrands = (brandIds: string[]) => {
                return brandIds?.flatMap((id) => {
                    return accountPosBrands
                        .filter((posBrand) => posBrand.value === id)
                        .map(({ name }) => name);
                });
            };

            const getCategories = (categoryIds: string[]) => {
                return categoryIds?.flatMap((id) => {
                    return accountPosCategories
                        .filter((posCategory) => posCategory.value === id)
                        .map(({ name }) => name);
                });
            };
            if (accountPosDataIsReady) {
                const brands = getBrands(retailerFilters?.posBrandIds ?? []);
                const mappedCategories = getCategories(retailerFilters?.posCategoryIds ?? []);
                if (mappedCategories && mappedCategories?.length > 0) {
                    tooltips.push(
                        <RuleTooltip groupName="Category" groupMembers={mappedCategories} />,
                    );
                }
                if (brands && brands?.length > 0) {
                    tooltips.push(<RuleTooltip groupName="Brands" groupMembers={brands} />);
                } else if (isAllProducts || retailerFilters?.primaryFilter === 'brands') {
                    tooltips.push(
                        <RuleTooltip
                            groupName="Brands"
                            groupMembers={accountPosBrands.map(({ name }) => name)}
                            allBrands
                        />,
                    );
                }
                return tooltips;
            } else {
                return [];
            }
        }
    }, [vendorFilters, retailerFilters]);

    const filtersList = filters?.map((filter, index) => {
        return (
            <>
                {filter}
                {index !== filters.length - 1 ? <span key={index}>and</span> : null}
            </>
        );
    });

    if (isEmpty(retailerFilters) && isEmpty(vendorFilters)) {
        return <></>;
    }

    return (
        <>
            <CalloutMessage
                className="spark-rules-filter-banner"
                message={
                    <div data-testid="spark-qualifying-products-banner">
                        The following list of <strong>{productsTotal} qualifying products</strong>{' '}
                        is generated by
                        {isAllProducts ? (
                            <> the inclusion of all products.</>
                        ) : (
                            <>
                                &nbsp;the
                                {filtersList}
                                {filters && filters.length > 1 ? 'filters' : 'filter'} applied in
                                the Spark.
                            </>
                        )}
                    </div>
                }
            />
        </>
    );
};

interface SparkProductsModalProps extends IDefaultModalProps {}

const SparkProductsModal: FC<SparkProductsModalProps> = ({ isVisible, onClose }) => {
    const { userIsSuperAdmin } = useApp();
    const {
        spark,
        sparkIsReady,
        sparkCommissionMap,
        sparkPosDataIsReady,
        sparkPosData,
        isVendorPosRulesBasedSpark,
        isVendorTagsRulesBasedSpark,
        isCreatingMultiRetailerSpark,
        isRulesBasedSpark,
    } = useSpark();

    if (!sparkIsReady || !sparkPosDataIsReady) {
        return <></>;
    }

    const { sparkHistory } = useSparkHistory(spark._id, userIsSuperAdmin);
    const { sparkHistoryUsers } = useSparkHistoryUsers(
        spark._id,
        uniq(
            sparkHistory?.flatMap(({ updatedBy, creatorUserId }) => [updatedBy, creatorUserId]) ??
                [],
        ),
        userIsSuperAdmin,
    );

    const { associatedProducts: originalAssociatedProducts } = sparkPosData;

    const [showDiffs, setShowDiffs] = useState(false);
    const [selectedSparkVersionIndex, setSelectedSparkVersionIndex] = useState(-1);

    const sparkHistoryOptions = useMemo(
        () => [
            { value: -1, label: 'Current' },
            ...(sparkHistory?.map((sparkSnapshot, i) => {
                const snapshotUser =
                    i === sparkHistory.length - 1
                        ? sparkHistoryUsers?.find(
                              (user) => user._id === sparkSnapshot.creatorUserId,
                          )
                        : sparkHistoryUsers?.find((user) => user._id === sparkSnapshot.updatedBy);
                const snapshotDate = moment(sparkSnapshot.updatedAt).format(DATE_DISPLAY_FORMAT);
                const snapshotTime = moment(sparkSnapshot.updatedAt).format('h:mma');
                return {
                    ...sparkSnapshot,
                    value: i,
                    label: `${
                        snapshotUser?.fullName ?? 'Unknown User'
                    } @ ${snapshotDate} ${snapshotTime}`,
                };
            }) ?? []),
        ],
        [sparkHistory, sparkHistoryUsers],
    );

    const sparkVersion = useMemo(
        () =>
            selectedSparkVersionIndex > -1 ? sparkHistory?.[selectedSparkVersionIndex] : undefined,

        [selectedSparkVersionIndex, sparkHistory],
    );

    const previousSparkVersion = useMemo(
        () => sparkHistory?.[selectedSparkVersionIndex + 1],

        [selectedSparkVersionIndex, sparkHistory],
    );

    const hasRetailerFilters = !isEmpty(spark?.retailerFilters);

    const sparkVersionRetailerFilters = usePosRulesBasedProductTableFilters({
        isEditingExistingSpark: false,
        initialFilters: {
            ...sparkVersion?.retailerFilters,
            brands: sparkVersion?.retailerFilters?.posBrandIds?.map((id: string) => ({
                key: id,
                value: id,
            })),
            categories: sparkVersion?.retailerFilters?.posCategoryIds?.map((id: string) => ({
                key: id,
                value: id,
            })),
            excludedProductIds: [],
        },
        sparkStartDate: sparkVersion?.startDate,
    });

    const previousSparkVersionRetailerFilters = usePosRulesBasedProductTableFilters({
        isEditingExistingSpark: false,
        initialFilters: {
            ...previousSparkVersion?.retailerFilters,
            brands: previousSparkVersion?.retailerFilters?.posBrandIds?.map((id: string) => ({
                key: id,
                value: id,
            })),
            categories: previousSparkVersion?.retailerFilters?.posCategoryIds?.map(
                (id: string) => ({
                    key: id,
                    value: id,
                }),
            ),
            excludedProductIds: [],
        },
        sparkStartDate: sparkVersion?.startDate,
    });

    const sparkVersionVendorFilters = useRulesBasedProductTableFilters({
        spark: sparkVersion,
        allowSearch: false,
    });

    const previousSparkVersionVendorFilters = useRulesBasedProductTableFilters({
        spark: previousSparkVersion,
        allowSearch: false,
    });

    const { sparkPosProductData } = useSparkPosProductData(sparkVersion);
    const {
        sparkPosProductData: { products: previousAssociatedProducts },
    } = useSparkPosProductData(previousSparkVersion);

    useEffect(() => {
        if (previousSparkVersion) {
            if (hasRetailerFilters) {
                previousSparkVersionRetailerFilters.setFilters({
                    ...previousSparkVersion?.retailerFilters,
                    brands: previousSparkVersion?.retailerFilters?.posBrandIds?.map(
                        (id: string) => ({
                            key: id,
                            value: id,
                        }),
                    ),
                    categories: previousSparkVersion?.retailerFilters?.posCategoryIds?.map(
                        (id: string) => ({ key: id, value: id }),
                    ),
                    excludedProductIds: [],
                });
            } else {
                previousSparkVersionVendorFilters.setFilters(
                    { ...previousSparkVersion?.vendorFilters, lastSoldAt: '-60days' } ?? [],
                );
            }
        }
    }, [previousSparkVersion]);

    useEffect(() => {
        if (sparkVersion) {
            if (hasRetailerFilters) {
                sparkVersionRetailerFilters.setFilters({
                    ...sparkVersion?.retailerFilters,
                    brands: sparkVersion?.retailerFilters?.posBrandIds?.map((id: string) => ({
                        key: id,
                        value: id,
                    })),
                    categories: sparkVersion?.retailerFilters?.posCategoryIds?.map(
                        (id: string) => ({
                            key: id,
                            value: id,
                        }),
                    ),
                    excludedProductIds: [],
                });
            } else {
                sparkVersionVendorFilters.setFilters(
                    { ...sparkVersion?.vendorFilters, lastSoldAt: '-60days' } ?? [],
                );
            }
        }
    }, [sparkVersion]);

    const { vendorBrandRetailerProducts } = useVendorBrandRetailersProductsByBrandId({
        brandId: spark.sparkBrandId,
        vendorAccountId: spark.originatorGroupId,
        vendorBrandRetailerIds: [spark.groupId],
    });
    const multiRetailerProducts = uniq(
        Object.entries(vendorBrandRetailerProducts).flatMap(([accountId, products]) => {
            return products;
        }),
    );

    const selectedProducts = useMemo(() => {
        return hasRetailerFilters
            ? sparkVersionRetailerFilters.getRulesBasedFilteredProducts(
                  sparkPosProductData.products,
              )
            : sparkVersionVendorFilters.getRulesBasedFilteredProducts(multiRetailerProducts);
    }, [
        sparkPosProductData.products,
        sparkVersionRetailerFilters,
        multiRetailerProducts,
        sparkVersionVendorFilters,
    ]);

    const previousSelectedProducts = useMemo(() => {
        return hasRetailerFilters
            ? previousSparkVersionRetailerFilters.getRulesBasedFilteredProducts(
                  previousAssociatedProducts,
              )
            : previousSparkVersionVendorFilters.getRulesBasedFilteredProducts(
                  multiRetailerProducts,
              );
    }, [
        previousAssociatedProducts,
        previousSparkVersionRetailerFilters,
        multiRetailerProducts,
        previousSparkVersionVendorFilters,
    ]);

    const associatedProducts = useMemo(() => {
        if (!userIsSuperAdmin) {
            return originalAssociatedProducts;
        }
        if (selectedSparkVersionIndex === -1) {
            return originalAssociatedProducts;
        }
        return selectedProducts;
        // associated products ON THE SELECTED VERSION
    }, [userIsSuperAdmin, sparkVersion, originalAssociatedProducts, sparkPosProductData]);

    const isRulesBased =
        isVendorTagsRulesBasedSpark || isVendorPosRulesBasedSpark || isRulesBasedSpark;

    const isAllProducts = [
        'order_average',
        'transaction_count',
        'units_per_transaction',
        'percent_increase',
    ].includes(spark.metric);

    return (
        <InfoModal
            className="spark-details-content-modal extra-wide"
            isVisible={isVisible}
            title="Qualifying Products"
            onClose={onClose}
        >
            {userIsSuperAdmin && !!sparkHistory?.length && (
                <div className="change-log">
                    {showDiffs ? (
                        <Select
                            options={sparkHistoryOptions}
                            value={selectedSparkVersionIndex}
                            onChange={(e) => setSelectedSparkVersionIndex(Number(e.target.value))}
                        />
                    ) : (
                        <Button onClick={() => setShowDiffs(true)}>View Changes</Button>
                    )}
                </div>
            )}
            {isRulesBased && (
                <SparkRulesBasedFilterBanner
                    groupId={spark.groupId}
                    productsTotal={associatedProducts.length}
                    retailerFilters={
                        selectedSparkVersionIndex === -1
                            ? spark.retailerFilters
                            : previousSparkVersion?.retailerFilters ?? []
                    }
                    vendorFilters={
                        selectedSparkVersionIndex === -1
                            ? spark.vendorFilters
                            : previousSparkVersion?.vendorFilters ?? []
                    }
                    isAllProducts={isAllProducts}
                />
            )}

            <SparkProductsTable
                spark={spark}
                sparkCommissionMap={sparkCommissionMap}
                associatedProducts={associatedProducts}
                previousAssociatedProducts={
                    userIsSuperAdmin && showDiffs ? previousSelectedProducts : undefined
                }
                isVendorTagsRulesBasedSpark={isVendorTagsRulesBasedSpark}
            />
        </InfoModal>
    );
};

export default SparkProductsModal;
