import BrandGallery from '@features/spark-brands/components/SparkBrandGallery';

import AppView from '@components/layout/AppView';
import Paper from '@components/layout/Paper';
import { SectionTitle } from '@components/layout/SectionTitle';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

import { AccountDetailsWidget } from './AccountDetailsWidget';

import './AdminGeneralSettingsView.scss';

const AdminGeneralSettingsView = ({ account }: { account: IAccount }) => {
    return (
        <Paper className="admin-general-settings">
            <section>
                <SectionTitle>Account Details</SectionTitle>
                <AccountDetailsWidget
                    accountName={account.name}
                    markets={account.markets}
                    industries={account.industries}
                    referralUrl={account.referralUrl}
                    logo={account.photo ?? undefined}
                />
            </section>

            <section>
                <SectionTitle>Spark Branding</SectionTitle>
                <BrandGallery accountId={account._id} />
            </section>
        </Paper>
    );
};

export default () => {
    const { account } = useSparkplugAccount();

    if (!account) {
        return <></>;
    }

    return (
        <AppView.Content title="General">
            <AdminGeneralSettingsView account={account} />
        </AppView.Content>
    );
};
