import { FC, useMemo } from 'react';

import { uniqBy } from 'lodash';

import { HydratedBrandProduct, SparkCommissionType } from '@sparkplug/lib';

import { TagsMenuItemWrapper } from '@features/product-tags/views/AssigningProductTagsView/ProductTableToolbar/ProductTableToolbar';
import {
    MultiRetailerTableFilters,
    UseMultiRetailerCommissionTableFilters,
} from '@features/spark-wizard/components/CommissionCommissionsFormFields/hooks/useMultiRetailerCommissionTableFilters';

import Toolbar from '@components/toolbar/Toolbar';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { sortByString } from '@helpers/ui';

import { IAccountRetailers } from '@app/types/AccountsTypes';
import { IOption } from '@app/types/UITypes';

import { CommissionMap } from '../../../helpers/getHeadCells';
import ToolbarCommissionInput from '../../ToolbarCommissionInput';

import './MultiRetailerCommissionTableToolbar.scss';

const getRetailerOptions = (products: { groupId: string }[], retailers: IAccountRetailers[]) => {
    uniqBy(products, 'groupId')
        .map(({ groupId }) => ({
            value: groupId,
            label: retailers?.find(({ _id }) => _id === groupId)?.name ?? '',
        }))
        .sort(sortByString('label', 'asc'));
    return retailers;
};

interface MultiRetailerCommissionTableToolbarProps {
    commissionType: SparkCommissionType;
    brandOptions: IOption[];
    categoryOptions: IOption[];
    tableFilters: UseMultiRetailerCommissionTableFilters;

    products: HydratedBrandProduct[];
    updateDisplayedCommissionValues: (
        productIds: string[],
        applyAllValue: number | undefined,
    ) => void;
    isVendorEditExperience: boolean;
    originalCommissionValuesMap: CommissionMap;
    showRetailerFilterOptions: boolean;
    showPosFilterOptions?: boolean;
}

const MultiRetailerCommissionTableToolbar: FC<MultiRetailerCommissionTableToolbarProps> = ({
    updateDisplayedCommissionValues,
    commissionType,
    tableFilters,
    brandOptions,
    categoryOptions,
    isVendorEditExperience,
    products,
    originalCommissionValuesMap,
    showRetailerFilterOptions,
    showPosFilterOptions = false,
}) => {
    const { account } = useSparkplugAccount();
    const { filters, setFilters, onChangeSearchFilter } = tableFilters;

    const tagOptions = useMemo(
        () =>
            uniqBy(
                products.flatMap(({ tags }) => tags || []),
                '_id',
            )
                .map(({ _id, name, color, groupName }) => {
                    return {
                        value: _id,
                        label: name,
                        color,
                        category: groupName,
                    };
                })
                .sort((a, b) => {
                    if (a.category === b.category) {
                        return a.label.localeCompare(b.label);
                    }
                    return a.category.localeCompare(b.category);
                }),
        [products],
    );

    const retailerOptions = useMemo(() => {
        return getRetailerOptions(products, account?.retailers ?? []);
    }, [products]);

    return (
        <>
            <Toolbar>
                {onChangeSearchFilter && (
                    <Toolbar.Search
                        name="commission-product-search-filter"
                        onChange={onChangeSearchFilter}
                    />
                )}
                {showPosFilterOptions && (
                    <>
                        <Toolbar.MultiSelectDropdown
                            label="Categories"
                            options={categoryOptions}
                            selected={filters.categoryIds}
                            onApply={(value) =>
                                setFilters((prevValue) => ({
                                    ...prevValue,
                                    categoryIds: value,
                                }))
                            }
                        />
                        <Toolbar.MultiSelectDropdown
                            label="Brands"
                            options={brandOptions}
                            selected={filters.brandIds}
                            onApply={(value) =>
                                setFilters((prevValue) => ({
                                    ...prevValue,
                                    brandIds: value,
                                }))
                            }
                        />
                    </>
                )}
                {tagOptions.length > 0 && (
                    <Toolbar.MultiSelectDropdown
                        className="table-toolbar-tag-dropdown"
                        label="Tags"
                        options={tagOptions}
                        selected={filters.tagIds}
                        onApply={(value) =>
                            setFilters((prevValue: MultiRetailerTableFilters) => ({
                                ...prevValue,
                                tagIds: value,
                            }))
                        }
                        MenuItemWrapper={TagsMenuItemWrapper}
                    />
                )}
                {showRetailerFilterOptions && (
                    <Toolbar.MultiSelectDropdown
                        label="Retailers"
                        options={retailerOptions}
                        selected={filters.retailerIds}
                        onApply={(value) =>
                            setFilters((prevValue) => ({
                                ...prevValue,
                                retailerIds: value,
                            }))
                        }
                    />
                )}

                <ToolbarCommissionInput
                    updateDisplayedCommissionValues={updateDisplayedCommissionValues}
                    commissionType={commissionType}
                    isVendorEditExperience={isVendorEditExperience}
                    originalCommissionValuesMap={originalCommissionValuesMap}
                />
            </Toolbar>
        </>
    );
};

export default MultiRetailerCommissionTableToolbar;
