import {
    DependencyList,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import { debounce, get } from 'lodash';

import { useQueryParamsState } from '@hooks/QueryParamsHooks';

import Intercom from '@helpers/Intercom';

export const useTabs = (initialValue: number = 0) => {
    const [currentTab, setCurrentTab] = useState<number>(initialValue);

    return [currentTab, setCurrentTab];
};

export const useRouteTabs = () => {
    const { path } = useRouteMatch();
    const { pathname } = useLocation();

    return {
        tabValue: pathname,
        currentPath: path,
    };
};

export const useSearch = (searchKeys: string[], usesSearchQueryParam: boolean = false) => {
    let timer: any;

    const [query, setQuery] = usesSearchQueryParam
        ? useQueryParamsState({
              s: '',
          })
        : [{}, () => {}];
    const [searchFilter, _setSearchFilter] = useState<string>(query?.s ?? '');

    const setSearchFilter = (value: string) => {
        _setSearchFilter(value);

        if (usesSearchQueryParam) {
            setQuery(value ? { s: value } : { s: undefined });
        }
    };

    const onChangeSearchFilter = (event: any) => {
        clearTimeout(timer);

        timer = setTimeout(() => {
            const value =
                event.target.value != null ? event.target.value.trim().toLowerCase() : null;
            setSearchFilter(value);
        }, 250);
    };

    const applySearch = useCallback(
        (list: any[]) =>
            list.filter((listItem) => {
                if (listItem) {
                    const listItemSearchStr = searchKeys
                        .map((searchKey) => get(listItem, searchKey))
                        .join('|')
                        .toLowerCase();
                    return listItemSearchStr.includes(searchFilter);
                }

                return false;
            }),
        [searchFilter],
    );

    return {
        searchFilter,
        resetSearchFilter: () => setSearchFilter(''),
        onChangeSearchFilter,
        applySearch,
    };
};

export function useIntercom() {
    const openIntercom = () => {
        Intercom.open();
    };

    return {
        openIntercom,
    };
}

interface IUseCountdownHook {
    remainingTime: number;
    startCountdown: (duration: number) => any;
    countdownIsRunning: boolean;
}

export function useCountdown() {
    const [remainingTime, setRemainingTime] = useState<number>(0);

    const countdownIsRunning = remainingTime > 0;
    useEffect(() => {
        if (countdownIsRunning) {
            const timer = setTimeout(() => {
                setRemainingTime((prev) => prev - 1);
            }, 1000);

            return () => clearTimeout(timer);
        }
        return undefined;
    }, [countdownIsRunning, remainingTime]);

    const startCountdown = (duration: number) => setRemainingTime(duration);

    const value: IUseCountdownHook = useMemo(
        () => ({
            remainingTime,
            startCountdown,
            countdownIsRunning,
        }),
        [remainingTime, countdownIsRunning],
    );

    return value;
}

export const useResize = (cb: () => void) => {
    useEffect(() => {
        const debouncedHandleResize = debounce(cb, 500);

        window.addEventListener('resize', debouncedHandleResize);

        return () => {
            window.removeEventListener('resize', debouncedHandleResize);
        };
    }, []);
};

export const useDynamicHeight = (
    containerRef: RefObject<HTMLElement> | undefined,
    childRef: RefObject<HTMLElement> | undefined,
    options: {
        defaultHeight?: number;
        dependencies?: DependencyList;
    } = {},
): number => {
    const { defaultHeight = 400, dependencies = [] } = options;
    const [height, setHeight] = useState(defaultHeight);

    const updateHeight = () => {
        if (containerRef?.current && childRef?.current) {
            const containerComputedStyle = getComputedStyle(containerRef.current);
            const containerHeight =
                containerRef.current.clientHeight -
                parseFloat(containerComputedStyle.paddingTop) -
                parseFloat(containerComputedStyle.paddingBottom);
            const containerOffsetTop = containerRef.current.getBoundingClientRect().top;
            const listOffsetTop = childRef.current.getBoundingClientRect().top;

            const listContainerOffset = listOffsetTop - containerOffsetTop;

            const updatedHeight = containerHeight - listContainerOffset;

            setHeight(updatedHeight > 0 ? updatedHeight : defaultHeight);
        }
    };

    useResize(updateHeight);

    useLayoutEffect(() => {
        updateHeight();
    }, dependencies);

    return height;
};

export const useScrollToTop = () => {
    const { listen } = useHistory();
    useEffect(() => {
        const unlisten = listen(() => {
            window.scrollTo(0, 0);
        });
        return () => {
            unlisten();
        };
    }, []);
};
