import { FC } from 'react';

import { HydratedBrandProduct } from '@sparkplug/lib';

import { useBulkClearTags } from '@features/product-tags/mutations';

import ConfirmModal from '@components/overlays/ConfirmModal';
import { IDefaultModalProps } from '@components/overlays/Modal';
import toast from '@components/toast';

import { useTableContext } from '@hooks/TableHooks';

import { isEmpty } from '@helpers/util';

interface BulkClearTagsConfirmModalProps extends IDefaultModalProps {
    vendorAccountId: string;
    retailerAccountId: string;
    brandId: string;
    brandLinkId?: string;
}

const BulkClearTagsConfirmModal: FC<BulkClearTagsConfirmModalProps> = ({
    vendorAccountId,
    retailerAccountId,
    brandId,
    brandLinkId = '',
    isVisible,
    onClose,
}) => {
    if (!vendorAccountId || !retailerAccountId || !brandId) {
        return <></>;
    }

    const { isBulkClearingTags, bulkClearTagsAsync } = useBulkClearTags(
        vendorAccountId,
        retailerAccountId,
        brandLinkId,
    );

    const { tableRows, tableSelected, tableUncheckAll } = useTableContext<
        HydratedBrandProduct & { key: string }
    >();

    const selectedRows =
        tableRows?.filter((product) => {
            return tableSelected.includes(product.key) && product.tags.length > 0;
        }) || [];

    const selectedRowsWithoutTags =
        tableRows?.filter((product) => {
            return tableSelected.includes(product.key) && product.tags.length === 0;
        }) || [];

    const confirmBulkInvalidateProducts = async () => {
        if (isEmpty(selectedRows)) {
            onClose(false);
            return;
        }

        toast.promise(
            bulkClearTagsAsync(
                {
                    productIds: selectedRows.map(({ _id }) => _id),
                    brandId,
                    retailerId: retailerAccountId,
                },
                {
                    onSuccess: () => {
                        tableUncheckAll();
                        onClose(true);
                    },
                    onError: () => {
                        onClose(false);
                    },
                },
            ),
            {
                loading: 'Clearing tags...',
                success: `${selectedRows.length} product tag${
                    selectedRows.length === 1 ? '' : 's'
                } cleared`,
                error: `Error clearing tags from selected product${
                    selectedRows.length === 1 ? '' : 's'
                }  - please try again and contact support if this issue persists.`,
            },
        );
    };

    let clearMessage;
    switch (selectedRows.length) {
        case 0:
            clearMessage = `No products selected currently have tags assigned.`;
            break;
        case 1:
            clearMessage = `Are you sure you wish to clear all tags from this product?`;
            break;
        default:
            clearMessage = `Are you sure you wish to clear all tags from these ${selectedRows.length} products?`;
            break;
    }

    if (selectedRows.length && selectedRowsWithoutTags.length) {
        const noTagsMessage =
            selectedRowsWithoutTags.length === 1
                ? `There was ${selectedRowsWithoutTags.length} product selected that does not have tags - it will not be affected by this action.`
                : `There were ${selectedRowsWithoutTags.length} products selected that do not have tags - these will not be affected by this action.`;
        clearMessage = `${clearMessage} ${noTagsMessage}`;
    }

    return (
        <ConfirmModal
            title="Clear product tags?"
            message={clearMessage}
            isVisible={isVisible}
            onConfirm={
                selectedRows.length > 0 ? confirmBulkInvalidateProducts : () => onClose(false)
            }
            onClose={() => onClose(false)}
            confirmText={selectedRows.length > 0 ? 'Clear Tags' : 'Ok'}
            disabled={isBulkClearingTags}
        />
    );
};

export default BulkClearTagsConfirmModal;
