import { FC, useEffect, useRef, useState } from 'react';

import Button from '@components/buttons/Button';
import Skeleton from '@components/layout/Skeleton';

import { ReactComponent as CashBalanceCardGraphic } from './CashBalanceCard.svg';

import './CashBalanceCard.scss';

interface CashBalanceCardProps {
    animate?: boolean;
    cashBalance: number;
    onClickCashOut: () => void;
    isLoading?: boolean;
}

const TIME_TO_REACH_UPDATED_BALANCE = 1000;
const FPS = 30;
const NUMBER_OF_STEPS = TIME_TO_REACH_UPDATED_BALANCE / FPS;

const CashBalanceCard: FC<CashBalanceCardProps> = ({
    animate = false,
    cashBalance,
    onClickCashOut,
    isLoading = false,
}) => {
    const incrementValue = useRef(0);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if (!animate) {
            // Regardless, make sure they are in sync
            if (balance !== cashBalance) {
                setBalance(cashBalance);
            }
            return undefined;
        }

        if (!incrementValue.current) {
            incrementValue.current = (cashBalance - balance) / NUMBER_OF_STEPS;
        }

        let direction: string | undefined = cashBalance < balance ? 'dec' : 'inc';

        if (cashBalance === balance) {
            direction = undefined;
        }

        if (direction) {
            // use min/max based on the direction
            const timer = setTimeout(() => {
                setBalance((prevBalance) => {
                    const newVal = prevBalance + incrementValue.current;
                    return direction === 'inc'
                        ? Math.min(newVal, cashBalance)
                        : Math.max(newVal, cashBalance); // 'dec'
                });
            }, 1000 / FPS);

            return () => {
                clearTimeout(timer);
            };
        } else {
            incrementValue.current = 0;
        }

        return undefined;
    }, [balance, cashBalance]);

    const cashBalanceFormatted = `$${(animate ? balance : cashBalance).toFixed(2)}`;

    if (isLoading) {
        return (
            <div className="wallet-card_card wallet-card_loading-card">
                <Skeleton />
            </div>
        );
    }

    return (
        <div className="wallet-card_card">
            <CashBalanceCardGraphic />
            <section>
                <div className="wallet-card_balance-container">
                    <span className="balance" data-testid="cash-balance">
                        {cashBalanceFormatted}
                    </span>
                    <span className="balance-label">Cash Balance</span>
                </div>
                <Button
                    className="wallet-card_cash-out-button"
                    color="blue"
                    variant="filled"
                    onClick={onClickCashOut}
                >
                    Cash Out
                </Button>
            </section>
        </div>
    );
};

export default CashBalanceCard;
