import { useEffect } from 'react';

import { AnimatedCheckmarkGraphic } from '@components/graphics';
import { SparkplugLogo } from '@components/logos';
import Modal from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';

import './CodeVerifiedOverlay.scss';

interface ICodeVerifiedOverlayProps {
    isVisible: boolean;
    redirectTo: string;
}

const CodeVerifiedOverlay = ({ isVisible, redirectTo }: ICodeVerifiedOverlayProps) => {
    const { history } = useApp();

    useEffect(() => {
        if (isVisible) {
            setTimeout(() => {
                history.push(redirectTo);
            }, 2000);
        }
    }, [isVisible, redirectTo]);

    return (
        <Modal className="code-verified-overlay" isVisible={isVisible} onClose={() => {}}>
            <Modal.Content>
                <div className="sparkplug-logo">
                    <SparkplugLogo />
                </div>
                <AnimatedCheckmarkGraphic />
            </Modal.Content>
        </Modal>
    );
};

export default CodeVerifiedOverlay;
