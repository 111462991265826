import {
    SparkInternalInvoiceStatus,
    SparkInternalPayoutStatus,
    SparkInternalStatus,
    SparkRequestState,
    SparkType,
} from '@sparkplug/lib';

import {
    CreditCardIcon,
    DollarCoinIcon,
    IncreaseIcon,
    ProductIcon,
    PurchaseOrderIcon,
    ShoppingCartPromotionIcon,
    TransactionIcon,
} from '@components/icons';
import { RadioOption } from '@components/inputs/RadioGroup/RadioGroupOption';

export const internalTrackingFieldValueNames: Record<
    SparkInternalStatus | SparkInternalInvoiceStatus | SparkInternalPayoutStatus,
    string
> = {
    // spark status fields
    verify: 'Verify Spark',
    announcement: 'Announcement SMS',
    'reminder-1': 'Reminder 1 SMS',
    'update-1': 'Update Spark 1',
    'reminder-2': 'Reminder 2 SMS',
    'update-2': 'Update Spark 2',
    update: 'Update',
    'do-not-payout': 'Do not Payout',
    'ready-for-payout': 'Ready for Payout',

    // invoice status fields
    'not-sent': 'Not Sent',
    sent: 'Sent',
    failed: 'Failed',
    void: 'Void',
    // payout status field
    'not-paid': 'Not Paid',

    // invoice + payout status field
    paid: 'Paid',
    'special-payout': 'Special Payout',
    none: '--',
};

export const TrainingType = { required: 'REQUIRED', optional: 'OPTIONAL' };

export const TrainingRequiredOptions: RadioOption[] = [
    {
        label: 'Required Training',
        value: TrainingType.required,
        description:
            'Employees cannot participate or earn rewards until they complete this course.',
    },
    {
        label: 'Optional Training',
        value: TrainingType.optional,
        description: 'Employees can participate and earn rewards without completing the course.',
    },
];
const { REACT_APP_PERCENT_INCREASE = false } = process.env;
export interface SparkMetricOption extends Omit<RadioOption, 'label'> {
    label: string;
    percentIncreaseMetricOptions?: string[];
}

export const MetricOptionsAll: SparkMetricOption[] = [
    {
        icon: <ProductIcon />,
        label: 'Units Sold',
        description: 'Track sales of selected products in units',
        value: 'total_units',
    },
    {
        icon: <CreditCardIcon />,
        label: 'Transactions',
        description: 'Track number of transactions made',
        value: 'transaction_count',
    },
    {
        icon: <DollarCoinIcon />,
        label: 'Dollar Amount',
        description: 'Track sales of selected products in dollars',
        value: 'total_sales',
    },
    {
        icon: <TransactionIcon />,
        label: 'Units Per Transaction',
        description: 'Track average units sold per transaction',
        value: 'units_per_transaction',
    },
    {
        icon: <ShoppingCartPromotionIcon />,
        label: 'Percentage of Total Sales',
        description: 'Track sales of selected products as a percentage of overall sales',
        value: 'percent_of_total_sales',
    },
    {
        icon: <PurchaseOrderIcon />,
        label: 'Order Average',
        description: 'Track average order size in dollars',
        value: 'order_average',
    },
    ...(REACT_APP_PERCENT_INCREASE === 'true'
        ? [
              {
                  icon: <IncreaseIcon />,
                  label: 'Percentage Increase',
                  description: 'Track performance compared to previous period',
                  value: 'percent_increase',
                  percentIncreaseMetricOptions: ['order_average'],
              },
          ]
        : []),
];
export const MetricOptionsLimited = MetricOptionsAll.filter(({ value }) =>
    ['total_units', 'total_sales', 'percent_of_total_sales'].includes(value),
);

export const ScopeOptionsAll = [
    {
        label: 'All Products',
        value: 'all',
    },
    {
        label: 'Product Brand',
        value: 'brands',
    },
    {
        label: 'Product Category',
        value: 'categories',
    },
    {
        label: 'Specific Product',
        value: 'products',
    },
];

export const ScopeOptionsLimited = [
    {
        label: 'All Products',
        value: 'all',
    },
    {
        label: 'Product Brand',
        value: 'brands',
    },
    {
        label: 'Product Category',
        value: 'categories',
    },
];

export const ScopeOptions = [
    {
        label: 'All Products',
        value: 'all',
    },
    {
        label: 'Brands',
        value: 'brands',
    },
    {
        label: 'Categories',
        value: 'categories',
    },
];

export const CommissionTypeOptions: SparkMetricOption[] = [
    {
        icon: <DollarCoinIcon />,
        label: 'Dollar Amount Commission',
        description:
            'Apply a dollar amount (e.g. $1) to products that employees will earn on each unit sold.',
        value: 'flat',
    },
    {
        icon: <ShoppingCartPromotionIcon />,
        label: 'Percentage Commission',
        description:
            'Apply a percentage (e.g. 5%) to products that employees will earn on each unit sold.',
        value: 'percentage',
    },
];

export const PrizeOptions: Partial<
    Record<
        SparkType,
        Array<{
            label: string;
            value: string;
        }>
    >
> = {
    leaderboard: [
        {
            label: 'Top 1',
            value: '1',
        },
        {
            label: 'Top 2',
            value: '2',
        },
        {
            label: 'Top 3',
            value: '3',
        },
        {
            label: 'Top 4',
            value: '4',
        },
        {
            label: 'Top 5',
            value: '5',
        },
        {
            label: 'Top 6',
            value: '6',
        },
    ],
    goal: [
        {
            label: '1 Goal',
            value: '1',
        },
        {
            label: '2 Goals',
            value: '2',
        },
        {
            label: '3 Goals',
            value: '3',
        },
        {
            label: '4 Goals',
            value: '4',
        },
        {
            label: '5 Goals',
            value: '5',
        },
        {
            label: 'Unlimited Goals',
            value: 'unlimited',
        },
    ],
};

export const PrizeLabels = [
    '1st Place',
    '2nd Place',
    '3rd Place',
    '4th Place',
    '5th Place',
    '6th Place',
];

export const GoalLabels = ['1st Goal', '2nd Goal', '3rd Goal', '4th Goal', '5th Goal', '6th Goal'];

export enum ESparkStatusColors {
    Upcoming = 'yellow',
    Active = 'green',
    Complete = 'dark-gray',
    Rejected = 'red',
    Pending = 'neutral',
    Expired = 'light-gray',
    None = 'neutral',
}

export const SparkRequestStatuses: Partial<Record<SparkRequestState, string>> = {
    rejected: 'Rejected',
    expired: 'Expired',
    pending: 'Pending',
};

export const internalTrackingDropdownOptions = {
    status: [
        {
            value: 'none',
            label: 'Unverified',
        },
        {
            value: 'update',
            label: 'Update',
        },
        {
            value: 'do-not-payout',
            label: 'Do not Payout',
        },
        {
            value: 'ready-for-payout',
            label: 'Ready for Payout',
        },
    ],

    invoiceStatus: [
        {
            value: 'not-sent',
            label: 'Not Sent',
        },
        {
            value: 'sent',
            label: 'Sent',
        },
        {
            value: 'paid',
            label: 'Paid',
        },
        {
            value: 'failed',
            label: 'Failed',
        },
        {
            value: 'void',
            label: 'Void',
        },
    ],

    payoutStatus: [
        {
            value: 'not-paid',
            label: 'Not Paid',
        },
        {
            value: 'paid',
            label: 'Paid',
        },
        {
            value: 'special-payout',
            label: 'Special Payout',
        },
    ],
};

export const CompletedSparkTypeOptions = [
    {
        value: 'all',
        label: 'All Types',
    },
    {
        value: 'claimed',
        label: 'Claimed',
    },
    {
        value: 'unclaimed',
        label: 'Unclaimed',
    },
];

export const DayNameFromNumber = {
    0: {
        long: 'Sunday',
        short: 'Sun',
    },
    1: {
        long: 'Monday',
        short: 'Mon',
    },
    2: {
        long: 'Tuesday',
        short: 'Tue',
    },
    3: {
        long: 'Wednesday',
        short: 'Wed',
    },
    4: {
        long: 'Thursday',
        short: 'Thu',
    },
    5: {
        long: 'Friday',
        short: 'Fri',
    },
    6: {
        long: 'Saturday',
        short: 'Sat',
    },
};
