import React, { useState } from 'react';

import moment from 'moment-timezone';

import { ACCOUNT_MARKETS_RECORD } from '@sparkplug/lib';

import formatDuration from '@core/utils/formatDuration';

import SnapTableCell from '@features/spark-snaps/components/SnapTableCell/SnapTableCell';
import { downloadSnapEngagementCSV } from '@features/spark-snaps/queries';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import Dropdown from '@components/dropdown/Dropdown';
import { MoreVert as MoreIcon } from '@components/icons';
import Spinner from '@components/layout/Spinner';
import Table from '@components/table/Table';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { THeadCell } from '@app/types/TableTypes';

import { ISnapRow } from '../types';

export const useFeaturedSnapHeadCells = ({
    onDeleteClick,
    onRemoveClick,
    onPreviewClick,
}: {
    onDeleteClick: (snapId: number) => void;
    onRemoveClick: ({
        snapId,
        featuredPeriodId,
    }: {
        snapId: number;
        featuredPeriodId: string;
    }) => void;
    onPreviewClick: ({
        selectedSnapId,
        accountId,
    }: {
        selectedSnapId: number;
        accountId: string;
    }) => void;
}) => {
    const [csvIsDownloading, setCsvIsDownloading] = useState(false);
    const { account } = useSparkplugAccount();
    const { user, history } = useApp();
    const handleDownloadEnagementCSV = (row: ISnapRow) => {
        setCsvIsDownloading(true);
        downloadSnapEngagementCSV({
            accountId: account?._id || '',
            snapId: row.storifymeSnapId || 0,
            filename: `featured-snap-${row.storifymeSnapId}-engagement.csv`,
        }).finally(() => {
            setCsvIsDownloading(false);
        });
    };
    const isFeaturedSnapsMarketTargeted =
        process.env.REACT_APP_FEATURED_SNAPS_MARKET_TARGETED === 'true';

    const headCells: THeadCell<ISnapRow>[] = [
        {
            id: 'name',
            label: 'Featured Snap',
            render: (row: ISnapRow) => (
                <Table.Cell
                    onClick={() => {
                        return (
                            row.storifymeSnapId &&
                            onPreviewClick({
                                selectedSnapId: row.storifymeSnapId,
                                accountId: account?._id || '',
                            })
                        );
                    }}
                >
                    <SnapTableCell
                        name={row.name || ''}
                        iconUrl={row.brandPhoto || row.accountPhoto || row.thumbnailUrl}
                    />
                </Table.Cell>
            ),
        },
        {
            id: 'status',
            label: 'Status',
            sortType: 'string',
            render: ({ status, brandId }: ISnapRow) => (
                <Table.Cell>
                    <Chip color={status === 'removed' ? 'neutral' : 'green'} label={status} />
                </Table.Cell>
            ),
        },
        ...(isFeaturedSnapsMarketTargeted
            ? [
                  {
                      id: 'audience',
                      label: 'Audience',
                      render: ({ markets }: ISnapRow) => (
                          <Table.Cell>
                              {markets?.length === Object.keys(ACCOUNT_MARKETS_RECORD).length
                                  ? 'All Markets'
                                  : markets?.join(', ') || 'All Markets'}
                          </Table.Cell>
                      ),
                  },
              ]
            : []),
        {
            id: 'launchDate',
            label: 'Launch Date',
            render: (row: ISnapRow) => (
                <Table.Cell>
                    {moment(row?.featuredPeriods?.[0]?.startDate).format('MMM D, YYYY')}
                </Table.Cell>
            ),
        },
        {
            id: 'removedDate',
            label: 'Removed Date',
            render: (row: ISnapRow) => (
                <Table.Cell>
                    {row?.featuredPeriods?.[0]?.endDate ? (
                        moment(row?.featuredPeriods?.[0].endDate).format('MMM D, YYYY')
                    ) : (
                        <Button
                            dense
                            onClick={() =>
                                onRemoveClick({
                                    snapId: row.storifymeSnapId || 0,
                                    featuredPeriodId: row.featuredPeriods?.[0]?._id || '',
                                })
                            }
                        >
                            Remove
                        </Button>
                    )}
                </Table.Cell>
            ),
        },
        {
            id: 'views',
            label: 'Views',
            render: (row: ISnapRow) => (
                <Table.Cell>
                    <span style={{ display: 'none' }}>{row.uniqueViewCount?.toLocaleString()}</span>
                    {row.cumulativeViewCount ? row.cumulativeViewCount.toLocaleString() : '-'}
                </Table.Cell>
            ),
        },
        {
            id: 'totalViewTime',
            label: 'Total View Time',
            render: ({ viewTime }) => {
                if (!viewTime) {
                    return <Table.Cell>0</Table.Cell>;
                }
                const viewTimeInMs = viewTime * 1000;
                return <Table.Cell>{formatDuration(viewTimeInMs)}</Table.Cell>;
            },
        },
        {
            id: 'actions',
            label: '',
            render: (row: ISnapRow) => (
                <Table.Cell>
                    <Dropdown>
                        <Dropdown.IconButton color="neutral">
                            <MoreIcon />
                        </Dropdown.IconButton>

                        <Dropdown.Menu>
                            {!row?.featuredPeriods?.[0]?.endDate && (
                                <Dropdown.MenuItem
                                    onClick={() => {
                                        return onRemoveClick({
                                            snapId: row.storifymeSnapId || 0,
                                            featuredPeriodId: row.featuredPeriods?.[0]?._id || '',
                                        });
                                    }}
                                >
                                    Remove Snap
                                </Dropdown.MenuItem>
                            )}

                            <Dropdown.MenuItem
                                onClick={() => {
                                    return (
                                        row.storifymeSnapId &&
                                        onPreviewClick({
                                            selectedSnapId: row.storifymeSnapId,
                                            accountId: account?._id || '',
                                        })
                                    );
                                }}
                            >
                                Preview Snap
                            </Dropdown.MenuItem>
                            <Dropdown.MenuItem
                                onClick={() =>
                                    history.push(
                                        `/${account?._id}/snaps/${row?.storifymeSnapId || ''}`,
                                    )
                                }
                                endIcon={csvIsDownloading ? <Spinner size="small" /> : <></>}
                            >
                                Snap Analytics
                            </Dropdown.MenuItem>
                            <Dropdown.MenuItem
                                onClick={() => handleDownloadEnagementCSV(row)}
                                endIcon={csvIsDownloading ? <Spinner size="small" /> : <></>}
                            >
                                Export Snap Analytics
                            </Dropdown.MenuItem>
                            {row?.featuredPeriods?.[0]?.endDate && (
                                <Dropdown.MenuItem
                                    color="red"
                                    onClick={() =>
                                        row.storifymeSnapId && onDeleteClick(row.storifymeSnapId)
                                    }
                                >
                                    Delete Snap
                                </Dropdown.MenuItem>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Cell>
            ),
        },
    ];
    return headCells;
};
