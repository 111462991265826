import { FC, useMemo, useState } from 'react';
import { UseMutateFunction } from 'react-query';

import { difference } from 'lodash';

import {
    AccountMarket,
    AccountSparkBrand,
    CreateSparkBrandAndUploadPhotoParams,
} from '@sparkplug/lib';

import { AddBrandAliasModal } from '@features/spark-brands/views/CCSparkBrandsView/SparkBrandsTable/AddBrandAliasModal/AddBrandAliasModal';

import Button from '@components/buttons/Button';
import Chip, { IChipProps } from '@components/chips/Chip';
import { Add as AddIcon, GeneralSettingsIcon, NoInvoicesIcon } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Skeleton from '@components/layout/Skeleton';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useSearch } from '@hooks/UIHooks';

import { THeadCell } from '@app/types/TableTypes';

import './SparkBrandsTable.scss';

interface RowDetails {
    key: string;
}

export type SparkBrandsTableRow = AccountSparkBrand & RowDetails;

const getHeadCells = ({
    onManage,
    handleAddAliasClick,
    markets,
}: {
    onManage: (sparkBrand: AccountSparkBrand) => void;
    handleAddAliasClick: (sparkBrand: AccountSparkBrand) => void;
    markets: AccountMarket[];
}): THeadCell<AccountSparkBrand>[] => [
    {
        id: 'photo',
        label: 'Logo',
        render: ({ photo, name }) => (
            <Table.Cell className="table-cell_spark-brand-logo">
                {photo && <img src={photo} alt={name} />}
            </Table.Cell>
        ),
    },
    {
        id: 'name',
        label: 'Brand Name',
        sortType: 'string',
        render: ({ name }) => <Table.Cell>{name}</Table.Cell>,
    },
    {
        id: 'aliases',
        label: 'Brand Aliases',
        render: (row) => {
            return (
                <Table.Cell>
                    <div className="!flex flex-row flex-wrap">
                        <span className="pr-2 flex items-center">{row.aliases?.join(', ')}</span>
                        <Button
                            className="!pl-0 !font-medium [&_span]:!mr-1"
                            variant="flat"
                            color="blue"
                            disableHover
                            startIcon={<AddIcon className="!h-[18px] !w-[18px]" />}
                            onClick={() => handleAddAliasClick(row)}
                        >
                            Add
                        </Button>
                    </div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'excludedMarkets',
        label: 'Markets',
        render: ({ excludedMarkets }) => {
            const selectedMarkets = excludedMarkets
                ? difference(markets, excludedMarkets)
                : markets;
            return (
                <Table.Cell>
                    <div className="!flex flex-row flex-wrap">
                        {selectedMarkets.length === markets.length ? (
                            <>All Vendor Markets</>
                        ) : (
                            <>{selectedMarkets.join(', ')}</>
                        )}
                    </div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'isEnabled',
        label: 'Status',
        sortType: 'boolean',
        render: ({ isEnabled }) => {
            const props: IChipProps = isEnabled
                ? {
                      color: 'green',
                      label: 'Active',
                  }
                : {
                      color: 'neutral',
                      label: 'Disabled',
                  };
            return (
                <Table.Cell>
                    <Chip {...props} />
                </Table.Cell>
            );
        },
    },
    {
        id: '',
        label: '',
        sortType: 'string',
        render: (row) => (
            <Table.Cell className="table-cell_manage-spark-brand">
                <Button
                    onClick={() => onManage(row)}
                    color="blue"
                    variant="outlined"
                    startIcon={<GeneralSettingsIcon />}
                >
                    Manage
                </Button>
            </Table.Cell>
        ),
    },
];

interface SparkBrandsTableProps {
    isReady: boolean;
    rows: SparkBrandsTableRow[];
    markets: AccountMarket[];
    onCreate: () => void;
    onManage: (sparkBrand: AccountSparkBrand) => void;
    updateSparkBrand: UseMutateFunction<
        void,
        unknown,
        { sparkBrandId: string; payload: Partial<CreateSparkBrandAndUploadPhotoParams> },
        unknown
    >;
}

const SparkBrandsTable: FC<SparkBrandsTableProps> = ({
    isReady,
    rows = [],
    markets = [],
    onCreate,
    onManage,
    updateSparkBrand,
}) => {
    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch(['name']);
    const [showBrandAliasModal, setShowBrandAliasModal] = useState<boolean>(false);
    const [sparkBrand, setSparkBrand] = useState<AccountSparkBrand>();

    const handleAddAliasClick = (_sparkBrand: AccountSparkBrand) => {
        setSparkBrand(_sparkBrand);
        setShowBrandAliasModal(true);
    };

    const headCells = useMemo(() => getHeadCells({ onManage, handleAddAliasClick, markets }), []);

    const handleAddAliasSave = (aliasName: string) => {
        updateSparkBrand({
            sparkBrandId: sparkBrand?._id ?? '',
            payload: { aliases: [...(sparkBrand?.aliases ?? []), aliasName] },
        });
    };

    const handleCloseAddAliasClick = () => {
        setSparkBrand(undefined);
        setShowBrandAliasModal(false);
    };

    if (!isReady) {
        return <Skeleton height={400} />;
    }

    if (isReady && !rows?.length) {
        return (
            <EmptyStateDisplay
                className="spark-brands-table_empty-state"
                graphic={<NoInvoicesIcon />}
                label="no spark brands!"
                actionButton={{
                    label: 'Add New Brand',
                    onClick: onCreate,
                    startIcon: <AddIcon />,
                }}
            />
        );
    }

    return (
        <div className="spark-brands-table-wrapper">
            <Toolbar>
                <Toolbar.Search
                    alignStart
                    name="spark-brands-table.search"
                    onChange={onChangeSearchFilter}
                />
                <Toolbar.Button
                    variant="smooth"
                    startIcon={<AddIcon />}
                    color="blue"
                    onClick={onCreate}
                >
                    Add New Brand
                </Toolbar.Button>
            </Toolbar>
            <Table
                className="spark-brands-table"
                isLoading={!isReady}
                variant="raised"
                rows={rows}
                headCells={headCells}
                filters={[applySearch]}
                defaultOptions={{
                    orderBy: 'name',
                    order: 'asc',
                }}
            >
                <Table.RenderHead />
                <Table.RenderBody emptyStateText={`No spark brands matching "${searchFilter}"`} />
            </Table>
            {showBrandAliasModal && sparkBrand && (
                <AddBrandAliasModal
                    isVisible={showBrandAliasModal}
                    onSave={handleAddAliasSave}
                    brandName={sparkBrand.name}
                    onClose={handleCloseAddAliasClick}
                />
            )}
        </div>
    );
};

export default SparkBrandsTable;
