import { FC, useMemo } from 'react';

import { CondensedHydratedBrandProduct, Spark } from '@sparkplug/lib';

import { useBrandTagGroups, useVendorRetailerPosData } from '@features/product-tags';

import Skeleton from '@components/layout/Skeleton';

import { useBrandRetailerByGroupId, useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import { IBrandRetailer } from '@app/types/AccountsTypes';

import {
    useBrandOverride,
    wrapWithBrandOverrideProvider,
} from '../PosRulesBasedSparkProductSelectorTable/BrandOverrideContext';
import { ProductTableFilters } from '../SparkProductSelectorTable';
import ManuallyManagedVendorSparkProductSelector from './VendorRulesBasedProductSelector/ManuallyManagedVendorSparkProductSelector';
import MultiRetailerRulesBasedProductSelector from './VendorRulesBasedProductSelector/MultiRetailerRulesBasedProductSelector';
import SingleRetailerPosRulesBasedProductSelector from './VendorRulesBasedProductSelector/SingleRetailerPosRulesBasedProductSelector';
import SingleRetailerTagsRulesBasedProductSelector from './VendorRulesBasedProductSelector/SingleRetailerTagsRulesBasedProductSelector';
import { useRulesBasedProductTableFilters } from './VendorRulesBasedProductSelector/useRulesBasedProductTableFilters';

import './VendorSparkProductSelector.scss';

interface VendorSparkProductSelectorProps {
    spark: Spark;
    brandRetailer: IBrandRetailer;
    vendorRetailerProducts?: CondensedHydratedBrandProduct[];
    initialFilterValues?: Partial<ProductTableFilters>;
}

export const VendorSparkProductSelector: FC<VendorSparkProductSelectorProps> = ({
    spark,
    brandRetailer,
    initialFilterValues,
    vendorRetailerProducts = [],
}) => {
    const {
        isCreatingMultiRetailerSpark,
        isVendorTagsRulesBasedSpark,
        isVendorPosRulesBasedSpark,
    } = useSpark();
    const rulesBasedTableFilters = useRulesBasedProductTableFilters({
        spark,
        allowSearch: true,
    });
    const { brandTagGroupsAreReady, brandTagGroups } = useBrandTagGroups(spark.sparkBrandId ?? '');

    if (isCreatingMultiRetailerSpark) {
        return (
            <MultiRetailerRulesBasedProductSelector
                tableFilters={rulesBasedTableFilters}
                brandId={spark.sparkBrandId ?? ''}
                brandTagGroups={brandTagGroupsAreReady ? brandTagGroups : []}
                vendorAccountId={spark.originatorGroupId ?? ''}
            />
        );
    }

    /**
     * `SingleRetailer**Tags**RulesBasedProductSelector` and `SingleRetailer**Pos**RulesBasedProductSelector` are
     * a little hard too read and differentiate... can we rename and refactor for easier readability at some point?
     */
    if (isVendorTagsRulesBasedSpark) {
        return (
            <SingleRetailerTagsRulesBasedProductSelector
                retailerAccountId={brandRetailer._id}
                products={vendorRetailerProducts}
                brandTagGroups={brandTagGroupsAreReady ? brandTagGroups : []}
                tableFilters={rulesBasedTableFilters}
                spark={spark}
            />
        );
    }

    if (isVendorPosRulesBasedSpark) {
        return (
            <SingleRetailerPosRulesBasedProductSelector
                products={vendorRetailerProducts.map((product) => ({
                    ...product,
                    value: product._id,
                    label: product.name,
                }))}
            />
        );
    }

    return (
        <ManuallyManagedVendorSparkProductSelector
            spark={spark}
            brandRetailer={brandRetailer}
            initialFilterValues={initialFilterValues}
        />
    );
};

export default wrapWithBrandOverrideProvider(() => {
    const { account } = useSparkplugAccount();
    const {
        spark,
        isCreatingMultiRetailerSpark,
        isVendorPosRulesBasedSpark,
        isVendorTagsRulesBasedSpark,
    } = useSpark();
    const { brandRetailerIsReady, brandRetailer } = useBrandRetailerByGroupId(
        account,
        spark.groupId,
    );
    const isVendorRulesBasedSpark = isVendorPosRulesBasedSpark || isVendorTagsRulesBasedSpark;

    const { brandOverrideIsEnabled } = useBrandOverride();

    const { vendorRetailerPosDataIsReady, vendorRetailerPosData } = useVendorRetailerPosData(
        account?._id ?? '',
        spark.groupId ?? '',
        isVendorRulesBasedSpark,
    );

    const vendorRetailerProducts = useMemo(() => {
        if (brandOverrideIsEnabled) {
            return Object.values(vendorRetailerPosData?.productsVendorBrandMap ?? {}).flatMap(
                ({ products = [] }) => products,
            );
        }

        return (
            vendorRetailerPosData?.productsVendorBrandMap?.[spark?.sparkBrandId || '']?.products ??
            []
        );
    }, [brandOverrideIsEnabled, vendorRetailerPosData, spark.sparkBrandId]);

    if (
        (isVendorRulesBasedSpark &&
            !vendorRetailerPosDataIsReady &&
            !isCreatingMultiRetailerSpark) ||
        (!isVendorRulesBasedSpark && !brandRetailerIsReady)
    ) {
        return <Skeleton height="100%" />;
    }

    return (
        <VendorSparkProductSelector
            spark={spark}
            brandRetailer={brandRetailer}
            vendorRetailerProducts={vendorRetailerProducts}
        />
    );
});
