import { FC } from 'react';

import moment from 'moment';

import { WalletEmployeeDeposit, formatCurrency } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { KeyboardArrowRight } from '@components/icons';
import { Detail, DetailsList } from '@components/layout/DetailsList/DetailsList';
import Drawer from '@components/overlays/Drawer';
import { SparkCardLogo } from '@components/sparks/SparkCardLogo';
import SparkGroupChip from '@components/sparks/SparkGroupChip';

import { useApp } from '@hooks/AppHooks';

import { getSparkTypeDisplayName } from '@helpers/sparks';

import NonCashRewardClaimInstructions from '../NonCashRewardClaimInstructions';
import TransactionDetails from '../TransactionDetails';

import './RewardDetailsDrawer.scss';

interface RewardDetailsDrawerProps {
    isVisible: boolean;
    deposit?: WalletEmployeeDeposit;
    onClose: (doActionOnClose?: boolean) => void;
    showCompletedSparkButtton?: boolean;
}

const RewardDetailsDrawer: FC<RewardDetailsDrawerProps> = ({
    isVisible,
    deposit,
    onClose,
    showCompletedSparkButtton = true,
}) => {
    const spark = deposit?.spark;
    const learningResourceBrandInfo = deposit?.learningResourceBrandInfo;
    const { history } = useApp();

    const dateRangeFormat = moment.localeData().longDateFormat('L').replace(/YYYY/g, 'YY');

    const sparkDateRange = `${moment(spark?.startDate).format(dateRangeFormat)} 
        - ${moment(spark?.endDate).format(dateRangeFormat)}`;

    const goBackToCompletedSpark = () => {
        history.push(`/user/sparks/${deposit?.sparkId}`, {
            prevUrl: document.location.pathname,
            prevTitle: `${spark?.name}`,
        });
    };

    const viewCompletedSparkButton = (
        <Button
            color="white"
            variant="smooth"
            endIcon={<KeyboardArrowRight fontSize="medium" className="rightArrow" />}
            onClick={goBackToCompletedSpark}
        >
            <>
                View Completed {spark ? 'Spark' : ''}
                {learningResourceBrandInfo ? 'Course' : ''}
            </>
        </Button>
    );

    const rewardDetail = deposit?.amount ? (
        <div className="reward-detail">+ {formatCurrency((deposit?.amount ?? 0) / 100, true)}</div>
    ) : (
        <>
            <div className="reward-detail">{deposit?.name}</div>
            <NonCashRewardClaimInstructions
                claimInstructions={deposit?.claimInstructions}
                claimedDate={deposit?.claimedAt}
            />
        </>
    );

    const rewardDetailItems: Detail[] = [
        {
            title: 'Claimed Date',
            content: moment(deposit?.claimedAt).format('MMMM D, YYYY [at] h:mm A'),
        },
        ...(deposit?.isLegacy && deposit?.deliveryLink
            ? [
                  {
                      title: 'Cash Out URL',
                      content: (
                          <a href={deposit.deliveryLink} target="_blank" rel="noopener noreferrer">
                              {deposit.deliveryLink}
                          </a>
                      ),
                  },
              ]
            : []),
        ...(deposit?.learningResourceId
            ? [
                  {
                      title: 'Reward Type',
                      content: 'Training Course',
                  },
              ]
            : []),
        ...(spark
            ? [
                  {
                      title: 'Spark Type',
                      content: spark?.type ? getSparkTypeDisplayName(spark.type) : '',
                  },
              ]
            : []),
        ...(spark
            ? [
                  {
                      title: 'Spark Name',
                      content: spark?.name,
                  },
              ]
            : []),
        {
            title: deposit?.learningResourceId ? 'Reward Description' : 'Spark Description',
            content: deposit?.name || spark?.description,
        },
    ];

    const chip = spark?.sparkBrand?.name ? (
        <SparkGroupChip groupName={spark.sparkBrand.name} />
    ) : undefined;

    return (
        <>
            <Drawer
                className="reward-details-drawer bring-drawer-to-front"
                title="Reward Details"
                variant="right"
                isVisible={isVisible}
                onCloseHandler={() => onClose()}
            >
                {deposit && (
                    <>
                        <TransactionDetails.Header
                            className="details-card-header-container"
                            headerImage={
                                <SparkCardLogo
                                    emoji={spark?.emoji}
                                    sparkBrand={spark?.sparkBrand || learningResourceBrandInfo}
                                    size="large"
                                />
                            }
                            chip={chip}
                            title={spark?.name || learningResourceBrandInfo?.name}
                            subtitle={spark ? sparkDateRange : 'Training Course'}
                            rewardDetail={rewardDetail}
                        >
                            {showCompletedSparkButtton && viewCompletedSparkButton}
                        </TransactionDetails.Header>

                        <TransactionDetails.Content className="reward-details-list">
                            <DetailsList details={rewardDetailItems} />
                        </TransactionDetails.Content>
                    </>
                )}
            </Drawer>
        </>
    );
};

export default RewardDetailsDrawer;
