import { FC } from 'react';

import { groupBy } from 'lodash';

import {
    HydratedBrandProduct,
    MultiRetailerProductSelection,
    MultiRetailerSparkParticipantGroup,
    Spark,
    SparkCommission,
    VendorSparkFilters,
} from '@sparkplug/lib';

import toast from '@components/toast';

import { useModal } from '@hooks/ModalHooks';
import { useSpark } from '@hooks/SparksHooks';

export const ModalNext: FC<{
    vendorFilters: VendorSparkFilters;
    selectedProducts: HydratedBrandProduct[];
    updatedParticipantGroups?: MultiRetailerSparkParticipantGroup[];
}> = ({ vendorFilters, selectedProducts, updatedParticipantGroups }) => {
    const {
        spark,
        sparkCommissionMap,
        updateSpark,
        isCreatingMultiRetailerSpark,
        updateMultiRetailerProductSelection,
        multiRetailerParticipantGroups,
        multiRetailerProductSelection,
        updateMultiRetailerParticipantGroups,
        updateOriginalMultiRetailerParticipantGroups,
    } = useSpark();
    const { updateValidationFn, updateBackFn } = useModal();

    const applySparkProducts = () => {
        // For Multi-retailer Sparks, we need to group the selected products by retailerAccountId
        if (isCreatingMultiRetailerSpark) {
            const productsByAccountId = groupBy(selectedProducts, 'groupId');

            const updatedProducts: MultiRetailerProductSelection = Object.fromEntries(
                Object.entries(productsByAccountId).map(([accountId, products]) => {
                    const productSelectionValue: MultiRetailerProductSelection[string] = {
                        posProductIds: products.map(({ _id }) => _id),
                    };
                    if (spark.type === 'commission') {
                        productSelectionValue.commissions = products.map(({ _id, name }) => {
                            const commissionValue =
                                multiRetailerProductSelection?.[accountId]?.commissions?.find(
                                    (commission) => commission.posProductId === _id,
                                )?.value ?? 0;

                            return {
                                posProductId: _id,
                                type: 'flat',
                                name,
                                value: commissionValue, // Use commissionValue which checks both spark.commissions and sparkCommissionMap
                            };
                        });
                    }

                    return [accountId, productSelectionValue];
                }),
            );

            updateMultiRetailerProductSelection(updatedProducts);
            if (updatedParticipantGroups) {
                updateOriginalMultiRetailerParticipantGroups(multiRetailerParticipantGroups);
                updateMultiRetailerParticipantGroups(updatedParticipantGroups);
            }
            updateSpark({
                vendorFilters,
                posBrandIds: [],
                posCategoryIds: [],
                posProductIds: [],
                productFilters: {},
                commissions: [],
            });

            return;
        }

        const updatedSparkData: Pick<
            Spark,
            | 'vendorFilters'
            | 'posBrandIds'
            | 'posCategoryIds'
            | 'posProductIds'
            | 'productFilters'
            | 'commissions'
        > = {
            vendorFilters,
            posBrandIds: [],
            posCategoryIds: [],
            /**
             * If rules-based Spark, make sure to clear out all manually managed Product selections
             *
             * We set posProductIds for the "Review" step to display the correct products but
             * we will unset them server-side to prevent the Spark from being saved with the
             * wrong products
             */
            posProductIds: selectedProducts.map(({ _id }) => _id),
            productFilters: {},
            commissions: [],
        };

        if (spark.type === 'commission') {
            updatedSparkData.commissions = selectedProducts.map(({ _id: productId, name }) => {
                const commissionItem = sparkCommissionMap?.get(productId);

                return {
                    posProductId: productId,
                    type: 'flat',
                    value: commissionItem ? commissionItem.value : undefined,
                    name,
                } as SparkCommission;
            });
        }

        updateSpark(updatedSparkData);
    };
    updateBackFn(() => {
        requestAnimationFrame(() => {
            applySparkProducts();
        });
        return true;
    });
    updateValidationFn(() => {
        const validation = [
            () => {
                if (!selectedProducts.length) {
                    toast.error('No products match the currently selected rules');

                    return false;
                }

                return true;
            },
        ];

        const allValid = validation.every((fn) => fn());
        if (allValid) {
            requestAnimationFrame(() => {
                applySparkProducts();
            });
        }

        return allValid;
    });

    return <></>;
};
