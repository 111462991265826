import {
    FC,
    PropsWithChildren,
    ReactNode,
    createContext,
    useEffect,
    useMemo,
    useState,
} from 'react';

import LocalStorage from '@data/LocalStorage';

import { AccountMarket, UserRole } from '@sparkplug/lib';

import { useApp } from '@hooks/AppHooks';
import { useAccountQueryByGroupId, useBrandRetailerByGroupId } from '@hooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';
import { noop } from '@helpers/util';

import {
    IAccount,
    IAccountContext,
    IAccountPosDataContext,
    IBrandRetailer,
    TAccountUserView,
} from '@app/types/AccountsTypes';
import { IAuthUser } from '@app/types/UsersTypes';

import { useUserAccountPermissions } from './useUserAccountPermissions';

export const AccountIdKey = 'sparkplug::accountId';

interface IBrandRetailerContext {
    brandRetailerIsReady: boolean;
    brandRetailer?: IBrandRetailer;
    brandRetailerId?: string;
    changeBrandRetailerById: (accountId?: string) => void;
}

export const BrandRetailerContext = createContext<IBrandRetailerContext>(
    {} as IBrandRetailerContext,
);

interface IBrandRetailerProviderProps {
    account: IAccount;
}

export const BrandRetailerProvider: FC<PropsWithChildren<IBrandRetailerProviderProps>> = ({
    account,
    children,
}) => {
    const [brandRetailerId, setBrandRetailerId] = useState<string>();

    const value = useBrandRetailerByGroupId(account, brandRetailerId);

    return (
        <BrandRetailerContext.Provider
            value={{
                ...value,
                changeBrandRetailerById: setBrandRetailerId,
            }}
        >
            {children}
        </BrandRetailerContext.Provider>
    );
};

export const AccountContext = createContext<IAccountContext>({
    accountIsReady: true,
    connectEnabled: true,
    hasSnapsEntitlement: false,
    hasInventoryEntitlement: false,
    inventoryEntitlementMarkets: [],
    changeAccountById: () => {},
    switchAccountUserView: () => {},
    account: undefined,
    refetchAccount: () => {},
    isRefetching: false,
    refetchAccountLocations: () => {},
    userCan: () => false,
    currentUserMarkets: [],
    isFullScreenWizard: true,
});

export const AccountProvider = ({
    initialAccountId,
    children,
}: {
    initialAccountId: string;
    children?: ReactNode;
}) => {
    const [accountId, setAccountId] = useState<string>(initialAccountId);

    const { user, userIsAdmin, userCanSwitchView, setCustomUserRole } = useApp();
    const [customUserView, setCustomUserView] = useState<TAccountUserView>();

    const { isLoading, account, refetchAccount, isRefetching } =
        useAccountQueryByGroupId(accountId);
    const subscriptionTypeIsPaid = account?.metaData?.subscriptionType === 'paid';
    const userIsNotSuperAdminOrNone = !['super-admin', 'none'].includes(user?.role || '');
    const enablePaymentConditions =
        subscriptionTypeIsPaid &&
        // for now, if no payment health comes back, we assume the account is okay. Or there is something wrong on our end.
        account?.paymentHealth &&
        userIsNotSuperAdminOrNone;
    const hasPaymentMethods = !!account?.paymentHealth?.hasPaymentMethods;
    const hasNoFailedPayment = account?.paymentHealth?.latestChargeStatus !== 'failed';
    const paymentConditionRedirect = useMemo(() => {
        if (!hasPaymentMethods && !isLoading) {
            return `/${accountId}/setup-payment`;
        }
        if (!hasNoFailedPayment && !isLoading) {
            return `/${accountId}/update-payment`;
        }
        return undefined;
    }, [hasPaymentMethods, hasNoFailedPayment, accountId, isLoading]);

    const accountPaymentConditions = useMemo(() => {
        if (enablePaymentConditions) {
            return {
                hasPaymentMethods,
                hasNoFailedPayment,
                paymentConditionRedirect,
            };
        }
        return undefined;
    }, [enablePaymentConditions, hasPaymentMethods, hasNoFailedPayment, paymentConditionRedirect]);

    useEffect(() => {
        if (account) {
            Intercom.boot({
                isSuperAdmin: user?.role === 'super-admin',
                fullName: user?.fullName,
                email: user?.email,
                phone: user?.phoneNumber,
                userId: user?._id,
                userType: `${account?.type} ${userIsAdmin ? 'admin' : 'employee'}`,
                accountName: account?.name,
                accountType: account?.type,
                accountId: account._id,
            });
        }
    }, [account, user, userIsAdmin]);

    const modifiedAccount = useMemo(() => {
        const useCustomView = customUserView && customUserView !== 'super-admin';

        if (useCustomView && user && account) {
            const newAccountRoles = {
                ...(account?.roles || {}),
                [user._id]: customUserView,
            };

            return {
                ...account,

                roles: newAccountRoles,
            };
        }

        return account;
    }, [account, user, customUserView]);

    const changeAccountById = (newAccountId: string | undefined) => {
        if (newAccountId != null && newAccountId !== accountId) {
            LocalStorage.set(AccountIdKey, newAccountId);

            setAccountId(newAccountId);
        }
    };

    const switchAccountUserView = (value: TAccountUserView) => {
        if (user && userCanSwitchView) {
            let newSparkplugUserRole: UserRole = 'super-admin';

            if (value === 'group-admin') {
                newSparkplugUserRole = account?.type === 'brand' ? 'brand-admin' : 'retailer-admin';
            } else if (['group-member', 'none'].includes(value)) {
                newSparkplugUserRole = 'none';
            }

            setCustomUserRole(newSparkplugUserRole);
            setCustomUserView(value);
        }
    };

    const { currentUserPermissionsAreReady, userCan, userMarkets } = useUserAccountPermissions({
        user: user ?? ({} as IAuthUser),
        account: account ?? ({} as IAccount),
    });

    const value = useMemo(() => {
        const accountIsReady =
            user?.role === 'super-admin'
                ? !isLoading
                : // For non-super-admins, we need to wait for the account to load and the user's permissions to be ready
                  modifiedAccount?._id === accountId && currentUserPermissionsAreReady;

        const hasSnapsEntitlement =
            modifiedAccount?.snapsEnabled ||
            !!modifiedAccount?.subscriptionConfig?.entitlements?.snaps;

        const hasInventoryEntitlement =
            modifiedAccount?.inventoryEnabled ||
            !!modifiedAccount?.subscriptionConfig?.entitlements?.inventory;

        const inventoryEntitlementMarkets =
            (modifiedAccount?.subscriptionConfig?.entitlements?.inventory?.markets?.map((market) =>
                market.toUpperCase(),
            ) as AccountMarket[]) ?? [];

        return {
            accountIsReady,
            connectEnabled: !modifiedAccount?.connectDisabled,
            hasSnapsEntitlement,
            hasInventoryEntitlement,
            inventoryEntitlementMarkets,
            changeAccountById,
            switchAccountUserView,
            account: modifiedAccount,
            refetchAccount: () => refetchAccount(),
            isRefetching,
            refetchAccountLocations: refetchAccount,
            userCan,
            currentUserMarkets: userMarkets,
            isFullScreenWizard: true,
            accountPaymentConditions,
        };
    }, [
        user,
        accountId,
        modifiedAccount,
        isLoading,
        currentUserPermissionsAreReady,
        userCan,
        userMarkets,
    ]);

    return (
        <AccountContext.Provider value={value}>
            {account ? (
                <BrandRetailerProvider account={account}>{children}</BrandRetailerProvider>
            ) : (
                children
            )}
        </AccountContext.Provider>
    );
};

export const AccountPosDataContext = createContext<IAccountPosDataContext>({
    accountPosDataIsReady: true,

    refetchAccountPosData: noop,
    refetchAccountPosLocations: noop,
    refetchAllAccountPosEmployeeProfiles: noop,
    refetchAccountPosEmployeeProfiles: noop,
    refetchAccountPosBrands: noop,
    refetchAccountPosCategories: noop,
    refetchAccountPosProducts: noop,

    accountPosLocations: [],
    accountAllPosLocations: [],
    accountPosEmployeeProfiles: [],
    accountAllPosEmployeeProfiles: [],
    accountPosBrands: [],
    accountPosCategories: [],
    accountPosProducts: [],
});
