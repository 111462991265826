import { FC, useMemo } from 'react';

import moment from 'moment';

import { BrandProductTagGroup, HydratedBrandProduct, Spark } from '@sparkplug/lib';

import { getMissingTagsCount } from '@features/product-tags/utils';
import ProductsMatched from '@features/spark-wizard/components/SparkProductSelector/components/ProductsMatched';

import Form from '@components/form/Form';
import CalloutMessage from '@components/layout/CalloutMessage';

import { SINGLE_RETAILER_INTERCOM_CALLOUT_MESSAGE } from '../../../constants';
import { VENDOR_RULES_BASED_PRODUCT_TAGS_LEARN_MORE_URL } from '../VendorRulesBasedProductSelectorConstants';
import VendorRulesBasedProductSelectorTableToolbar from '../VendorRulesBasedProductSelectorTableToolbar';
import VendorRulesBasedSparkProductSelectorTable from '../VendorRulesBasedSparkProductSelectorTable';
import { UseVendorRulesBasedSparkProductSelectorTableFilters } from '../useRulesBasedProductTableFilters';
import { ModalNext } from '../vendorRulesBasedModalNext';

import './SingleRetailerTagsRulesBasedProductSelector.scss';

interface SingleRetailerTagsRulesBasedProductSelectorProps {
    retailerAccountId: string;
    products: HydratedBrandProduct[];
    brandTagGroups?: BrandProductTagGroup[];
    tableFilters: UseVendorRulesBasedSparkProductSelectorTableFilters;
    spark: Spark;
}

const SingleRetailerTagsRulesBasedProductSelector: FC<
    SingleRetailerTagsRulesBasedProductSelectorProps
> = ({ retailerAccountId, products: unfilteredProducts, tableFilters, brandTagGroups, spark }) => {
    const { filters, getRulesBasedFilteredProducts, onChangeSearchFilter } = tableFilters;

    const products = useMemo(
        () =>
            unfilteredProducts.filter(({ lastSoldAt }) =>
                moment(lastSoldAt).isSameOrAfter(
                    (moment(spark?.startDate).isSameOrBefore(moment())
                        ? moment(spark?.startDate)
                        : moment()
                    ).subtract(60, 'days'),
                    'day',
                ),
            ),
        [unfilteredProducts],
    );

    // NOTE: This might be able to be replaced with the missingTagsCount from the Tag Counts query
    const missingTagCount = useMemo(() => getMissingTagsCount(products), [products]);

    const rulesBasedFilteredProducts = useMemo(
        () => getRulesBasedFilteredProducts(products),
        [filters],
    );

    return (
        <>
            <CalloutMessage
                className="filters-callout"
                message={
                    <>
                        {`${SINGLE_RETAILER_INTERCOM_CALLOUT_MESSAGE} `}
                        <a
                            href={VENDOR_RULES_BASED_PRODUCT_TAGS_LEARN_MORE_URL}
                            target="_blank"
                            rel="noreferrer"
                        >
                            Learn more
                        </a>
                    </>
                }
            />
            <VendorRulesBasedProductSelectorTableToolbar
                brandTagGroups={brandTagGroups}
                tableFilters={tableFilters}
            />
            <Form.Label>Qualifying Products (Last 60 days)</Form.Label>
            <ProductsMatched
                onChangeSearchFilter={onChangeSearchFilter ?? (() => {})}
                matchingProducts={rulesBasedFilteredProducts}
                total={products.length}
            />
            <VendorRulesBasedSparkProductSelectorTable
                retailerAccountId={retailerAccountId}
                products={rulesBasedFilteredProducts}
                tableFilters={tableFilters}
                filteredProductsMissingTagCount={missingTagCount}
            />
            <ModalNext
                vendorFilters={tableFilters.filters}
                selectedProducts={rulesBasedFilteredProducts}
            />
        </>
    );
};

export default SingleRetailerTagsRulesBasedProductSelector;
