import { useMemo } from 'react';

import {
    CondensedUIAccount,
    Spark,
    getRecurringSparkScheduleLabel,
    isFulfilledBySparkplug,
} from '@sparkplug/lib';

import { PinnedSparkTableData, SparkDashboardTableRowData } from '@features/spark-dashboard/types';

import { useSparks } from '@contexts/SparksContext';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import {
    areSparkActionsDisabled,
    formatSparkInfo,
    getSparkTypeDisplayName,
    userCanCloneSpark,
} from '@helpers/sparks';
import { getStateNameByAbbreviation } from '@helpers/util';

import { IAccount } from '@app/types/AccountsTypes';

export const getPinnedSparkData = ({
    spark,
    account,
    sparkRetailer,
    sparkVendor,
}: {
    spark: Spark;
    account?: IAccount;
    sparkRetailer: CondensedUIAccount;
    sparkVendor?: CondensedUIAccount;
}): PinnedSparkTableData => {
    if (account?.type === 'brand') {
        return {
            label: 'Retailer',
            name: sparkRetailer.name,
            photo: sparkRetailer?.photo ?? undefined,
            missingCommissionCount: spark.missingCommissionCount,
        };
    }
    // Note: sparkBrand and sparkVendor will be undefined for Retailer created sparks
    return {
        label: 'Spark Creator',
        name: spark.sparkBrand?.name ?? sparkVendor?.name ?? sparkRetailer.name,
        photo: spark.sparkBrand?.photo ?? sparkVendor?.photo ?? sparkRetailer.photo ?? undefined,
        missingCommissionCount: spark.missingCommissionCount,
    };
};

/**
 * @returns -1 for sparks that don't have any rewards setup to be fulfilled by sparkplug
 * In this case, -1 is a useful number for column-based sorting.
 */
export const getSparkSpend = ({ fulfillmentTypes, type, payoutAmount = 0 }: Spark) => {
    const isFBS = isFulfilledBySparkplug({ fulfillmentTypes, type });
    return payoutAmount > 0 || isFBS ? payoutAmount : -1;
};

const getTrainingState = ({ trainingEnabled, courseData }: Spark) => {
    if (!trainingEnabled) {
        return 'None';
    } else {
        return courseData?.required ? 'Required' : 'Optional';
    }
};

interface UseSparkDashboardTableDataParams {
    filteredSparks: Spark[];
    onSparkSelected: (spark: Spark) => void;
}
export const useSparkDashboardTableData = ({
    filteredSparks,
    onSparkSelected,
}: UseSparkDashboardTableDataParams) => {
    const { user } = useApp();
    const { account, accountIsReady } = useSparkplugAccount();
    const { sparksAreReady } = useSparks();
    const sparkDashboardTableData: SparkDashboardTableRowData[] = useMemo(() => {
        if (!accountIsReady) {
            return [];
        }

        return filteredSparks.map((spark) => {
            const {
                _id: sparkId,
                startDate,
                sparkRetailer,
                sparkVendor,
                endDate,
                recurringSchedule,
                requestState,
            } = spark;

            const { status } = formatSparkInfo(startDate, endDate, requestState, recurringSchedule);

            return {
                spark,
                sparkId,
                key: sparkId,
                sparkRetailer,
                sparkVendor,
                type: getSparkTypeDisplayName(spark.type),
                market: getStateNameByAbbreviation(sparkRetailer?.market ?? ''),
                pinnedSparkData: getPinnedSparkData({
                    spark,
                    account,
                    sparkRetailer: sparkRetailer!,
                    sparkVendor,
                }),
                sparkSchedule: getRecurringSparkScheduleLabel(recurringSchedule?.interval),
                startDate,
                endDate,
                participants: spark.participantCount ?? 0,
                sales: spark.unitsSold ?? 0,
                spend: getSparkSpend(spark),
                sparkStatus: status,
                actionDetails: {
                    onSparkSelected,
                    disableSparkActions: areSparkActionsDisabled(spark, user),
                    canCloneSpark: !!user && !!account && userCanCloneSpark(user, account, spark),
                },
                sparkTraining: getTrainingState(spark),
            };
        });
    }, [account, accountIsReady, filteredSparks]);

    return {
        sparkDashboardDataIsReady: accountIsReady && sparksAreReady,
        sparkDashboardTableData,
    };
};
