import { FC } from 'react';

import Toolbar from '@components/toolbar/Toolbar';

import { UseVendorRulesBasedSparkProductSelectorTableFilters } from '../useRulesBasedProductTableFilters';

import './VendorRulesBasedProductSearchBar.scss';

interface VendorRulesBasedProductSearchBarProps {
    tableFilters: UseVendorRulesBasedSparkProductSelectorTableFilters;
    hideSearch?: boolean;
    numRetailers: number;
}

const VendorRulesBasedProductSearchBar: FC<VendorRulesBasedProductSearchBarProps> = ({
    tableFilters,
    hideSearch = false,
    numRetailers,
}) => {
    const { onChangeSearchFilter } = tableFilters;
    return (
        <div className="vendor-rules-based-search-bar">
            <strong>{numRetailers}</strong>
            &nbsp;Retailers participating
            <Toolbar>
                {onChangeSearchFilter && !hideSearch && (
                    <Toolbar.Search
                        name="brand-spark-product-selector_search"
                        onChange={onChangeSearchFilter}
                    />
                )}
            </Toolbar>
        </div>
    );
};

export default VendorRulesBasedProductSearchBar;
