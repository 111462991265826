import LottieGraphic from './LottieGraphic';
import AnimatedCheckmarkJSON from './lottie/animated-checkmark.json';
import AnimatedGiftJSON from './lottie/animated-gift.json';
import AnimatedTrainingCourseBadgeJSON from './lottie/animated-training-course-badge.json';
import AnimatedLoadingFilesJSON from './lottie/loading-files.json';

export { ReactComponent as CombinedLeaderboardGraphic } from './CombinedLeaderboard.svg';
export { ReactComponent as MultiLeaderboardGraphic } from './MultiLeaderboard.svg';
export { ReactComponent as ExpiredLinkGraphic } from './ExpiredLink.svg';
export { ReactComponent as BillingGraphic } from './Billing.svg';
export { ReactComponent as HelpGraphic } from './HelpGraphic.svg';
export { ReactComponent as CreditCardGraphic } from './CreditCardGraphic.svg';
export { SparkCreditsCard } from './SparkCreditsCard';
export { ConfettiGraphic } from './Confetti/Confetti';

export function AnimatedCheckmarkGraphic() {
    return (
        <LottieGraphic
            className="animated-checkmark-graphic"
            json={AnimatedCheckmarkJSON}
            speed={0.75}
        />
    );
}

export function AnimatedGiftGraphic({ startPlay }: { startPlay: boolean }) {
    const onInit = (ref: any) => {
        ref.current.addEventListener('ready', () => {
            ref.current.seek(87);
        });
    };

    return (
        <LottieGraphic
            className="animated-gift-graphic"
            json={AnimatedGiftJSON}
            autoplay={false}
            startPlay={startPlay}
            onInit={onInit}
        />
    );
}

export function AnimatedTrainingCourseBadgeGraphic({ autoplay }: { autoplay?: boolean }) {
    return (
        <LottieGraphic
            autoplay={autoplay}
            className="training-course-badge-graphic"
            json={AnimatedTrainingCourseBadgeJSON}
            speed={0.75}
        />
    );
}

export function AnimatedLoadingFilesGraphic({
    autoplay,
    loop,
}: {
    autoplay?: boolean;
    loop?: boolean;
}) {
    return (
        <LottieGraphic
            autoplay={autoplay}
            loop={loop}
            className="loading-files-graphic"
            json={AnimatedLoadingFilesJSON}
        />
    );
}

export * from './EmptyStates';
