import { FC, useState } from 'react';

import { TAG_ASSISTANT_LEARN_MORE_LINK } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { TagAssistIcon } from '@components/icons';
import Checkbox from '@components/inputs/Checkbox';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal from '@components/overlays/Modal';

import './TagAssistEnableModal.scss';

interface TagAssistEnableModalProps {
    isVisible: boolean;
    productTagCount?: number;
    onClose: () => void;
    onConfirm: () => void;
}

const TagAssistEnableModal: FC<TagAssistEnableModalProps> = ({
    isVisible,
    productTagCount,
    onClose,
    onConfirm,
}) => {
    const [confirmCheckboxChecked, setConfirmCheckboxChecked] = useState<boolean>(false);

    const closeAndReset = () => {
        onClose();
        setConfirmCheckboxChecked(false);
    };

    const confirmAndReset = () => {
        onConfirm();
        setConfirmCheckboxChecked(false);
    };

    return (
        <Modal className="tag-assist-enable-modal" isVisible={isVisible} onClose={closeAndReset}>
            <Modal.Title onClose={closeAndReset}>Enable Tag Assistant</Modal.Title>

            <Modal.Content>
                <CalloutMessage
                    message={
                        <>
                            The Tag Assistant is a SparkPlug AI tool that allows you to effortlessly
                            apply tags to products across linked Retailers.{' '}
                            <a
                                href={TAG_ASSISTANT_LEARN_MORE_LINK}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Learn more
                            </a>
                        </>
                    }
                />
            </Modal.Content>

            <Modal.Content>
                <div className="how-it-works">
                    <h1>How it works:</h1>
                    <ul>
                        <li>The Tag Assistant tags all products that are missing tags.</li>
                        <li>The Tag Assistant usually takes 4-6 hours to complete.</li>
                        <li>
                            Tags with a <span className="purple">purple</span> outline indicate they
                            were applied by the Tag Assistant.
                        </li>
                        <li>
                            <strong>Important:</strong> You cannot edit your tags or tag groups when
                            the Tag Assistant is enabled. You must disable to make edits.
                        </li>
                    </ul>
                </div>
            </Modal.Content>

            <Modal.Content>
                <div className="required-confirm-checkbox">
                    <Checkbox
                        name="required-confirm-checkbox"
                        value={confirmCheckboxChecked}
                        onChange={(e) => setConfirmCheckboxChecked(e.target.checked)}
                    />
                    <div className="confirm-text">
                        I confirm I&apos;ve reviewed and{' '}
                        <strong>finalized all {productTagCount} product tags setup</strong> before
                        enabling the Tag Assistant.
                    </div>
                </div>
            </Modal.Content>

            <Modal.Actions>
                <div className="actions">
                    <Button color="neutral" variant="flat" onClick={closeAndReset}>
                        Cancel
                    </Button>
                    <Button
                        color="purple"
                        startIcon={<TagAssistIcon />}
                        variant="filled"
                        disabled={!confirmCheckboxChecked}
                        onClick={confirmAndReset}
                    >
                        Enable
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};

export default TagAssistEnableModal;
