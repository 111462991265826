import { FC, useMemo, useState } from 'react';

import { ClickAwayListener } from '@mui/material';

import {
    CreateBrandTagRequestBody,
    DO_NOT_HAVE_PERMISSIONS_MESSAGE,
    TAG_ASSISTANT_TAGS_DISABLED_MESSAGE,
} from '@sparkplug/lib';

import { useCreateGroupTag } from '@features/product-tags/mutations';

import Button from '@components/buttons/Button';
import { Add } from '@components/icons';
import toast from '@components/toast';

import { TagForm } from './TagForm';

export const CreateTagButton: FC<{
    tagGroupId: string;
    selectedBrandId: string;
    userCanManageProductTags?: boolean;
    tagAssistantEnabled?: boolean;
}> = ({
    tagGroupId,
    selectedBrandId,
    userCanManageProductTags = true,
    tagAssistantEnabled = false,
}) => {
    const [isCreatingTag, setIsCreatingTag] = useState(false);
    const { createGroupTag, isCreatingTagGroup } = useCreateGroupTag(selectedBrandId);

    const createTagTooltip = useMemo(() => {
        if (!userCanManageProductTags) {
            return DO_NOT_HAVE_PERMISSIONS_MESSAGE;
        }
        if (tagAssistantEnabled) {
            return TAG_ASSISTANT_TAGS_DISABLED_MESSAGE;
        }
        return undefined;
    }, [userCanManageProductTags, tagAssistantEnabled]);

    const handleSave = (tagForm: CreateBrandTagRequestBody) => {
        createGroupTag(
            {
                groupId: tagGroupId,
                brandId: selectedBrandId,
                body: {
                    name: tagForm.name,
                    color: tagForm.color,
                },
            },
            {
                onSuccess: () => {
                    setIsCreatingTag(false);
                    toast.success('Tag created');
                },
                onError: (e) => {
                    toast.error(`Error creating tag`);
                },
            },
        );
    };

    return (
        <ClickAwayListener onClickAway={() => setIsCreatingTag(false)}>
            <div>
                {isCreatingTag ? (
                    <TagForm
                        onSave={handleSave}
                        setIsCreatingTag={setIsCreatingTag}
                        tagGroupId={tagGroupId}
                        selectedBrandId={selectedBrandId}
                        isLoading={isCreatingTagGroup}
                    />
                ) : (
                    <Button
                        startIcon={<Add />}
                        onClick={() => setIsCreatingTag(true)}
                        data-testid="add-tag-button"
                        disabled={!userCanManageProductTags || tagAssistantEnabled}
                        tooltip={createTagTooltip}
                    >
                        Add tag
                    </Button>
                )}
            </div>
        </ClickAwayListener>
    );
};
