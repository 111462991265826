import { FC, useState } from 'react';

import { ClickAwayListener } from '@mui/material';

import {
    BrandProductTag,
    CreateBrandTagRequestBody,
    TAG_ASSISTANT_TAGS_DISABLED_MESSAGE,
} from '@sparkplug/lib';

import { useDeleteGroupTag, useUpdateGroupTag } from '@features/product-tags/mutations';

import IconButton from '@components/buttons/IconButton';
import { Delete, Dot, Edit } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Tooltip from '@components/layout/Tooltip';
import ConfirmModal from '@components/overlays/ConfirmModal';
import toast from '@components/toast';

import { TagForm } from './TagForm/TagForm';

type TagWidgetProps = {
    tag: BrandProductTag;
    tagGroupId: string;
    selectedBrandId: string;
    userCanManageProductTags?: boolean;
    tagAssistantEnabled?: boolean;
};

export const TagWidget: FC<TagWidgetProps> = ({
    tag,
    tagGroupId,
    selectedBrandId,
    userCanManageProductTags = true,
    tagAssistantEnabled = false,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const { updateGroupTag, isUpdatingGroupTag } = useUpdateGroupTag(selectedBrandId);
    const { deleteGroupTag } = useDeleteGroupTag(selectedBrandId);
    const handleSave = (tagForm: CreateBrandTagRequestBody) => {
        updateGroupTag(
            {
                groupId: tagGroupId,
                brandId: selectedBrandId,
                tagId: tag._id,
                body: {
                    name: tagForm.name,
                    color: tagForm.color,
                },
            },
            {
                onSuccess: () => {
                    setIsEditing(false);
                    toast.success('Tag updated');
                },
                onError: (e) => {
                    toast.error(`Error updating tag`);
                },
            },
        );
    };

    const onRemove = () => {
        deleteGroupTag(
            { groupId: tagGroupId, brandId: selectedBrandId, tagId: tag._id },
            {
                onSuccess: () => {
                    setShowDeleteModal(false);
                    toast.success('Tag deleted');
                },
                onError: (e) => {
                    setShowDeleteModal(false);
                    toast.error(`Error deleting tag`);
                },
            },
        );
    };
    return (
        <ClickAwayListener onClickAway={() => setIsEditing(false)}>
            <div className="tag-group-list-item tag-group-action-row">
                {isEditing ? (
                    <TagForm
                        tagGroupId={tagGroupId}
                        selectedBrandId={selectedBrandId}
                        setIsCreatingTag={setIsEditing}
                        onSave={handleSave}
                        isLoading={false}
                        tag={tag}
                    />
                ) : (
                    <>
                        <Dot style={{ color: tag.color }} className="tag-group-list-item-color" />
                        <div className="tag-group-list-item-label">{tag.name}</div>
                        {userCanManageProductTags && (
                            <Tooltip
                                title={
                                    tagAssistantEnabled ? TAG_ASSISTANT_TAGS_DISABLED_MESSAGE : ''
                                }
                                placement="top"
                            >
                                <div className="tag-group-action-row-buttons">
                                    <IconButton
                                        onClick={() => setIsEditing(true)}
                                        size="small"
                                        data-testid="tag-group-header-edit-button"
                                        disabled={tagAssistantEnabled}
                                    >
                                        <Tooltip title="Edit Tag" placement="top">
                                            <Edit fontSize="small" />
                                        </Tooltip>
                                    </IconButton>

                                    <IconButton
                                        size="small"
                                        onClick={() => setShowDeleteModal(true)}
                                        disabled={tagAssistantEnabled}
                                    >
                                        <Tooltip title="Delete Tag" placement="top">
                                            <Delete fontSize="small" />
                                        </Tooltip>
                                    </IconButton>
                                    <ConfirmModal
                                        title="Delete Tag?"
                                        className="delete-tag-group-modal"
                                        message={
                                            <CalloutMessage
                                                color="red"
                                                customIcon="alert"
                                                message={
                                                    <p>
                                                        <strong>
                                                            Are you sure you wish to delete the{' '}
                                                            {tag.name} tag?
                                                        </strong>{' '}
                                                        This tag will be deleted and removed from
                                                        all products to which it is currently
                                                        assigned.
                                                    </p>
                                                }
                                            />
                                        }
                                        extraModalContent={
                                            <p className="confirm-delete">
                                                Please type <strong>DELETE</strong> below to confirm
                                                that you want to permanently remove this tag. You
                                                can’t undo this.
                                            </p>
                                        }
                                        isVisible={showDeleteModal}
                                        onConfirm={onRemove}
                                        onClose={() => {
                                            setShowDeleteModal(false);
                                        }}
                                        confirmText="Delete"
                                        confirmBtnColor="red"
                                        requiredConfirmText="DELETE"
                                    />
                                </div>
                            </Tooltip>
                        )}
                    </>
                )}
            </div>
        </ClickAwayListener>
    );
};
