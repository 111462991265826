import Divider from '@components/Divider';
import Button from '@components/buttons/Button';
import { CustomSmsIcon } from '@components/icons';
import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import { SparkplugLogo } from '@components/logos';
import QRCode from '@components/qrcode';
import toast from '@components/toast';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import './UserTeammateReferralView.scss';

export const copyToClipboard = async (text: string) => {
    navigator.clipboard
        .writeText(text)
        .then(() => toast.success('Referral link copied!'))
        .catch(() => toast.error('Error Sharing Referral'));
};

export const shareReferral = async (shareData: { text: string }) => {
    try {
        if (navigator.share && navigator.canShare(shareData)) {
            await navigator.share(shareData);
        } else {
            await copyToClipboard(shareData.text);
        }
    } catch (err: any) {
        if (err.name !== 'AbortError') {
            await copyToClipboard(shareData.text);
        }
    }
};

const UserTeammateReferralView = () => {
    const { user } = useApp();
    const { account } = useSparkplugAccount();

    const back = {
        to: '/user/profile',
        component: 'Refer a Teammate',
    };

    const shareData = {
        text: `Hi! Join the rest of your team at ${account?.name} on SparkPlug and get paid extra for your efforts. Click the link to sign in and start earning! ${account?.onboardingUrl}`,
    };

    return (
        <div className="refer-a-teammate">
            <AppHeaderMobile hideAppFooter back={back} />
            <div className="referral-intro">
                <strong>{user?.firstName}, show this QR Code</strong> to your teammates so they can
                get paid extra for their efforts!
            </div>
            <div className="qr-code-container">
                <QRCode url={account?.onboardingUrl ?? ''} />
                <span>
                    <SparkplugLogo />
                </span>
            </div>
            <div className="qr-instructions">
                <strong>Scan this QR Code to participate in Sparks</strong>
                <ul>
                    <li>1. Open your phone camera</li>
                    <li>2. Point camera at the QR Code</li>
                    <li>3. Tap the button that appears</li>
                </ul>
            </div>
            <Divider text="or" />
            <Button
                className="referral-button"
                variant="filled"
                color="blue"
                onClick={() => shareReferral(shareData)}
                startIcon={<CustomSmsIcon />}
            >
                Message the Sign Up Link
            </Button>
        </div>
    );
};

export default UserTeammateReferralView;
