import { useMemo } from 'react';

import { Emoji } from 'emoji-mart';
import moment from 'moment';

import { Spark } from '@sparkplug/lib';

import Chip from '@components/chips/Chip';
import ManagerChip from '@components/chips/ManagerChip';
import { Check as CheckIcon, RecurringSparkIcon } from '@components/icons';
import Table from '@components/table/Table';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import {
    areSparkActionsDisabled,
    formatSparkInfo,
    getOtherGroupName,
    userCanCloneSpark,
} from '@helpers/sparks';
import { formatRecurringTooltip } from '@helpers/ui';

import { IAccount, TAccountType } from '@app/types/AccountsTypes';
import { TSparkStatus } from '@app/types/SparksTypes';
import { THeadCell } from '@app/types/TableTypes';
import { IAuthUser } from '@app/types/UsersTypes';

import SparkGroupChip from '../SparkGroupChip';
import SparkMoreButton from '../SparkMoreButton';
import SparkStatusChip from '../SparkStatusChip';

import './SparksListTable.scss';

const buildSparkRow = ({
    spark,
    account,
    user,
    onSparkSelected,
}: {
    spark: Spark;
    account: IAccount;
    user: IAuthUser;
    onSparkSelected: (spark: Spark) => void;
}) => {
    const otherGroupName = getOtherGroupName(spark, account) ?? '';
    const vendorGroupName = account?.type !== 'brand' ? spark.sparkVendor?.name : '';
    const start = moment(spark.startDate);
    const end = moment(spark.endDate);
    const { status, remainingDays, daysUntilStart } = formatSparkInfo(
        spark.startDate,
        spark.endDate,
        spark.requestState,
        spark.recurringSchedule,
    );
    const disableSparkActions = areSparkActionsDisabled(spark, user);
    const canCloneSpark = !!user && !!account && userCanCloneSpark(user, account, spark);

    return {
        ...spark,
        key: spark?._id,
        otherGroupName,
        vendorGroupName,
        shortName: spark.name.length > 40 ? `${spark.name.substring(0, 37)}...` : spark.name,
        startDate: start.format('M/D/YY'),
        endDate: end.format('M/D/YY'),
        details:
            spark.description.length > 40
                ? `${spark.description.substring(0, 37)}...`
                : spark.description,
        status,
        // coerced `daysLeft` and `daysUntilStart` into strings for table sorting
        daysLeft: String(remainingDays),
        daysUntilStart: String(daysUntilStart),
        disableSparkActions,
        canCloneSpark,
        onSparkSelected,
    };
};

type SparkRow = ReturnType<typeof buildSparkRow>;

const CustomCompleteChip = () => (
    <Chip
        className="custom-complete-chip"
        label={null}
        color="dark-gray"
        icon={<CheckIcon className="custom-complete-chip-icon" />}
    />
);

const headCells: THeadCell<SparkRow>[] = [
    {
        id: '',
        sortType: 'string',
        label: '',
        render: (row: SparkRow) => {
            return (
                <Table.Cell align="center">
                    <div className="table-icon-wrapper">
                        {row.sparkBrand ? (
                            <div className="spark-table_spark-brand-logo">
                                <img src={row.sparkBrand?.photo} alt={row.sparkBrand?.name} />
                            </div>
                        ) : (
                            <Emoji emoji={row.emoji ?? 'trophy'} set="apple" size={24} />
                        )}

                        {row.recurringSchedule && (
                            <Table.Tooltip title={formatRecurringTooltip(row.recurringSchedule)}>
                                <RecurringSparkIcon />
                            </Table.Tooltip>
                        )}
                    </div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'name',
        sortType: 'string',
        label: 'Spark Name',
        render: (row: SparkRow) => {
            return (
                <Table.Tooltip title={row.name}>
                    <Table.Cell scope="row">{row.name}</Table.Cell>
                </Table.Tooltip>
            );
        },
    },
    {
        id: 'type',
        sortType: 'string',
        label: 'Type',
        render: (row: SparkRow) => {
            return (
                <Table.Cell align="left" style={{ textTransform: 'capitalize' }}>
                    {row.type} {row.detailedSparkType === 'goalManager' && <ManagerChip />}
                </Table.Cell>
            );
        },
    },
    {
        id: 'description',
        sortType: 'string',
        label: 'Details',
        render: (row: SparkRow) => {
            return (
                <Table.Tooltip title={row.description}>
                    <Table.Cell align="left">{row.description}</Table.Cell>
                </Table.Tooltip>
            );
        },
    },
    {
        id: 'startDate',
        sortType: 'date',
        label: 'Start',
        render: (row: SparkRow) => {
            return <Table.Cell align="left">{row.startDate}</Table.Cell>;
        },
    },
    {
        id: 'endDate',
        sortType: 'date',
        label: 'End',
        render: (row: SparkRow) => {
            return <Table.Cell align="left">{row.endDate}</Table.Cell>;
        },
    },
    {
        id: 'otherGroupName',
        sortType: 'string',
        label: '', // will be 'Retailer' or 'Brand'
        render: (row: SparkRow) => {
            const { connectEnabled } = useSparkplugAccount();

            if (!connectEnabled) return <></>; //  TODO EK: what put here?

            return (
                <Table.Cell align="left">
                    {row.otherGroupName ? (
                        <SparkGroupChip
                            groupName={row.otherGroupName}
                            vendorName={row.vendorGroupName}
                        />
                    ) : null}
                </Table.Cell>
            );
        },
    },
    {
        id: 'status',
        sortType: 'string',
        label: 'Status',
        render: (row: SparkRow) => {
            let customStatusLabel: any = null;

            if (row.status === 'Active') {
                customStatusLabel =
                    row.daysLeft === '1' ? `${row.daysLeft} day` : `${row.daysLeft} days`;
            } else if (row.status === 'Upcoming') {
                customStatusLabel =
                    row.daysUntilStart === '1'
                        ? `${row.daysUntilStart} day`
                        : `${row.daysUntilStart} days`;
            }

            return (
                <Table.Cell align="left">
                    {row.status === 'Complete' ? (
                        <CustomCompleteChip />
                    ) : (
                        <SparkStatusChip status={row.status} customLabel={customStatusLabel} />
                    )}
                </Table.Cell>
            );
        },
    },
    {
        id: 'actions',
        label: '',
        render: (row: SparkRow) => {
            return (
                <Table.Cell align="left">
                    <SparkMoreButton
                        className="more-button"
                        variant="vertical"
                        spark={row}
                        originRoute="spark-dashboard"
                    />
                </Table.Cell>
            );
        },
    },
];

const getHeadCells = (
    accountType?: TAccountType,
    status?: TSparkStatus | null,
    connectEnabled: boolean = true,
): THeadCell<SparkRow>[] => {
    if (!accountType) {
        return headCells;
    }

    const otherGroupType = accountType === 'brand' ? 'Retailer' : 'Brand';
    const otherGroupIndex = headCells.findIndex((cell) => cell.id === 'otherGroupName');
    headCells[otherGroupIndex].label = otherGroupType;
    if (otherGroupType === 'Brand') {
        headCells[otherGroupIndex].info = 'For brand-sponsored Sparks only';
    } else {
        headCells[otherGroupIndex].info = '';
    }

    const pentultimateIndex = headCells.length - 2;
    if (status === 'Active') {
        headCells[pentultimateIndex].id = 'daysLeft';
        headCells[pentultimateIndex].label = 'Days Left';
    } else if (status === 'Upcoming') {
        headCells[pentultimateIndex].id = 'daysUntilStart';
        headCells[pentultimateIndex].label = 'Starts In';
    } else {
        headCells[pentultimateIndex].id = 'status';
        headCells[pentultimateIndex].label = 'Status';
    }

    return connectEnabled ? headCells : headCells.filter(({ id }) => id !== 'otherGroupName');
};

interface ISparkListTableProps {
    variant?: 'raised' | 'flat';
    sparks: Spark[];
    onSparkSelected: (spark: Spark) => void;
}

const SparksListTable = ({ variant = 'raised', sparks, onSparkSelected }: ISparkListTableProps) => {
    const { account, connectEnabled } = useSparkplugAccount();
    const { user } = useApp();

    const tableHeadCells = useMemo(() => {
        // TODO: do the status stuff better, maybe pull status from url?
        let status: TSparkStatus | null = null;
        if (sparks?.length > 0 && sparks[0]?.endDate && sparks[0]?.startDate) {
            const { endDate, startDate, requestState, recurringSchedule } = sparks[0];
            status = formatSparkInfo(startDate, endDate, requestState, recurringSchedule).status;
        }
        return getHeadCells(account?.type, status, connectEnabled);
    }, [account, sparks, connectEnabled]);

    const mappedSparks = useMemo(() => {
        return sparks.map((spark) =>
            buildSparkRow({ spark, account: account!, user: user!, onSparkSelected }),
        );
    }, [sparks, account, user, onSparkSelected]);

    return (
        <Table
            variant={variant}
            rows={mappedSparks}
            headCells={tableHeadCells}
            defaultOptions={{
                orderBy: 'createdAt',
                order: 'desc',
            }}
        >
            <Table.RenderHead />
            <Table.RenderBody
                onRowClick={onSparkSelected}
                emptyStateText="No sparks with these filters."
            />
        </Table>
    );
};

export default SparksListTable;
