import { trace } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { ConsoleSpanExporter, SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';

if (process.env.REACT_APP_ENABLE_TRACING === 'true') {
    const provider = new WebTracerProvider();

    provider.register({
        // Changing default contextManager to use ZoneContextManager - supports asynchronous operations - optional
        contextManager: new ZoneContextManager(),
    });

    registerInstrumentations({
        instrumentations: [new DocumentLoadInstrumentation()],
    });

    provider.addSpanProcessor(
        new SimpleSpanProcessor(
            new OTLPTraceExporter({
                url: `http://${process.env.REACT_APP_ADOT_URL}/v1/traces`,
                headers: { 'Content-Type': 'application/json' },
            }),
        ),
    );

    provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
}

export const getTracer = (name: string) => trace.getTracer(name);
