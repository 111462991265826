export function getShopifyAuthURL(subdomain: string) {
    const { REACT_APP_API, REACT_APP_SHOPIFY_CLIENT_ID } = process.env;
    const sparkplugDomain =
        REACT_APP_API === 'production'
            ? 'my.sparkplug.app'
            : `${REACT_APP_API}.sparkplug-technology.io`;
    const scopes = [
        'read_orders',
        'read_all_orders',
        'read_locations',
        'read_products',
        'read_users',
    ].join(',');
    const params = new URLSearchParams({
        scopes,
        client_id: REACT_APP_SHOPIFY_CLIENT_ID!,
        redirect_uri: `http://${sparkplugDomain}/oauth/shopify`,
        state: 'nonce',
    });
    return `https://${subdomain}.myshopify.com/admin/oauth/authorize?${params}`;
}
