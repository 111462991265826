import { FC, useEffect, useMemo } from 'react';

import { ChartPrecisionOptions, ChartViewOptions } from '@constants/ChartConstants';
import LocalStorage from '@data/LocalStorage';
import { useProductsOptionsWithSales } from '@views/dashboard/DashboardView/VendorDashboardView/hooks/useProductsOptionsWithSales';
import DashboardUserCharts from '@views/dashboard/DashboardView/components/DashboardUserCharts';
import { useDashboardContext } from '@views/dashboard/DashboardView/contexts/DashboardContext';
import moment, { Moment } from 'moment';

import { ListAccountLinksResponseBody, TChartDataMetric } from '@sparkplug/lib';

import { useChartContext } from '@core/charts/components/AdvancedChart/ChartContext';
import {
    ComparisonChartProvider,
    useComparisonChartContext,
} from '@core/charts/components/AdvancedComparisonChart/ComparisonChartContext';

import { useVendorRetailerDataPermissions } from '@features/account-links';

import BreakdownChart from '@components/charts/BreakdownChart';
import ComparisonChart from '@components/charts/ComparisonChart';
import SparkTimelineWidget from '@components/charts/SparkTimelineWidget';
import { TableChart as TableChartIcon } from '@components/icons';
import Paper from '@components/layout/Paper';
import Toolbar from '@components/toolbar/Toolbar';
import {
    getDateRangeLabel,
    getRangeByStoredValue,
    yearRangeOptions,
} from '@components/toolbar/ToolbarDateRangeSelector';

import { useSparkplugBrandRetailer } from '@hooks/SparkplugAccountsHooks';

import { getUniqueLeavesFromList } from '@helpers/ui';

import { IAccount, IBrandRetailer } from '@app/types/AccountsTypes';
import { IChartDataSettings, TChartType } from '@app/types/ChartDataTypes';
import { IPosLocation, IPosProduct } from '@app/types/PosTypes';

import '../VendorRetailerDashboardView.scss';

export const DateRangeKey = 'sparkplug::dashboard::dateRange';
export const ViewKey = 'sparkplug::dashboard::view';
export const MetricKey = 'sparkplug::dashboard::metric';

export const ChartBreakdownOptions = [
    {
        label: 'None',
        value: 'total',
    },
    {
        label: 'Location',
        value: 'location',
    },
    {
        label: 'Brand',
        value: 'brand',
    },
    {
        label: 'Category',
        value: 'category',
    },
    {
        label: 'Product',
        value: 'product',
    },
];

const useDashboardSelectedProducts = ({ brandRetailer }: { brandRetailer?: IBrandRetailer }) => {
    const { chartSettings } = useChartContext();
    const { comparisonRange, showComparisonWindows } = useComparisonChartContext();

    const { productsOptionsWithSales, productOptionsAreReady } = useProductsOptionsWithSales({
        vendorAccountId: chartSettings.brandGroupId ?? '',
        vendorRetailerId: brandRetailer?._id ?? '',
        currentDateRange: chartSettings,
        previousDateRange: {
            isEnabled: showComparisonWindows,
            dateStart: comparisonRange.previousDateStart,
            dateEnd: comparisonRange.previousDateEnd,
        },
        productHierarchy: brandRetailer?.productHierarchy ?? [],
    });

    // Selected products based on filters and products with sales
    const selectedProducts = useMemo<IPosProduct[]>(() => {
        const productsWithSales = getUniqueLeavesFromList(productsOptionsWithSales);
        const filterProducts = chartSettings?.filters?.products || [];

        const filterProductsValues = filterProducts.map(({ value }) => value);
        return productsWithSales.filter(({ value }) => {
            return filterProductsValues.includes(value);
        });
    }, [chartSettings?.filters?.products, productsOptionsWithSales]);

    const selectedCountProps = useMemo(() => {
        const selectedProductsCount = selectedProducts.length;
        const totalProductsCount = brandRetailer?.products?.length || 0;
        const productsWithSalesCount = getUniqueLeavesFromList(productsOptionsWithSales).length;
        const hiddenCount = totalProductsCount - productsWithSalesCount;

        const isOrAre = hiddenCount > 1 ? 'products are' : 'product is';
        const tooltipMessage = `${hiddenCount} ${isOrAre} hidden because there are no sales based on the current date range or location filters applied.`;

        if (!brandRetailer) {
            return undefined;
        }

        return {
            total: totalProductsCount,
            selected: selectedProductsCount,
            tooltipMessage: hiddenCount > 0 ? tooltipMessage : undefined,
            pluralUnitLabel: 'products',
        };
    }, [brandRetailer, selectedProducts, productsOptionsWithSales]);

    return {
        productOptionsAreReady,
        productsOptionsWithSales,
        selectedProducts,
        selectedCountProps,
    };
};

export interface VendorRetailerDashboardSalesViewProps {
    account: IAccount;
    brandRetailer?: IBrandRetailer;
    shareSalesData?: boolean;
    accountLinks?: ListAccountLinksResponseBody;
    userIsSuperAdmin?: boolean;
}
export const VendorRetailerDashboardSalesView: FC<VendorRetailerDashboardSalesViewProps> = ({
    account,
    brandRetailer,
    shareSalesData = false,
    accountLinks,
    userIsSuperAdmin = false,
}) => {
    const { chartSettings, updateChartSettings, onUpdateChartSetting } = useChartContext();

    const {
        showComparisonWindows,
        setShowComparisonWindows,
        comparisonPeriod,
        setComparisonPeriod,
    } = useComparisonChartContext();

    const {
        productOptionsAreReady,
        productsOptionsWithSales,
        selectedProducts,
        selectedCountProps,
    } = useDashboardSelectedProducts({ brandRetailer });

    const COMPARETO_BREAKDOWN_REMINDER =
        'The "Compare to" view with "Breakdown" enabled is only available in Table view.';
    const viewOptions = useMemo(
        () => [
            ...ChartViewOptions.map((option) => {
                const disabled = showComparisonWindows && chartSettings.breakdown !== 'total';
                return {
                    ...option,
                    disabled,
                    tooltipProps: disabled ? { title: COMPARETO_BREAKDOWN_REMINDER } : undefined,
                };
            }),
            {
                StartIcon: TableChartIcon,
                label: 'Table',
                value: 'table',
            },
        ],
        [account, chartSettings.breakdown, showComparisonWindows],
    );

    const locationOptions = useMemo(
        () => brandRetailer?.locations ?? [],
        [brandRetailer?.locations],
    );
    const selectedLocations = useMemo(() => {
        return locationOptions.filter(({ value }) => chartSettings.locationIds.includes(value));
    }, [chartSettings.locationIds, locationOptions]);

    const { setDateRange: setCustomContextDateRange } = useDashboardContext();

    const updateDateRange = (dateStart: Moment | null, dateEnd: Moment | null) => {
        setCustomContextDateRange({
            dateStart,
            dateEnd,
        });
        updateChartSettings({ dateStart, dateEnd });
    };

    const currentPeriodLabel = useMemo(() => {
        return getDateRangeLabel(chartSettings.dateStart, chartSettings.dateEnd);
    }, [chartSettings.dateStart, chartSettings.dateEnd]);

    const updateSelectedLocations = (selected: IPosLocation[]) => {
        if (selected.length > 0) {
            updateChartSettings({
                locationIds: selected.map((obj) => obj.value),
            });
        }
    };

    const updateSelectedProducts = (selected: IPosProduct[]) => {
        if (selected.length > 0) {
            updateChartSettings({
                filters: {
                    products: selected,
                },
            });
        }
    };

    const updateBrandView = (value: TChartType) => {
        LocalStorage.set(ViewKey, value);
        updateChartSettings({
            type: value,
        });
    };

    /**
     * NOTE: Because the metric is updated by the context `updateChartSettings` function in
     * another component, we need to use a useEffect here to switch the view to table if the
     * user has selected a comparison window and a breakdown
     * */
    useEffect(() => {
        const forceTableView = showComparisonWindows && chartSettings.breakdown !== 'total';

        if (forceTableView && chartSettings.type !== 'table') {
            updateChartSettings({ type: 'table' });
        }
    }, [chartSettings.metric, chartSettings.breakdown, showComparisonWindows]);

    /**
     * NOTE: Because the metric is updated by the context `updateChartSettings` function in
     * another component, we need to use a useEffect here to locally store the metric value
     * when the user revisits the dashboard.
     * */
    useEffect(() => {
        LocalStorage.set(MetricKey, chartSettings.metric);
    }, [chartSettings.metric]);

    /**
     * NOTE: Because the metric is updated by the context `updateChartSettings` function in
     * another component, we need to use a useEffect here to switch the breakdown if the current
     * breakdown does not support total_sales (ie brand, category, product)
     * */
    useEffect(() => {
        const isTotalSales = chartSettings.metric === 'total_sales';
        const breakdownSupportsTotalSales = ['total', 'location'].includes(chartSettings.breakdown);

        if (isTotalSales && !breakdownSupportsTotalSales) {
            onUpdateChartSetting('breakdown')('total');
        }
    }, [chartSettings.metric]);

    return (
        <>
            <Toolbar scrollOnMobile>
                <Toolbar.Text className="font-weight-400">Filter:</Toolbar.Text>

                <Toolbar.DropdownListSelector
                    label="Locations"
                    selected={selectedLocations || []}
                    onApply={updateSelectedLocations}
                    options={locationOptions || []}
                    clear={{
                        active: (selectedLocations || []).length !== (locationOptions || []).length,
                        onClear: () => {
                            updateSelectedLocations(locationOptions);
                        },
                    }}
                />

                <Toolbar.DropdownListSelector
                    label="Products"
                    isLoading={!productOptionsAreReady}
                    selected={selectedProducts || []}
                    onApply={updateSelectedProducts}
                    options={productsOptionsWithSales || []}
                    clear={{
                        active:
                            selectedProducts.length !==
                            getUniqueLeavesFromList(productsOptionsWithSales || []).length,
                        onClear: () => {
                            updateSelectedProducts(
                                getUniqueLeavesFromList(productsOptionsWithSales || []),
                            );
                        },
                    }}
                    selectedCountProps={selectedCountProps}
                />
                <Toolbar.DateRangeSelector
                    data-chromatic="ignore"
                    id="dashboard-date-range"
                    className="toolbar-group-end"
                    dateStart={chartSettings.dateStart}
                    dateEnd={chartSettings.dateEnd}
                    onApply={updateDateRange}
                    alwaysShowDates
                    selectionStorageKey={account.type === 'brand' ? DateRangeKey : ''}
                    anchorDirection="right"
                    openDirection="down"
                    additionalRangeOptions={yearRangeOptions}
                />
            </Toolbar>

            <Paper className="section">
                <Toolbar scrollOnMobile>
                    <ComparisonChart.ToolbarDropdownToggle
                        isActive={showComparisonWindows}
                        value={comparisonPeriod}
                        precision={chartSettings.precision}
                        onToggle={(enabled: boolean) => {
                            setShowComparisonWindows(enabled);
                        }}
                        onChange={setComparisonPeriod}
                    />
                    <Toolbar.Dropdown
                        label="Breakdown"
                        value={chartSettings.breakdown}
                        onSelect={onUpdateChartSetting('breakdown')}
                        options={ChartBreakdownOptions.map((option) => {
                            if (option.value === 'location') {
                                const disabled = brandRetailer?.locations?.length === 1;
                                return {
                                    ...option,
                                    disabled,
                                    tooltipProps: disabled
                                        ? { title: 'This retailer only has 1 location' }
                                        : undefined,
                                };
                            }

                            return option;
                        })}
                        clear={{
                            active: chartSettings.breakdown !== 'total',
                            onClear: () => {
                                updateChartSettings({ breakdown: 'total' });
                            },
                        }}
                    />
                    {chartSettings.type !== 'table' && (
                        <Toolbar.Dropdown
                            className="toolbar-group-end"
                            label="View"
                            value={chartSettings.precision}
                            onSelect={onUpdateChartSetting('precision')}
                            options={ChartPrecisionOptions}
                        />
                    )}
                    <Toolbar.Dropdown
                        className={chartSettings.type === 'table' ? 'toolbar-group-end' : ''}
                        label={chartSettings.type === 'table' ? 'View' : ''}
                        value={chartSettings.type}
                        onSelect={updateBrandView}
                        options={viewOptions}
                    />
                </Toolbar>

                {chartSettings.breakdown === 'total' || chartSettings.type === 'table' ? (
                    <ComparisonChart
                        showLegend={chartSettings.type !== 'table'}
                        currentPeriodLabel={currentPeriodLabel}
                        showComparisonPeriod={showComparisonWindows}
                        chartSettings={chartSettings}
                        locations={selectedLocations}
                        metricOptions={shareSalesData ? ['total_units', 'total_sales'] : undefined}
                    />
                ) : (
                    <BreakdownChart
                        metricOptions={shareSalesData ? ['total_units', 'total_sales'] : undefined}
                        account={account}
                        accountLinks={accountLinks}
                        userIsSuperAdmin={userIsSuperAdmin}
                    />
                )}
            </Paper>

            <SparkTimelineWidget
                displayType="paper"
                chartSettings={chartSettings}
                comparisonPeriod={showComparisonWindows ? comparisonPeriod : undefined}
            />

            <Paper className="section">
                <DashboardUserCharts
                    retailerAccountId={
                        account.type === 'retailer' ? account._id : brandRetailer?._id
                    }
                    chartSettings={
                        {
                            ...chartSettings,
                            breakdown: 'employee' as any,
                            brandGroupId: account._id,
                            retailerAccountIds: [brandRetailer?._id],
                        } as any
                    }
                />
            </Paper>
        </>
    );
};

const withVendorRetailerChartProvider =
    (Component: FC<VendorRetailerDashboardSalesViewProps>) =>
    ({ account, userIsSuperAdmin, accountLinks }: VendorRetailerDashboardSalesViewProps) => {
        // Note: No need to get `isReady` from these custom hooks because it is done in the root export
        const { brandRetailer, brandRetailerId } = useSparkplugBrandRetailer();
        const { shareSalesData } = useVendorRetailerDataPermissions({
            vendorAccountId: account?._id,
            retailerAccountId: brandRetailer?._id,
            isEnabled: account?.type === 'brand',
        });

        const retailerAccountId = brandRetailerId || account?.retailers?.[0]?._id;

        const locationIds = (brandRetailer?.locations || []).map(({ value }) => value);

        const {
            dateStart = moment().subtract(30, 'days').toDate(),
            dateEnd = moment().toDate(),
            precision = 'day',
        } = getRangeByStoredValue(account?.type === 'brand' ? DateRangeKey : '', '-30days');

        const filters = {
            products: getUniqueLeavesFromList(brandRetailer?.productHierarchy || []),
        };

        const { dateStart: customDateStart, dateEnd: customDateEnd } = useDashboardContext();

        const defaultVendorView = LocalStorage.get<TChartType>(ViewKey) ?? 'line';

        // In case the user switches retailers, but the new retailer is not fully loaded
        if (brandRetailer && brandRetailerId !== brandRetailer?._id) {
            return <></>;
        }

        const defaultVendorMetric = shareSalesData
            ? LocalStorage.get<TChartDataMetric>(MetricKey) ?? 'total_units'
            : 'total_units';

        const initialMetric = account?.type === 'retailer' ? 'total_sales' : defaultVendorMetric;

        const initialChartSettings: IChartDataSettings = {
            locationIds,
            metric: initialMetric,
            precision,
            type: account?.type === 'brand' ? defaultVendorView : 'bar',
            dateStart: customDateStart || dateStart,
            dateEnd: customDateEnd || dateEnd,
            breakdown: 'total',
            brandGroupId: account?.type === 'brand' ? account?._id : undefined,
            filters,
        };

        return (
            <ComparisonChartProvider
                retailerAccountId={retailerAccountId ?? ''}
                initialSettings={initialChartSettings}
            >
                <Component
                    account={account}
                    accountLinks={accountLinks}
                    brandRetailer={brandRetailer}
                    shareSalesData={shareSalesData}
                    userIsSuperAdmin={userIsSuperAdmin}
                />
            </ComparisonChartProvider>
        );
    };

export default withVendorRetailerChartProvider(VendorRetailerDashboardSalesView);
