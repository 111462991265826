import { AccountSparkBrand, BrandProductTagGroup, MAX_TAGS_PER_GROUP } from '@sparkplug/lib';

import { useCopyBrandTagGroup } from '@features/product-tags/mutations';

import toast from '@components/toast';

import { CreateTagButton } from './TagForm/CreateTagButton';
import TagGroupHeader from './TagGroupHeader/TagGroupHeader';
import { TagWidget } from './TagWidget';

import './TagGroup.scss';

export interface TagGroupProps extends BrandProductTagGroup {
    brands: AccountSparkBrand[];
    selectedBrandId?: string;
    userCanManageProductTags?: boolean;
    tagAssistantEnabled?: boolean;
}

export const TagGroup = ({
    _id,
    name,
    tags,
    brands,
    selectedBrandId = '',
    userCanManageProductTags = true,
    tagAssistantEnabled = false,
}: TagGroupProps) => {
    const { isCopyingBrandTagGroup, copyBrandTagGroup } = useCopyBrandTagGroup(selectedBrandId);
    const onCopy = (targetBrandId: string) => {
        if (!selectedBrandId) {
            return;
        }
        copyBrandTagGroup(
            {
                tagGroupId: _id,
                body: { targetBrandId },
            },
            {
                onSuccess: () => {
                    toast.success(
                        `Group copied to ${
                            brands.find((brand) => brand._id === targetBrandId)?.name ?? 'brand'
                        }.`,
                    );
                },
                onError: ({ response }: any) => {
                    toast.error(
                        response.data.details ?? 'Something went wrong - please try again.',
                    );
                },
            },
        );
    };
    return (
        <div className="tag-group">
            <TagGroupHeader
                initialValue={name}
                tagGroupId={_id}
                brands={brands}
                onCopy={onCopy}
                selectedBrandId={selectedBrandId || ''}
                userCanManageProductTags={userCanManageProductTags}
                tagAssistantEnabled={Boolean(tagAssistantEnabled)}
            />
            <div className="tag-group-list">
                {tags.map((tag) => (
                    <TagWidget
                        key={tag._id}
                        tag={tag}
                        tagGroupId={_id}
                        selectedBrandId={selectedBrandId || ''}
                        userCanManageProductTags={userCanManageProductTags}
                        tagAssistantEnabled={Boolean(tagAssistantEnabled)}
                    />
                ))}
            </div>
            {tags.length < MAX_TAGS_PER_GROUP && (
                <CreateTagButton
                    userCanManageProductTags={userCanManageProductTags}
                    tagAssistantEnabled={tagAssistantEnabled}
                    tagGroupId={_id}
                    selectedBrandId={selectedBrandId || ''}
                />
            )}
        </div>
    );
};
