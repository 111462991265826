import React from 'react';

import SparksAPI from '@api/SparksAPI';

import { formatCurrency } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';
import ConfirmModal from '@components/overlays/ConfirmModal';

import { useSpark } from '@hooks/SparksHooks';

const UnfinalizeSparkModal = ({
    isVisible,
    onClose,
    claimedRewardAmount,
}: {
    isVisible: boolean;
    onClose: () => void;
    claimedRewardAmount?: number;
}) => {
    const { spark, refetchSparkData } = useSpark();

    const handleOnConfirm = async () => {
        await SparksAPI.unfinalizeSpark(spark._id);
        await refetchSparkData();
        onClose();
    };
    return (
        <ConfirmModal
            className="unfinalize-spark-modal"
            onConfirm={handleOnConfirm}
            title={`Unfinalize Spark - ${spark.name}`}
            isVisible={isVisible}
            onClose={onClose}
            message={
                <>
                    <p>
                        Unfinalizing a spark removes employees’ ability to cash out. All employees
                        that have already cashed out their wallet will not be undone.{' '}
                    </p>
                    <CalloutMessage
                        color={claimedRewardAmount ? 'red' : 'neutral'}
                        customIcon={claimedRewardAmount ? 'alert' : 'info'}
                        message={
                            claimedRewardAmount ? (
                                <strong>
                                    Warning! There are {formatCurrency(claimedRewardAmount)} of
                                    claimed rewards that will remain claimed
                                </strong>
                            ) : (
                                'There are no claimed rewards'
                            )
                        }
                    />
                </>
            }
            confirmText="Unfinalize Spark"
            confirmBtnColor="red"
            requiredConfirmInputLabel={
                <>
                    To verify, enter the name of the spark, <strong>{spark.name}</strong>.
                </>
            }
            requiredConfirmText={spark.name}
        />
    );
};

export default UnfinalizeSparkModal;
