import { AccountLink } from '@sparkplug/lib';

import {
    useDeleteBrandLink,
    useRespondToAccountLinkRequest,
    useUpdateAccountLink,
} from '@features/account-links/mutations';
import { useAccountLinks } from '@features/account-links/queries';

import { useHistory } from '@components/router';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { ITableRow } from '@app/types/TableTypes';

export const useAccountLinksTableActions = () => {
    const { account } = useSparkplugAccount();
    const history = useHistory();

    const {
        isSendingAccountLinkRequest: isRespondingToAccountLinkRequest,
        respondToAccountLinkRequestAsync,
    } = useRespondToAccountLinkRequest(account?._id || '');
    const onRespondToAccountLinkRequest = async (
        vendorAccountId: string,
        accepted: boolean,
        updateRow?: (row: any) => any,
    ) => {
        toast.promise(respondToAccountLinkRequestAsync({ vendorAccountId, accepted }), {
            loading: `${accepted ? 'Accepting' : 'Declining'} request...`,
            success: () => {
                if (accepted) {
                    updateRow?.((row: ITableRow<AccountLink>) => {
                        return {
                            ...row,
                            status: 'enabled',
                        } as any;
                    });
                    history.push(
                        `/${account?._id}/settings/vendor/${vendorAccountId}/manage?isNewVendorLink=true`,
                    );
                } else {
                    updateRow?.((row: ITableRow<AccountLink>) => {
                        return {
                            ...row,
                            status: 'rejected',
                        } as any;
                    });
                }
                return `Vendor link request ${accepted ? 'accepted' : 'declined'}`;
            },
            error: 'Something went wrong - please try again and contact support if this issue persists.',
        });
    };

    const onManageAccountLink = (accountLink: AccountLink) => {
        if (accountLink.accountType === 'brand') {
            history.push(`/${account?._id}/settings/vendor/${accountLink.accountId}/manage`);
        } else {
            history.push(`/${account?._id}/settings/product-tags/${accountLink.accountId}`);
        }
    };

    const onCreateSpark = (accountLink: AccountLink) => {
        if (accountLink.accountType === 'retailer') {
            history.push(`/${account?._id}/sparks/create?groupId=${accountLink.accountId}`);
        } else {
            history.push(`/${account?._id}/sparks/create`);
        }
    };

    const { isDeletingBrandLink: isDeletingLink, deleteBrandLink } = useDeleteBrandLink(
        account?._id ?? '',
    );
    const onDeleteLink = (accountLink: AccountLink) => {
        deleteBrandLink(accountLink.accountId, {
            onSuccess: () => {
                toast.success('Link Deleted');
                history.push(`/${account?._id}/partners`);
            },
            onError: () => {
                toast.error(
                    'Something went wrong - please try again and contact support if this issue persists.',
                );
            },
        });
    };

    const { refetchAccountLinks } = useAccountLinks(account?._id || '', false);
    const { isUpdatingAccountLink: isUpdatingAccountLinkStatus, updateAccountLink } =
        useUpdateAccountLink(account?._id || '');
    const updateAccountLinkStatus = async ({
        accountLink,
        updatedStatus,
    }: {
        accountLink: AccountLink;
        updatedStatus: Extract<AccountLink['status'], 'disabled' | 'enabled'>;
    }) => {
        await updateAccountLink(
            {
                vendorAccountId: accountLink.accountId,
                body: { state: updatedStatus },
            },
            {
                onSuccess: () => {
                    refetchAccountLinks();
                    toast.success(`Link ${updatedStatus}`);
                },
                onError: () => {
                    toast.error(
                        'Something went wrong - please try again and contact support if this issue persists.',
                    );
                },
            },
        );
    };

    return {
        isRespondingToAccountLinkRequest,
        onRespondToAccountLinkRequest,
        isDeletingLink,
        onDeleteLink,
        updateAccountLinkStatus,
        isUpdatingAccountLinkStatus,
        onManageAccountLink,
        onCreateSpark,
    };
};
