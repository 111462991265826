import { createHash } from 'crypto';
import moment from 'moment';

function sha256(value: string) {
    return createHash('sha256').update(value).digest('hex');
}

export function getSquareAuthURL(groupId: string) {
    const { REACT_APP_SQUARE_APP_ID, REACT_APP_API } = process.env;

    const scopes = [
        'PAYMENTS_READ',
        'ORDERS_READ',
        'ITEMS_READ',
        'EMPLOYEES_READ',
        'MERCHANT_PROFILE_READ',
    ];
    const state = sha256(`${groupId}${moment().format('YYYYMMDD')}`);

    const useProd = ['production', 'demo'].includes(String(REACT_APP_API));
    const query = new URLSearchParams({
        client_id: REACT_APP_SQUARE_APP_ID!,
        state,
        session: String(!useProd),
    });

    const domain = useProd ? 'squareup' : 'squareupsandbox';

    return `https://connect.${domain}.com/oauth2/authorize?${query.toString()}&scope=${scopes.join(
        '+',
    )}`;
}
