import { useState } from 'react';

import { ClickAwayListener } from '@mui/material';

import { AccountSparkBrand, TAG_ASSISTANT_GROUPS_DISABLED_MESSAGE } from '@sparkplug/lib';

import { useDeleteBrandTagGroup, useUpdateBrandTagGroup } from '@features/product-tags/mutations';

import IconButton from '@components/buttons/IconButton';
import { Delete, Edit } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';
import Tooltip from '@components/layout/Tooltip';
import ConfirmModal from '@components/overlays/ConfirmModal';
import toast from '@components/toast';

import { CreateEditTagGroupForm } from '../../CreateEditTagGroupForm';
import TagGroupCopyButton from './TagGroupCopyButton';

const TagGroupHeader = ({
    initialValue,
    brands,
    onCopy,
    selectedBrandId = '',
    tagGroupId,
    userCanManageProductTags = true,
    tagAssistantEnabled = false,
}: {
    brands: AccountSparkBrand[];
    initialValue: string;
    onCopy: (targetBrandId: string) => void;
    selectedBrandId: string;
    tagGroupId: string;
    userCanManageProductTags?: boolean;
    tagAssistantEnabled?: boolean;
}) => {
    const [value, setValue] = useState(initialValue);
    const [isEditing, setIsEditing] = useState(false);
    const [sustainHover, setSustainHover] = useState(false);
    const { isUpdatingBrandTagGroup, updateBrandTagGroup } =
        useUpdateBrandTagGroup(selectedBrandId);
    const { deleteBrandTagGroup } = useDeleteBrandTagGroup(selectedBrandId);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleSave = (newName: string) => {
        if (!selectedBrandId) {
            return;
        }
        updateBrandTagGroup(
            {
                tagGroupId,
                body: { name: newName },
            },
            {
                onSuccess: () => {
                    setIsEditing(false);
                    toast.success('Group name updated.');
                },
                onError: ({ response }: any) => {
                    toast.error(response.data.details ?? 'Error updating group name.');
                },
            },
        );
    };

    const onRemove = () => {
        deleteBrandTagGroup(
            { tagGroupId, brandId: selectedBrandId },
            {
                onSuccess: () => {
                    setShowDeleteModal(false);
                    toast.success('Group deleted');
                },
                onError: (e) => {
                    setShowDeleteModal(false);
                    toast.error(`Error deleting group`);
                },
            },
        );
    };

    return (
        <ClickAwayListener onClickAway={() => setIsEditing(false)}>
            <div>
                {isEditing ? (
                    <CreateEditTagGroupForm
                        value={value}
                        onCancel={setIsEditing}
                        onChange={setValue}
                        onSave={handleSave}
                        selectedBrandId={selectedBrandId}
                        isLoading={isUpdatingBrandTagGroup}
                    />
                ) : (
                    <div
                        className={`tag-group-header tag-group-action-row  ${
                            sustainHover ? 'sustained-hover' : ''
                        }`}
                    >
                        <div className="tag-group-header-value">{initialValue}</div>

                        {userCanManageProductTags && (
                            <>
                                <Tooltip
                                    title={
                                        tagAssistantEnabled
                                            ? TAG_ASSISTANT_GROUPS_DISABLED_MESSAGE
                                            : ''
                                    }
                                    placement="top"
                                >
                                    <div className="tag-group-action-row-buttons">
                                        <IconButton
                                            onClick={() => setIsEditing(true)}
                                            size="small"
                                            data-testid="tag-group-header-edit-button"
                                            disabled={tagAssistantEnabled}
                                        >
                                            <Tooltip title="Edit Group Name" placement="top">
                                                <Edit fontSize="small" />
                                            </Tooltip>
                                        </IconButton>

                                        <TagGroupCopyButton
                                            onCopy={onCopy}
                                            setSustainHover={setSustainHover}
                                            brands={brands}
                                            tagAssistantEnabled={tagAssistantEnabled}
                                        />

                                        <IconButton
                                            size="small"
                                            onClick={() => setShowDeleteModal(true)}
                                            disabled={tagAssistantEnabled}
                                        >
                                            <Tooltip title="Delete Group" placement="top">
                                                <Delete fontSize="small" />
                                            </Tooltip>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <ConfirmModal
                                    title="Delete Group?"
                                    className="delete-tag-group-modal"
                                    message={
                                        <CalloutMessage
                                            color="red"
                                            customIcon="alert"
                                            message={
                                                <p>
                                                    <strong>
                                                        Are you sure you wish to delete the {value}{' '}
                                                        tag group?
                                                    </strong>{' '}
                                                    All tags within this group will be deleted and
                                                    removed from the products they are applied to.
                                                </p>
                                            }
                                        />
                                    }
                                    extraModalContent={
                                        <p className="confirm-delete">
                                            Please type <strong>DELETE</strong> below to confirm
                                            that you want to permanently remove this group. You
                                            can’t undo this.
                                        </p>
                                    }
                                    isVisible={showDeleteModal}
                                    onConfirm={onRemove}
                                    onClose={() => {
                                        setShowDeleteModal(false);
                                    }}
                                    confirmText="Delete"
                                    confirmBtnColor="red"
                                    requiredConfirmText="DELETE"
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
        </ClickAwayListener>
    );
};
export default TagGroupHeader;
