import { useMemo, useState } from 'react';

import { useBrandTagGroups } from '@features/product-tags/queries';

import Button from '@components/buttons/Button';
import TextField from '@components/inputs/TextField';
import Spinner from '@components/layout/Spinner';

export const CreateEditTagGroupForm = ({
    value,
    onChange,
    onCancel,
    onSave,
    isLoading,
    selectedBrandId,
}: {
    value: string;
    onChange: (value: string) => void;
    onCancel: (value: boolean) => void;
    onSave: (newName: string) => void;
    isLoading?: boolean;
    selectedBrandId: string;
}) => {
    const { brandTagGroupsAreReady, brandTagGroups = [] } = useBrandTagGroups(selectedBrandId);

    const existingBrandTagGroupsNames = useMemo(
        () =>
            brandTagGroups
                ?.map((tagGroup) => tagGroup.name.toLowerCase())
                .filter((x) => x !== value.toLowerCase()),
        [brandTagGroupsAreReady, brandTagGroups],
    );
    const [error, setError] = useState<string | undefined>();

    const checkExists = (newValue: string) =>
        existingBrandTagGroupsNames?.includes(newValue.toLowerCase());

    const errors = {
        required: 'A group name is required',
        exists: 'A group with this name already exists',
    };
    const handleChange = (newValue: string) => {
        if (newValue === '') {
            setError(errors.required);
        } else if (checkExists(newValue)) {
            setError(errors.exists);
        } else {
            setError(undefined);
        }
        onChange(newValue);
    };
    const handleSave = () => {
        if (error) return;
        onSave(value);
    };
    return (
        <div className="tag-group-name-form" data-testid="tag-group-name-form">
            <TextField
                error={!!error}
                name="tag-group-name"
                className="tag-group-name-form-input"
                value={value}
                required
                onChange={(e) => handleChange(e.target.value)}
                autoFocus
                helperText={error}
                data-testid="tag-group-name-form-input"
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleSave();
                    }
                }}
            />
            <Button onClick={() => onCancel(false)}>Cancel</Button>
            <Button
                onClick={() => handleSave()}
                disabled={isLoading || !!error}
                endIcon={isLoading ? <Spinner /> : null}
            >
                Save
            </Button>
        </div>
    );
};
