import { FC, useMemo } from 'react';

import {
    CommissionTypeOptions,
    MetricOptionsAll,
    MetricOptionsLimited,
} from '@constants/SparkConstants';

import { PercentIncreaseMetric, SparkCommissionType, SparkMetric, SparkType } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Restaurant as RestaurantIcon } from '@components/icons';
import RadioGroup from '@components/inputs/RadioGroup';

import { useAppAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import { InputEventOnChange } from '@app/types/UITypes';

import PercentIncreaseOption from './PercentIncreaseOption';
import { getSparkComparisonStartDate, getSparkDurationInDays } from './percentIncreaseUtils';

import './SparkMetricSelector.scss';

// This component has some unexpected and competeing logic. sparkCommissionType is used in place of spark.metric when the spark type is commission.
interface SparkMetricSelectorProps {
    sparkCommissionType?: SparkCommissionType;
    setSparkCommissionType: (updatedCommissionType: SparkCommissionType) => void;
    isRecurring: boolean;
}

const getLabel = (sparkType: SparkType) => {
    switch (sparkType) {
        case 'commission':
            return 'Commission Type';
        case 'goal':
            return 'Goal Metric';
        default:
            return 'Leaderboard Metric';
    }
};
const SparkMetricSelector: FC<SparkMetricSelectorProps> = ({
    sparkCommissionType,
    setSparkCommissionType,
    isRecurring,
}) => {
    const { spark, updateSpark } = useSpark();
    const { account } = useAppAccount();
    const label = getLabel(spark.type);

    const options = useMemo(() => {
        // Commission options
        if (spark.type === 'commission') {
            return CommissionTypeOptions;
        }
        // Goal options
        if (spark.type === 'goal') {
            return MetricOptionsLimited;
        }

        // Leaderboard Options (logical default)
        let leaderboardOptions = MetricOptionsAll;

        if (account?.industries?.includes('food service')) {
            // Added option for food service
            const excludedMetrics = ['percent_increase', 'percent_of_total_sales'];
            const restaurantOptions = MetricOptionsAll.filter(
                ({ value }) => !excludedMetrics.includes(value),
            );
            leaderboardOptions = [
                ...restaurantOptions,
                {
                    icon: <RestaurantIcon />,
                    label: 'Guest Check Average',
                    description: 'Track total net sales / total number of guests served',
                    value: 'guest_check_average',
                },
            ];
        }
        if (isRecurring || spark.detailedSparkType === 'leaderboardLocation') {
            return leaderboardOptions.map((option) => {
                if (option.value === 'percent_increase') {
                    return {
                        ...option,
                        disabled: true,
                        tooltipProps: {
                            title: `Percentage Increase Leaderboard are only available for ${
                                isRecurring
                                    ? 'One-time Sparks'
                                    : 'Single or Multi Leaderboard Sparks'
                            }`,
                        },
                    };
                }
                return option;
            });
        }

        return leaderboardOptions;
    }, [spark.type, sparkCommissionType]);

    const value: string = useMemo(() => {
        if (spark.type === 'commission') {
            return sparkCommissionType ?? '';
        }

        return spark.metric;
    }, [spark, sparkCommissionType]);

    const selectedMetric = useMemo(() => {
        return options.find((option) => option.value === value);
    }, [value]);

    const onChange: InputEventOnChange = (event) => {
        if (spark.type === 'commission') {
            setSparkCommissionType(event.target.value as SparkCommissionType);
            return;
        }
        const updateValues = {
            metric: event.target.value as SparkMetric,
            ...(event.target.value === 'percent_increase'
                ? {
                      percentIncreaseData: {
                          metric: 'order_average' as PercentIncreaseMetric,
                          startDate: getSparkComparisonStartDate(spark),
                          endDate: '',
                      },
                  }
                : {
                      percentIncreaseData: undefined,
                  }),
        };

        updateSpark(updateValues);
    };

    return (
        <div className="spark-metric-selector">
            {selectedMetric ? (
                <>
                    <Form.Label>{label}*</Form.Label>
                    <div className="selected-metric">
                        <div className="icon">{selectedMetric.icon} </div>
                        <div className="label-info">
                            <div className="label">{selectedMetric.label}</div>
                            <div className="description">{selectedMetric.description}</div>
                        </div>
                        <div className="right-side">
                            {selectedMetric.percentIncreaseMetricOptions && (
                                <PercentIncreaseOption
                                    durationInDays={getSparkDurationInDays(spark)}
                                    options={options.filter((option) =>
                                        selectedMetric.percentIncreaseMetricOptions?.includes(
                                            option.value,
                                        ),
                                    )}
                                />
                            )}
                            <Button
                                variant="flat"
                                color="blue"
                                disableHover
                                onClick={() => onChange({ target: { value: '' } })}
                            >
                                Change
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <RadioGroup
                    color="neutral"
                    variant="button"
                    required
                    label={label}
                    value={value}
                    options={options}
                    onChange={onChange}
                />
            )}
        </div>
    );
};

export default SparkMetricSelector;
