import { FC, useMemo, useState } from 'react';

import { AccountSparkBrand } from '@sparkplug/lib';

import { useSparkBrandsQuery } from '@features/spark-brands';

import AppView from '@components/layout/AppView';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { TagGroupsSidebar } from './TagGroupsSidebar';
import { TagGroupsWidget } from './TagGroupsWidget';

import './AdminAccountProductTagsView.scss';

interface ProductTagsHeaderProps {
    brandIsReady: boolean;
    readMoreUrl: string;
    brandName?: string;
}
const ProductTagsHeader: FC<ProductTagsHeaderProps> = ({
    brandIsReady,
    brandName,
    readMoreUrl,
}) => {
    if (!brandIsReady || !brandName) {
        return <Skeleton width="100%" height={40} />;
    }

    return (
        <header className="admin-product-tags_header-message">
            <span>
                Create up to 3 groups of product tags for <b>{brandName}</b> to organize and filter
                products when creating Sparks and analyzing sales. Tags created in this section can
                be applied across all linked Retailer products mapped to this brand.{' '}
                <a href={readMoreUrl} target="_blank" rel="noreferrer">
                    Read More
                </a>
            </span>
        </header>
    );
};

export const useSelectedBrand = (
    vendorBrands: AccountSparkBrand[],
): {
    selectedBrand?: AccountSparkBrand;
    updateSelectedBrand: (selectedBrandId: string) => void;
} => {
    const [selectedBrandId, setSelectedBrandId] = useState<string>();
    const selectedBrand = useMemo(
        () =>
            selectedBrandId
                ? vendorBrands.find(({ _id }) => _id === selectedBrandId)
                : vendorBrands[0],
        [vendorBrands, selectedBrandId],
    );

    return { selectedBrand, updateSelectedBrand: setSelectedBrandId };
};

interface AdminAccountProductTagsViewProps {
    vendorBrands: AccountSparkBrand[];
    vendorBrandsAreReady: boolean;
}
export const AdminAccountProductTagsView: FC<AdminAccountProductTagsViewProps> = ({
    vendorBrands = [],
    vendorBrandsAreReady,
}) => {
    const { account } = useSparkplugAccount();

    const { selectedBrand, updateSelectedBrand } = useSelectedBrand(vendorBrands);

    const readMoreUrl =
        'https://help.sparkplug.app/en/articles/7881380-creating-custom-product-tags-and-tag-groups';

    return (
        <Paper className="admin-product-tags">
            <div className="admin-product-tags_sidebar-container">
                <header>Your Brands</header>
                <TagGroupsSidebar
                    selectedBrandId={selectedBrand?._id}
                    updateSelectedBrand={updateSelectedBrand}
                    brands={vendorBrands}
                    brandsAreReady={vendorBrandsAreReady}
                />
            </div>

            <div className="admin-product-tags_content-container">
                <ProductTagsHeader
                    brandIsReady={vendorBrandsAreReady && !!selectedBrand}
                    brandName={selectedBrand?.name}
                    readMoreUrl={readMoreUrl}
                />
                {account && selectedBrand && (
                    <TagGroupsWidget
                        selectedBrandId={selectedBrand?._id}
                        brands={vendorBrands.filter(
                            (brand) => brand._id !== selectedBrand?._id && brand.isEnabled,
                        )}
                    />
                )}
            </div>
        </Paper>
    );
};

export default () => {
    const { account } = useSparkplugAccount();
    const { sparkBrands = [], sparkBrandsAreReady } = useSparkBrandsQuery(account?._id ?? '');

    return (
        <AppView.Content title="Product Tags">
            {account && (
                <AdminAccountProductTagsView
                    vendorBrandsAreReady={sparkBrandsAreReady}
                    vendorBrands={sparkBrands}
                />
            )}
        </AppView.Content>
    );
};
