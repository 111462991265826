import axios from 'axios';

import { AdminUpdateAccountRequestBody } from '@sparkplug/lib';

import { getAccountQueryKey } from '@core/accounts';

import { useAdvancedMutation } from '@hooks/QueryHooks';

type UpdateMutationFnPayload = {
    accountId: string;
    body: AdminUpdateAccountRequestBody;
};

const API = {
    adminAccountUpdate: async ({ accountId, body }: UpdateMutationFnPayload) => {
        return axios.put(`/api/v1/admin-accounts/${accountId}`, body);
    },
};

export const useAdminAccountUpdate = (accountId: string) => {
    const { mutate, mutateAsync, isLoading } = useAdvancedMutation(
        (body: AdminUpdateAccountRequestBody) => API.adminAccountUpdate({ accountId, body }),
        {
            updateQuery: { queryKey: getAccountQueryKey(accountId) },
        },
    );

    return {
        isAdminUpdatingAccount: isLoading,
        adminUpdateAccount: mutate,
        adminUpdateAccountAsync: mutateAsync,
    };
};
