import { FC, useMemo } from 'react';
import { RouteProps } from 'react-router';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import ConditionalRoute, { AccountCondition, UserCondition } from './ConditionalRoute';

interface AdminRouteProps extends RouteProps {
    unauthorizedRedirect?: string;
}

const AdminRoute: FC<AdminRouteProps> = ({ unauthorizedRedirect, ...otherProps }) => {
    const { accountPaymentConditions } = useSparkplugAccount();
    const redirect = useMemo(() => {
        if (accountPaymentConditions && accountPaymentConditions.paymentConditionRedirect) {
            return accountPaymentConditions.paymentConditionRedirect;
        }
        return unauthorizedRedirect;
    }, [accountPaymentConditions, unauthorizedRedirect]);
    const accountConditions: AccountCondition[] = [];
    const userConditions: UserCondition[] = [
        (user, account) => account?.roles[user?._id] === 'group-admin',
    ];
    if (accountPaymentConditions) {
        accountConditions.push(() => !!accountPaymentConditions.hasPaymentMethods);
        accountConditions.push(() => !!accountPaymentConditions.hasNoFailedPayment);
    }
    const newProps = {
        ...otherProps,
        unauthorizedRedirect: redirect,
        userConditions,
        accountConditions,
    };

    return <ConditionalRoute {...newProps} />;
};

export default AdminRoute;
