import { FC, useState } from 'react';

import Button from '@components/buttons/Button';
import TextField from '@components/inputs/TextField';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal from '@components/overlays/Modal';

interface AddBrandAliasModalProps {
    isVisible: boolean;
    onSave: (alias: string) => void;
    onClose: () => void;
    brandName: string;
}

export const AddBrandAliasModal: FC<AddBrandAliasModalProps> = ({
    isVisible,
    onSave,
    onClose,
    brandName,
}) => {
    const [aliasName, setAliasName] = useState<string>('');
    return (
        <Modal
            className="send-link-request-modal"
            maxWidth={480}
            isVisible={isVisible}
            onClose={onClose}
        >
            <Modal.Title onClose={onClose}>Add Brand Alias</Modal.Title>

            <CalloutMessage
                className="mx-6 w-fit"
                color="yellow"
                title="Important"
                message="Once a brand alias is added, it cannot be removed or changed. We use this to determine product matches across all Retailers on the platform. Upon saving, a link will be established between the Vendor and Retailer were an exact match is detected."
            />
            <Modal.Content>
                <TextField
                    className="w-full"
                    label="Brand Alias"
                    name="brand_alias"
                    value={aliasName}
                    required
                    helperText={`Enter an alias for ${brandName}`}
                    onChange={(e) => setAliasName(e.target.value)}
                />
            </Modal.Content>
            <Modal.Actions>
                <div>
                    <Button color="neutral" variant="flat" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        color="blue"
                        variant="smooth"
                        disabled={aliasName.length < 1}
                        onClick={() => {
                            onSave(aliasName ?? '');
                            onClose();
                        }}
                    >
                        Save Alias
                    </Button>
                </div>
            </Modal.Actions>
        </Modal>
    );
};
