import { FC, useMemo, useState } from 'react';

import { orderBy, uniqBy } from 'lodash';

import RetailerLinkDetails from '@features/account-links/components/RetailerLinkDetails';
import { useAccountLinks, useBrandLinksTagCounts } from '@features/account-links/queries';
import UntaggedProductsCallout from '@features/product-tags/components/UntaggedProductsCallout';
import { useAssignProductTag, useRemoveProductTag } from '@features/product-tags/mutations';
import { useBrandTagGroups, useVendorRetailerPosData } from '@features/product-tags/queries';

import Chip from '@components/chips/Chip';
import { HelpOutline } from '@components/icons';
import FullscreenNav from '@components/layout/FullscreenNav';
import PageHeader from '@components/layout/PageHeader';
import Skeleton from '@components/layout/Skeleton';
import Tabs from '@components/layout/Tabs/Tabs';
import { useParams } from '@components/router';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import BrandTabs from './BrandTabs';
import ProductTable from './ProductTable';
import ProductTableToolbar from './ProductTableToolbar';
import { MultiSelectProductTagOptions } from './ProductTableToolbar/ProductTableToolbar';
import { useProductTagsTableFilters } from './useProductTagsTableFilters';

import './AssigningProductTagsView.scss';

export const LearnMoreDetails: FC<{ onlyAssistant?: boolean }> = ({ onlyAssistant }) => {
    const { REACT_APP_AI_TAGGING = false } = process.env;
    return (
        <>
            <span>Learn more about {onlyAssistant ? 'the' : 'tagging'}</span>
            {REACT_APP_AI_TAGGING === 'true' && (
                <>
                    {!onlyAssistant && <span>&nbsp;+</span>}
                    <Chip
                        color="neutral"
                        variant="outlined"
                        className="tag-assist-chip"
                        label={
                            <>
                                <span>Tag Assistant</span>
                                <HelpOutline />
                            </>
                        }
                    />
                </>
            )}
        </>
    );
};

interface AssigningProductTagsViewProps {}

const AssigningProductTagsView: FC<AssigningProductTagsViewProps> = () => {
    // This feature flag doesn't actually exist but I dont want to delete code that might be used in the future
    const displayProductTagDetails = process.env.REACT_APP_DISPLAY_PRODUCT_TAGS === 'true';

    const tableFilters = useProductTagsTableFilters({});
    const { accountIsReady, account } = useSparkplugAccount();

    const [showTable, setShowTable] = useState<boolean>(true);
    const [selectedBrandId, setSelectedBrandId] = useState<string | undefined>(
        Object.values(account?.sparkBrands ?? {})?.filter(({ isEnabled }) => isEnabled)?.[0]?._id,
    );
    const [productType, setProductType] = useState<'active' | 'invalid'>('active');
    const { retailerAccountId }: { retailerAccountId: string } = useParams();
    const {
        vendorRetailerPosDataIsReady,
        vendorRetailerPosData,
        isRefetchingVendorRetailerPosData,
    } = useVendorRetailerPosData(account?._id ?? '', retailerAccountId ?? '');

    const { accountLinksAreReady, accountLinks } = useAccountLinks(account?._id || '');
    const { brandTagGroupsAreReady, brandTagGroups } = useBrandTagGroups(selectedBrandId ?? '');

    const retailerAccount = useMemo(() => {
        return accountLinks?.find(({ accountId }) => accountId === retailerAccountId);
    }, [retailerAccountId, accountLinksAreReady]);

    // TODO: rename this to brandLinkProductCounts as we're no longer tracking tags for now
    const { brandLinksTagCountsAreReady, brandLinksTagCounts } = useBrandLinksTagCounts({
        brandLinkIds:
            (retailerAccount?.brandLinks.map(({ _id }) => _id)?.filter(Boolean) as string[]) ?? [],
        isEnabled: !!retailerAccount,
    });

    const sparkBrands = useMemo(() => {
        if (!account?.sparkBrands || !retailerAccount || !brandLinksTagCountsAreReady) {
            return [];
        }

        return orderBy(account.sparkBrands, ['isEnabled', 'name'], ['desc', 'asc']);
    }, [accountIsReady, accountLinksAreReady, brandLinksTagCountsAreReady, brandLinksTagCounts]);

    const selectedBrand = useMemo(() => {
        if (!accountIsReady || !sparkBrands || !selectedBrandId) {
            return undefined;
        }

        return (
            sparkBrands.find(({ _id }) => _id === selectedBrandId) ??
            // In case first brand is disabled or for some reason cannot be found
            sparkBrands?.[0]
        );
    }, [accountIsReady, sparkBrands, selectedBrandId]);

    const selectedBrandLinkId = useMemo(() => {
        return (
            retailerAccount?.brandLinks?.find(
                ({ brandId: brandLinkBrandId }) => selectedBrandId === brandLinkBrandId,
            )?._id || ''
        );
    }, [retailerAccount, selectedBrandId]);

    const { assignProductTag, isAssigningTag } = useAssignProductTag(
        account?._id ?? '',
        retailerAccountId,
        selectedBrandLinkId,
    );
    const { removeProductTag } = useRemoveProductTag(
        account?._id ?? '',
        retailerAccountId,
        selectedBrandLinkId,
    );

    const { pageHeaderTitle, selectedBrandNameTitle: brandNameTitle } = useMemo(() => {
        const selectedBrandName = selectedBrand?.name;
        const selectedBrandNameTitle = selectedBrandName ?? 'Loading...';

        const loadedTitle = (
            <>
                {`${selectedBrandName} Products `}
                <span className="brand-name-subtitle">at {retailerAccount?.accountName}</span>
            </>
        );

        const nonLoadedTitle = <Skeleton height={38} width={500} />;

        return {
            pageHeaderTitle: selectedBrandName ? loadedTitle : nonLoadedTitle,
            selectedBrandNameTitle,
        };
    }, [accountIsReady, retailerAccountId, selectedBrand, sparkBrands, account]);

    const activeOrExcludedProducts = useMemo(() => {
        const { products = [], excludedProducts = [] } =
            vendorRetailerPosData?.productsVendorBrandMap?.[selectedBrand?._id ?? ''] ?? {};
        return productType === 'active' ? products : excludedProducts;
    }, [vendorRetailerPosData, selectedBrand, productType]);

    const excludedProductsCountMemo = useMemo(() => {
        const { excludedProducts = [] } =
            vendorRetailerPosData?.productsVendorBrandMap?.[selectedBrand?._id ?? ''] ?? {};
        return excludedProducts.length;
    }, [vendorRetailerPosData, selectedBrand, productType]);

    const allTagOptions = useMemo(() => {
        if (brandTagGroups) {
            return brandTagGroups
                .flatMap(({ name: categoryName, tags }) =>
                    tags.map(({ _id, name, color }) => ({
                        value: _id,
                        label: name,
                        color,
                        category: categoryName,
                    })),
                )
                .sort((a, b) => {
                    if (a.category === b.category) {
                        return a.label.localeCompare(b.label);
                    }
                    return a.category.localeCompare(b.category);
                });
        }

        return [];
    }, [brandTagGroups]);

    const { brandOptions, categoryOptions, tagOptions } = useMemo(() => {
        if (vendorRetailerPosDataIsReady && activeOrExcludedProducts) {
            const newBrandOptions = uniqBy(
                activeOrExcludedProducts.flatMap(({ brands }) => brands),
                '_id',
            )
                .map(({ _id, name }) => ({ value: _id, label: name }))
                .sort((a, b) => a.label.localeCompare(b.label));
            const newCategoryOptions = uniqBy(
                activeOrExcludedProducts.flatMap(({ categories }) => categories),
                '_id',
            )
                .map(({ _id, name }) => ({ value: _id, label: name }))
                .sort((a, b) => a.label.localeCompare(b.label));
            const newTagOptions = uniqBy(
                activeOrExcludedProducts.flatMap(({ tags }) => tags || []),
                '_id',
            )
                .map(({ _id, name, color }) => {
                    const category = allTagOptions.find((tag) => tag.value === _id)?.category ?? '';

                    return {
                        value: _id,
                        label: name,
                        color,
                        category,
                    };
                })
                .sort((a, b) => {
                    if (a.category === b.category) {
                        return a.label.localeCompare(b.label);
                    }
                    return a.category.localeCompare(b.category);
                });

            return {
                brandOptions: newBrandOptions,
                categoryOptions: newCategoryOptions,
                tagOptions: [
                    { value: 'none', label: 'No Tags' } as MultiSelectProductTagOptions,
                    ...newTagOptions,
                ],
            };
        }

        return {
            brandOptions: [],
            categoryOptions: [],
            tagOptions: [],
        };
    }, [vendorRetailerPosDataIsReady, activeOrExcludedProducts]);

    const handleActive = () => {
        setShowTable(false);
        setProductType('active');
        setTimeout(() => setShowTable(true), 0);
    };

    const handleInvalid = () => {
        setShowTable(false);
        setProductType('invalid');
        setTimeout(() => setShowTable(true), 0);
    };

    const handleSelectedBrandUpdate = (brandId: string) => {
        setShowTable(false);
        setSelectedBrandId(brandId);
        setTimeout(() => setShowTable(true), 0);
    };

    const productTable = useMemo(
        () => (
            <ProductTable
                initialDataIsReady={vendorRetailerPosDataIsReady && brandTagGroupsAreReady}
                vendorRetailerPosData={activeOrExcludedProducts}
                brandTagGroups={brandTagGroups}
                productType={productType}
                selectedBrandId={selectedBrand?._id ?? ''}
                selectedBrandLinkId={selectedBrandLinkId}
                vendorAccountId={account?._id}
                retailerAccountId={retailerAccountId}
                tableFilters={tableFilters}
                assignProductTag={assignProductTag}
                isAssigningTag={isAssigningTag}
                removeProductTag={removeProductTag}
            />
        ),
        [vendorRetailerPosDataIsReady, brandTagGroupsAreReady, tableFilters],
    );

    return (
        <FullscreenNav
            className="assigning-product-tags"
            backLinkText="Linked Retailers"
            variant="sidebar"
            sidebar={
                <>
                    <RetailerLinkDetails accountLink={retailerAccount} />
                    <BrandTabs
                        brands={sparkBrands}
                        selectedBrandId={selectedBrand?._id ?? sparkBrands?.[0]?._id}
                        updateSelectedBrand={handleSelectedBrandUpdate}
                        brandsAreReady={accountIsReady}
                        vendorRetailerPosDataIsReady={vendorRetailerPosDataIsReady}
                        vendorRetailerPosData={vendorRetailerPosData}
                    />
                </>
            }
            backLinkFallbackUrl={`/${account?._id}/partners`}
        >
            <PageHeader title={pageHeaderTitle} metaTitle={brandNameTitle} />
            <Tabs className="product-type-selection" value={productType} color="blue">
                <Tabs.Tab
                    onClick={() => handleActive()}
                    key="active"
                    label="Active"
                    value="active"
                />
                <Tabs.Tab
                    onClick={() => handleInvalid()}
                    key="invalid"
                    label={
                        <>
                            Invalid
                            {excludedProductsCountMemo ? (
                                <Chip color="neutral" label={`${excludedProductsCountMemo}`} />
                            ) : (
                                ''
                            )}
                        </>
                    }
                    value="invalid"
                />
            </Tabs>
            <ProductTableToolbar
                brandOptions={brandOptions}
                categoryOptions={categoryOptions}
                tagOptions={tagOptions}
                tableFilters={tableFilters}
                vendorAccountId={account?._id}
                isRefetchingVendorRetailerPosData={isRefetchingVendorRetailerPosData}
            />
            {displayProductTagDetails && (
                <UntaggedProductsCallout
                    productType={productType}
                    selectedBrand={selectedBrand ?? sparkBrands?.[0]}
                    tableFilters={tableFilters}
                />
            )}
            {showTable ? productTable : <Skeleton height="80%" />}
        </FullscreenNav>
    );
};

export default AssigningProductTagsView;
