import { FC, ReactElement, useEffect, useState } from 'react';

import { clsx } from 'clsx';
import moment from 'moment';

import { SparkRequestState } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import Dropdown from '@components/dropdown/Dropdown';
import { useDropdown } from '@components/dropdown/DropdownContext';
import { NoNotificationsGraphic } from '@components/graphics';
import {
    FiberManualRecord as Dot,
    Link as LinkIcon,
    MessageOutlined,
    Notifications,
    OfflineBolt,
} from '@components/icons';
import Badge from '@components/layout/Badge';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import List from '@components/layout/List';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { getNotificationItems, markNotificationRead } from '@helpers/notifications';

import { IAuthUser } from '@app/types/UsersTypes';

import './NotificationWidget.scss';

interface NotificationComponentProps {
    notification: any;
    onClick: Function;
}

const GenericNotification = ({
    notificationId,
    type,
    icon,
    isRead,
    to,
    message,
    createdAt,
    onClick,
}: {
    notificationId: string;
    type: string;
    icon: any;
    isRead: boolean;
    to?: string;
    message: ReactElement;
    createdAt: string;
    onClick: Function;
}) => {
    const { handleClose } = useDropdown();

    return (
        <List.Item
            className={clsx(['notification-list-item', type, isRead ? 'is-read' : 'is-unread'])}
            to={to}
            onClick={() => {
                if (to != null) {
                    handleClose();
                }
                onClick(notificationId);
            }}
        >
            <div className="notification-icon">{icon}</div>
            <div className="notification-content">
                <h4>{message}</h4>
                <span>{moment(createdAt).fromNow()}</span>
            </div>
            <Dot className="unread-indicator" />
        </List.Item>
    );
};

const BrandLinkRequestNotification = ({
    notification,
    onClick,
}: {
    notification: any;
    onClick: () => any;
}) => {
    const to = `/${notification.data.retailerGroupId}/partners`;

    if (notification.data.brandGroupId != null && notification.data.brandName != null) {
        return (
            <GenericNotification
                notificationId={notification?._id}
                type={notification?.type}
                icon={<LinkIcon />}
                isRead={notification?.readAt != null}
                to={to}
                message={
                    <>
                        <strong>{notification.data.brandName}</strong>
                        <span> requested a POS link</span>
                    </>
                }
                createdAt={notification.createdAt}
                onClick={onClick}
            />
        );
    }

    return null;
};

type TBrandSparkRequestData = {
    sparkId: string;
    brandGroupName: string;
    sparkRequestState: SparkRequestState;
    sparkStartDate: string;
    type: 'spark-request';
};

export const BrandSparkRequestNotification: FC<NotificationComponentProps> = ({
    notification,
    onClick,
}) => {
    const {
        brandGroupName: vendorAccountName,
        sparkRequestState,
        sparkStartDate,
        sparkId,
    }: TBrandSparkRequestData = notification.data;

    const { account } = useSparkplugAccount();

    let to = `/${account?._id}/sparks/${sparkId}`;
    if (sparkRequestState === 'pending') {
        to = `/${account?._id}/sparks/inbox/${sparkId}`; // opens "accept/reject" modal
    }
    if (account?.type === 'retailer' && sparkRequestState === 'rejected') {
        // if a retailer still has a notification from a spark they rejected, we route to the main view
        // we don't display rejected sparks to a retailer
        to = `/${account?._id}/sparks`;
    }

    if (sparkRequestState && vendorAccountName) {
        return (
            <GenericNotification
                notificationId={notification?._id}
                type={notification?.type}
                icon={<OfflineBolt />}
                isRead={!!notification?.readAt}
                to={to}
                message={
                    <>
                        <strong>{vendorAccountName}</strong>
                        <span> sent you a new Spark scheduled to launch </span>
                        <strong>{moment(sparkStartDate).format('M/D/YY')}</strong>
                        <span>.</span>
                    </>
                }
                createdAt={notification.createdAt}
                onClick={onClick}
            />
        );
    }

    return null;
};

type TBrandSparkResponseData = {
    sparkId: string;
    accepted: boolean;
    responseText: string;
    retailerGroupId: string;
    retailerGroupName: string;
    sparkName: string;
    sparkStartDate: string;
    type: 'spark-response';
};

export const BrandSparkResponseNotification: FC<NotificationComponentProps> = ({
    notification,
    onClick,
}) => {
    const { accepted, sparkName, retailerGroupName, sparkId }: TBrandSparkResponseData =
        notification.data;

    const { account } = useSparkplugAccount();

    const to = `/${account?._id}/sparks/${sparkId}`;

    /* eslint-disable */
    const message = accepted ? (
        <>
            <strong>{retailerGroupName}</strong>
            <span> accepted your proposed Spark: </span>
            <strong>{sparkName}</strong>
        </>
    ) : (
        <>
            <strong>{retailerGroupName}</strong>
            <span> rejected your proposed Spark: </span>
            <strong>{sparkName}</strong>
        </>
    );
    /* eslint-enable */

    return (
        <GenericNotification
            notificationId={notification?._id}
            type={notification?.type}
            icon={<OfflineBolt />}
            isRead={notification?.readAt != null}
            to={to}
            message={message}
            createdAt={notification.createdAt}
            onClick={onClick}
        />
    );
};

const MessageNotification = ({
    notification,
    onClick,
}: {
    notification: any;
    onClick: () => any;
}) => {
    return (
        <GenericNotification
            notificationId={notification?._id}
            type={notification?.type}
            icon={<MessageOutlined />}
            isRead={notification?.readAt != null}
            message={notification.data.content}
            createdAt={notification.createdAt}
            onClick={onClick}
        />
    );
};

export const RetailerSparkRequestNotification = ({
    notification,
    onClick,
}: {
    notification: any;
    onClick: () => any;
}) => {
    const { account } = useSparkplugAccount();
    const to = `/${account?._id}/sparks/requests?rid=${notification.data.requestForSparkId}`;

    return (
        <GenericNotification
            notificationId={notification?._id}
            type={notification?.type}
            icon={<LinkIcon />}
            isRead={notification?.readAt != null}
            to={to}
            message={
                <>
                    <strong>{notification.data.retailerName}</strong>
                    <span> requested a Spark!</span>
                </>
            }
            createdAt={notification.createdAt}
            onClick={onClick}
        />
    );
};

export const NotificationListItem = (props: { notification: any; onClick: any }) => {
    const { notification } = props;

    if (notification.type === 'brand-link-request') {
        return <BrandLinkRequestNotification {...props} />;
    }

    if (notification.type === 'spark-request') {
        return <BrandSparkRequestNotification {...props} />;
    }

    if (notification.type === 'spark-response') {
        return <BrandSparkResponseNotification {...props} />;
    }

    if (notification.type === 'message') {
        return <MessageNotification {...props} />;
    }

    if (notification.type === 'retailer-spark-request') {
        return <RetailerSparkRequestNotification {...props} />;
    }

    return null;
};

const NotificationWidget = ({ user }: { user: IAuthUser }) => {
    const [notifications, setNotifications] = useState<any[]>([]);
    const [badgeCount, setBadgeCount] = useState<number>(0);

    const updateNotifications = () => {
        if (user) {
            getNotificationItems(user?._id).then((newNotifications) => {
                setNotifications(newNotifications);
                setBadgeCount(newNotifications.filter(({ readAt }) => readAt == null).length);
            });
        }
    };

    useEffect(() => {
        updateNotifications();
    }, []);

    const updateNotificationReadStatus = (id?: string) => {
        setNotifications((prevNotifications) => {
            let didUpdate = false;

            const newNotifications = prevNotifications.map((notification) => {
                if (
                    notification?._id &&
                    (!id || notification._id === id) &&
                    notification?.readAt == null
                ) {
                    didUpdate = true;
                    markNotificationRead(notification._id);

                    return {
                        ...notification,
                        readAt: '',
                    };
                }

                return notification;
            });

            if (didUpdate) {
                setBadgeCount(newNotifications.filter(({ readAt }) => readAt == null).length);
                return newNotifications;
            }

            return prevNotifications;
        });
    };

    const onClickMarkAllRead = () => {
        updateNotificationReadStatus();
    };

    const onClickNotification = (notificationId: string) => {
        updateNotificationReadStatus(notificationId);
    };

    return (
        <div className="notification-widget">
            <Dropdown>
                <Dropdown.Button
                    hideExpandIcon
                    color="neutral"
                    variant="flat"
                    onClick={() => {
                        updateNotifications();
                    }}
                >
                    <Badge count={badgeCount}>
                        <Notifications />
                    </Badge>
                </Dropdown.Button>
                <Dropdown.Popover
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List className="notification-list">
                        {notifications.length > 0 ? (
                            <>
                                <div className="notification-list-header">
                                    <div className="notification-list-title">Notifications</div>
                                    <div>
                                        <Button onClick={onClickMarkAllRead}>
                                            Mark all as read
                                        </Button>
                                    </div>
                                </div>
                                {notifications.map((notification) => (
                                    <NotificationListItem
                                        key={`${notification?._id}-${notification?.readAt}-${notification?.archivedAt}`}
                                        notification={notification}
                                        onClick={onClickNotification}
                                    />
                                ))}
                            </>
                        ) : (
                            <List.Item className="notification-list-item no-notifications">
                                <div className="notification-content">
                                    <div className="notification-label">Notifications</div>
                                    <EmptyStateDisplay
                                        graphic={<NoNotificationsGraphic />}
                                        label="Nothing New"
                                    />
                                </div>
                            </List.Item>
                        )}
                    </List>
                </Dropdown.Popover>
            </Dropdown>
        </div>
    );
};

export default NotificationWidget;
