import {
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { CommissionTypeOptions } from '@constants/SparkConstants';
import { has, isEqual, keyBy } from 'lodash';
import moment from 'moment';

import {
    DetailedSparkType,
    MultiRetailerProductSelection,
    MultiRetailerSparkParticipantGroup,
    RetailerSparkFilters,
    Spark,
    SparkCommissionType,
    SparkDeclineResponse,
} from '@sparkplug/lib';

import {
    useCreateMultiRetailerSparkMutation,
    useRequestMultiRetailerSparkMutation,
} from '@core/sparks/mutations/SparkMutations';
import { useAccountUsersQuery } from '@core/users';

import {
    useAllVendorRetailerProductsByBrandId,
    useVendorBrandRetailersProductsByBrandId,
} from '@features/account-links';
import { UIRequestForSparkWithAccountLink } from '@features/request-for-spark/types';

import { useQueryClient } from '@hooks/QueryHooks';
import { useAccountPosDataQuery, useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { getSparkQueryKey, useSparkPosArchives } from '@hooks/SparksHooks';
import { useSparkPosData } from '@hooks/SparksHooks/useSparkPosData';

import { isCypressRunning } from '@helpers/cypress';
import {
    applySparkSubGroupsPosData,
    buildSparkSubGroups,
    fetchSparkSubGroups,
    getDetailedSparkType,
    getSpark,
} from '@helpers/sparks';
import { isJestRunning } from '@helpers/testing';
import SparksUI from '@helpers/toast/SparksUI';
import { isEmpty } from '@helpers/util';

import { IPosLocation } from '@app/types/PosTypes';
import {
    ISparkContext,
    ISparkSubGroup,
    MultiRetailerProducts,
    SparkCreateEditAction,
    SparkParticipant,
} from '@app/types/SparksTypes';
import { IOption } from '@app/types/UITypes';
import { IAccountUser } from '@app/types/UsersTypes';

import { useSparkQualifyingProductsState } from './useSparkQualifyingProductsState';

let defaultSparkData = {
    groupId: '',
    name: '',
    description: '',
    locationIds: [],
    type: '',
    isPublic: false,
    emoji: 'trophy',
    posEmployeeProfileIds: [],
    posBrandIds: [],
    posCategoryIds: [],
    posProductIds: [],
    awards: [],
    goals: [],
    commissions: [],
} as unknown as Spark;

if (process.env.REACT_APP_ENV === 'development') {
    if (!isCypressRunning && !isJestRunning) {
        defaultSparkData = {
            ...defaultSparkData,
            name: `DEBUG: Spark Name`,
            startDate: moment().subtract(14, 'days').format('YYYY-MM-DD'),
            endDate: moment().add(14, 'days').format('YYYY-MM-DD'),
            description: `This is a placeholder description`,
            awards: [{ award: '$300' }, { award: '$200' }, { award: '$100' }],
            fulfillmentTypes: ['sparkplug', 'sparkplug', 'sparkplug'],
        };
    }
}

export const SparkContext = createContext({} as ISparkContext);

const formatSparkForEdit = (spark: Spark, overrideProperties: Partial<Spark>) => {
    if (isEmpty(overrideProperties)) {
        return spark;
    }

    return {
        ...spark,
        ...overrideProperties,
    };
};

const formatSparkForCreateClone = (spark: Spark) => {
    return {
        ...spark,
        internalTracking: {
            status: 'verify',
            invoiceStatus: 'not-sent',
            payoutStatus: 'not-paid',
            notes: '',
        },
    };
};

export const updateSparkSubGroupsByLocationsAndParticipants =
    (
        detailedSparkType: DetailedSparkType,
        setSparkSubGroupRevisions: Dispatch<SetStateAction<ISparkSubGroup[]>>,
        updateSpark: (updatedSparkProperties: Partial<Spark>) => void,
    ) =>
    (
        selectedLocations: IOption<IPosLocation>[],
        selectedParticipants: IOption<IAccountUser>[],
        activeParticipantOptions?: IAccountUser[],
    ) => {
        const selectedLocationIds = selectedLocations.map(({ value }) => value);
        const selectedParticipantIds = selectedParticipants
            // Filter users at participating locations
            .filter(({ locationIds, lastTransactionLocationId }) => {
                if (detailedSparkType === 'goalManager') {
                    return true;
                }

                if (detailedSparkType === 'leaderboardMulti') {
                    return lastTransactionLocationId
                        ? selectedLocationIds.includes(lastTransactionLocationId)
                        : selectedLocationIds.some((locationId) =>
                              locationIds?.includes(locationId),
                          );
                }

                return selectedLocationIds.some((locationId) => locationIds?.includes(locationId));
            })
            .flatMap(({ posEmployeeProfileIds }) => posEmployeeProfileIds);

        setSparkSubGroupRevisions(
            buildSparkSubGroups(
                detailedSparkType,
                selectedLocations,
                selectedParticipants,
                activeParticipantOptions,
            ),
        );
        const allOptions = (activeParticipantOptions || []).flatMap(
            (participant) => participant.posEmployeeProfileIds,
        );
        const selectedParticipantPosEmployeeProfileIds = selectedParticipants.flatMap(
            (participant) => participant.posEmployeeProfileIds,
        );

        // if we're working with a spark that does not allow individual participant selection, we don't want to provide excluded participants (because it will then include all!)
        const excludedParticipants =
            selectedParticipantPosEmployeeProfileIds.length > 0
                ? allOptions.filter((participant) =>
                      selectedParticipantPosEmployeeProfileIds.every((selectedPosEpId) => {
                          return !participant.includes(selectedPosEpId);
                      }),
                  )
                : [];

        updateSpark({
            locationIds: selectedLocationIds,
            posEmployeeProfileIds: selectedParticipantIds,
            // multi-lb sparks have excludedPosEmployeeProfileIds on each subgroup instead of on the spark itself
            ...(detailedSparkType !== 'leaderboardMulti'
                ? { participantFilters: { excludedPosEmployeeProfileIds: excludedParticipants } }
                : {}),
        });
    };

export const SparkProvider: FC<
    PropsWithChildren<{
        sparkId?: string;
        initialSparkData?: Partial<Spark>;
        requestForSpark?: UIRequestForSparkWithAccountLink;
    }>
> = ({ sparkId, initialSparkData = {}, requestForSpark, children }) => {
    const queryClient = useQueryClient();

    const [isReady, setIsReady] = useState(false);
    const [sparkNotFound, setSparkNotFound] = useState(false);
    const [sparkHasChanged, setSparkHasChanged] = useState(false);
    const [spark, setSpark] = useState<Spark>(defaultSparkData);
    const [sparkSubGroupRevisions, setSparkSubGroupRevisions] = useState<ISparkSubGroup[]>([]);
    const [multiRetailerParticipantGroups, updateMultiRetailerParticipantGroups] = useState<
        MultiRetailerSparkParticipantGroup[]
    >([]);
    /**
     * @description
     *
     * If filters applied when selecting products eliminate all products at a selected retailer, we remove the retailer from the participant group before getting to the review/create step.  However, if the user then goes back to the product selection screen, we need to be able to display the originally selected retailers in case they want to change the filters to include more retailers again.
     */
    const [originalMultiRetailerParticipantGroups, updateOriginalMultiRetailerParticipantGroups] =
        useState<MultiRetailerSparkParticipantGroup[]>([]);
    const [multiRetailerParticipantSelection, setMultiRetailerParticipantSelection] = useState<
        string[]
    >([]);
    const [multiRetailerProductSelection, updateMultiRetailerProductSelection] =
        useState<MultiRetailerProductSelection>({});
    /**
     * @description
     *
     * This stores multi-retailer locations that don't have all participants selected. The server
     * will use these to calculate if all participants should be includes or only those in the
     * `posEmployeeProfileIds` in the `multiRetailerParticipantGroups`. Because of work in parallel,
     * this has been separated, but ideally, this would be stored with the multiRetailerParticipantGroups.
     */
    const [uniqueParticipantLocationIdsById, setUniqueParticipantLocationIdsById] = useState<
        Record<string, boolean>
    >({});

    const isArchivedSpark = !!spark.archivedAt;

    const isRecurringSpark = useMemo(() => {
        return !!spark.recurringSparkScheduleId || !!spark.recurringSchedule;
    }, [spark.recurringSparkScheduleId, spark.recurringSchedule]);

    const { account } = useSparkplugAccount();
    const { accountUsers: retailerAccountUsers } = useAccountUsersQuery(spark?.groupId, true);
    const { accountAllPosLocations } = useAccountPosDataQuery(spark.groupId, {
        includedDatasets: ['locations'],
        isEnabled: isReady && !isArchivedSpark,
    });

    const {
        isRulesBasedSpark,
        isCreatingMultiRetailerSpark,
        isRetailerRulesBasedSpark,
        isVendorTagsRulesBasedSpark,
        isVendorPosRulesBasedSpark,
        updateSparkQualifyingProductsState,
    } = useSparkQualifyingProductsState({ account, spark });

    const shouldQueryVendorBrandRetailerProducts =
        (isCreatingMultiRetailerSpark ||
            isVendorTagsRulesBasedSpark ||
            isVendorPosRulesBasedSpark) &&
        account?.type === 'brand' &&
        !!account?._id &&
        !!spark.sparkBrandId &&
        (isCreatingMultiRetailerSpark
            ? !!multiRetailerParticipantGroups.length
            : isVendorTagsRulesBasedSpark || isVendorPosRulesBasedSpark);

    useAllVendorRetailerProductsByBrandId({
        vendorAccountId: spark.originatorGroupId ?? '',
        brandId: spark.sparkBrandId ?? '',
        isEnabled:
            isCreatingMultiRetailerSpark && !!spark.sparkBrandId && account?.type === 'brand',
    });

    const { vendorBrandRetailerProductsAreReady, vendorBrandRetailerProducts = {} } =
        useVendorBrandRetailersProductsByBrandId({
            brandId: spark.sparkBrandId,
            vendorAccountId: account?._id ?? '',
            vendorBrandRetailerIds: isCreatingMultiRetailerSpark
                ? multiRetailerParticipantGroups.map(
                      ({ retailerAccountId }) => retailerAccountId ?? '',
                  )
                : [spark.groupId],
            isEnabled: shouldQueryVendorBrandRetailerProducts,
        });

    /**
     * This is used for any vendor rules-based Sparks. If we are creating a
     * multi-retailer spark, then we filter by the selected retailers. Otherwise,
     * we return the products for the single retailer
     */
    const multiRetailerProducts = useMemo<MultiRetailerProducts>(() => {
        if (!vendorBrandRetailerProductsAreReady) return {};

        if (
            Object.keys(multiRetailerProductSelection).length === 0 &&
            !isCreatingMultiRetailerSpark &&
            (isVendorTagsRulesBasedSpark || isVendorPosRulesBasedSpark)
        ) {
            // return all products if no selection has been made
            return vendorBrandRetailerProducts;
        }

        return Object.fromEntries(
            Object.entries(multiRetailerProductSelection).map(
                ([retailerAccountId, { posProductIds }]) => {
                    const retailerProducts = vendorBrandRetailerProducts[retailerAccountId] ?? [];
                    const selectedProducts = retailerProducts.filter(({ _id }) =>
                        posProductIds.includes(_id),
                    );

                    return [retailerAccountId, selectedProducts];
                },
            ),
        );
    }, [
        vendorBrandRetailerProductsAreReady,
        vendorBrandRetailerProducts,
        multiRetailerProductSelection,
    ]);
    const { createMultiRetailerSparkAsync } = useCreateMultiRetailerSparkMutation({
        accountId: account?._id ?? '',
    });
    const { requestMultiRetailerSparkAsync } = useRequestMultiRetailerSparkMutation({
        accountId: account?._id ?? '',
    });

    const { sparkPosDataIsReady, sparkPosData, sparkCommissionMap } = useSparkPosData({
        spark,
        isEnabled: isReady,
    });

    const archivedMultiLeaderboardSparkIds: string[] = sparkSubGroupRevisions
        .filter(({ archivedAt }) => !!archivedAt)
        .map(({ sparkId: subgroupSparkId }) => subgroupSparkId as string);

    // For archived ML Sparks query all pos archives
    useSparkPosArchives(
        archivedMultiLeaderboardSparkIds,
        Boolean(archivedMultiLeaderboardSparkIds.length && !!spark.tag && sparkPosDataIsReady),
    );

    const sparkCommissionType = useMemo(() => {
        const isCommissionSpark = spark.type === 'commission';
        const sparkHasCommissionItems = !!spark?.commissions?.length;

        return (
            isCommissionSpark && sparkHasCommissionItems
                ? CommissionTypeOptions.find((option) => {
                      return option.value === spark?.commissions[0]?.type;
                  })
                : CommissionTypeOptions[0]
        )?.value as SparkCommissionType;
    }, [spark]);

    const detailedSparkType = useMemo(() => {
        return getDetailedSparkType(spark);
    }, [spark]);

    const sparkSubGroups = useMemo(() => {
        const multiLeaderboardLocations = !isArchivedSpark
            ? accountAllPosLocations
            : /**
               * During Spark Checkout, not all multi-leaderboard Sparks are finalized at once.
               * To fix this, we need to merge the archived and current pos locations
               */
              Object.values({
                  ...keyBy(accountAllPosLocations, '_id'),
                  ...keyBy(sparkPosData.locations, '_id'),
              });

        const multiLeaderboardParticipants = !isArchivedSpark
            ? retailerAccountUsers.map(
                  (accountUser) =>
                      ({
                          ...accountUser,
                          value: accountUser.flexibleEmployeeId,
                      } as SparkParticipant),
              )
            : sparkPosData.participants;

        return applySparkSubGroupsPosData(
            sparkSubGroupRevisions,
            multiLeaderboardLocations,
            multiLeaderboardParticipants,
        );
    }, [
        sparkSubGroupRevisions,
        isArchivedSpark,
        sparkPosData.participants,
        sparkPosData.locations,

        retailerAccountUsers,
        accountAllPosLocations,
    ]);

    const fetchSparkData = async (forceRefetch: boolean = false) => {
        let newSpark: Spark = defaultSparkData;
        let newSparkSubGroups: ISparkSubGroup[] = [];

        if (sparkId != null) {
            if (forceRefetch) {
                newSpark = (await getSpark(spark?._id || sparkId)) || defaultSparkData;
                queryClient.setQueryData(getSparkQueryKey(spark?._id || sparkId), newSpark);
            } else {
                try {
                    newSpark = await queryClient.fetchQuery(
                        getSparkQueryKey(spark?._id || sparkId),
                        () => getSpark(spark?._id || sparkId),
                    );
                } catch (error) {
                    setSparkNotFound(true);
                    return;
                }
            }

            newSparkSubGroups = await fetchSparkSubGroups(newSpark);

            if (spark.tag) {
                queryClient.setQueryData(['sparkSubGroups', spark.tag], newSparkSubGroups);
            }
        } else {
            newSpark = {
                ...newSpark,
                ...initialSparkData,
            };

            if (newSpark.type === 'commission') {
                newSpark.metric = 'total_units';
            }
        }

        setSpark(newSpark);
        setSparkSubGroupRevisions(newSparkSubGroups);
        setIsReady(true);
    };

    const updateSpark = (newValues: Partial<Spark>) => {
        // If any of these values are updated, the spark will reset all pos data
        const isEditingSpark = !!sparkId;
        const resetPosDataKeys = ['groupId'];
        const keys = Object.keys(newValues);

        const resetPosData = keys.some((key) => {
            return resetPosDataKeys.includes(key);
        });

        if (resetPosData) {
            setSparkSubGroupRevisions([]);
        }

        setSpark((prevValues) => {
            if (isEditingSpark) {
                Object.entries(newValues).forEach(([key, value]) => {
                    if (!isEqual(prevValues[key as keyof Spark], value)) {
                        setSparkHasChanged(true);
                    }
                });
            }
            const resetValues = resetPosData
                ? {
                      locationIds: [],
                      posEmployeeProfileIds: [],
                      posBrandIds: [],
                      posCategoryIds: [],
                      posProductIds: [],
                      commissions: [],
                      productFilters: {},
                      participantFilters: {},
                      retailerFilters: {
                          primaryFilter: 'brands',
                          posBrandIds: [],
                          posCategoryIds: [],
                          lastSoldAt: '-60days',
                          hideSampleProducts: false,
                          productNameFilters: [],
                          productNameContains: [],
                          productNameDoesNotContain: [],
                          excludedProductIds: [],
                      } as RetailerSparkFilters,
                      vendorFilters: undefined,
                      teamType: undefined,
                  }
                : {};

            const updatedValues = {
                ...prevValues,
                ...newValues,
                ...resetValues,
            };

            // Leaderboard Location sparks do not currently support minimumTransactionsToQualify
            if (
                updatedValues.detailedSparkType === 'leaderboardLocation' &&
                has(updatedValues, 'minimumTransactionsToQualify')
            ) {
                delete updatedValues.minimumTransactionsToQualify;
            }
            // If spark metric is not percent_increase then we don't want to include percentIncreaseData
            if (
                updatedValues.metric !== 'percent_increase' &&
                has(updatedValues, 'percentIncreaseData')
            ) {
                delete updatedValues.percentIncreaseData;
            }

            return updatedValues;
        });
    };

    const saveSpark = (action: SparkCreateEditAction, overrideSparkProperties: Partial<Spark>) => {
        if (isCreatingMultiRetailerSpark) {
            return createMultiRetailerSparkAsync({
                ...spark,
                multiRetailerParticipantGroups,
                multiRetailerProductSelection,
                uniqueParticipantLocationIds: Object.entries(uniqueParticipantLocationIdsById)
                    .filter(([, isUnique]) => isUnique)
                    .map(([locationId]) => locationId),
                requestForSparkId: requestForSpark?._id,
            });
        }

        const isEdit = action === 'edit';

        const saveSparkId = isEdit ? sparkId : undefined;
        const saveSparkSubGroups = isEdit
            ? sparkSubGroups
            : sparkSubGroups.map((obj) => ({
                  ...obj,
                  sparkId: undefined,
              }));

        if (isEdit) {
            queryClient.invalidateQueries('spark-history');
        }

        const savedSpark = isEdit
            ? formatSparkForEdit(spark, overrideSparkProperties)
            : (formatSparkForCreateClone(spark) as Spark);

        if (!detailedSparkType) {
            throw new Error('`detailedSparkType` is undefined');
        }

        const includeRequestForSparkId =
            requestForSpark?.sourceAccountId === spark?.groupId && !isEdit;
        return SparksUI.createUpdateSpark(
            saveSparkId,
            {
                ...savedSpark,
                // For rules-based sparks, we don't want to actually send posProductIds to the server but the UI needs them in the SparkContext
                ...(isRulesBasedSpark ? { posProductIds: [] } : {}),
            },
            detailedSparkType,
            saveSparkSubGroups,
            includeRequestForSparkId ? requestForSpark._id : undefined,
        ).then(() => {
            if (saveSparkId) {
                queryClient.invalidateQueries(getSparkQueryKey(saveSparkId));
            }
            // TODO: May not be necessary
            if (includeRequestForSparkId) {
                queryClient.invalidateQueries('requestForSpark');
            }

            queryClient.invalidateQueries(['account', account?._id, 'sparks']);
        });
    };

    const sendSparkRequest = useCallback(() => {
        const savedSpark = {
            ...spark,
            // For rules-based sparks, we don't want to actually send posProductIds to the server but the UI needs them in the SparkContext
            ...(isRulesBasedSpark ? { posProductIds: [] } : {}),
        };

        if (!detailedSparkType) {
            throw new Error('`detailedSparkType` is undefined');
        }

        return isCreatingMultiRetailerSpark
            ? requestMultiRetailerSparkAsync({
                  sparkProperties: savedSpark,
                  multiRetailerParticipantGroups,
                  multiRetailerProductSelection,
                  requestForSparkId: requestForSpark?._id,
              })
            : SparksUI.sendBrandSparkRequest(
                  savedSpark,
                  detailedSparkType,
                  sparkSubGroups,
                  requestForSpark?._id,
              ).then(() => {
                  queryClient.invalidateQueries(['account', account?._id, 'sparks']);
              });
    }, [spark, detailedSparkType, sparkSubGroups]);

    const respondToSparkRequest = useCallback(
        (accepted: boolean, declineResponse?: SparkDeclineResponse) => {
            return SparksUI.respondToBrandSparkRequest(
                spark,
                sparkSubGroups,
                accepted,
                declineResponse,
            ).then(() => {
                // TODO: we really only need to invalidate two queries here:
                // 1. the list view of sparks (works fine)
                // queryClient.invalidateQueries(['account', account?._id, 'sparks']);
                // 2. the spark detail view for this spark that was just accepted/rejected (does not work)
                // queryClient.invalidateQueries([getSparkQueryKey(spark._id))]);
                // However, the detail view invalidation call does not seem to work - so if the admin accepting a spark changes the number of locations or participants, that change is not reflected in the spark detail view until the page is fully refreshed.  Invalidating _all_ queries (as we do below) does work, but is not ideal.
                // Revisit with https://linear.app/sparkplug/issue/EXP-703/write-e2e-test-for-spark-accept-flow-when-locationsparticipants
                queryClient.invalidateQueries();
            });
        },
        [spark, detailedSparkType, sparkSubGroups],
    );

    const changeSparkSubgroup = useCallback(
        (newSparkId: string) => {
            if (sparkId) {
                const newSparkSubGroup = sparkSubGroups.find(
                    (sparkSubGroup) => sparkSubGroup.sparkId === newSparkId,
                );

                if (newSparkSubGroup) {
                    setSpark((prevValue) => {
                        const {
                            sparkId: _id,
                            locationIds,
                            posEmployeeProfileIds,
                            internalTracking = {},
                            archivedAt = '',
                            finalizedAt = '',
                        } = newSparkSubGroup;

                        return {
                            ...prevValue,
                            ...({
                                _id,
                                locationIds,
                                posEmployeeProfileIds,
                                internalTracking,
                                finalizedAt,
                                archivedAt,
                            } as Spark),
                        };
                    });
                }
            }
        },
        [sparkId, sparkSubGroups],
    );

    useEffect(() => {
        fetchSparkData();
    }, [sparkId]);

    const value = useMemo(() => {
        return {
            sparkIsReady: isReady,
            sparkHasChanged,
            sparkNotFound,
            sparkPosDataIsReady,
            refetchSparkData: () => fetchSparkData(true),
            sparkSubGroups,
            updateSparkSubGroupsByLocationsAndParticipants:
                updateSparkSubGroupsByLocationsAndParticipants(
                    detailedSparkType!,
                    setSparkSubGroupRevisions,
                    updateSpark,
                ),
            spark: {
                ...spark,
                detailedSparkType,
            },
            sparkPosData,
            detailedSparkType,
            isRecurringSpark,
            sparkCommissionType,
            sparkCommissionMap,
            updateSpark,
            changeSparkSubgroup,
            saveSpark,
            sendSparkRequest,
            respondToSparkRequest,

            /**
             * Pertaining to the RequestForSpark collection, not related to spark.requestState
             */
            requestForSpark,

            // Rules-Based Spark Properties
            isRulesBasedSpark,
            isRetailerRulesBasedSpark,
            isVendorTagsRulesBasedSpark,
            isVendorPosRulesBasedSpark,
            updateSparkQualifyingProductsState,

            // Multi-Retailer Properties
            multiRetailerParticipantSelection,
            setMultiRetailerParticipantSelection,
            isCreatingMultiRetailerSpark,
            multiRetailerParticipantGroups,
            updateMultiRetailerParticipantGroups,
            originalMultiRetailerParticipantGroups,
            updateOriginalMultiRetailerParticipantGroups,
            multiRetailerProductSelection,
            updateMultiRetailerProductSelection,
            uniqueParticipantLocationIdsById,
            setUniqueParticipantLocationIdsById,
            multiRetailerProducts,
            multiRetailerProductsAreReady:
                vendorBrandRetailerProductsAreReady && shouldQueryVendorBrandRetailerProducts,
        };
    }, [
        isReady,

        sparkSubGroups,
        spark,
        sparkPosData,
        detailedSparkType,
        isRecurringSpark,
        sparkCommissionType,
        sparkCommissionMap,
        changeSparkSubgroup,
        saveSpark,
        sendSparkRequest,
        respondToSparkRequest,
        /**
         * Pertaining to the RequestForSpark collection, not related to spark.requestState
         */
        requestForSpark,

        // Rules-Based Spark Properties
        isRulesBasedSpark,
        isRetailerRulesBasedSpark,
        isVendorTagsRulesBasedSpark,
        isVendorPosRulesBasedSpark,

        // Multi-Retailer Properties
        isCreatingMultiRetailerSpark,
        multiRetailerParticipantGroups,
        originalMultiRetailerParticipantGroups,
        multiRetailerProductSelection,
        multiRetailerProducts,
        uniqueParticipantLocationIdsById,
        vendorBrandRetailerProductsAreReady,
        shouldQueryVendorBrandRetailerProducts,
    ]);

    return <SparkContext.Provider value={value}>{children}</SparkContext.Provider>;
};
