import { ComponentType, FC } from 'react';

import { AccountType } from '@sparkplug/lib';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

import RetailerDashboardView from './RetailerDashboardView';
import VendorDashboardView from './VendorDashboardView';

import './DashboardView.scss';

const ACCOUNT_DASHBOARD_VIEW_RECORD: Record<AccountType, ComponentType<{ account: IAccount }>> = {
    brand: VendorDashboardView,
    retailer: RetailerDashboardView,
};

export interface DashboardViewProps {}

export const DashboardView: FC<DashboardViewProps> = () => {
    const { account, accountIsReady } = useSparkplugAccount();

    if (accountIsReady && account?.type) {
        const Component = ACCOUNT_DASHBOARD_VIEW_RECORD[account.type];
        return <Component account={account} />;
    }

    return <></>;
};

export default DashboardView;
