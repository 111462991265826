import { useEffect, useLayoutEffect, useRef } from 'react';

import { debounce, isUndefined, omitBy } from 'lodash';

import { CreateAccountSnapRequestBody } from '@sparkplug/lib';

import {
    useAccountSnapSdkQuery,
    useGlobalSnapTemplatesQuery,
    useStorifymeSDKTokenQuery,
} from '../../queries';

interface StorifymeEvent {
    detail: {
        id?: number;
    };
}

interface SaveModalConfig {
    title?: string;
    body?: string;
    submit?: string;
    cancel?: string;
}

/**
 * The `<storifyme-snaps-sdk>` is a custom element that allows you to open the Storifyme Snap Editor.
 * This HTML element is in the `index.html` file and is not part of the React component tree becuase
 * there were issues with the component being unmounted and remounted when the component tree was updated.
 */

export const useSnapEditorSdk = ({
    accountId: sparkplugAccountId,
    snapsEnabled,
    onSnapSaved,
    createSnapPayload = {},
}: {
    accountId: string;
    snapsEnabled: boolean;
    onSnapSaved: (params: { snapId: number; storifymeAccountId: string }) => {};
    createSnapPayload?: CreateAccountSnapRequestBody;
}) => {
    const env =
        process.env.REACT_APP_API === 'production'
            ? // TODO: We should double check with the Storifyme team to make sure this is the correct `env` that we should use for the production environment
              'EU'
            : 'dev';

    const snapsSdkRef = useRef<any>();
    const { storifymeSDKToken } = useStorifymeSDKTokenQuery(sparkplugAccountId, snapsEnabled);
    const { accountSnapSdkCredentials } = useAccountSnapSdkQuery(
        {
            accountId: sparkplugAccountId,
        },
        snapsEnabled,
    );
    const { accountId, sdkKey } = accountSnapSdkCredentials ?? {};
    const { globalSnapTemplates = [] } = useGlobalSnapTemplatesQuery();
    /**
     * We're using debounce here because Storifyme has a bug on their side where 'onSnapSaved' events can be triggered
     * multiple times if multiple snaps/templates have been created/updated prior to a refresh.
     */
    const handleSnapSaved = debounce((event: StorifymeEvent) => {
        const { id: snapId } = event.detail;
        snapsSdkRef.current.close();

        if (snapId) {
            onSnapSaved({ snapId, storifymeAccountId: accountId ?? '' });
        }
    }, 100);

    useLayoutEffect(() => {
        snapsSdkRef.current = document.querySelector('storifyme-snaps-sdk');

        snapsSdkRef.current?.addEventListener('onSnapSaved', handleSnapSaved);

        return () => {
            snapsSdkRef.current?.removeEventListener('onSnapSaved', handleSnapSaved);
        };
    }, []);

    useLayoutEffect(() => {
        if (!!accountId && !!sdkKey) {
            snapsSdkRef.current?.init({
                account: accountId,
                apiKey: sdkKey,
                env,
                snapConfig: {
                    shareEnabled: false,
                },
            });
        }
    }, [accountId, sdkKey]);

    useEffect(() => {
        if (storifymeSDKToken) {
            snapsSdkRef.current?.setToken?.(storifymeSDKToken);
        }
    }, [storifymeSDKToken]);

    const openSnapEditor = (params: { snapId?: number; name?: string; configuration?: any }) => {
        const { snapId, name, configuration } = params;

        const saveModalConfig: SaveModalConfig = {
            title: 'Do you want to save this Snap?',
            cancel: 'No, keep editing',
            submit: 'Yes, save Snap',
        };

        snapsSdkRef.current?.open(
            omitBy(
                {
                    snapId, // Snap Id of the snap, if editing
                    saveModalConfig,
                    snapConfig: {
                        shareEnabled: false, // Don't include the "share" button in snaps
                    },
                    published: true, // Published snaps emit engagement events
                    name, // Name of the new snap design, if creating
                    user: 'shared-user', // Any User ID - this should always be the same string so that all account users share the same account (primiarily custom templates and assets) in storifyme
                    token: storifymeSDKToken,
                    templates: globalSnapTemplates,
                    configuration,
                },
                (value) => isUndefined(value),
            ),
        );
    };

    return {
        accountSnapSdkCredentials,
        openSnapEditor,
    };
};
