import { Dispatch, SetStateAction } from 'react';

import { AccountSparkBrand } from '@sparkplug/lib';

import Dropdown from '@components/dropdown/Dropdown';
import { FileCopy } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';

const TagGroupCopyButton = ({
    setSustainHover,
    brands,
    onCopy,
    tagAssistantEnabled = false,
}: {
    setSustainHover: Dispatch<SetStateAction<boolean>>;
    brands: AccountSparkBrand[];
    onCopy: (targetBrandId: string) => void;
    tagAssistantEnabled?: boolean;
}) => {
    return (
        <Dropdown onClose={() => setSustainHover(false)}>
            <Dropdown.IconButton
                onClick={() => setSustainHover(true)}
                className="tag-group-copy-btn"
                size="small"
                disabled={tagAssistantEnabled}
            >
                <Tooltip title="Copy Group to Brand" placement="top">
                    <FileCopy fontSize="small" />
                </Tooltip>
            </Dropdown.IconButton>
            <Dropdown.Menu
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                {brands.map((brand) => {
                    return (
                        <Dropdown.MenuItem key={brand._id} onClick={() => onCopy(brand._id)}>
                            {brand.name}
                        </Dropdown.MenuItem>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default TagGroupCopyButton;
