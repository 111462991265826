import { useState } from 'react';

import { SidebarLayout } from '@componentLibrary';

import { IPublicAccount, accountCanCreateSpark as accountCanCreateSparkFn } from '@sparkplug/lib';

import { useGetPendingSparksCountQuery } from '@core/sparks/queries/GetPendingSparksQuery';

import { useGetRequestForSparksTotal } from '@features/request-for-spark';
import SparkStatusButtons from '@features/spark-dashboard/components/SparkStatusButtons';
import { SparkTemplate } from '@features/spark-dashboard/types';
import sparkTemplateData from '@features/spark-wizard/spark-templates';

import CalloutMessage from '@components/layout/CalloutMessage';
import PageHeader from '@components/layout/PageHeader';
import UpgradeModal from '@components/overlays/UpgradeModal';
import { StartFromBlankButton } from '@components/sparks/CreateSparkButton/CreateSparkButton';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { getDetailedSparkTypeDisplayName } from '@helpers/sparks';

import CreateTemplateDrawer from './components/CreateTemplateDrawer';
import SparkTemplateCard from './components/SparkTemplateCard';
import { getSparkTemplateCardImage } from './components/SparkTemplateCard/SparkTemplateCardUtils';

const SparkTemplatePageContent = () => {
    const { history, userIsSuperAdmin } = useApp();
    const { account, userCan } = useSparkplugAccount();
    const userCanCreateSpark = userCan('createSpark');
    const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined);
    const templateData = Object.values(sparkTemplateData).filter(
        (template) => template.accountType === account?.type,
    );

    const handleViewDetailsClicked = (template: SparkTemplate) => {
        setSelectedTemplateId(template._id || '');
    };
    const accountCanCreateSpark = accountCanCreateSparkFn(account ?? ({} as IPublicAccount));

    const [upgradeModalIsVisible, setUpgradeModalIsVisible] = useState(false);
    const handleCreateSpark = (template: SparkTemplate) => {
        if (accountCanCreateSpark || userIsSuperAdmin) {
            history.push(
                `/${account?._id}/sparks/create/${template.type}?templateId=${template._id}`,
            );
            return;
        }
        setUpgradeModalIsVisible(true);
    };
    return (
        <div data-testid="spark-templates-page-content" className="px-2 md:px-0">
            {userCanCreateSpark && (
                <StartFromBlankButton className="mb-4 w-[356px]" originRoute="spark-templates" />
            )}
            <CalloutMessage
                className="mb-4 "
                message="Based on our comprehensive Spark data, we’ve built Spark Templates to help you kick start your incentives and sales goals!"
            />
            <div className="grid grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4">
                {templateData.map((template, index) => (
                    <SparkTemplateCard
                        key={index}
                        title={template.templateName ?? ''}
                        desc={template.templateDescription ?? ''}
                        cardImage={getSparkTemplateCardImage(template.type)}
                        subHeading={
                            template.detailedSparkType
                                ? getDetailedSparkTypeDisplayName(template.detailedSparkType)
                                : ''
                        }
                        showButtons
                        onCreateClicked={() => handleCreateSpark(template)}
                        onViewDetailsClicked={() => handleViewDetailsClicked(template)}
                        className="h-full flex-1"
                    />
                ))}
            </div>

            <CreateTemplateDrawer
                isVisible={!!selectedTemplateId}
                onClose={() => setSelectedTemplateId(undefined)}
                template={sparkTemplateData[selectedTemplateId ?? '']}
                onCreateClick={() => handleCreateSpark(sparkTemplateData[selectedTemplateId ?? ''])}
            />
            <UpgradeModal
                isVisible={upgradeModalIsVisible}
                onClose={() => setUpgradeModalIsVisible(false)}
            />
        </div>
    );
};

const SparkTemplates = () => {
    const { account, connectEnabled } = useSparkplugAccount();
    const { history, userIsAdmin } = useApp();
    const { count: openRequestCount } = useGetRequestForSparksTotal({ account });

    const { count: pendingSparkCount = 0 } = useGetPendingSparksCountQuery({
        account,
        isAdminApp: userIsAdmin,
    });

    return (
        <SidebarLayout
            header={
                <PageHeader
                    title={`${account?.name || ''}`.trim()}
                    heading="Sparks"
                    className="m-0"
                />
            }
            sidebar={
                <div className="sparks-view">
                    <SparkStatusButtons
                        value="templates"
                        onSelect={({ value, to }) => {
                            history.push(`/${account?._id}${to}`);
                        }}
                        isBrand={account?.type === 'brand'}
                        inboxCount={pendingSparkCount}
                        requestCount={openRequestCount}
                        isAdmin={userIsAdmin}
                        connectEnabled={connectEnabled}
                    />
                </div>
            }
            content={<SparkTemplatePageContent />}
        />
    );
};
export default SparkTemplates;
