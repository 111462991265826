import PointOfSaleAPI from '@api/PointOfSaleAPI';
import SparkplugAPI from '@api/SparkplugAPI';
import UploadsAPI from '@api/UploadsAPI';
import axios from 'axios';

import {
    CreateAccountRequestBody,
    CreateAccountResponseBody,
    CreateChargeInvoiceRequestBody,
    CreateChargeInvoiceResponseBody,
    CreatePosConfigRequestBody,
    Industry,
    PosConfig,
    PosConfigType,
    UPLOAD_FOLDERS,
    UpdateAccountRequestBody,
    UpdatePosConfigRequestBody,
    UpdatePosLocationRequestBody,
} from '@sparkplug/lib';

import { useAdvancedMutation } from '@hooks/QueryHooks';

import { getAccountQueryKey } from '../queries';

const API = {
    createAccount: async (group: CreateAccountRequestBody): Promise<CreateAccountResponseBody> => {
        return (await axios.post('/api/v1/accounts', group)).data;
    },
    updateAccount: async (groupId: string, properties: UpdateAccountRequestBody): Promise<void> => {
        return axios.put(`/api/v1/accounts/${groupId}`, properties);
    },
    deleteAccount: async (groupId: string): Promise<void> => {
        return axios.delete(`/api/v1/accounts/${groupId}`);
    },
    removeMember: async (groupId: string, userId: string): Promise<void> => {
        return axios.delete(`/api/v1/accounts/${groupId}/members`, { data: { userId } });
    },
    createAndChargeInvoice: async (
        groupId: string,
        body: CreateChargeInvoiceRequestBody,
    ): Promise<CreateChargeInvoiceResponseBody> => {
        return (await axios.post(`/api/v1/accounts/${groupId}/billing/create-invoice`, body)).data;
    },
};
export const createSparkplugConfig = (sparkplugConfig: { groupId: string }) => {
    return SparkplugAPI.createConfig(sparkplugConfig);
};

export const createAccount = async ({
    name,
    type,
    industries,
    hubspotId,
}: {
    name: string;
    type: 'brand' | 'retailer';
    industries: Industry[];
    hubspotId: string;
}) => {
    const group = await API.createAccount({
        name,
        type,
        industries,
        hubspotId,
    });

    const sparkplugConfig = await createSparkplugConfig({
        groupId: group._id,
    });

    return {
        ...group,
        sparkplugConfig,
    };
};

export const updateGroup = (groupId: string, properties: UpdateAccountRequestBody) => {
    return API.updateAccount(groupId, properties);
};

export const deleteAccountByGroupId = (groupId: string) => {
    return API.deleteAccount(groupId);
};

export const updateSparkplugConfig = (configId: string, properties: any) => {
    return SparkplugAPI.updateConfig(configId, properties);
};

export const saveSparkplugConfig = async (configId: string, properties: any) => {
    const config = {
        ...properties,
    };
    if (configId != null) {
        await updateSparkplugConfig(configId, config);
        config._id = configId;
    }

    return config;
};

export async function uploadAccountLogo(accountId: string, file: File): Promise<{ key: string }> {
    const data = await UploadsAPI.getPresignedUploadUrl({
        groupId: accountId,
        folder: UPLOAD_FOLDERS.ACCOUNT,
    });

    return UploadsAPI.upload(data, file);
}

export type UpdateAccountFnPayload = Omit<UpdateAccountRequestBody, 'photo'> & { photo?: File };
const updateAccount = async (
    groupId: string,
    {
        name,
        type,
        photo,
        metaData,
        connectDisabled,
        trainingEnabled,
        industries,
        hubspotId,
        stripeCustomerId,
        assignedSuperAdminUserId,
        assignedOwnerUserId,
        referralUrl,
        isActive,
        managerSparksEnabled,
        autoLinkingEnabled,
        inventoryEnabled,
        productTagsEnabled,
        snapsEnabled,
        rulesBasedSparksEnabled,
        status,
    }: UpdateAccountFnPayload,
) => {
    const payload: UpdateAccountRequestBody = {
        name,
        type,
        connectDisabled,
        trainingEnabled,
        industries,
        stripeCustomerId,
        hubspotId,
        assignedSuperAdminUserId,
        assignedOwnerUserId,
        referralUrl,
        isActive,
        managerSparksEnabled,
        productTagsEnabled,
        snapsEnabled,
        rulesBasedSparksEnabled,
        status,
        autoLinkingEnabled,
        inventoryEnabled,
    };

    if (['none', 'paid'].includes(metaData?.subscriptionType || '')) {
        payload.metaData = { ...metaData };
    }

    if (photo) {
        const { key } = await uploadAccountLogo(groupId, photo);

        payload.photo = key;
    }

    return updateGroup(groupId, payload);
};

export const useSaveAccountMutation = ({
    account,
    runUpdate = true,
}: {
    account: { _id: string; name: string };
    runUpdate?: boolean;
}) => {
    const accountName = account.name;

    const { mutate: saveAccount, isLoading } = useAdvancedMutation(
        (payload: UpdateAccountFnPayload) =>
            account?._id ? updateAccount(account._id, payload) : Promise.resolve(),
        {
            ...(runUpdate
                ? {
                      updateQuery: {
                          queryKey: getAccountQueryKey(account._id),
                      },
                  }
                : {}),
            toastOptions: {
                loading: 'Updating...',
                success: `Updated ${accountName}`,
                error: `Could not update ${accountName}`,
            },
        },
    );

    return { saveAccount, saveAccountMutationLoading: isLoading };
};

export const savePosConfig = async (configId: string | null | undefined, properties: PosConfig) => {
    const config: any = {
        groupId: properties.groupId,
        type: properties.type,
        enabled: properties.enabled,
        lookback: properties.lookback,
        timeZone: properties.timeZone,
        credentials: properties.credentials,
        reconcileLookbackHours: properties.reconcileLookbackHours,
    };

    if (configId != null) {
        await PointOfSaleAPI.updateConfig({ configId }, config as UpdatePosConfigRequestBody);
        config._id = configId;
    } else {
        const newConfig = await PointOfSaleAPI.createConfig(config as CreatePosConfigRequestBody);
        config._id = newConfig._id;
    }

    return config;
};

export const reimportPosConfig = (
    configId: string,
    options?: { lookback?: string; notify?: boolean },
) => {
    return PointOfSaleAPI.reimportConfig(configId, options);
};

export const verifyPosConfigCredentials = (
    configStateType: PosConfigType,
    configStateCredentials: any,
) => {
    return PointOfSaleAPI.verifyPosCredentials(configStateType, configStateCredentials);
};

export const updatePosLocation = (locationId: string, props: UpdatePosLocationRequestBody) => {
    return SparkplugAPI.updatePosLocation({ locationId }, props);
};

export const registerPointOfSale = ({
    groupId,
    code,
    shop,
    provider,
}: {
    groupId: string;
    code: string;
    shop?: string;
    provider: string;
}) => {
    return SparkplugAPI.registerPointOfSale({ type: provider, groupId, code, shop });
};

export const deactivatePointOfSale = (posConfigId: string) => {
    return SparkplugAPI.deactivatePointOfSale(posConfigId);
};

export const removeMember = (groupId: string, userId: string) => {
    return API.removeMember(groupId, userId);
};

export const createAndChargeInvoice = (groupId: string, body: CreateChargeInvoiceRequestBody) => {
    return API.createAndChargeInvoice(groupId, body);
};
