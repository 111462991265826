import { useMemo, useState } from 'react';

import { useSparkplugUsers } from '@core/users';

import { GiftCardInvertedIcon } from '@components/icons';
import PageHeader from '@components/layout/PageHeader';
import Toolbar from '@components/toolbar/Toolbar';

import { usePayoutOptions } from '@hooks/PayoutHooks';
import { useSparkplugAccounts } from '@hooks/SparkplugAccountsHooks';

import { useAllGiftCardsQuery } from '../../queries';
import { GiftCardsTableWithFilters } from './GiftCardsTable';
import { SendGiftCardModal } from './SendGiftCardModal';
import { getGiftCardsTableRows } from './getGiftCardsTableRows';

import './CCGiftCardsView.scss';

export const CCGiftCardsView = () => {
    const [showGiftCardModal, setShowGiftCardModal] = useState<boolean>(false);

    const { accounts = [], accountsAreReady } = useSparkplugAccounts();
    const { sparkplugUsers: users = [], isSparkplugUsersReady: usersAreReady } =
        useSparkplugUsers();
    const { sparkplugUsers: superAdminUsers = [], isSparkplugUsersReady: superAdminUsersAreReady } =
        useSparkplugUsers({ role: 'super-admin' });
    const { giftCards = [], giftCardsAreReady } = useAllGiftCardsQuery();
    const { campaignOptions } = usePayoutOptions();

    const giftCardsTableRows = useMemo(
        () =>
            getGiftCardsTableRows({
                groups: accounts,
                users,
                superAdminUsers,
                giftCards,
                campaignOptions,
            }),
        [accounts, users, superAdminUsers, giftCards, campaignOptions],
    );

    const tableIsReady =
        accountsAreReady && usersAreReady && superAdminUsersAreReady && giftCardsAreReady;

    return (
        <div className="cc-gift-cards-view">
            <PageHeader
                title={[
                    {
                        url: `/control-center`,
                        title: 'Control Center',
                    },
                    'Gift Cards',
                ]}
                metaTitle="Gift Cards | Control Center"
            >
                <Toolbar>
                    <Toolbar.Button
                        className="toolbar-group-end send-gift-card_button"
                        variant="filled"
                        startIcon={<GiftCardInvertedIcon />}
                        onClick={() => setShowGiftCardModal(true)}
                    >
                        Send Gift Card
                    </Toolbar.Button>
                </Toolbar>
            </PageHeader>

            <GiftCardsTableWithFilters
                isReady={tableIsReady}
                rows={giftCardsTableRows}
                superAdminUsers={superAdminUsers}
            />

            <SendGiftCardModal
                groups={accounts}
                users={users}
                isVisible={showGiftCardModal}
                onClose={() => setShowGiftCardModal(false)}
            />
        </div>
    );
};
