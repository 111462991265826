import { useCallback, useEffect, useState } from 'react';

import { EmployeeSpark, IPublicReward, Spark } from '@sparkplug/lib';

import { useEmployeeSparks } from '@core/employees/queries';

import SparkStatusButtons from '@features/spark-dashboard/components/SparkStatusButtons';
import {
    FilterFns,
    SparkFilterType,
    useSparksFilters,
} from '@features/spark-dashboard/hooks/useSparkFilters';
import SparksList from '@features/user-home/components/SparksList';

import { NoSparksGraphic } from '@components/graphics';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Grid from '@components/layout/Grid';
import Skeleton from '@components/layout/Skeleton';

import { useApp } from '@hooks/AppHooks';
import { useCurrentUserRewards } from '@hooks/RewardsHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

export const applySparkClaimFilters = (
    sparks: Spark[],
    rewards: IPublicReward[],
    value: 'claimed' | 'unclaimed' | 'all',
): Spark[] => {
    return sparks.filter((spark) => {
        if (value === 'all') {
            return true;
        }

        const userPayout = (rewards || []).find((reward) => {
            const isSameSpark = spark?._id === reward?.sparkId;
            return isSameSpark;
        });

        if (!userPayout) {
            return false;
        }

        return value === 'claimed'
            ? userPayout?.status === 'claimed'
            : userPayout?.status === 'confirmed';
    });
};

interface ISparksModuleProps {
    initialSparkStatus?: 'active' | 'upcoming' | 'complete';
}

const SparksModule = ({ initialSparkStatus = 'active' }: ISparksModuleProps) => {
    const { history } = useApp();

    const { sparks, sparksAreReady, refetchSparks } = useEmployeeSparks();
    const { account } = useSparkplugAccount();

    const { sparkFilters, updateSparkFilters } = useSparksFilters({
        initialFilters: {
            location: account?.locations || [],
            type: [],
            status: initialSparkStatus,
            schedule: [],
        },
    });
    const applyFilters = useCallback(
        (sparksToFilter: EmployeeSpark[]): EmployeeSpark[] =>
            Object.entries(sparkFilters).reduce((res, [filter, value]) => {
                const filterFn = FilterFns[filter as SparkFilterType](value, account?.type);
                return res.filter(filterFn);
            }, sparksToFilter),
        [sparkFilters],
    );
    useEffect(() => {
        if (sparkFilters.status === 'complete') {
            // we want to show sparks associated with disabled locations for complete sparks, so we need to use "allLocations" as they're filtered out of "locations"
            updateSparkFilters({ location: account?.allLocations || [] });
        }
    }, [sparkFilters.status]);

    const { isLoadingCurrentUserRewards, currentUserRewards, refetchCurrentUserRewards } =
        useCurrentUserRewards();

    const { searchFilter, applySearch } = useSearch(['name', 'description']);

    const [filteredSparks, setFilteredSparks] = useState(sparks);

    useEffect(() => {
        refetchSparks();
        refetchCurrentUserRewards();
    }, []);

    useEffect(() => {
        let newFilteredSparks = applyFilters(sparks || []);
        newFilteredSparks = applySearch(newFilteredSparks);

        setFilteredSparks(newFilteredSparks);
    }, [sparks, sparkFilters, searchFilter, currentUserRewards]);

    const sparkStatus = sparkFilters.status;

    const emptyLabel = `no ${sparkStatus} sparks found`;

    const onSparkCardSelected = (spark: Pick<Spark, '_id' | 'requestState'>) => {
        history.push(`/user/sparks/${spark._id}`, {
            prevUrl: document.location.pathname,
            prevTitle: `${sparkFilters.status} Sparks`,
        });
    };

    return (
        <div>
            <div className="mb-4 max-sm:[&_.status-tab-group]:flex max-sm:[&_.status-tab-group>.btn]:flex-grow">
                <SparkStatusButtons
                    value={sparkFilters.status || 'active'}
                    onSelect={({ value, to }) => {
                        window.history.pushState(null, '', `/user${to}`);
                        updateSparkFilters({ status: value });
                    }}
                    isBrand={false}
                    isAdmin={false}
                />
            </div>
            {sparksAreReady && !isLoadingCurrentUserRewards ? (
                <SparksList
                    isUserSparksView
                    viewMode="grid"
                    showTrainingIcon
                    sparkStatus={sparkStatus}
                    isEmployeeView
                    sparks={filteredSparks}
                    onSparkCardSelected={onSparkCardSelected}
                    emptyStateComponent={
                        <EmptyStateDisplay graphic={<NoSparksGraphic />} label={emptyLabel} />
                    }
                />
            ) : (
                <>
                    <Grid className="app-sparks-list-grid">
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                        <Grid.Item>
                            <Skeleton height={177} />
                        </Grid.Item>
                    </Grid>
                </>
            )}
        </div>
    );
};

export default SparksModule;
