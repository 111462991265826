import { FC, useMemo } from 'react';

import { uniqBy } from 'lodash';

import { HydratedBrandProduct, parseCurrencyToNumber } from '@sparkplug/lib';

import { UseMultiRetailerCommissionTableFilters } from '@features/spark-wizard/components/CommissionCommissionsFormFields/hooks/useMultiRetailerCommissionTableFilters';
import { UseSparkProductSelectorTableFilters } from '@features/spark-wizard/components/SparkProductSelector/SparkProductSelectorTable';

import TextField from '@components/inputs/TextField';

import { useSpark } from '@hooks/SparksHooks';

import { sortByString } from '@helpers/ui';

import { IPosProduct } from '@app/types/PosTypes';

import { CommissionMap } from '../../helpers/getHeadCells';
import DefaultCommissionTableToolbar from './DefaultCommissionTableToolbar';
import TagRulesBasedCommissionTableToolbar from './MultiRetailerCommissionTableToolbar';

import './CommissionTableToolbar.scss';

const getBrandAndCategoryOptions = (
    products: Pick<IPosProduct, 'categories' | 'categoryId' | 'brands' | 'brandId'>[],
) => {
    const options = products.reduce<{
        brands: { _id: string; name: string }[];
        categories: { _id: string; name: string }[];
    }>(
        (res, product) => {
            const category = product.categories?.find(({ _id }) => _id === product.categoryId);
            const brand = product.brands?.find(({ _id }) => _id === product.brandId);

            if (category) {
                res.categories.push(category);
            }

            if (brand) {
                res.brands.push(brand);
            }

            return res;
        },
        {
            brands: [],
            categories: [],
        },
    );

    return {
        brandOptions: uniqBy(options.brands, '_id')
            .map(({ _id, name }) => ({
                value: _id,
                label: name,
            }))
            .sort(sortByString('label', 'asc')),
        categoryOptions: uniqBy(options.categories, '_id')
            .map(({ _id, name }) => ({
                value: _id,
                label: name,
            }))
            .sort(sortByString('label', 'asc')),
    };
};

interface CommissionTableToolbarProps {
    isVendorEditExperience: boolean;
    tableFilters: UseSparkProductSelectorTableFilters;
    multiTableFilters: UseMultiRetailerCommissionTableFilters;
    products: HydratedBrandProduct[];
    originalCommissionValuesMap: CommissionMap;
    updateFixedCommissionValue: (updatedValue: number) => void;
    updateDisplayedCommissionValues: (
        productIds: string[],
        applyAllValue: number | undefined,
    ) => void;
}

const CommissionTableToolbar: FC<CommissionTableToolbarProps> = ({
    tableFilters,
    multiTableFilters,
    isVendorEditExperience,
    products,
    originalCommissionValuesMap,
    updateFixedCommissionValue,
    updateDisplayedCommissionValues,
}) => {
    const { detailedSparkType, isCreatingMultiRetailerSpark, isVendorTagsRulesBasedSpark, spark } =
        useSpark();

    const { brandOptions, categoryOptions } = useMemo(() => {
        if (isCreatingMultiRetailerSpark) {
            return { brandOptions: [], categoryOptions: [] };
        }
        return getBrandAndCategoryOptions(products);
    }, [products]);

    if (!detailedSparkType) {
        return <></>;
    }

    if (spark.commissionRules?.type === 'fixed') {
        return (
            <TextField
                required
                className="single-commission-value"
                label="Commission for All Products"
                name="single-commission-value"
                defaultValue={spark.commissionRules?.fixedValue}
                type="number"
                step={0.01}
                isMoneyField={detailedSparkType === 'commissionFlat'}
                moneyFieldIsActive={detailedSparkType === 'commissionFlat'}
                endIcon={detailedSparkType === 'commissionPercentage' && '%'}
                onChange={(event) => {
                    const updatedValue = event.target.value;
                    const parsedValue = updatedValue ? parseCurrencyToNumber(updatedValue) : 0;
                    updateFixedCommissionValue(parsedValue);
                }}
            />
        );
    }

    // for creating a spark for a multi retailer or editing a spark that is a tags rules based.
    if (isCreatingMultiRetailerSpark || isVendorTagsRulesBasedSpark)
        return (
            <TagRulesBasedCommissionTableToolbar
                commissionType={detailedSparkType === 'commissionFlat' ? 'flat' : 'percentage'}
                products={products as HydratedBrandProduct[]}
                brandOptions={brandOptions}
                categoryOptions={categoryOptions}
                isVendorEditExperience={isVendorEditExperience}
                showRetailerFilterOptions={isCreatingMultiRetailerSpark}
                tableFilters={multiTableFilters}
                originalCommissionValuesMap={originalCommissionValuesMap}
                updateDisplayedCommissionValues={updateDisplayedCommissionValues}
            />
        );
    return (
        <DefaultCommissionTableToolbar
            commissionType={detailedSparkType === 'commissionFlat' ? 'flat' : 'percentage'}
            brandOptions={brandOptions}
            categoryOptions={categoryOptions}
            tableFilters={tableFilters}
            isVendorEditExperience={isVendorEditExperience}
            originalCommissionValuesMap={originalCommissionValuesMap}
            updateDisplayedCommissionValues={updateDisplayedCommissionValues}
        />
    );
};

export default CommissionTableToolbar;
