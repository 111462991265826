import { RouteProps } from 'react-router';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import ConditionalRoute, { AccountCondition, UserCondition } from './ConditionalRoute';

const AuthRoute = (props: RouteProps) => {
    const accountConditions: AccountCondition[] = [];
    const userConditions: UserCondition[] = [];
    const { accountPaymentConditions } = useSparkplugAccount();
    if (accountPaymentConditions) {
        accountConditions.push(() => !!accountPaymentConditions.hasPaymentMethods);
        accountConditions.push(() => !!accountPaymentConditions.hasNoFailedPayment);
    }
    const newProps = {
        ...props,
        userConditions,
        accountConditions,
        unauthorizedRedirect: accountPaymentConditions?.paymentConditionRedirect,
    };

    return <ConditionalRoute {...newProps} />;
};

export default AuthRoute;
