import React, { useState } from 'react';

import { OpenInNew } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

import { getStripeUrl } from '@core/accounts';

import Button from '@components/buttons/Button';
import { CreditCardGraphic } from '@components/graphics';
import Paper from '@components/layout/Paper';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';

const AdminBillingSetupView = () => {
    const { userCan, account } = useSparkplugAccount();
    const userCanAccessBilling = userCan('accessBillingPortal');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchStripeUrl = (accountId: string) => {
        setIsLoading(true);
        getStripeUrl(accountId, '/dashboard')
            .then((data) => {
                window.location.href = data.url;
            })
            .catch(() => toast.error('Unable to connect to the customer portal. Please try again.'))
            .finally(() => setIsLoading(false));
    };
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start',
                width: '100%',
            }}
        >
            <Paper
                sx={{
                    maxWidth: '475px',
                    mx: 'auto',
                    p: '24px',
                }}
            >
                <Box textAlign="center" mb="24px" mt="16px">
                    <CreditCardGraphic />
                </Box>
                <Typography align="center" variant="displaySmall" fontWeight={500} mb="8px">
                    Unlock the power of SparkPlug
                </Typography>
                <Typography align="center" variant="body">
                    To complete the sign up process and activate your SparkPlug account, please add
                    your payment info to your billing portal.
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        gap: '8px',
                        marginTop: '32px',
                        alignItems: 'center',
                    }}
                >
                    {account && userCanAccessBilling && (
                        <Button
                            variant="filled"
                            color="blue"
                            startIcon={<OpenInNew />}
                            disabled={!account._id || isLoading}
                            onClick={() => fetchStripeUrl(account._id)}
                        >
                            Setup Payment
                        </Button>
                    )}
                    <Button
                        variant="flat"
                        color="neutral"
                        onClick={() => {
                            Intercom.open();
                        }}
                    >
                        Chat with us
                    </Button>
                </Box>
            </Paper>
        </Box>
    );
};

export default AdminBillingSetupView;
