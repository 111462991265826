import { FC, useMemo } from 'react';

import { orderBy } from 'lodash';

import { AccountLink } from '@sparkplug/lib';

import { useMarketOptionsFilter } from '@features/account-links/hooks/useMarketOptionsFilter';
import { useAccountTagCounts } from '@features/account-links/queries';
import { addAccountLinkSearchableFields, isLinkPending } from '@features/account-links/utils';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import { applyFilterFns } from '@helpers/ui';

import { AccountLinkInfoDisplay } from './components/AccountLinkInfoDisplay/AccountLinkInfoDisplay';
import { AccountLinksTable } from './components/AccountLinksTable';
import { AccountLinksTableHeader } from './components/AccountLinksTableHeader';

export interface LinkedAccountsTabProps {
    accountLinksAreReady: boolean;
    accountLinks: AccountLink[];
    isInboxView?: boolean;
}
export const LinkedAccountsTab: FC<LinkedAccountsTabProps> = ({
    accountLinksAreReady,
    accountLinks = [],
    isInboxView = false,
}) => {
    const { account } = useSparkplugAccount();

    const { applyMarketsFilter } = useMarketOptionsFilter({ account });

    const accountLinksFiltered = useMemo(
        () => applyFilterFns([applyMarketsFilter], accountLinks ?? []),
        [accountLinks, applyMarketsFilter],
    );

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'accountName',
        'mappedNamesStr',
    ]);

    const { accountTagCountsAreReady, accountTagCountsByBrandLinkId } = useAccountTagCounts({
        accountId: account?._id ?? '',
        isEnabled: account?.type === 'brand',
    });

    const sortedAccountLinks = useMemo(() => {
        const sortedLinks = orderBy(
            accountLinksFiltered,
            [(links) => links?.accountName.toLowerCase()],
            ['asc'],
        );

        if (account?.type === 'retailer') {
            const { requested, pending, other } = sortedLinks.reduce<{
                requested: AccountLink[];
                pending: AccountLink[];
                other: AccountLink[];
            }>(
                (res, accountLink) => {
                    if (accountLink.status === 'pending') {
                        res.requested.push(accountLink);
                    } else if (isLinkPending(accountLink)) {
                        res.pending.push(accountLink);
                    } else {
                        res.other.push(accountLink);
                    }
                    return res;
                },
                { requested: [], pending: [], other: [] },
            );
            return [...pending, ...requested, ...other];
        }

        return sortedLinks.map((accountLink) => {
            return {
                ...accountLink,
                brandLinks: accountLink.brandLinks?.map((brandLink) => ({
                    ...brandLink,
                    ...(brandLink._id
                        ? accountTagCountsByBrandLinkId?.[brandLink?._id ?? ''] ?? {}
                        : {}),
                })),
            };
        });
    }, [accountLinksFiltered, accountTagCountsByBrandLinkId]);

    // This lets us search the `accountLinks` array by brand name, or maybe other keys in the future...?
    const searchableAccountLinks = useMemo(() => {
        return addAccountLinkSearchableFields(sortedAccountLinks);
    }, [sortedAccountLinks]);

    if (accountLinksAreReady && accountLinks.length === 0) {
        return <AccountLinkInfoDisplay className="!mt-[50px]" />;
    }

    return (
        <>
            <AccountLinksTableHeader
                isInboxView={isInboxView}
                searchFilter={searchFilter}
                onChangeSearchFilter={onChangeSearchFilter}
            />
            <AccountLinksTable
                isInboxView={isInboxView}
                applySearch={applySearch}
                accountLinksAreReady={accountLinksAreReady}
                searchableAccountLinks={searchableAccountLinks}
                accountTagCountsAreReady={accountTagCountsAreReady}
            />
        </>
    );
};

export default LinkedAccountsTab;
