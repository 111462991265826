import { useMemo } from 'react';
import NumberFormat from 'react-number-format';

import { ChartMetricOptions } from '@constants/ChartConstants';
import { keyBy } from 'lodash';

import { Spark, getGoalProgressByValue } from '@sparkplug/lib';

import {
    filterAccountUsersByPosEmployeeProfileIds,
    keyAccountUsersByArchivedSparkParticipantFlexibleEmployeeId,
    useAccountUsersQuery,
} from '@core/users';

import SmsEnrollmentInfoIcon from '@components/charts/UserCharts/SmsEnrollmentInfoIcon';
import ManagerChip from '@components/chips/ManagerChip';
import SMSEnrollmentChip from '@components/chips/SMSEnrollmentChip';
import { PhoneIphone as PhoneIcon } from '@components/icons';
import FormattedMetricValue from '@components/layout/FormattedMetricValue';
import Table from '@components/table/Table';

import { useSpark, useSparkIndividualStandings } from '@hooks/SparksHooks';

import { appendClasses } from '@helpers/ui';

import { ChartLeader } from '@app/types/ChartDataTypes';
import { THeadCell } from '@app/types/TableTypes';
import { IAccountUser } from '@app/types/UsersTypes';

const allHeadCells: THeadCell<SparkLeaderboardTableDataRow>[] = [
    {
        id: 'firstName',
        sortType: 'string',
        label: 'First Name',
        render: (row) => (
            <Table.Cell align="left">
                {row?.firstName}
                {!!row.managedLocationIds?.length && <ManagerChip />}
            </Table.Cell>
        ),
    },
    {
        id: 'lastName',
        sortType: 'string',
        label: 'Last Name',
        render: (row) => <Table.Cell align="left">{row?.lastName}</Table.Cell>,
    },
    {
        id: 'locationStr',
        sortType: 'string',
        label: 'Location(s)',
        render: (row) => <Table.Cell align="left">{row?.locationStr || '--'}</Table.Cell>,
    },
    {
        id: 'smsStatus',
        label: () => {
            const { detailedSparkType } = useSpark();

            return detailedSparkType !== 'leaderboardMulti' ? (
                <>SMS Status</>
            ) : (
                <PhoneIcon fontSize="small" />
            );
        },
        render: (row) => {
            const { detailedSparkType } = useSpark();

            return detailedSparkType === 'leaderboardMulti' ? (
                <Table.Cell align="center">
                    <SmsEnrollmentInfoIcon
                        smsStatus={row.smsStatus}
                        isInactive={row?.role === 'none'}
                    />
                </Table.Cell>
            ) : (
                <Table.Cell align="left">
                    <SMSEnrollmentChip status={row?.smsStatus} isMuted={row?.muteScheduledSms} />
                </Table.Cell>
            );
        },
    },
];

type TSpecificHeadCells = {
    [sparkType: string]: (
        detailedSparkType: string,
        sparkMetric: string,
        spark: Spark,
    ) => THeadCell<SparkLeaderboardTableDataRow>[];
};

const specificHeadCells: TSpecificHeadCells = {
    leaderboard: (detailedSparkType: string, metricValue: string, spark: Spark) => {
        const metric = ChartMetricOptions.find(({ value }) => metricValue === value);
        const percentMetricsLabels = {
            total_sales: 'Total Sales',
            total_units: 'Total Units',
            transaction_count: 'Txn Count',
            units_per_transaction: 'Units per Txn',
            order_average: 'Order Avg',
        };
        return [
            {
                id: 'rank',
                sortType: 'numeric',
                label: 'Standing',
                render: (row) => <Table.Cell align="left">{row?.standing}</Table.Cell>,
            },
            {
                id: 'value',
                sortType: 'numeric',
                isHidden: spark.metric !== 'percent_increase',
                label: spark.percentIncreaseData?.metric
                    ? percentMetricsLabels[spark.percentIncreaseData?.metric]
                    : '',
                render: (row) => {
                    // this value cell is for percent increase sparks
                    const classNames = appendClasses([
                        row.value < 0 ? 'negative-value' : undefined,
                    ]);
                    return (
                        <Table.Cell className={classNames} align="left">
                            <NumberFormat
                                value={row.value}
                                displayType="text"
                                thousandSeparator
                                suffix="%"
                                decimalScale={2}
                                fixedDecimalScale
                            />
                        </Table.Cell>
                    );
                },
            },
            {
                id: 'value',
                sortType: 'numeric',
                isHidden: spark.metric === 'percent_increase',
                label: metric?.label || 'Total Units',
                render: (row) => (
                    // this value cell is for all other spark types
                    <Table.Cell align="left">
                        <FormattedMetricValue metric={metricValue} value={row.value} />
                    </Table.Cell>
                ),
            },
            {
                id: 'transactionCount',
                sortType: 'numeric',
                label: 'Transactions',
                isHidden: !spark?.minimumTransactionsToQualify,
                render: (row) => <Table.Cell align="left">{row.transactionCount}</Table.Cell>,
            },
        ];
    },
    goal: (detailedSparkType: string) => {
        const render = (row: ChartLeaderGoalData & { metric: string }) => {
            const isUnlimited = row?.goalsTotal === -1;

            if (isUnlimited || detailedSparkType === 'goalManager') {
                const goalsMet = row?.goalsMet ?? 0;
                return (
                    <Table.Cell align="left">
                        <strong>{goalsMet}</strong> goal{goalsMet === 1 ? '' : 's'} reached
                    </Table.Cell>
                );
            }

            return (
                <Table.Cell className="send-sms_user-value-two-lines" align="left">
                    <strong>{`${row?.goalsMet}/${row?.goalsTotal}`}</strong>
                    <br />
                    <FormattedMetricValue
                        metric={row?.metric}
                        value={row?.amountAwayFromNextGoal || 0}
                        suffix={row?.metric === 'total_units' ? ' units away' : ' away'}
                    />
                </Table.Cell>
            );
        };

        switch (detailedSparkType) {
            case 'goalTeam':
                return [
                    {
                        id: 'value',
                        sortType: 'numeric',
                        label: 'Team Goal',
                        render,
                    },
                ];

            case 'goalManager':
                return [
                    {
                        id: 'value',
                        sortType: 'numeric',
                        label: 'Manager Goal',
                        render,
                    },
                ];

            default:
                return [
                    {
                        id: 'value',
                        sortType: 'numeric',
                        label: 'Individual Goal',
                        render,
                    },
                ];
        }
    },
    commission: () => [
        {
            id: 'value',
            sortType: 'numeric',
            label: 'Earned Commission',
            render: (row) => (
                <Table.Cell className="send-sms_user-value-two-lines" align="left">
                    <strong>
                        <FormattedMetricValue metric="total_sales" value={row?.value} />
                    </strong>
                    <br />
                    <span>{`${row?.unitCount} units`}</span>
                </Table.Cell>
            ),
        },
    ],
};

interface ChartLeaderGoalData {
    goalsMet?: number;
    goalsTotal?: number;
    nextGoalAmount?: number;
    amountAwayFromNextGoal?: number;
}

export interface SparkLeaderboardTableDataRow
    extends IAccountUser,
        ChartLeader,
        ChartLeaderGoalData {
    key: string;
    standing: string;
    rank: number;
    metric: Spark['metric'];
    value: number;
    unitCount: number;
    selectionDisabled: boolean;
    transactionCount: number;
}

export const getHeadCells = (
    sparkType: string,
    detailedSparkType: string,
    metric: string,
    spark: Spark,
): THeadCell<SparkLeaderboardTableDataRow>[] => {
    const headCells = [
        ...specificHeadCells[sparkType](detailedSparkType, metric, spark),
        ...allHeadCells,
    ];

    return headCells;
};

const getGoalResults = ({ spark, value }: { spark: Spark; value: number }) => {
    if (spark.type !== 'goal') {
        return undefined;
    }
    return getGoalProgressByValue(spark.goals ?? [], spark.fulfillmentTypes ?? [], value);
};

export function useSparkLeaderboardTableData() {
    const {
        sparkIsReady,
        sparkPosDataIsReady,
        sparkPosData,
        spark,
        detailedSparkType = 'leaderboard',
    } = useSpark();

    const { accountUsers: retailerAccountUsers } = useAccountUsersQuery(
        spark?.groupId,
        !!spark.archivedAt,
    );

    const { isLoading: sparkParticipantStandingsAreLoading, sparkParticipantStandings } =
        useSparkIndividualStandings({ spark });

    const headCells = useMemo(() => {
        return sparkIsReady ? getHeadCells(spark.type, detailedSparkType, spark.metric, spark) : [];
    }, [sparkIsReady, spark, detailedSparkType]);

    // Map AccountUsers to Individual Standings and update shape to work with Tables
    const rows = useMemo(() => {
        const sparkIsArchived = !!spark.archivedAt;
        const unarchivedParticipatingAccountUsers = filterAccountUsersByPosEmployeeProfileIds(
            retailerAccountUsers,
            spark.posEmployeeProfileIds,
        );
        const participantsByFlexibleId = sparkIsArchived
            ? keyAccountUsersByArchivedSparkParticipantFlexibleEmployeeId({
                  archivedSparkParticipants: sparkPosData.participants,
                  accountUsers: retailerAccountUsers,
              })
            : keyBy(unarchivedParticipatingAccountUsers, 'flexibleEmployeeId');

        return sparkParticipantStandings.map<SparkLeaderboardTableDataRow>(
            (sparkParticipantStanding) => {
                const {
                    primaryValue: value = 0,
                    secondaryValue: unitCount = 0,
                    flexibleEmployeeId,
                    rank,
                    transactionCount = 0,
                } = sparkParticipantStanding;

                const associatedAccountUser = participantsByFlexibleId?.[flexibleEmployeeId];
                const standing = `#${rank}`;

                const goalProgressProperties = getGoalResults({
                    spark,
                    value,
                });

                return {
                    ...associatedAccountUser,
                    ...goalProgressProperties,
                    key: flexibleEmployeeId,
                    standing,
                    rank,
                    metric: spark.metric,
                    value,
                    unitCount,
                    selectionDisabled: !['enrolled', 'pending'].includes(
                        associatedAccountUser?.smsStatus!,
                    ),
                    transactionCount,
                } as SparkLeaderboardTableDataRow;
            },
        );
    }, [detailedSparkType, sparkParticipantStandings, spark, retailerAccountUsers, sparkPosData]);

    return {
        isLoading: !sparkIsReady || !sparkPosDataIsReady || sparkParticipantStandingsAreLoading,
        headCells,
        rows,
    };
}
