import { FC, useMemo, useState } from 'react';

import { intersection } from 'lodash';
import moment from 'moment';

import { DetailedSparkType, ProductLabels, Spark, SparkCommission } from '@sparkplug/lib';

import { ChevronRight } from '@components/icons';
import PanelField from '@components/layout/PanelField';
import Paper from '@components/layout/Paper';
import Spinner from '@components/layout/Spinner';

import { useSpark } from '@hooks/SparksHooks';

import { getChartMetricLabel } from '@helpers/charts';
import {
    formatSparkCommissionItemValue,
    getDetailedSparkTypeDisplayName,
    getMinThresholdLabel,
    isPercentIncreaseSpark,
} from '@helpers/sparks';

import SearchableListModalMobile from '../SearchableListModalMobile';

import '../SparkDetailsViewMobile.scss';
import './SparkObjectivePanel.scss';

const ComparisonPeriodSection: FC<{ spark: Spark }> = ({ spark }) => {
    if (!spark.percentIncreaseData?.startDate || !spark.percentIncreaseData?.endDate) {
        return <></>;
    }

    const dateFormat = 'MM/DD/YY';
    const currentStartDate = moment(spark.startDate).format(dateFormat);
    const currentEndDate = moment(spark.endDate).format(dateFormat);
    const previousStartDate = moment(spark.percentIncreaseData?.startDate).format(dateFormat);
    const previousEndDate = moment(spark.percentIncreaseData?.endDate).format(dateFormat);

    return (
        <div className="comparison-period-objective">
            <h3>Comparison Period:</h3>
            <section>
                <div className="current-period">
                    {`Current: ${currentStartDate} - ${currentEndDate}`}
                </div>
                <div className="previous-period">
                    {`Previous: ${previousStartDate} - ${previousEndDate}`}
                </div>
            </section>
        </div>
    );
};

interface CommissionListItemProps {
    listItem?: {
        productId: string;
        label: string;
    };
    sparkCommissions?: SparkCommission[];
}
const CommissionListItem: FC<CommissionListItemProps> = ({ listItem }) => {
    const { sparkCommissionMap } = useSpark();
    const commissionItem = sparkCommissionMap?.get(listItem?.productId ?? '');

    if (!commissionItem) {
        return <></>;
    }

    return (
        <div className="commission-item-row">
            <span className="commission-item-row_product-name">{listItem?.label}</span>
            <span className="commission-item-row_value">
                {formatSparkCommissionItemValue(commissionItem)}
            </span>
        </div>
    );
};

export interface SparkObjectivePanelProps {
    spark: Spark;
    detailedSparkType?: DetailedSparkType;
    associatedProducts: ProductLabels[];
    variant?: 'raised' | 'smooth';
    isLoading?: boolean;
}
export function SparkObjectivePanel({
    spark,
    detailedSparkType,
    associatedProducts = [],
    variant = 'raised',
    isLoading = false,
}: SparkObjectivePanelProps) {
    const [modalIsVisible, setModalIsVisible] = useState(false);

    const metricLabel = getChartMetricLabel(spark?.metric, spark?.percentIncreaseData);
    const minTransactionCount = spark?.minimumTransactionsToQualify;
    const minThresholdLabel = getMinThresholdLabel(spark);

    const qualifiedProductCount = useMemo(() => {
        if (spark.type === 'commission') {
            const productIds = associatedProducts.map((product) => product.productId);
            const commissionProductIds = spark?.commissions?.map(
                (commission) => commission.posProductId,
            );
            return intersection(productIds, commissionProductIds ?? []).length;
        }

        return associatedProducts.length;
    }, [associatedProducts, spark?.commissions, spark.type]);

    return (
        <>
            <Paper className="spark-objective-container" variant={variant}>
                <Paper.Title>Contest Objective</Paper.Title>
                <PanelField label="Objective:" value={metricLabel || ''} />
                <PanelField
                    label="Type:"
                    value={getDetailedSparkTypeDisplayName(detailedSparkType || 'leaderboard')}
                />

                {minThresholdLabel && (
                    <PanelField label="Minimum Threshold:" value={minThresholdLabel} />
                )}

                {minTransactionCount && (
                    <PanelField label="Minimum Transactions:" value={minTransactionCount} />
                )}

                {isPercentIncreaseSpark(spark) && <ComparisonPeriodSection spark={spark} />}

                <Paper.DetailsButton
                    color="blue"
                    variant="flat"
                    endIcon={<ChevronRight />}
                    startIcon={isLoading ? <Spinner size="small" /> : undefined}
                    onClick={() => setModalIsVisible(true)}
                    disabled={isLoading}
                >
                    View All Qualifying Products
                </Paper.DetailsButton>
            </Paper>

            {modalIsVisible && (
                <SearchableListModalMobile
                    isVisible
                    className="spark-products-modal"
                    onClose={() => setModalIsVisible(false)}
                    title={spark?.name}
                    listTitle={`${qualifiedProductCount} Qualified Products`}
                    list={associatedProducts}
                    ListItemComponent={
                        spark?.type === 'commission' ? CommissionListItem : undefined
                    }
                    searchFields={['name', 'label']}
                />
            )}
        </>
    );
}
