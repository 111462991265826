import { CheckCircle as CheckIcon } from '@components/icons';
import List from '@components/layout/List';
import Paper from '@components/layout/Paper';

import { useSpark } from '@hooks/SparksHooks';

import { appendClasses } from '@helpers/ui';

import { IOption } from '@app/types/UITypes';

import './SparkLeaderboardsPaper.scss';

interface ILocationListItemProps {
    isActive: boolean;
    isChecked?: boolean;
    onClick: () => void;
    label: string;
}

const LocationListItem = ({ isActive, isChecked, onClick, label }: ILocationListItemProps) => {
    const classNamesAppended = appendClasses([
        isActive ? 'is-active' : '',
        isChecked != null ? 'has-checkbox' : '',
        isChecked === true ? 'is-checked' : 'is-not-checked',
    ]);

    return (
        <List.Item className={classNamesAppended} onClick={onClick}>
            <span>{label}</span>
            <CheckIcon />
        </List.Item>
    );
};

interface ISparkLeaderboardsPaperProps {
    selectedValue: string;
    locationOptions: (IOption & { checked?: boolean })[];
    onChangeSparkSubgroup: (sparkId: string) => void;
    showCheckmarks?: boolean;
}

const SparkLeaderboardsPaper = ({
    selectedValue,
    locationOptions,
    onChangeSparkSubgroup,
    showCheckmarks = false,
}: ISparkLeaderboardsPaperProps) => {
    return (
        <Paper className="spark-multi-location-options">
            <Paper.Title>Leaderboards</Paper.Title>
            <List>
                {locationOptions.map(({ value, label, checked }) => (
                    <LocationListItem
                        key={value}
                        isActive={selectedValue === value}
                        isChecked={showCheckmarks ? checked || false : undefined}
                        label={label}
                        onClick={() => onChangeSparkSubgroup(value)}
                    />
                ))}
            </List>
        </Paper>
    );
};

interface ISparkLeaderboardsPaperExternalProps
    extends Pick<ISparkLeaderboardsPaperProps, 'showCheckmarks'> {
    selectedValue?: string;
    locationOptions?: (IOption & { checked?: boolean })[];
    checkedValues?: string[];
    onChange?: (value: string) => void;
}

export default ({
    showCheckmarks = false,
    selectedValue,
    locationOptions: _locationOptions,
    checkedValues = [],
    onChange,
}: ISparkLeaderboardsPaperExternalProps) => {
    const { detailedSparkType, spark, sparkSubGroups, changeSparkSubgroup } = useSpark();

    if (
        spark?._id == null ||
        !['leaderboardMulti', 'leaderboardLocation'].includes(detailedSparkType ?? '')
    ) {
        return <></>;
    }

    const multiLeaderboardLocationOptions = sparkSubGroups.map((sparkSubGroup) => ({
        value: sparkSubGroup?.sparkId as string,
        label: sparkSubGroup?.locations[0]
            ? sparkSubGroup?.locations[0].label ?? sparkSubGroup?.locations[0].name
            : `Location Unknown`,
        checked: checkedValues.includes(sparkSubGroup?.sparkId as string),
    }));

    const locationOptions = _locationOptions ?? multiLeaderboardLocationOptions;

    const onChangeSparkSubgroup =
        onChange ??
        ((sparkId: string) => {
            requestAnimationFrame(() => {
                changeSparkSubgroup(sparkId);
            });
        });

    return (
        <SparkLeaderboardsPaper
            showCheckmarks={showCheckmarks}
            selectedValue={selectedValue ?? spark._id}
            locationOptions={locationOptions}
            onChangeSparkSubgroup={onChangeSparkSubgroup}
        />
    );
};
