import { FC, useMemo } from 'react';

import { Spark } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import UserCharts from '@components/charts/UserCharts';
import { NonParticipantLeader } from '@components/chips/NonParticipantChip';
import { Info } from '@components/icons';
import Paper from '@components/layout/Paper';

import { useSpark } from '@hooks/SparksHooks';

import { currencyFormatterFactory } from '@helpers/chartFormatters';

import { ChartLeader, TChartType } from '@app/types/ChartDataTypes';
import { IPosLocation } from '@app/types/PosTypes';

import './CommissionUserChart.scss';

// TODO: Why is this a button? Revisit as design debt.
const CommissionMinThresholdCallout: FC<{ spark: Spark }> = ({ spark }) => (
    <Button className="commission-minimum-btn" startIcon={<Info />} color="red" variant="smooth">
        {`Minimum Payout: ${currencyFormatterFactory(2)(spark?.minimumThresholdToQualify ?? 0)}`}
    </Button>
);

interface CommissionUserChartProps {
    leaders: ChartLeader[];
    type: TChartType;
    maximumIndividualLeaderValue?: number;
    locations: IPosLocation[];
    nonParticipantLeaders: NonParticipantLeader[];
}

export const CommissionUserChart: FC<CommissionUserChartProps> = ({
    leaders = [],
    type,
    maximumIndividualLeaderValue,
    locations,
    nonParticipantLeaders,
}) => {
    const { spark } = useSpark();

    const showNonParticipantChip = spark.trainingEnabled && spark.courseData?.required;

    const minThreshold = spark?.minimumThresholdToQualify ?? 0;
    const topIneligibleParticipantId = useMemo(() => {
        return leaders.find(({ value }) => value < minThreshold)?.flexibleEmployeeId;
    }, [minThreshold, leaders]);
    return (
        <Paper className="commission-spark-user-charts">
            <UserCharts
                title="Commission Results"
                people={leaders}
                type={type}
                metric="commission"
                showRanks
                locations={locations}
                separator={{
                    listItemId: topIneligibleParticipantId,
                    element: <CommissionMinThresholdCallout spark={spark} />,
                }}
                excludedPeople={nonParticipantLeaders}
                showNonParticipantChip={showNonParticipantChip}
                spark={spark}
            />
        </Paper>
    );
};
