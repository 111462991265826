import { FC } from 'react';

import Button from '@components/buttons/Button';
import { OpenInNew } from '@components/icons';
import CalloutMessage from '@components/layout/CalloutMessage';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import { openUrl } from '@helpers/ui';

import { IAccountRetailers } from '@app/types/AccountsTypes';

import './UntaggedProductsCallout.scss';

interface UntaggedProductsCalloutProps {
    count: number;
    retailerAccountId?: string;
}

const UntaggedProductsCallout: FC<UntaggedProductsCalloutProps> = ({
    count,
    retailerAccountId,
}) => {
    const { spark } = useSpark();
    const { account } = useSparkplugAccount();
    const accountId = retailerAccountId ?? spark?.groupId;
    const retailer: IAccountRetailers | undefined = account?.retailers?.find(
        (ret) => ret?._id === accountId,
    );
    const isOrAre = count === 1 ? 'is' : 'are';
    const plurality = count === 1 ? '' : 's';
    const UntaggedProductsMessage = () => {
        return (
            <>
                <span>
                    There {isOrAre} {count} product{plurality} mapped to {spark.sparkBrand?.name}
                    {retailer?.name ? ` at ${retailer.name}` : ' '} that {isOrAre} missing tags.
                </span>
                <Button
                    variant="raised"
                    onClick={() => openUrl(`/${account?._id}/settings/product-tags/${accountId}`)}
                >
                    <>
                        Assign Tags <OpenInNew />
                    </>
                </Button>
            </>
        );
    };

    return count > 0 ? (
        <CalloutMessage
            className="untagged-products"
            message={<UntaggedProductsMessage />}
            color="red"
            customIcon="alert"
        />
    ) : (
        <></>
    );
};

export default UntaggedProductsCallout;
