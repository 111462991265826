import { FC } from 'react';

import UserCharts from '@components/charts/UserCharts';

import { useChartStandings } from '@hooks/ChartDataHooks/useChartStandings';
import { useAccountPosDataQuery } from '@hooks/SparkplugAccountsHooks';

import { IChartDataSettings } from '@app/types/ChartDataTypes';

import './DashboardUserCharts.scss';

interface DashboardUserChartsProps {
    retailerAccountId?: string;
    chartSettings: IChartDataSettings;
}

const DashboardUserCharts: FC<DashboardUserChartsProps> = ({
    retailerAccountId = '',
    chartSettings,
}) => {
    const { chartDataLeaders } = useChartStandings({
        retailerAccountId,
        settings: chartSettings,
        isEnabled: !!retailerAccountId,
    });
    const { accountAllPosLocations } = useAccountPosDataQuery(retailerAccountId, {
        includedDatasets: 'locations',
    });

    return (
        <UserCharts
            highlightNewMembers
            people={chartDataLeaders}
            type={chartSettings.type}
            metric={chartSettings.metric}
            locations={accountAllPosLocations}
        />
    );
};

export default DashboardUserCharts;
