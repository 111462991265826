import { ComponentType, useMemo } from 'react';

import moment from 'moment';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE, Spark } from '@sparkplug/lib';

import { SparkWizardOriginRoute } from '@features/spark-wizard/views/SparkWizardView/SparkWizardView';

import { useSparkManagementModal } from '@contexts/SparkManagementModalContext';

import Dropdown from '@components/dropdown/Dropdown';
import { MoreHoriz as MoreHorizIcon, MoreVert as MoreVertIcon } from '@components/icons';
import ClickableArea from '@components/layout/ClickableArea';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { userCanCloneSpark } from '@helpers/sparks';
import { openUrl } from '@helpers/ui';

import { cn } from '@app/componentLibrary/utils';

type SparkMoreButtonVariant = 'horizontal' | 'vertical';

const SparkMoreButtonIcons: Record<SparkMoreButtonVariant, ComponentType> = {
    horizontal: MoreHorizIcon,
    vertical: MoreVertIcon,
};

type SparkMoreButtonProps = {
    className?: string;
    variant: SparkMoreButtonVariant;
    spark: Spark;
    showViewOption?: boolean;
    hasExistingTrainingCourse?: boolean;
    onTraining?: () => void;
    originRoute: SparkWizardOriginRoute;
};

function SparkMoreButton({
    variant = 'horizontal',
    className = '',
    spark,
    showViewOption = true,
    hasExistingTrainingCourse = false,
    onTraining,
    originRoute,
}: SparkMoreButtonProps) {
    const { user, userIsSuperAdmin } = useApp();
    const { userCan, account } = useSparkplugAccount();
    const { openEditModal, openCloneModal, openDeleteModal, openApprovalModal } =
        useSparkManagementModal();

    const userCanEditSpark = userCan('updateSpark');
    const accountIsRetailer = account?.type === 'retailer';
    const accountIsVendor = account?.type === 'brand';
    const canEditSpark = userIsSuperAdmin || userCanEditSpark;

    // TODO: This should really move to the API, and that work is to be ticket
    const showEditOption = useMemo(() => {
        const sparkIsNotFinalized = !spark.finalizedAt;
        const sparkIsAccepted = ['none', 'accepted'].includes(spark.requestState);
        const sparkIsPending = spark.requestState === 'pending';

        // Only allow editing for accepted, pending, and non-finalized sparks
        if (sparkIsNotFinalized && (sparkIsAccepted || sparkIsPending)) {
            // Super-admins can always edit these sparks
            if (userIsSuperAdmin) {
                return true;
            }

            if (accountIsVendor) {
                // Vendors can only edit these sparks if they are pending
                return sparkIsPending;
            }

            // Retailer admins can only edit these sparks if they are not pending
            if (accountIsRetailer) {
                return !sparkIsPending;
            }
        }

        // Default to false, specifically for vendor-admins and uneditable spark states
        return false;
    }, [spark, accountIsRetailer, account, userIsSuperAdmin]);

    const actionNeedsCsApproval = useMemo(() => {
        // super admins can perform all actions
        if (userIsSuperAdmin) {
            return false;
        }

        if (
            // if there's no user
            !user ||
            // or the account is a retailer and this is a vendor-sponsored spark
            (accountIsRetailer && spark.originatorGroupId) ||
            // or the account is a vendor and the spark has already been accepted by the retailer
            (accountIsVendor && ['accepted', 'none'].includes(spark.requestState))
        ) {
            // editing/deleting this spark will require CS approval
            return true;
        }

        return false;
    }, [spark, accountIsRetailer, account, user, userIsSuperAdmin]);

    // We currently only allow super-admins to delete sparks that are expired
    const showDeleteOption =
        showEditOption || (spark.requestState === 'expired' && userIsSuperAdmin);
    const canDeleteSpark = userCan('deleteSpark');

    const canCloneSpark =
        userCan('createSpark') && user && account && userCanCloneSpark(user, account, spark);

    if (!showViewOption && !canEditSpark && !canCloneSpark && !canDeleteSpark && !onTraining) {
        return <></>;
    }

    const onView = () => {
        if (spark?.requestState === 'pending' && account?.type !== 'brand') {
            openApprovalModal(spark._id);
            return;
        }

        openUrl(`/${account?._id}/sparks/${spark._id}`);
    };
    const onEdit = () => openEditModal(spark._id, originRoute, actionNeedsCsApproval);

    const onClone = () => {
        const {
            _id,
            description,
            emoji,
            name,
            type,
            awards,
            courseData,
            detailedSparkType,
            endDate,
            fulfillmentTypes,
            goalType,
            goals,
            metric,
            minimumThresholdToQualify,
            minimumTransactionsToQualify,
            recurringSchedule,
            startDate,
            trainingCourseId,
            trainingEnabled,
            sparkBrand,
            sparkBrandId,
            splitPayout,
            commissionRules,
            percentIncreaseData,
            groupId,
            participantFilters,
            retailerFilters,
            vendorFilters,
            productFilters,
            snaps,
            teamType,
            locationIds,
            posEmployeeProfileIds,
        } = spark;

        const trainingData = { courseData, trainingEnabled, trainingCourseId };
        const dateData = { endDate, startDate };
        const sparkVendorData = { sparkBrand, sparkBrandId };

        // checks to see if the start date is 3 days in advance for Vendor Spark or 1 day in advance for Retailer Sparks
        const threeDaysAway = moment().add(3, 'days').startOf('day');
        const canCloneDate =
            (accountIsVendor
                ? moment(startDate).isSameOrAfter(threeDaysAway)
                : moment(startDate).isAfter(moment())) && !recurringSchedule;
        const canCloneRecurringSchedule = accountIsVendor
            ? moment(recurringSchedule?.startDate).isSameOrAfter(threeDaysAway)
            : moment(recurringSchedule?.startDate).isAfter(moment());
        const canCloneVendorData = account?._id === spark.originatorGroupId;

        // const sparkSnapData = { snaps };
        // const canCloneSnapData =
        //     canCloneVendorData ||
        //     (spark?.originatorGroupId === undefined && spark.groupId === account?._id);

        openCloneModal(
            {
                clonedFromSparkId: _id,
                description,
                emoji,
                name,
                type,
                awards,
                detailedSparkType,
                fulfillmentTypes,
                goalType,
                goals,
                metric,
                minimumThresholdToQualify,
                minimumTransactionsToQualify,
                splitPayout,
                percentIncreaseData,
                commissionRules,
                groupId,
                participantFilters,
                retailerFilters,
                vendorFilters,
                productFilters,
                teamType,
                locationIds,
                posEmployeeProfileIds,
                ...(trainingEnabled ? trainingData : {}),
                ...(canCloneDate ? dateData : {}),
                ...(canCloneVendorData ? sparkVendorData : {}),
                // ...(canCloneSnapData ? sparkSnapData : {}),
                ...(canCloneRecurringSchedule ? recurringSchedule : {}),
            },
            originRoute,
            spark._id,
        );
    };
    const onDelete = () => openDeleteModal(spark._id, actionNeedsCsApproval);

    const MoreIcon = SparkMoreButtonIcons[variant];

    return (
        <ClickableArea
            className={cn('more-button', className)}
            onClick={(e) => e.stopPropagation()}
        >
            <Dropdown>
                <Dropdown.IconButton color="neutral">
                    <MoreIcon />
                </Dropdown.IconButton>
                <Dropdown.Menu>
                    {showViewOption && (
                        <Dropdown.MenuItem onClick={onView}>
                            Open Spark in New Tab
                        </Dropdown.MenuItem>
                    )}

                    {showEditOption && (
                        <Dropdown.MenuItem
                            disabled={!canEditSpark}
                            tooltipProps={
                                !canEditSpark
                                    ? { title: DO_NOT_HAVE_PERMISSIONS_MESSAGE }
                                    : undefined
                            }
                            onClick={onEdit}
                        >
                            Edit Spark
                        </Dropdown.MenuItem>
                    )}

                    {showEditOption && !!onTraining && (
                        <Dropdown.MenuItem
                            disabled={!canEditSpark}
                            tooltipProps={
                                !canEditSpark
                                    ? { title: DO_NOT_HAVE_PERMISSIONS_MESSAGE }
                                    : undefined
                            }
                            onClick={onTraining}
                        >
                            {hasExistingTrainingCourse ? 'Edit' : 'Add'} Training
                        </Dropdown.MenuItem>
                    )}

                    <Dropdown.MenuItem
                        disabled={!canCloneSpark}
                        tooltipProps={
                            !canCloneSpark ? { title: DO_NOT_HAVE_PERMISSIONS_MESSAGE } : undefined
                        }
                        onClick={onClone}
                    >
                        Clone Spark
                    </Dropdown.MenuItem>

                    {showDeleteOption && (
                        <Dropdown.MenuItem
                            disabled={!canDeleteSpark}
                            tooltipProps={
                                !canDeleteSpark
                                    ? { title: DO_NOT_HAVE_PERMISSIONS_MESSAGE }
                                    : undefined
                            }
                            onClick={onDelete}
                        >
                            Delete Spark
                        </Dropdown.MenuItem>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        </ClickableArea>
    );
}

export default SparkMoreButton;
