import HighchartsReact from 'highcharts-react-official';
import React, { useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { Refresh } from '@mui/icons-material';
import Highcharts from 'highcharts';
import { groupBy } from 'lodash';
import moment from 'moment-timezone';

import { useSnapEventsQuery, useSnapQuery } from '@features/spark-snaps/queries';

import IconButton from '@components/buttons/IconButton';
import Chip from '@components/chips/Chip';
import { SparkExpiredOrRejectedGraphic } from '@components/graphics';
import Select, { SelectOption } from '@components/inputs/Select';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { cn } from '@app/componentLibrary/utils';
import { InputEvent } from '@app/types/UITypes';

const EVENT_TYPES = ['story_started', 'story_complete'];
const EVENT_TYPES_LABELS: { [key: string]: string } = {
    story_started: 'Views',
    story_complete: 'Snap Complete',
};
export const eventTypeOptions: SelectOption<{
    value: string;
    label: string;
}>[] = EVENT_TYPES.map((value) => ({
    value,
    label: EVENT_TYPES_LABELS[value],
}));
export const timeGroupingOptions: SelectOption<{
    value: string;
    label: string;
}>[] = [
    { value: 'hour', label: 'Hour' },
    { value: 'day', label: 'Day' },
    { value: 'month', label: 'Month' },
];

// Helper function to group events by day and count occurrences
export const groupEventsByMonth = (events: any[]) => {
    return Object.entries(
        groupBy(events, (event) => moment(event.createdAt).startOf('month').format()),
    ).map(([month, e]) => [moment(month).valueOf(), e.length]);
};
export const groupEventsByDay = (events: any[]) => {
    const groupedEvents: { [key: string]: number } = {};

    events.forEach((event) => {
        const date = new Date(event.createdAt);
        const day = date.toISOString().split('T')[0];

        if (groupedEvents[day]) {
            groupedEvents[day] += 1;
        } else {
            groupedEvents[day] = 1;
        }
    });

    return Object.entries(groupedEvents).map(([day, count]) => [new Date(day).getTime(), count]);
};
export const groupEventsByHour = (events: any[]) => {
    return Object.entries(
        groupBy(events, (event) => moment(event.createdAt).startOf('hour').format()),
    ).map(([hour, e]) => [moment(hour).valueOf(), e.length]);
};

export const getGroupedData = (timeGrouping: string, snapEvents: any) => {
    if (!snapEvents) return [];

    switch (timeGrouping) {
        case 'day':
            return groupEventsByDay(snapEvents);
        case 'month':
            return groupEventsByMonth(snapEvents);
        default:
            return groupEventsByHour(snapEvents);
    }
};

export const formatXAxisLabel = ({ value }: any) => {
    return moment(value).format('M/D h:mma');
};

const containerClasses = 'rounded-lg bg-white px-4 py-5 shadow sm:p-6 bg-[#ffffff]';

const SnapAnalytics: React.FC = () => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

    const { user, history } = useApp();
    const { hasSnapsEntitlement = false } = useSparkplugAccount();
    const [eventType, setEventType] = useState(EVENT_TYPES[0]);
    const [timeGrouping, setTimeGrouping] = useState('hour');
    const { snapId = '', accountId = '' } = useParams<{ snapId?: string; accountId?: string }>();
    const { snap } = useSnapQuery({ accountId, snapId: Number(snapId) }, hasSnapsEntitlement);

    const { snapEvents, snapEventsAreReady, refetch } = useSnapEventsQuery(
        {
            eventTypes: eventType,
            snapIds: [snapId],
            limit: '0',
        },
        hasSnapsEntitlement,
    );

    const handleEventTypeChange = (event: InputEvent) => {
        setEventType(event.target.value);
    };
    const handleTimeGroupingChange = (e: any) => {
        setTimeGrouping(e.target.value);
    };

    const chartOptions: Highcharts.Options = useMemo(
        () => ({
            title: {
                text: '',
            },
            chart: {
                animation: {
                    duration: 500,
                },
                style: {
                    fontFamily: 'inherit',
                },
                backgroundColor: 'transparent',
            },
            series: [
                {
                    type: 'line',
                    name: EVENT_TYPES_LABELS[eventType],
                    data: getGroupedData(timeGrouping, snapEvents),
                    animation: {
                        duration: 500,
                    },
                },
            ],
            xAxis: {
                type: 'datetime',
                labels: {
                    formatter: formatXAxisLabel,
                },
                gridLineWidth: 1,
                crosshair: {
                    dashStyle: 'Dash',
                    color: '#777777',
                },
            },
            yAxis: {
                title: {
                    text: '',
                },
                min: 0,
                allowDecimals: false,
            },
            plotOptions: {
                series: {
                    animation: {
                        duration: 500,
                    },
                    turboThreshold: 0,
                },
                line: {
                    lineWidth: 2,
                    marker: {
                        enabled: true,
                        radius: 4,
                    },
                },
            },
            tooltip: {
                shared: true,
                crosshairs: true,
                xDateFormat: '%Y-%m-%d %H:%M:%S',
            },
            legend: {
                maxHeight: 160,
                borderWidth: 1,
                borderColor: '#ccd3de',
                borderRadius: 4,
                padding: 12,
                itemStyle: {
                    fontWeight: '700',
                },
                itemHiddenStyle: {
                    color: '#A0A7B1',
                },
                navigation: {
                    activeColor: '#0089ab',
                    style: {
                        margin: '0 auto',
                    },
                },
            },
            time: {
                useUTC: false,
            },
            credits: {
                enabled: false,
            },
        }),
        [snapEvents, timeGrouping, eventType],
    );

    const uniqueEventsByUserId = useMemo(() => groupBy(snapEvents, 'userId'), [snapEvents]);
    const stats = [
        { name: EVENT_TYPES_LABELS[eventType], stat: snapEvents?.length.toLocaleString() },
        {
            name: 'Unique User Views',
            stat: Object.keys(uniqueEventsByUserId).length.toLocaleString(),
        },
        { name: 'Slides', stat: snap?.totalPages },
    ];
    const featuredSnapStatus = snap?.featuredPeriods?.some((period) => !period?.endDate)
        ? 'live'
        : 'removed';

    if (!hasSnapsEntitlement) {
        return (
            <EmptyStateDisplay
                label="No Access"
                smallText="You do not have access to Snap Insights. Contact your account manager for more information."
                graphic={<SparkExpiredOrRejectedGraphic />}
            />
        );
    }

    return (
        <div>
            <div className="mb-6 flex justify-between">
                <div className="flex-1 flex">
                    <div className="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                        <div className="h-16 w-16 rounded-full shadow overflow-hidden border border-gray-300 bg-white text-gray-300">
                            <img
                                className="w-full"
                                src={snap?.brandPhoto || snap?.accountPhoto || snap?.thumbnailUrl}
                                alt={snap?.name}
                            />
                        </div>
                    </div>
                    <div>
                        <h4 className="text-lg flex gap-2">
                            Snap Insights for{' '}
                            <span className="font-bold">&quot;{snap?.name}&quot;</span>
                            <Chip label="Beta" color="cerulean" />
                        </h4>
                        <p className="mt-1 space-x-2">
                            {/* link to spark if applicable */}

                            <Chip
                                color="light-gray"
                                label={
                                    snap?.brandId || snap?.accountId
                                        ? 'Featured Snap'
                                        : 'Spark Snap'
                                }
                            />
                            {snap?.featuredPeriods && snap?.featuredPeriods?.length > 0 && (
                                <Chip
                                    color={featuredSnapStatus === 'live' ? 'green' : 'light-gray'}
                                    label={featuredSnapStatus}
                                />
                            )}
                        </p>
                    </div>
                </div>
                <div className="flex items-center">
                    {/* <div className="grid gap-5 sm:grid-cols-2 "> */}
                    {/* <Select
                            value={eventType}
                            options={eventTypeOptions}
                            onChange={handleEventTypeChange}
                        /> */}
                    <Select
                        value={timeGrouping}
                        options={timeGroupingOptions}
                        onChange={handleTimeGroupingChange}
                    />
                    {/* </div> */}
                    <IconButton onClick={() => refetch()} aria-label="Refresh">
                        <Refresh />
                    </IconButton>
                </div>
            </div>
            <div className={containerClasses}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                    ref={chartComponentRef}
                    key={`${timeGrouping}-${eventType}`}
                />
            </div>

            <div className="mt-6">
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-5">
                    {stats.map((item) => (
                        <div key={item.name} className={cn('overflow-hidden', containerClasses)}>
                            <dt className="truncate text-base font-medium text-gray-500">
                                {item.name}
                            </dt>
                            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                                {item.stat}
                            </dd>
                        </div>
                    ))}
                </dl>
            </div>
        </div>
    );
};

export default SnapAnalytics;
