import * as Sentry from '@sentry/react';
import { FC, PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { hotjar } from 'react-hotjar';

import LocalStorage from '@data/LocalStorage';
import axios from 'axios';

import { UserRole } from '@sparkplug/lib';

import { useSparkplugUser } from '@core/users';

import { AccountProvider } from '@contexts/AccountContext';

import toast from '@components/toast';

import { useAppBodyClasses } from '@hooks/AppHooks/useAppBodyClasses';
import { useQueryClient } from '@hooks/QueryHooks';
import { useSparkplugAccounts, useUserAccounts } from '@hooks/SparkplugAccountsHooks';
import useMediaQuery from '@hooks/useMediaQuery';

import Intercom from '@helpers/Intercom';
import { getCurrentUserId, logIn, logOut, verifySmsCode } from '@helpers/auth';
import { isPWA } from '@helpers/isPWA';
import { appendClasses } from '@helpers/ui';

import { IAuthUser } from '@app/types/UsersTypes';

const AccountIdKey = 'sparkplug::accountId';
const AppTypeKey = 'sparkplug::appType';
const mobileMaxWidth = '767px';

export interface IAppContext {
    appIsReady: boolean;
    user?: IAuthUser;
    userId?: string;
    userLoadingStatus: 'idle' | 'loading' | 'error' | 'success';
    userLoadingError?: any;
    appIsMobile: boolean;
    userIsAdmin: boolean;
    userIsSuperAdmin: boolean;
    isAdminApp: boolean;
    /**
     * Determines if the app is running in the "standalone" display mode, which is indicative of an app launched from the homescreen.
     */
    isPWA: boolean;
    setIsAdminApp: (isAdminApp: boolean) => void;
    userCanSwitchView: boolean;
    refetchUserAppData: () => Promise<void>;
    verifySmsCode: (params: {
        code: string;
        phoneNumber?: string;
        email?: string;
    }) => Promise<void>;
    setCustomUserRole: (value: UserRole) => void;
    logIn: (params: {
        password?: string;
        email?: string;
        phoneNumber?: string;
        isEmployeeLogin?: boolean;
        existingJwt?: string;
    }) => Promise<void>;
    logOut: () => Promise<boolean>;
    history: any;
    requiredToVerify: boolean;
}
export const AppContext = createContext<IAppContext>({} as IAppContext);

export const AppProvider: FC<PropsWithChildren> = ({ children }) => {
    // using the stored accountId causes issues for
    // super-admins looking at two accounts in the
    // same browser
    const useStoredAccountId = false;
    const storedAccountId: any = useStoredAccountId ? LocalStorage.get(AccountIdKey) : null;
    const storedAppType = LocalStorage.get(AppTypeKey) || 'admin';
    const [isAdminApp, _setIsAdminApp] = useState<boolean>(storedAppType === 'admin');
    const [userId, setUserId] = useState<string>(getCurrentUserId() || '');
    const [customUserRole, _setCustomUserRole] = useState<UserRole>();
    const [requiredToVerify, setRequiredToVerify] = useState(false);
    const {
        user: userData,
        refetchUser: refetchUserData,
        userLoadingStatus,
        userLoadingError,
    } = useSparkplugUser(userId);

    useEffect(() => {
        // Under some circumstances, the storedAppType is not undefined/admin (could be "employee", or something more odd), but the user _is_ an admin.  In this case, the admin gets "stuck" in the employee app view.  This ensures that once we have the user's data, we update accordingly.  A user who is both an admin AND an employee will still have the option to "switch user view" if they need to see the employee view.
        if (['super-admin', 'brand-admin', 'retailer-admin'].includes(userData?.role || '')) {
            _setIsAdminApp(true);
        }
    }, [userData?.role]);

    const { accounts, refetchAccounts: refetchAllAccounts } = useSparkplugAccounts(
        'all',
        userData?.role === 'super-admin',
    );

    const { accounts: accountsForUser, refetchAccounts: refetchAccountsForUser } = useUserAccounts(
        userId,
        !!userData && userData?.role !== 'super-admin',
    );

    const user = useMemo<IAuthUser | undefined>(() => {
        if (!userData) {
            return undefined;
        }

        return {
            ...userData,
            fullName: `${userData.firstName} ${userData.lastName}`.trim(),
            accounts: userData?.role === 'super-admin' ? accounts : accountsForUser,
            role: customUserRole || userData.role,
        };
    }, [userData, accounts, accountsForUser]);

    const appIsReady = Boolean(userId ? user : true);

    Intercom.init();

    const appIsMobile = useMediaQuery(`(max-width:${mobileMaxWidth})`);

    const userIsAdmin = user?.role != null && user?.role !== 'none';

    const userIsSuperAdmin = user?.role != null && user?.role === 'super-admin';

    const userCanSwitchView = userIsSuperAdmin;

    const setCustomUserRole = (value: UserRole) => {
        if (userCanSwitchView) {
            _setCustomUserRole(value);
        }
    };

    const appBodyClasses = useMemo(() => {
        const classNamesAppended = appendClasses([
            user?.role != null ? `user-role-${user?.role}` : `user-is-not-logged-in`,
            appIsMobile ? 'app-is-mobile' : null,
        ]);

        return classNamesAppended.length > 0 ? classNamesAppended.split(' ') : [];
    }, [user, appIsMobile]);

    useAppBodyClasses(appBodyClasses, [appBodyClasses]);

    useEffect(() => {
        if (userId !== '' && user != null) {
            Sentry.setUser({
                id: user._id,
                email: user.email,
                /**
                 * For now, we'll use `username` for the phone number because sentry doesn't
                 * offer an alternative and we want to record the phone number for employees
                 * */
                username: user.phoneNumber,
            });
            if (hotjar.initialized()) {
                hotjar.identify(userId, {
                    email: user.email,
                    firstName: user.firstName,
                    groups: user.accounts.map((account) => ({
                        id: account._id,
                        name: account.name,
                    })),
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                    role: user.role,
                });
            }

            window.freshpaint?.identify(userId, {
                email: user?.email,
                firstName: user?.firstName,
                groups: user?.accounts.map((account) => ({ id: account._id, name: account.name })),
                lastName: user?.lastName,
                phoneNumber: user?.phoneNumber,
                role: user?.role,
                appMode: isPWA(),
            });
        }
    }, [userId, user]);

    const refetchUserAppData = async (): Promise<void> => {
        if (userData?.role === 'super-admin') {
            await refetchAllAccounts();
        } else {
            await refetchAccountsForUser();
        }

        await refetchUserData();
    };

    const onVerifySmsCode = async ({
        code,
        phoneNumber,
        email,
    }: {
        code: string;
        phoneNumber?: string;
        email?: string;
    }) => {
        const { isValidCode, userId: newUserId } = await verifySmsCode(code, phoneNumber, email);

        if (!isValidCode || !newUserId) {
            throw new Error('Unable to verify sms code');
        }

        setUserId(newUserId);
    };

    const setIsAdminApp = (_isAdminApp: boolean) => {
        LocalStorage.set(AppTypeKey, _isAdminApp ? 'admin' : 'employee');
        _setIsAdminApp(_isAdminApp);
    };

    const onLogIn = async ({
        password,
        email,
        phoneNumber,
        isEmployeeLogin = false,
        existingJwt,
    }: {
        password?: string;
        email?: string;
        phoneNumber?: string;
        isEmployeeLogin?: boolean;
        existingJwt?: string;
    }) => {
        const { userId: newUserId, requires2fa } = await logIn({
            password,
            email,
            phoneNumber,
            isEmployeeLogin,
            existingJwt,
        });
        if (requires2fa) {
            setRequiredToVerify(true);
            return;
        }
        if (!newUserId) {
            throw new Error('Unable to fetch user following login');
        }

        setUserId(newUserId);
        setRequiredToVerify(false);
        setIsAdminApp(!isEmployeeLogin);
    };

    const queryClient = useQueryClient();
    const onLogOut = async () => {
        logOut();

        queryClient.clear();
        Sentry.setUser(null);
        Intercom.shutdown();
        Intercom.boot();

        setUserId('');

        if (userLoadingStatus === 'error') {
            if (
                axios.isAxiosError(userLoadingError) &&
                userLoadingError?.response?.data?.details?.includes('jwt expired')
            ) {
                toast.error('Your session has expired. Please log in again.', {
                    id: 'session-expired',
                });
            }
        }

        return true;
    };

    const value: IAppContext = useMemo(
        () => ({
            appIsReady,
            user,
            userId,
            userLoadingStatus,
            userLoadingError,
            history: undefined,
            userCanSwitchView,
            appIsMobile,
            userIsAdmin,
            userIsSuperAdmin,
            isAdminApp,
            isPWA: isPWA(),
            setIsAdminApp,
            logIn: onLogIn,
            logOut: onLogOut,
            refetchUserAppData,
            verifySmsCode: onVerifySmsCode,
            setCustomUserRole,
            requiredToVerify,
        }),
        [
            appIsReady,
            user,
            userId,
            userLoadingStatus,
            userCanSwitchView,
            appIsMobile,
            userIsAdmin,
            userIsSuperAdmin,
            isAdminApp,
            onLogIn,
            onLogOut,
            refetchUserAppData,
            onVerifySmsCode,
            setCustomUserRole,
            requiredToVerify,
        ],
    );

    useEffect(() => {
        if (user?.associatedAccounts === 0 && user.role !== 'super-admin') {
            toast.error('You are not currently associated with any active accounts.');
            onLogOut();
        }
    }, [user?.accounts]);

    return (
        <AppContext.Provider value={value}>
            <AccountProvider initialAccountId={storedAccountId}>
                <HelmetProvider>{children}</HelmetProvider>
            </AccountProvider>
        </AppContext.Provider>
    );
};
