import { useCallback, useEffect, useState } from 'react';

import SMSConfirmationModal from '@views/auth/SMSConfirmationModal';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { ChevronRight as NextIcon } from '@components/icons';
import Paper from '@components/layout/Paper';

import { useApp } from '@hooks/AppHooks';

import { sendSmsAuth } from '@helpers/auth';

import { InputEvent } from '@app/types/UITypes';

import './EmployeeLoginView.scss';

const EmployeeLoginView = () => {
    const { history, user, isAdminApp, setIsAdminApp } = useApp();

    const [credentials, setCredentials] = useState({
        phoneNumber: '',
    });
    const [errorMessage, setErrorMessage] = useState<string | null>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const goToAdminLogin = () => {
        setIsAdminApp(true);
        history.push(`/login`);
    };

    useEffect(() => {
        if (isAdminApp) {
            goToAdminLogin();
        }
    }, [isAdminApp]);

    const updateCredential = (key: string) => {
        return (event: InputEvent) => {
            setErrorMessage(null);
            setCredentials((prevState) => {
                return {
                    ...prevState,
                    [key]: event.target.value,
                };
            });
        };
    };

    const onLogIn = useCallback(() => {
        const { phoneNumber } = credentials;

        setIsSubmitting(true);

        sendSmsAuth(phoneNumber)
            .then((didSend) => {
                if (didSend) {
                    setModalIsOpen(true);
                }
            })
            .catch((err) => {
                if (err?.message != null) {
                    setErrorMessage(err?.message);
                } else {
                    setErrorMessage('An unknown error occured');
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [credentials]);

    useEffect(() => {
        if (user?._id != null) {
            history.push(`/user/sparks`);
        }
    }, [user]);
    return (
        <div className="login-view employee-login-view">
            <Paper className="login-container" elevation={5}>
                <Paper.Title className="paper-title">Log in to SparkPlug</Paper.Title>
                <Form>
                    <Form.Description>
                        <p>Log in to your account with your mobile number.</p>
                    </Form.Description>
                    <Form.PhoneField
                        label="Phone"
                        name="phoneNumber"
                        required
                        autoComplete="on"
                        onChange={updateCredential('phoneNumber')}
                    />
                    {errorMessage != null && errorMessage.length > 0 && (
                        <Form.Description>
                            <p className="error-text">{errorMessage}</p>
                        </Form.Description>
                    )}
                    <Form.ActionGroup className="text-align-center" direction="vertical">
                        <Form.Button
                            disabled={isSubmitting}
                            color="blue"
                            variant="smooth"
                            onClick={onLogIn}
                        >
                            {!isSubmitting ? 'Log in' : 'Logging in...'}
                        </Form.Button>
                    </Form.ActionGroup>
                    <Form.Description>
                        <p>We’ll send you a verification code via SMS.</p>
                    </Form.Description>
                    <Button
                        className="text-blue"
                        variant="flat"
                        endIcon={<NextIcon />}
                        onClick={goToAdminLogin}
                    >
                        <>
                            <span className="text-question">Are you an Admin?&nbsp;</span>
                            <span>Log in</span>
                        </>
                    </Button>
                </Form>
            </Paper>

            {modalIsOpen && (
                // fully dismount the modal on close to reset states
                <SMSConfirmationModal
                    isVisible
                    phoneNumber={credentials?.phoneNumber}
                    onClose={() => {
                        setModalIsOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default EmployeeLoginView;
