import { FC, useMemo } from 'react';

import { keyBy } from 'lodash';

import { AdvancedComparisonChart } from '@core/charts/components/AdvancedComparisonChart';
import {
    VendorChartSettings,
    useVendorChartContext,
} from '@core/charts/contexts/VendorChartContext';

import { useAccountLinks } from '@features/account-links';

import ComparisonChart from '@components/charts/ComparisonChart';
import Skeleton from '@components/layout/Skeleton';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccountRetailers } from '@app/types/AccountsTypes';

import { useCurrentVendorDashboardMarket } from '../../../hooks/useCurrentVendorDashboardMarket';
import { VENDOR_MARKET_CHART_METRIC_OPTIONS } from '../VendorMarketChartConstants';
import VendorTableChart from '../VendorTableChart';
import { useVendorChartComparisonBuckets } from './hooks/useVendorChartComparisonBuckets';
import { useVendorChartComparisonValues } from './hooks/useVendorChartComparisonValues';

import './VendorMarketComparisonChart.scss';

const BucketChart = ({ retailers }: { retailers: IAccountRetailers[] }) => {
    const {
        chartSettings,
        comparisonRange,
        currentPeriodLabel,
        comparisonDatesByCurrentDate,
        chartValueFormatter,
        chartBucketFactory,
    } = useVendorChartContext();

    const { isLoadingChartData, periodBuckets } = useVendorChartComparisonBuckets();

    if (isLoadingChartData) {
        return <Skeleton height={400} />;
    }

    return (
        <AdvancedComparisonChart
            breakdownLabel="retailer"
            breakdownItemLabels={retailers.map((retailer) => retailer.name)}
            settings={chartSettings}
            data={periodBuckets}
            comparisonDatesByCurrentDate={comparisonDatesByCurrentDate}
            currentPeriodLabel={currentPeriodLabel}
            comparisonRange={comparisonRange}
            chartBucketFactory={chartBucketFactory}
            chartValueFormatter={chartValueFormatter}
        />
    );
};

const useSelectedLocations = () => {
    const { account } = useSparkplugAccount();
    const { accountLinks = [] } = useAccountLinks(account?._id ?? '');
    const { chartSettings } = useVendorChartContext();

    const selectedLocations = useMemo(() => {
        const accountLinksByRetailerId = keyBy(accountLinks, 'accountId');
        const selectedRetailers = chartSettings.retailerAccountIds
            .map((retailerId) => accountLinksByRetailerId[retailerId])
            .filter(Boolean);
        const retailerLocations = selectedRetailers.flatMap(({ locations }) => locations ?? []);

        const locationsAreExcluded = !!chartSettings.exclusionFilterParams?.locationIds?.length;
        if (locationsAreExcluded) {
            const excludedLocationsById = Object.fromEntries(
                chartSettings.exclusionFilterParams?.locationIds?.map((id) => [id, true]) ?? [],
            );
            return retailerLocations.filter(({ _id }) => !excludedLocationsById[_id]);
        }

        return retailerLocations;
    }, [accountLinks, chartSettings]);

    return { selectedLocations };
};

interface VendorMarketComparisonChartProps {
    retailers: IAccountRetailers[];
}

/**
 * This chart is specifically when looking at comparison windows OR when there is no breakdown
 * (i.e. when looking at the total for the market)
 *
 * For displaying a breakdown, use `<VendorBreakdownChart />`
 */
const VendorMarketComparisonChart: FC<VendorMarketComparisonChartProps> = ({ retailers }) => {
    const { currentMarketAbbreviation } = useCurrentVendorDashboardMarket();
    const {
        chartSettings,
        updateChartSettings,
        showComparisonWindows,
        comparisonRange,
        currentPeriodLabel,
    } = useVendorChartContext();

    /**
     * The total, comparison, and previous values will always be the same, so
     * we can call this query regardless of the chart type. Having this separated
     * we can have a separate loading state for the comparison values vs the actual
     * chart data itself.
     */
    const { isLoadingTotals, comparisonValues } = useVendorChartComparisonValues();

    const { selectedLocations } = useSelectedLocations();

    const titleLabel = useMemo(() => {
        const breakdownLabelName =
            selectedLocations?.length === 1
                ? // Show location name
                  selectedLocations?.[0]?.displayName ?? selectedLocations?.[0]?.name
                : // Show number of locations
                  `${selectedLocations?.length} Locations in ${currentMarketAbbreviation}`;

        return ` of ${breakdownLabelName}`;
    }, [selectedLocations, currentMarketAbbreviation]);

    const hasNoHistoricalSalesData = false; // TODO
    const showLegend = chartSettings.type !== 'table';
    const handleUpdateChartMetric = (updatedMetric: VendorChartSettings['metric']) =>
        updateChartSettings({ metric: updatedMetric });

    return (
        <>
            {/** Reuse the styled `<ComparisonChart.Title /> */}
            <ComparisonChart.Title
                isLoading={isLoadingTotals}
                showLegend={showLegend}
                showComparisonPeriod={showComparisonWindows}
                hasHistoricalSalesData={!hasNoHistoricalSalesData}
                metric={chartSettings.metric}
                handleUpdateChartMetric={handleUpdateChartMetric as any}
                comparisonValues={comparisonValues}
                currentStartDate={chartSettings.dateStart}
                currentEndDate={chartSettings.dateEnd}
                comparisonStartDate={comparisonRange?.previousDateStart ?? ''}
                comparisonEndDate={comparisonRange?.previousDateEnd ?? ''}
                currentPeriodLabel={currentPeriodLabel}
                titleLabelOverride={titleLabel}
                isSparkComparison={false}
                metricOptions={VENDOR_MARKET_CHART_METRIC_OPTIONS}
            />

            <div className="comparison-chart-container">
                {(hasNoHistoricalSalesData ||
                    (showComparisonWindows && comparisonValues.previousValue === -1)) && (
                    <>
                        {/** Reuse the styled `<ComparisonChart.NoHistoricalSalesMessage /> */}
                        <ComparisonChart.NoHistoricalSalesMessage chartType={chartSettings.type} />
                    </>
                )}

                {/**
                 * These two charts use completely different endpoints, in order to only
                 * query the endpont that we need, we will conditionally render the correct
                 * chart based on the chart type, and the query can be handled by the
                 * respective component itself
                 */}
                {chartSettings.type === 'table' ? (
                    <VendorTableChart />
                ) : (
                    <BucketChart retailers={retailers} />
                )}
            </div>
        </>
    );
};

export default VendorMarketComparisonChart;
