import { FC, useEffect, useRef, useState } from 'react';

import { IEmployeeWithdrawal, WalletEmployeeDeposit } from '@sparkplug/lib';

import { usePrefetchBaseEmployeeData } from '@core/employees/hooks';

import ConfirmNonFbsReceiptDrawer from '@features/wallet/components/ConfirmNonFbsReceiptDrawer';

import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import { useQueryParams } from '@components/router';

import { useApp, useAppBodyClasses } from '@hooks/AppHooks';

import CashOutDrawer from '../../components/CashOutDrawer';
import CashOutHistoryItemDrawer from '../../components/CashOutHistoryItemDrawer';
import ClaimRewardDrawer from '../../components/ClaimRewardDrawer/ClaimRewardDrawer';
import RewardDetailsDrawer from '../../components/RewardDetailsDrawer';
import { useCurrentUserWallet } from '../../queries/WalletQueries';
import WalletTabs from './WalletTabs';
import WalletCard from './WalletTabs/WalletCard';

import './UserWalletViewMobile.scss';

interface UserWalletViewMobileProps {}

const UserWalletViewMobile: FC<UserWalletViewMobileProps> = () => {
    const { rewardId = '', withdrawalId = '' }: { rewardId?: string; withdrawalId?: string } =
        useQueryParams();
    const [claimingRewardId, setClaimingRewardId] = useState(rewardId);
    const { history } = useApp();

    useAppBodyClasses(['screen-user-wallet', 'app-content-noPadding'], []);

    usePrefetchBaseEmployeeData({ canPrefetch: true });

    const [currentCashOutHistoryItem, setCurrentCashOutHistoryItem] =
        useState<IEmployeeWithdrawal>();
    const cashOutHistoryDetailsAreVisible: boolean = !!currentCashOutHistoryItem;
    const openCashOutHistoryItemDetails = (cashOutHistoryItem: IEmployeeWithdrawal) => {
        setCurrentCashOutHistoryItem(cashOutHistoryItem);
    };

    const { currentUserWallet, currentUserWalletIsReady } = useCurrentUserWallet();
    const cashOutBalance = (currentUserWallet?.balance ?? 0) / 100;
    const [cashOutIsVisible, setCashOutIsVisible] = useState(false);
    const openCashOutDrawer = () => {
        setCashOutIsVisible(true);
    };

    const [currentReward, setCurrentReward] = useState<WalletEmployeeDeposit>();
    const rewardDetailsAreVisible: boolean = !!currentReward;
    const openRewardDetails = (reward: WalletEmployeeDeposit) => {
        setCurrentReward(reward);
    };

    const [claimRewardIsVisible, setClaimRewardIsVisible] = useState(!!rewardId);

    const closeClaimRewardAndRemoveQueryParam = () => {
        setClaimRewardIsVisible(false);
        history.replace({ search: '' });
    };

    const didInit = useRef(false);
    useEffect(() => {
        if (currentUserWalletIsReady) {
            didInit.current = true;
        }
    }, [currentUserWalletIsReady]);

    useEffect(() => {
        if (withdrawalId) {
            const withdrawal = currentUserWallet?.withdrawals.find((w) => w._id === withdrawalId);
            if (withdrawal) openCashOutHistoryItemDetails(withdrawal);
        }
    }, [withdrawalId, currentUserWallet]);

    const onClickClaim = (rewardClaimId: string) => {
        setClaimingRewardId(rewardClaimId);
        setClaimRewardIsVisible(true);
    };

    const [showConfirmNonFbsReceipt, setShowConfirmNonFbsReceipt] = useState(false);

    const onConfirmNonFbsReceipt = (rewardClaimId: string) => {
        setClaimingRewardId(rewardClaimId);
        setShowConfirmNonFbsReceipt(true);
    };

    const appHeaderRef = useRef<HTMLDivElement>(null);

    return (
        <>
            <AppHeaderMobile innerRef={appHeaderRef} title="Your Wallet" />

            <section className="main-wallet-content">
                <WalletCard
                    cashBalance={cashOutBalance}
                    onClickCashOut={openCashOutDrawer}
                    animate={didInit.current}
                />

                <WalletTabs
                    appHeaderRef={appHeaderRef}
                    openRewardDetails={openRewardDetails}
                    openCashOutHistoryItemDetails={openCashOutHistoryItemDetails}
                    onClickClaim={onClickClaim}
                    onConfirmNonFbsReceipt={onConfirmNonFbsReceipt}
                />
            </section>

            <RewardDetailsDrawer
                isVisible={rewardDetailsAreVisible}
                deposit={currentReward}
                onClose={() => setCurrentReward(undefined)}
            />

            <CashOutHistoryItemDrawer
                isVisible={cashOutHistoryDetailsAreVisible}
                cashOutHistoryItem={currentCashOutHistoryItem}
                onClose={() => setCurrentCashOutHistoryItem(undefined)}
            />

            <CashOutDrawer
                balance={cashOutBalance}
                isVisible={cashOutIsVisible}
                onClose={() => setCashOutIsVisible(false)}
            />

            {claimRewardIsVisible && (
                <ClaimRewardDrawer
                    isVisible={claimRewardIsVisible}
                    onClose={closeClaimRewardAndRemoveQueryParam}
                    rewardId={claimingRewardId}
                />
            )}

            {showConfirmNonFbsReceipt && (
                <ConfirmNonFbsReceiptDrawer
                    isVisible={showConfirmNonFbsReceipt}
                    onClose={() => setShowConfirmNonFbsReceipt(false)}
                    rewardId={claimingRewardId}
                />
            )}
        </>
    );
};

export default UserWalletViewMobile;
