import { CustomVerticalDashedLineIcon, InfoIcon } from '@components/icons';
import ClickableArea from '@components/layout/ClickableArea';
import Paper from '@components/layout/Paper';
import Skeleton from '@components/layout/Skeleton';
import Tooltip from '@components/layout/Tooltip';
import MultiPartLinearProgress from '@components/progress/MultiPartLinearProgress';

import { AccountUserFilters } from '@hooks/SparkplugAccountsHooks';

import { appendClasses } from '@helpers/ui';

import { IAccountUser } from '@app/types/UsersTypes';

import './EnrollmentStatisticsPanel.scss';

const AVERAGE_ENROLLMENT_PERCENT = 86;

const AverageEnrollmentIndicator = ({
    averageEnrollmentPercent,
}: {
    averageEnrollmentPercent: number;
}) => {
    const milestoneMessage = `Businesses on SparkPlug like yours average ${averageEnrollmentPercent}% enrollment`;
    return (
        <div
            className="enrollment-milestone-callout hide-on-mobile"
            style={{ left: `${averageEnrollmentPercent}%` }}
        >
            <CustomVerticalDashedLineIcon />
            <section>
                <Tooltip title={milestoneMessage}>
                    <div className="icon-container">
                        <InfoIcon />
                    </div>
                </Tooltip>
            </section>
        </div>
    );
};

const EnrollmentStatisticsProgressBar = ({
    percentEnrolled,
    percentPending,
}: {
    percentEnrolled: number;
    percentPending: number;
}) => {
    const isFullBar = percentEnrolled + percentPending === 100;
    const className = appendClasses([
        'enrollment-statistics-progress-bar',
        isFullBar ? 'full-bar' : '',
    ]);

    return (
        <div className={className}>
            <MultiPartLinearProgress
                values={[percentEnrolled, percentPending]}
                colors={['green', 'yellow']}
            />
            <AverageEnrollmentIndicator averageEnrollmentPercent={AVERAGE_ENROLLMENT_PERCENT} />
        </div>
    );
};

export const getUserEnrollmentBreakdowns = (
    users: IAccountUser[],
): {
    percentEnrolled: number;
    percentPending: number;
    notEnrolledCount: number;
} => {
    const activeEmployeesWithPosEp = users.filter(
        ({ role, smsStatus, posEmployeeProfileIds = [] }) =>
            role !== 'none' &&
            role !== 'unmatched' &&
            smsStatus !== 'blocked' &&
            posEmployeeProfileIds.length,
    );

    const employeeBreakdownCounts = activeEmployeesWithPosEp.reduce(
        (counts, { smsStatus }) => {
            switch (smsStatus) {
                case 'enrolled':
                    return {
                        ...counts,
                        enrolled: counts.enrolled + 1,
                    };
                case 'pending':
                    return {
                        ...counts,
                        pending: counts.pending + 1,
                    };
                default:
                    return {
                        ...counts,
                        notEnrolled: counts.notEnrolled + 1,
                    };
            }
        },
        {
            enrolled: 0,
            pending: 0,
            notEnrolled: 0,
        },
    );

    const percentEnrolled = Math.round(
        (employeeBreakdownCounts.enrolled / activeEmployeesWithPosEp.length) * 100,
    );
    const percentPending = Math.round(
        (employeeBreakdownCounts.pending / activeEmployeesWithPosEp.length) * 100,
    );

    return {
        percentEnrolled,
        percentPending,
        notEnrolledCount: employeeBreakdownCounts.notEnrolled,
    };
};

const EnrollmentStatisticsPanel = ({
    users = [],
    setUserFilters,
}: {
    users: IAccountUser[];
    setUserFilters?: (filters: AccountUserFilters) => void;
}) => {
    // While loading user data, an array of null values is passed into the parent component in
    // order to create the desired skeleton loading effect in the UserTables
    const usersAreLoading = users[0] === null;
    if (usersAreLoading) {
        return (
            <Paper>
                <Skeleton height={50} width="100%" />
            </Paper>
        );
    }

    const { percentEnrolled, percentPending, notEnrolledCount } =
        getUserEnrollmentBreakdowns(users);

    const rawTotalEnrolledPercent = percentEnrolled + percentPending;
    if (Number.isNaN(rawTotalEnrolledPercent)) {
        return null;
    }

    // Never let totalEnrolledPercent go above 100%
    const totalEnrolledPercent = Math.min(100, rawTotalEnrolledPercent);
    return (
        <Paper className="enrollment-statistics-panel">
            <EnrollmentStatisticsProgressBar
                percentEnrolled={percentEnrolled}
                percentPending={percentPending}
            />

            <section>
                <div className="main-enrollment-text">
                    <span className="total">{`${totalEnrolledPercent}% `}</span>
                    of employees
                    <span className="enrolled">{` enrolled `}</span>
                    or <span className="pending">{` pending`}</span>
                </div>

                <span className="main-enrollment-subtext">
                    <ClickableArea
                        className="clickable"
                        onClick={() => {
                            if (setUserFilters) {
                                setUserFilters({
                                    status: 'none',
                                    role: 'active',
                                    location: 'all',
                                });
                            }
                        }}
                    >
                        <span>{`(${notEnrolledCount}) `}</span>Employees
                    </ClickableArea>
                    &nbsp;require a phone number to enroll as Spark participants and become eligible
                    for rewards.
                </span>
            </section>
        </Paper>
    );
};

export default EnrollmentStatisticsPanel;
