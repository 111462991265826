import { FC, useMemo } from 'react';

import moment from 'moment';

import { HydratedBrandProduct, SAMPLE_TEXT_TARGET } from '@sparkplug/lib';

import { getVendorRetailerPosDataQueryKey } from '@features/account-links';

import { TableProvider } from '@contexts/TableContext';

import Button from '@components/buttons/Button';
import Chip from '@components/chips/Chip';
import { Refresh } from '@components/icons';
import Table from '@components/table/Table';
import toast from '@components/toast';

import { useModal } from '@hooks/ModalHooks';
import { useQueryClient } from '@hooks/QueryHooks';
import { useSpark } from '@hooks/SparksHooks';

import { appendClasses, wrapSubstringWithComponent } from '@helpers/ui';

import { THeadCell } from '@app/types/TableTypes';

import UntaggedProductsCallout from '../../../../UntaggedProductsCallout';
import { SampleText } from '../../../constants';
import { UseVendorRulesBasedSparkProductSelectorTableFilters } from '../useRulesBasedProductTableFilters';

import './VendorRulesBasedSparkProductSelectorTable.scss';

interface ProductRow extends HydratedBrandProduct {
    key: string;
    category: string;
    brand: string;
}

const RefreshTagsButton = () => {
    const { spark, multiRetailerParticipantGroups } = useSpark();
    const queryClient = useQueryClient();
    const retailerIds = multiRetailerParticipantGroups.map(
        ({ retailerAccountId }) => retailerAccountId,
    );

    const handleRefreshTags = (event: any) => {
        event?.stopPropagation();

        retailerIds.forEach((retailerId) => {
            const vendorRetailerQueryKey = getVendorRetailerPosDataQueryKey(
                spark.originatorGroupId || '',
                retailerId,
            );
            queryClient.invalidateQueries(vendorRetailerQueryKey);
        });
        toast.success('Tags refreshed');
    };

    return (
        <Button
            variant="flat"
            disableHover
            dense
            endIcon={<Refresh />}
            onClick={(e) => handleRefreshTags(e)}
        >
            Refresh
        </Button>
    );
};
const getHeadCells = (filteredProductsMissingTagCount: number): THeadCell<ProductRow>[] => [
    {
        id: 'name',
        sortType: 'string',
        label: 'Product Name',
        render: (row) => {
            const hasSample = row.name.toLowerCase().includes(SAMPLE_TEXT_TARGET);

            return (
                <Table.Cell>
                    <div>
                        {hasSample
                            ? wrapSubstringWithComponent(SAMPLE_TEXT_TARGET, row.name, SampleText)
                            : row.name}
                    </div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'sparkBrandName',
        label: 'Brand',
        render: () => {
            const { spark } = useSpark();
            return (
                <Table.Cell>
                    <div>{spark?.sparkBrand?.name || '--'}</div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'lastSoldAt',
        sortType: 'string',
        label: 'Last Sold Date',
        render: (row) => {
            return (
                <Table.Cell>
                    <div>{row.lastSoldAt ? moment(row.lastSoldAt).format('MM/DD/YYYY') : '--'}</div>
                </Table.Cell>
            );
        },
    },
    {
        id: 'tags',
        label: () => <>Tags {filteredProductsMissingTagCount > 0 && <RefreshTagsButton />} </>,
        sortType: 'arrayLength',
        render: (row) => {
            return (
                <Table.Cell>
                    <div className="product-selector-tag-cell">
                        {row.tags.length > 0 ? (
                            row.tags.map((tag, i) => {
                                return (
                                    <Chip
                                        key={i}
                                        color="light-gray"
                                        variant="flat"
                                        avatar={
                                            // viewbox that's 10x10 with a circle radius of 5
                                            <svg className="tag-icon" viewBox="0 0 10 10">
                                                <circle cx="5" cy="5" r="5" fill={tag.color} />
                                            </svg>
                                        }
                                        label={tag.name}
                                    />
                                );
                            })
                        ) : (
                            <span className="no-tags">No tags</span>
                        )}
                    </div>
                </Table.Cell>
            );
        },
    },
];

interface VendorRulesBasedSparkProductSelectorTableProps {
    isCreatingMultiRetailerSpark?: boolean;
    retailerAccountId: string;
    products: HydratedBrandProduct[];
    tableFilters: UseVendorRulesBasedSparkProductSelectorTableFilters;
    filteredProductsMissingTagCount?: number;
}

export const VendorRulesBasedSparkProductSelectorTable: FC<
    VendorRulesBasedSparkProductSelectorTableProps
> = ({
    isCreatingMultiRetailerSpark,
    retailerAccountId,
    products,
    tableFilters,
    filteredProductsMissingTagCount = 0,
}) => {
    const { modalContentRef } = useModal();
    const { applyHideSampleProducts, applyLastSoldFilter, applyTagsFilter, applySearch } =
        tableFilters;
    const productRows = useMemo<ProductRow[]>(() => {
        return products.map((product) => {
            const category =
                product.categories?.find(({ _id }) => _id === product.categoryId)?.name ?? '';
            const brand = product.brands?.find(({ _id }) => _id === product.brandId)?.name ?? '';
            return {
                ...product,
                key: product._id,
                category,
                brand,
            };
        });
    }, [products]);

    const headCells = getHeadCells(filteredProductsMissingTagCount);

    const classNamesAppended = appendClasses([
        'vendor-rules-based-spark-product-selector-table-wrapper',
    ]);

    const applicableFilters = useMemo(() => {
        const defaults = [applyHideSampleProducts, applyLastSoldFilter, applyTagsFilter];
        return applySearch ? [...defaults, applySearch] : defaults;
    }, [tableFilters]);

    return (
        <TableProvider
            showPagination={false}
            headCells={headCells}
            rows={productRows}
            filters={applicableFilters}
            defaultOptions={{
                orderBy: 'name',
            }}
        >
            <div className={classNamesAppended}>
                <UntaggedProductsCallout
                    count={filteredProductsMissingTagCount}
                    retailerAccountId={retailerAccountId}
                />

                <Table useExternalProvider variant="smooth">
                    <Table.RenderHead />
                    <Table.RenderBody
                        emptyStateText="No products match the rules set"
                        dynamicHeight={{
                            infiniteScroll: true,
                            scrollToTopElement: {
                                getText: (itemCount) => `Viewing ${itemCount} products`,
                            },
                            ...(isCreatingMultiRetailerSpark
                                ? {
                                      staticHeightOverride: 300,
                                  }
                                : { containerRef: modalContentRef }),
                        }}
                        highlightRowOnHover
                    />
                </Table>
            </div>
        </TableProvider>
    );
};

export default VendorRulesBasedSparkProductSelectorTable;
