import { useEffect } from 'react';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@componentLibrary';

import { useGetEmployeeLearningResources } from '@features/learn-and-earn/queries/useGetEmployeeLearningResources';
import FeaturedSnapsList from '@features/spark-snaps/components/FeaturedSnapsList';
import { useEmployeeFeaturedSnaps } from '@features/spark-snaps/queries/FeaturedSnapQueries';
import { LearnAndEarn } from '@features/user-home/components/LearnAndEarn';
import { SparksModule } from '@features/user-home/components/SparksModule';

import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import { useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import Intercom from '@helpers/Intercom';
import { getUrlParameter } from '@helpers/ui';

import './HomeView.scss';

interface IHomeViewProps {
    initialSparkStatus?: 'active' | 'upcoming' | 'complete';
}

const HomeView = ({ initialSparkStatus = 'active' }: IHomeViewProps) => {
    const { tab } = useQueryParams();
    const { user, history } = useApp();

    const { employeeFeaturedSnaps } = useEmployeeFeaturedSnaps();
    const { account } = useSparkplugAccount();
    const { learningResources } = useGetEmployeeLearningResources();

    const handleTabChange = (newVal: string) => {
        if (newVal !== 'your-sparks') {
            history.push(`?tab=${newVal}`);
            return;
        }
        history.push('');
    };
    const incompleteLearningResourcesCount = learningResources.filter(
        (resource) => resource.surveyResponse?.status !== 'completed',
    ).length;

    useEffect(() => {
        if (getUrlParameter('openIntercom')) {
            Intercom.open();
        }
    }, []);

    return (
        <>
            <AppHeaderMobile title={`Welcome${user?.firstName ? `, ${user?.firstName}` : ''}`} />
            {employeeFeaturedSnaps && employeeFeaturedSnaps.length > 0 && (
                <FeaturedSnapsList snaps={employeeFeaturedSnaps} accountId={account?._id ?? ''} />
            )}

            <Tabs defaultValue="your-sparks" value={tab} onValueChange={handleTabChange}>
                <TabsList className="w-full sm:w-fit flex mb-4">
                    <TabsTrigger value="your-sparks" className="flex-1 text-nowrap">
                        Your Sparks
                    </TabsTrigger>
                    <TabsTrigger value="learn-and-earn" className="flex-1 text-nowrap">
                        <div className="flex items-center gap-2 justify-center">
                            Learn & Earn
                            {incompleteLearningResourcesCount > 0 && (
                                <div className="flex items-center justify-center w-5 h-5 rounded-full bg-cerulean-500 text-white text-xs">
                                    {incompleteLearningResourcesCount}
                                </div>
                            )}
                        </div>
                    </TabsTrigger>
                </TabsList>
                <TabsContent value="your-sparks">
                    <SparksModule initialSparkStatus={initialSparkStatus} />
                </TabsContent>
                <TabsContent value="learn-and-earn">
                    <LearnAndEarn />
                </TabsContent>
            </Tabs>
        </>
    );
};

export default HomeView;
