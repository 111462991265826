import { CSSProperties, FC, ReactElement, RefObject, isValidElement } from 'react';

import Button from '@components/buttons/Button';
import Paper from '@components/layout/Paper';

import { isEmpty, noop } from '@helpers/util';

import { cn } from '@app/componentLibrary/utils';

import './EmptyStateDisplay.scss';

interface ActionButtonProps {
    // Todo: Name this `actionButtonProps` or something similar?
    label: string;
    onClick: (e: any) => any;
    startIcon?: ReactElement;
    disabled?: boolean;
    tooltip?: string;
}

export interface EmptyStateDisplayProps {
    divRef?: RefObject<HTMLDivElement>;
    graphic: ReactElement;
    className?: string;
    contentClassName?: string;
    customContent?: ReactElement;
    label?: string;
    smallText?: string | ReactElement;
    actionButton?: ActionButtonProps | ReactElement | null;
    style?: CSSProperties;
    layout?: 'vertical' | 'horizontal';
    addPaperWrapper?: boolean;
}

const EmptyStateDisplay: FC<EmptyStateDisplayProps> = ({
    divRef,
    graphic,
    className = '',
    contentClassName = '',
    customContent,
    label,
    smallText,
    actionButton = null,
    style,
    layout = 'vertical',
    addPaperWrapper = false,
}) => {
    const ActionButton: FC = () => {
        if (!actionButton) {
            return <></>;
        }

        return isValidElement(actionButton) ? (
            actionButton
        ) : (
            <Button
                className="empty-state_fallback-action-button"
                variant="filled"
                color="blue"
                onClick={(actionButton as ActionButtonProps)?.onClick || noop}
                startIcon={(actionButton as ActionButtonProps)?.startIcon}
                disabled={(actionButton as ActionButtonProps)?.disabled}
                tooltip={(actionButton as ActionButtonProps)?.tooltip}
            >
                {(actionButton as ActionButtonProps)?.label}
            </Button>
        );
    };

    const EmptyState: FC = () => {
        return (
            <div
                ref={divRef}
                className={cn('empty-state-display', className, layout)}
                style={style}
            >
                <div className="empty-state-display_graphic">{graphic}</div>
                <div className={cn('content', contentClassName)}>
                    {!isEmpty(customContent) && customContent}
                    {!isEmpty(label) && <p className="empty-state-display_label">{label}</p>}
                    {!isEmpty(smallText) && (
                        <p className="empty-state-display_small-text">{smallText}</p>
                    )}
                    {!isEmpty(actionButton) && <ActionButton />}
                </div>
            </div>
        );
    };

    return (
        <>
            {addPaperWrapper ? (
                <Paper sx={{ minHeight: '462px', display: 'flex', alignItems: 'center' }}>
                    <EmptyState />
                </Paper>
            ) : (
                <EmptyState />
            )}
        </>
    );
};

export default EmptyStateDisplay;
