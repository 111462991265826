import { FC, useRef, useState } from 'react';

import { BrandProductTag, BrandProductTagGroup, HydratedBrandProductTag } from '@sparkplug/lib';

import { OnChangeParams } from '@features/product-tags/mutations';

import Button from '@components/buttons/Button';
import { TagIcon } from '@components/icons';

import TagSelectorDropdownMenu from '../TagSelectorDropdownMenu';

import './BulkTagSelector.scss';

interface BulkTagSelectorProps {
    tagGroups: BrandProductTagGroup[];
    selectedIds: string[];
    onAssignTag: (params: OnChangeParams) => void;
    disabled: boolean;
}

const BulkTagSelector: FC<BulkTagSelectorProps> = ({
    tagGroups,
    selectedIds,
    onAssignTag,
    disabled,
}) => {
    const [tagMenuOpen, setTagMenuOpen] = useState<boolean>(false);

    const addTagButtonRef = useRef<HTMLButtonElement>(null);

    const handleClickTag = (dehydratedTag: BrandProductTag) => {
        const brandGroup = tagGroups.find((tg) => tg.tags.some((t) => t._id === dehydratedTag._id));

        if (brandGroup) {
            const hydratedNewTag: HydratedBrandProductTag = {
                ...dehydratedTag,
                groupName: brandGroup.name,
            };

            onAssignTag({
                tagName: hydratedNewTag.name,
                tagGroupName: hydratedNewTag.groupName,
            });
        }
    };

    const handleAddTagMenuOpen = () => {
        setTagMenuOpen(true);
    };

    const handleAddTagMenuClose = () => {
        setTagMenuOpen(false);
    };

    const anchorEl = addTagButtonRef.current ?? undefined;

    return (
        <>
            <Button
                ref={addTagButtonRef}
                variant="raised"
                color="blue"
                startIcon={<TagIcon />}
                aria-controls="tag-selector-menu"
                aria-haspopup="true"
                onClick={handleAddTagMenuOpen}
                disabled={disabled || selectedIds.length === 0}
            >
                Add Tags
            </Button>
            {tagMenuOpen && (
                <TagSelectorDropdownMenu
                    tagGroups={tagGroups}
                    isVisible={tagMenuOpen}
                    onClickTag={handleClickTag}
                    onClose={handleAddTagMenuClose}
                    anchorEl={anchorEl}
                />
            )}
        </>
    );
};

export default BulkTagSelector;
