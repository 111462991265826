import { ReactNode, useMemo } from 'react';

import { AccountContext, BrandRetailerProvider } from '@contexts/AccountContext';

import Skeleton from '@components/layout/Skeleton';

import { useAccountQueryByGroupId, useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccountContext } from '@app/types/AccountsTypes';

/**
 * @description
 *
 * This is a modified version of the AccountContext.Provider that is used in the SparkWizardView.
 * It inherits the account context from the app, but overrides the account with the account that
 * is scoped to the wizard. This is so that the Wizard can use the Vendor account while remaining
 * on the Retailer account in the App.
 */
export const SparkWizardAccountProvider = ({
    accountId,
    children,
}: {
    accountId: string;
    children: ReactNode;
}) => {
    const appAccountContextValue = useSparkplugAccount();
    const {
        isLoading,
        account: wizardScopedAccount,
        refetchAccount,
        isRefetching,
    } = useAccountQueryByGroupId(accountId);

    const value = useMemo<IAccountContext>(
        () => ({
            ...appAccountContextValue,
            accountIsReady: !isLoading,
            account: wizardScopedAccount,
            refetchAccount: () => refetchAccount(),
            isRefetching,
            refetchAccountLocations: refetchAccount,
            userCan: () => false,
        }),
        [appAccountContextValue, wizardScopedAccount, isLoading],
    );

    if (!wizardScopedAccount) {
        return <Skeleton height="75vh" width="100%" />;
    }

    return (
        <AccountContext.Provider value={value}>
            {wizardScopedAccount ? (
                <BrandRetailerProvider account={wizardScopedAccount}>
                    {children}
                </BrandRetailerProvider>
            ) : (
                children
            )}
        </AccountContext.Provider>
    );
};
