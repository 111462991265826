import Form from '@components/form/Form';
import CalloutMessage from '@components/layout/CalloutMessage';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { TAccountUserView } from '@app/types/AccountsTypes';

import './SwitchUserViewModal.scss';

interface ISwitchUserViewModalProps extends IDefaultModalProps {}

const SuperAdminUserRoles: Array<{ label: string; value: TAccountUserView }> = [
    {
        label: 'Super Admin',
        value: 'super-admin',
    },
    {
        label: 'Admin',
        value: 'group-admin',
    },
    // ...AccountUserRoles, // TODO: Must modify to use `group-member` and `none` views
];

const ManagerAdminUserRoles: Array<{ label: string; value: TAccountUserView }> = [
    {
        label: 'Admin',
        value: 'group-admin',
    },
    {
        label: 'Participant',
        value: 'none',
    },
];

const SwitchUserViewModal = ({ isVisible, onClose }: ISwitchUserViewModalProps) => {
    const { user, userIsSuperAdmin, isAdminApp, setIsAdminApp, history } = useApp();
    const { account, switchAccountUserView } = useSparkplugAccount();

    if (user == null || account == null) {
        return null;
    }

    const currentSuperAdminView = account?.roles?.[user?._id] || 'super-admin';
    const currentManagerAdminView = isAdminApp ? 'group-admin' : 'none';

    return (
        <Modal className="switch-user-view-modal" isVisible={isVisible} onClose={onClose}>
            <Modal.Title onClose={onClose}>Switch User View</Modal.Title>

            <Modal.Content>
                {userIsSuperAdmin && (
                    <CalloutMessage message="Currently, this feature allows super admins to view an account as an account admin" />
                )}
                <Form>
                    <Form.Select
                        searchable
                        label="User Views"
                        value={userIsSuperAdmin ? currentSuperAdminView : currentManagerAdminView}
                        options={userIsSuperAdmin ? SuperAdminUserRoles : ManagerAdminUserRoles}
                        onChange={(event) => {
                            switchAccountUserView(event.target.value);
                            if (event.target.value === 'none') {
                                setIsAdminApp(false);
                                history.push(`/user/sparks`);
                            } else if (!userIsSuperAdmin && event.target.value === 'group-admin') {
                                setIsAdminApp(true);
                                history.push(`/${account._id}/dashboard`);
                            }
                            onClose(false);
                        }}
                    />
                </Form>
            </Modal.Content>

            <Modal.Actions />
        </Modal>
    );
};

export default SwitchUserViewModal;
