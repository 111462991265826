import { useMemo } from 'react';

import moment from 'moment';

import { useSparkplugAccount, useTotalProductTagCount } from '@hooks/SparkplugAccountsHooks';

export const useShowTagAssistantUI = () => {
    const { account, userCan } = useSparkplugAccount();

    const userCanManageLinks = userCan('manageLinks');

    const { totalProductTagCountIsReady, totalProductTagCount } = useTotalProductTagCount({
        accountId: account?._id ?? '',
        isEnabled: account?.type === 'brand',
    });

    const hasBeenMoreThanFiveHours = useMemo(() => {
        return account?.tagAssistantEnabledAt
            ? moment(account?.tagAssistantEnabledAt).add(5, 'hours').isBefore(moment().utc())
            : false;
    }, [account]);

    const isVendorAccountWithTaggedProducts =
        account?.type === 'brand' && totalProductTagCountIsReady && totalProductTagCount! > 0;

    const showTagAssistantCallout =
        isVendorAccountWithTaggedProducts &&
        !account?.tagAssistantMessageDismissed &&
        account?.rulesBasedSparksEnabled &&
        !hasBeenMoreThanFiveHours &&
        userCanManageLinks;

    const showTagAssistantToggle =
        isVendorAccountWithTaggedProducts &&
        (hasBeenMoreThanFiveHours || account?.tagAssistantMessageDismissed) &&
        account?.rulesBasedSparksEnabled;

    return {
        showTagAssistantCallout,
        showTagAssistantToggle,
    };
};
