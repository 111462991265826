import { useAccountLinks } from '@features/account-links/queries';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

export const usePendingVendorLinks = () => {
    const autoLinkingFeatureEnabled = process.env.REACT_APP_AUTO_LINKING === 'true';

    const { account } = useSparkplugAccount();

    const {
        accountLinks: pendingVendorLinks = [],
        accountLinksAreReady: pendingVendorLinksAreReady,
        refetchAccountLinks: refetchPendingVendorLinks,
    } = useAccountLinks(account?._id ?? '', !!account && account.type === 'retailer', ['pending']);

    const pendingVendorLinkCount = pendingVendorLinks.length;
    const showPendingVendorLinks =
        autoLinkingFeatureEnabled && account?.type === 'retailer' && pendingVendorLinkCount > 0;

    return {
        pendingVendorLinks,
        pendingVendorLinkCount,
        pendingVendorLinksAreReady,
        refetchPendingVendorLinks,
        showPendingVendorLinks,
    };
};
