import moment from 'moment';

import { HydratedBrandProduct } from '@sparkplug/lib';

export const getMissingTagsCount = (products?: HydratedBrandProduct[]) => {
    if (!products) {
        return 0;
    }

    return products
        .filter(({ lastSoldAt }) =>
            moment(lastSoldAt).isSameOrAfter(moment().subtract(60, 'days'), 'day'),
        )
        .filter(({ tags }) => !tags?.length).length;
};
