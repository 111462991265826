import { FC } from 'react';

import CalloutMessage from '@components/layout/CalloutMessage';

import { useSpark } from '@hooks/SparksHooks';

import { getMinThresholdLabel } from '@helpers/sparks';

import './MinThresholdCallout.scss';

interface MinThresholdCalloutProps {
    className?: string;
}
const MinThresholdCallout: FC<MinThresholdCalloutProps> = ({ className = '' }) => {
    const { spark, sparkIsReady } = useSpark();
    const { minimumThresholdToQualify, minimumTransactionsToQualify } = spark ?? {};

    if (!sparkIsReady || (!minimumThresholdToQualify && !minimumTransactionsToQualify)) {
        return null;
    }

    let messagePrefix = 'Must sell';
    if (spark?.type === 'leaderboard') {
        switch (spark.metric) {
            case 'percent_increase':
                messagePrefix = 'Must achieve a ';
                break;
            case 'order_average':
                messagePrefix = 'Must achieve an';
                break;
            case 'transaction_count':
                messagePrefix = 'Must achieve';
                break;
            case 'percent_of_total_sales':
                messagePrefix = 'Sales of incentivized products must account for at least';
                break;
            default:
                break;
        }
    }

    const onlyHasMinTransaction = minimumTransactionsToQualify && !minimumThresholdToQualify;
    const minThresholdLabel = getMinThresholdLabel(spark)?.toLowerCase?.();
    const minTransactionLabel = minimumTransactionsToQualify
        ? `${
              minimumThresholdToQualify ? ', including ' : ''
          }at least ${minimumTransactionsToQualify} transactions `
        : ' ';
    const minThresholdMessage = onlyHasMinTransaction
        ? `Must achieve ${minTransactionLabel} to qualify for top prizes.`
        : `${messagePrefix} ${minThresholdLabel}${minTransactionLabel}to qualify for top prizes.`;

    return (
        <CalloutMessage
            hideIcon
            className={`min-threshold-callout-message ${className}`.trim()}
            color="blue"
            message={minThresholdMessage}
        />
    );
};

export default MinThresholdCallout;
