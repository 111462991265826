import { FC } from 'react';

import { BrandProductTagGroup } from '@sparkplug/lib';

import { InfoIcon, TagIcon, TextContainsIcon, TextDoesNotContainIcon } from '@components/icons';
import Tooltip from '@components/layout/Tooltip';
import FilterSegmentToolbar from '@components/toolbar/FilterSegmentToolbar';
import Toolbar from '@components/toolbar/Toolbar';

import ProductSegment from '../../../ProductSegment';
import { UseVendorRulesBasedSparkProductSelectorTableFilters } from '../useRulesBasedProductTableFilters';

interface VendorRulesBasedProductSelectorTableToolbarProps {
    brandTagGroups?: BrandProductTagGroup[];
    tableFilters: UseVendorRulesBasedSparkProductSelectorTableFilters;
    customClassName?: string;
}

const ProductRuleSegmentToolbar: FC<VendorRulesBasedProductSelectorTableToolbarProps> = ({
    brandTagGroups,
    tableFilters,
}) => {
    const { filters, setFilters } = tableFilters;

    const segments = [
        ...(brandTagGroups?.map(({ name: groupName, tags }: BrandProductTagGroup) => {
            const currentAndSelected = filters.productTags.filter(
                ({ tagGroupName }) => tagGroupName === groupName,
            );
            const nonCurrentAndSelected = filters.productTags.filter(
                ({ tagGroupName }) => tagGroupName !== groupName,
            );

            return {
                startIcon: <TagIcon />,
                label: groupName,
                isActive: !!currentAndSelected.length,
                element: (
                    <Toolbar.DropdownListSelector
                        key={groupName}
                        label={groupName}
                        options={tags.map(({ name, color }) => {
                            const label = (
                                <span>
                                    <svg className="tag-icon" viewBox="0 0 10 10">
                                        <circle cx="5" cy="5" r="5" fill={color} />
                                    </svg>
                                    <span>{name}</span>
                                </span>
                            ) as any;
                            return {
                                labelText: name,
                                label,
                                value: `${groupName}:${name}`,
                            };
                        })}
                        selected={
                            currentAndSelected.map(({ tagName }) => ({
                                labelText: tagName,
                                label: tagName,
                                value: `${groupName}:${tagName}`,
                            })) || []
                        }
                        selectedCountProps={{
                            pluralUnitLabel: 'tags',
                        }}
                        onApply={(filteredOptions) => {
                            requestAnimationFrame(() => {
                                setFilters((prevValue) => ({
                                    ...prevValue,
                                    productTags: [
                                        ...nonCurrentAndSelected,
                                        ...filteredOptions.map((x) => ({
                                            tagName: x.labelText,
                                            tagGroupName: groupName,
                                        })),
                                    ],
                                }));
                            });
                        }}
                        showCount={!!currentAndSelected.length}
                        clear={{
                            active: !!currentAndSelected.length,
                            onClear: () => {
                                requestAnimationFrame(() => {
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        productTags: nonCurrentAndSelected,
                                    }));
                                });
                            },
                        }}
                    />
                ),
            };
        }) ?? []),
        {
            startIcon: <TextContainsIcon />,
            label: 'Product Name Contains',
            isActive: !!filters.productNameContains?.length,
            element: (
                <Toolbar.DynamicStringDropdownListSelector
                    createButtonLabel="Create New Rule"
                    buttonLabel="Product Name Contains"
                    pluralUnitLabel="rules"
                    textFieldLabel={
                        <>
                            Product name <strong>contains:</strong>
                        </>
                    }
                    chipText="contains"
                    initialStringFilters={filters.productNameContains ?? []}
                    onApply={(productNameContains) =>
                        setFilters((prevValue) => ({
                            ...prevValue,
                            productNameContains,
                        }))
                    }
                />
            ),
        },
        {
            startIcon: <TextDoesNotContainIcon />,
            label: 'Product Name Does Not Contain',
            isActive: !!filters.productNameDoesNotContain?.length,
            element: (
                <Toolbar.DynamicStringDropdownListSelector
                    createButtonLabel="Create New Rule"
                    buttonLabel="Product Name Does Not Contain"
                    pluralUnitLabel="rules"
                    textFieldLabel={
                        <>
                            Product name <strong>does not contain:</strong>
                        </>
                    }
                    chipText="does not contain"
                    initialStringFilters={filters.productNameDoesNotContain ?? []}
                    onApply={(productNameDoesNotContain) =>
                        setFilters((prevValue) => ({
                            ...prevValue,
                            productNameDoesNotContain,
                        }))
                    }
                />
            ),
        },
    ];

    return (
        <FilterSegmentToolbar
            label="Qualifying Product Rules"
            addButtonText="Add Rule"
            segments={segments}
        />
    );
};

const DeprecatedProductSegmentToolbar: FC<VendorRulesBasedProductSelectorTableToolbarProps> = ({
    brandTagGroups,
    tableFilters,
    customClassName = '',
}) => {
    const { filters, setFilters } = tableFilters;
    const vendorProductToolbar = (
        <>
            <Toolbar className={customClassName} justifyContentStart>
                {brandTagGroups?.map(({ name: groupName, tags }: BrandProductTagGroup) => {
                    const currentAndSelected = filters.productTags.filter(
                        ({ tagGroupName }) => tagGroupName === groupName,
                    );
                    const nonCurrentAndSelected = filters.productTags.filter(
                        ({ tagGroupName }) => tagGroupName !== groupName,
                    );

                    return (
                        <Toolbar.DropdownListSelector
                            key={groupName}
                            label={groupName}
                            options={tags.map(({ name, color }) => {
                                const label = (
                                    <span>
                                        <svg className="tag-icon" viewBox="0 0 10 10">
                                            <circle cx="5" cy="5" r="5" fill={color} />
                                        </svg>
                                        <span>{name}</span>
                                    </span>
                                ) as any;
                                return {
                                    labelText: name,
                                    label,
                                    value: `${groupName}:${name}`,
                                };
                            })}
                            selected={
                                currentAndSelected.map(({ tagName }) => ({
                                    labelText: tagName,
                                    label: tagName,
                                    value: `${groupName}:${tagName}`,
                                })) || []
                            }
                            selectedCountProps={{
                                pluralUnitLabel: 'tags',
                            }}
                            onApply={(filteredOptions) => {
                                requestAnimationFrame(() => {
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        productTags: [
                                            ...nonCurrentAndSelected,
                                            ...filteredOptions.map((x) => ({
                                                tagName: x.labelText,
                                                tagGroupName: groupName,
                                            })),
                                        ],
                                    }));
                                });
                            }}
                            showCount={!!currentAndSelected.length}
                            clear={{
                                active: !!currentAndSelected.length,
                                onClear: () => {
                                    requestAnimationFrame(() => {
                                        setFilters((prevValue) => ({
                                            ...prevValue,
                                            productTags: nonCurrentAndSelected,
                                        }));
                                    });
                                },
                            }}
                        />
                    );
                })}
                <Toolbar.Checkbox
                    label={
                        <>
                            {'Exclude '}
                            <strong>“Sample”</strong>
                            {' products'}
                        </>
                    }
                    value={filters.hideSampleProducts}
                    onChange={() =>
                        requestAnimationFrame(() => {
                            setFilters((prevValue) => ({
                                ...prevValue,
                                hideSampleProducts: !filters.hideSampleProducts,
                            }));
                        })
                    }
                />
                <Tooltip
                    title='This will exclude all products that have the word "Sample" in their product name or category'
                    placement="top"
                >
                    <InfoIcon className="tooltip-icon" />
                </Tooltip>
            </Toolbar>
        </>
    );

    return <ProductSegment toolbar={vendorProductToolbar} />;
};

const VendorRulesBasedProductSelectorTableToolbar: FC<
    VendorRulesBasedProductSelectorTableToolbarProps
> = ({ brandTagGroups, tableFilters, customClassName = '' }) => {
    const { filters } = tableFilters;

    const { REACT_APP_TAGS_FILTER_SEGMENT = false } = process.env;
    if (REACT_APP_TAGS_FILTER_SEGMENT === 'true' && !filters.hideSampleProducts) {
        return <ProductRuleSegmentToolbar {...{ brandTagGroups, tableFilters }} />;
    }

    return (
        <DeprecatedProductSegmentToolbar {...{ brandTagGroups, tableFilters, customClassName }} />
    );
};

export default VendorRulesBasedProductSelectorTableToolbar;
