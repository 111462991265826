import { FC, useState } from 'react';
import { createPortal } from 'react-dom';

import { ColumnDetail, TableKey } from '@sparkplug/lib';

import { useSaveTableSettings } from '@core/users/mutations';

import Button from '@components/buttons/Button';
import Dropdown from '@components/dropdown/Dropdown';
import Spinner from '@components/layout/Spinner';

import { appendClasses } from '@helpers/ui';

import { ColumnSettingsList } from './ColumnSettingsList';
import { ReactComponent as ColumnsIcon } from './ColumnsIcon.svg';
import { useColumnSettingsState } from './useColumnSettingsState';

import './ColumnSettingsManager.scss';

interface ColumnSettingsManagerProps {
    tableKey: TableKey;
    columns: ColumnDetail[];
    staticColumns?: ColumnDetail[];
    columnsAreReady: boolean;
}
export const ColumnSettingsManager: FC<ColumnSettingsManagerProps> = ({
    tableKey,
    columns = [],
    staticColumns = [],
    columnsAreReady,
}) => {
    const { columnSettings, resetColumnSettings, updateColumnOrder, updateColumnVisibility } =
        useColumnSettingsState({ columns });

    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const handleCloseAndReset = () => {
        setIsPopoverOpen(false);
        resetColumnSettings();
    };

    const { saveTableSettings, tableSettingsAreSaving } = useSaveTableSettings({ tableKey });
    const handleSave = () => {
        saveTableSettings({
            columns: columnSettings,
        }).then(() => setIsPopoverOpen(false));
    };

    return (
        <Dropdown className="column-manager" onClose={handleCloseAndReset}>
            <Dropdown.Button
                className={appendClasses([
                    'column-manager-button',
                    isPopoverOpen ? 'column-manager-button_active' : '',
                ])}
                onClick={() => setIsPopoverOpen(true)}
                disabled={!columnsAreReady}
                variant="flat"
                disableHover
            >
                {!columnsAreReady ? (
                    <Spinner size="small" />
                ) : (
                    <span>
                        <ColumnsIcon /> Table Columns
                    </span>
                )}
            </Dropdown.Button>

            {isPopoverOpen && (
                <Dropdown.Popover className="column-manager-popover">
                    <div className="column-manager-popover_content">
                        <section className="column-manager_header">
                            <header>Columns</header>
                            <div>
                                <Button
                                    className="column-manager_reset-button"
                                    color="neutral"
                                    variant="flat"
                                    onClick={resetColumnSettings}
                                    disabled={tableSettingsAreSaving}
                                >
                                    Reset
                                </Button>

                                <Button
                                    className="column-manager_save-button"
                                    variant="smooth"
                                    onClick={handleSave}
                                    disabled={tableSettingsAreSaving}
                                >
                                    {tableSettingsAreSaving ? <Spinner size="small" /> : 'Save'}
                                </Button>
                            </div>
                        </section>

                        <ColumnSettingsList
                            staticColumnSettings={staticColumns}
                            interactiveColumnSettings={columnSettings}
                            onVisibilityChange={updateColumnVisibility}
                            onOrderChange={updateColumnOrder}
                        />
                    </div>
                </Dropdown.Popover>
            )}
        </Dropdown>
    );
};

// Temporary: Use a portal to insert the ColumnSettingsManager into the SparksView toolbar to keep changes more minimal
export default ({
    columns,
    columnsAreReady,
    staticColumns,
    tableKey,
}: ColumnSettingsManagerProps) => {
    const sparksViewToolbarPlaceholder = document.querySelector('.column-widget-placeholder');

    if (!sparksViewToolbarPlaceholder) {
        return <></>;
    }

    return createPortal(
        <ColumnSettingsManager
            columnsAreReady={columnsAreReady}
            columns={columns}
            staticColumns={staticColumns}
            tableKey={tableKey}
        />,
        sparksViewToolbarPlaceholder,
    );
};
