import { FC, ReactElement } from 'react';

import clsx from 'clsx';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import Button from '@components/buttons/Button';
import { Add, InfoOutlined } from '@components/icons';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from '@app/componentLibrary/Card';
import Typography from '@app/componentLibrary/Typography';

export interface SparkTemplateCardProps {
    title: string;
    subHeading: string;
    desc: string;
    cardImage: ReactElement;
    showButtons: boolean;
    onCreateClicked?: () => void;
    onViewDetailsClicked?: () => void;
    className?: string;
}

export const SparkTemplateCard: FC<SparkTemplateCardProps> = ({
    title,
    subHeading,
    desc,
    cardImage,
    showButtons,
    onCreateClicked,
    onViewDetailsClicked,
    className,
}) => {
    const { userCan } = useSparkplugAccount();
    const userCanCreateSpark = userCan('createSpark');

    return (
        <Card
            className={clsx('border border-solid border-gray-100 flex flex-col h-full', className)}
        >
            <div className="flex-grow">
                <CardHeader>
                    {cardImage}
                    <CardDescription>
                        <Typography variant="sm" className="uppercase font-semibold text-gray-700">
                            {subHeading}
                        </Typography>
                    </CardDescription>
                    <CardTitle className="mb-1">
                        <Typography variant="lg" className="font-semibold">
                            {title}
                        </Typography>
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <Typography variant="sm" className="text-gray-800">
                        {desc}
                    </Typography>
                </CardContent>
            </div>
            {showButtons && (
                <CardFooter className="mt-auto">
                    <div className="flex justify-between items-center w-full">
                        <Button
                            dense
                            startIcon={<InfoOutlined />}
                            onClick={() => onViewDetailsClicked?.()}
                        >
                            View details
                        </Button>
                        <Button
                            startIcon={<Add />}
                            variant="filled"
                            color="blue"
                            disabled={!onCreateClicked || !userCanCreateSpark}
                            onClick={() => onCreateClicked?.()}
                            tooltip={!userCanCreateSpark ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : ''}
                        >
                            Create
                        </Button>
                    </div>
                </CardFooter>
            )}
        </Card>
    );
};
