import { FC, useMemo, useState } from 'react';

import { keyBy, uniqBy } from 'lodash';
import moment from 'moment';

import { SparkSnap } from '@sparkplug/lib';

import formatDuration from '@core/utils/formatDuration';

import SnapTableCell from '@features/spark-snaps/components/SnapTableCell/SnapTableCell';
import SnapsEmptyState from '@features/spark-snaps/components/SnapsEmptyState/SnapsEmptyState';
import { useSnapViewQueue } from '@features/spark-snaps/hooks/useSnapViewQueue';
import {
    useCreateSnapTemplateMutation,
    useRemoveSnapFromSpark,
} from '@features/spark-snaps/mutations';
import {
    downloadSnapEngagementCSV,
    useAccountSparkSnapsQuery,
    useSnapTemplatesQuery,
} from '@features/spark-snaps/queries';

import Dropdown from '@components/dropdown/Dropdown';
import { MoreVert as MoreIcon } from '@components/icons';
import Spinner from '@components/layout/Spinner';
import SparkStatusIcon from '@components/sparks/SparkStatusIcon';
import AdvancedTable from '@components/table/AdvancedTable';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import { openUrl } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';
import { THeadCell } from '@app/types/TableTypes';
import { IOption } from '@app/types/UITypes';

import './SparkSnapsDashboardView.scss';

export const getSparkStatus = (
    snap: SparkSnap,
): 'active' | 'upcoming' | 'completed' | 'pending' => {
    const spark = snap.spark;
    const now = moment();
    const start = moment(spark.startDate);
    const end = moment(spark.endDate);

    if (spark?.requestState === 'pending') {
        return 'pending';
    }

    if (now.isSameOrAfter(start, 'date') && now.isSameOrBefore(end, 'date')) {
        return 'active';
    } else if (now.isAfter(end, 'date')) {
        return 'completed';
    } else if (now.isBefore(start, 'date')) {
        return 'upcoming';
    }
    return 'pending';
};
const sparkStatusOptions = [
    { startIcon: <SparkStatusIcon status="active" />, label: 'Active', value: 'active' },
    {
        startIcon: <SparkStatusIcon status="upcoming" />,
        label: 'Upcoming',
        value: 'upcoming',
    },
    {
        startIcon: <SparkStatusIcon status="completed" />,
        label: 'Complete',
        value: 'completed',
    },
    {
        startIcon: <SparkStatusIcon status="pending" />,
        label: 'Pending',
        value: 'pending',
    },
];

export type SnapTableRow = SparkSnap & {
    status: 'active' | 'upcoming' | 'completed' | 'pending';
    key: string;
    snapLaunchDate: string;
};
export const useSnapsDashboardHeadCells = ({
    account,
}: {
    account: IAccount;
}): {
    stickyLeftHeadCells: THeadCell<SnapTableRow>[];
    stickyRightHeadCells: THeadCell<SnapTableRow>[];
    headCells: THeadCell<SnapTableRow>[];
    SnapViewer: FC<{}>;
} => {
    const { userIsSuperAdmin, history } = useApp();
    const { handleViewSnap, SnapViewer } = useSnapViewQueue();
    const accountId = account._id;
    const { isCreatingSnapTemplate, createSnapTemplateAsync } = useCreateSnapTemplateMutation({
        accountId,
    });

    const { removeSnapAsync } = useRemoveSnapFromSpark({ accountId });

    const handleSaveAsTemplate = async ({ snapId }: { snapId: number }) => {
        if (snapId && !isCreatingSnapTemplate) {
            await createSnapTemplateAsync({ snapId });
        }
    };

    const handleOpenSpark = (sparkId: string) => {
        openUrl(`/${accountId}/sparks/${sparkId}`);
    };

    const [csvIsDownloading, setCsvIsDownloading] = useState(false);
    const handleDownloadEnagementCSV = (row: SparkSnap) => {
        setCsvIsDownloading(true);
        downloadSnapEngagementCSV({
            accountId,
            snapId: row.storifymeSnapId,
            sparkId: row.spark._id,
        }).finally(() => {
            setCsvIsDownloading(false);
        });
    };

    const stickyLeftHeadCells: THeadCell<SnapTableRow>[] = [
        {
            id: 'name',
            label: () => {
                return (
                    <div className="sticky-header">
                        <div className="label">
                            <span>Snap Name</span>
                        </div>
                    </div>
                );
            },
            sortType: 'string',
            sticky: 'left',
            render: (row) => (
                <Table.Cell
                    onClick={() =>
                        handleViewSnap({ selectedSnapId: row.storifymeSnapId, accountId })
                    }
                >
                    <SnapTableCell name={row.name} iconUrl={row.posterImg} />
                </Table.Cell>
            ),
        },
    ];

    const stickyRightHeadCells: THeadCell<SnapTableRow>[] = [
        {
            id: 'actions',
            sticky: 'right',
            render: (row) => (
                <Table.Cell align="left" className="more-button">
                    <Dropdown>
                        <Dropdown.IconButton color="neutral">
                            <MoreIcon />
                        </Dropdown.IconButton>

                        <Dropdown.Menu>
                            <Dropdown.MenuItem
                                onClick={() =>
                                    handleViewSnap({
                                        selectedSnapId: row.storifymeSnapId,
                                        accountId,
                                    })
                                }
                            >
                                Preview Snap
                            </Dropdown.MenuItem>
                            <Dropdown.MenuItem onClick={() => handleOpenSpark(row.spark._id)}>
                                Open Spark in New Tab
                            </Dropdown.MenuItem>
                            <Dropdown.MenuItem
                                onClick={() =>
                                    removeSnapAsync({
                                        sparkId: row.spark._id,
                                        snapId: row.storifymeSnapId,
                                    })
                                }
                            >
                                Delete Snap
                            </Dropdown.MenuItem>
                            <Dropdown.MenuItem
                                onClick={() => handleDownloadEnagementCSV(row)}
                                endIcon={csvIsDownloading ? <Spinner size="small" /> : <></>}
                            >
                                Export Snap Analytics
                            </Dropdown.MenuItem>

                            <Dropdown.MenuItem
                                onClick={() =>
                                    history.push(
                                        `/${account?._id}/snaps/${row?.storifymeSnapId || ''}`,
                                    )
                                }
                            >
                                Snap Analytics
                            </Dropdown.MenuItem>
                            {userIsSuperAdmin && (
                                <Dropdown.MenuItem
                                    tooltipProps={{ title: 'Only use this link internally' }}
                                    onClick={() => navigator.clipboard.writeText(row.iframeUrl)}
                                >
                                    Copy Internal QA Link
                                </Dropdown.MenuItem>
                            )}
                        </Dropdown.Menu>
                    </Dropdown>
                </Table.Cell>
            ),
        },
    ];

    const headCells: THeadCell<SnapTableRow>[] = [
        {
            id: 'spark.sparkBrand.name',
            label: 'Brand',
            isHidden: account.type === 'retailer',
            sortType: 'string',
            render: (row) => <Table.Cell>{row.spark.sparkBrand.name}</Table.Cell>,
        },
        {
            id: 'spark.name',
            label: 'Spark Name',
            sortType: 'string',
            render: (row) => {
                return (
                    <Table.Cell
                        className="table-cell_spark-name"
                        onClick={() => handleOpenSpark(row.spark._id)}
                    >
                        <div>
                            <SparkStatusIcon status={row.status} /> {row.spark.name}
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'spark.retailer.name',
            label: 'Retailer',
            sortType: 'string',
            render: (row) => <Table.Cell>{row.spark.retailer.name}</Table.Cell>,
        },
        {
            id: 'snapLaunchDate',
            label: 'Snap Launch Date',
            sortType: 'string',
            render: (row) => (
                <Table.Cell>{moment(row.snapLaunchDate).format('MM/DD/YYYY')}</Table.Cell>
            ),
        },
        {
            id: 'spark.endDate',
            label: 'End Date',
            sortType: 'string',
            render: (row) => (
                <Table.Cell>{moment(row.spark.endDate).format('MM/DD/YYYY')}</Table.Cell>
            ),
        },
        {
            id: 'viewTime',
            label: 'View Time',
            sortType: 'numeric',
            isHidden: !userIsSuperAdmin,
            render: ({ viewTime }) => {
                if (!viewTime) {
                    return <Table.Cell>0</Table.Cell>;
                }
                const viewTimeInMs = viewTime * 1000;
                return <Table.Cell>{formatDuration(viewTimeInMs)}</Table.Cell>;
            },
        },
        {
            id: 'viewCount',
            label: 'Views',
            isHidden: true,
            sortType: 'numeric',
            render: (row) => <Table.Cell>{row.viewCount ?? '--'}</Table.Cell>,
        },
        {
            id: 'avgReadTime',
            label: 'Avg Read Time',
            isHidden: true,
            sortType: 'numeric',
            render: (row) => <Table.Cell>{row.avgReadTime ?? '--'}</Table.Cell>,
        },
    ];

    return { stickyLeftHeadCells, stickyRightHeadCells, headCells, SnapViewer };
};
interface SnapDashboardTableFilters {
    sparkStatus?: 'active' | 'upcoming' | 'completed' | 'pending';
    retailers: IOption[];
    templates: IOption[];
    brands: IOption[];
}

const useSnapDashboardFilters = ({
    initialFilterValues,
}: {
    initialFilterValues?: Partial<SnapDashboardTableFilters>;
}) => {
    const [filters, setFilters] = useState({
        sparkStatus: undefined,
        retailers: [],
        templates: [],
        brands: [],
        ...initialFilterValues,
    });

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'name',
        'spark.name',
        'spark.sparkBrand.name',
        'spark.retailer.name',
    ]);

    const applyRetailerFilter = (snaps: SnapTableRow[]) => {
        if (!filters.retailers.length) {
            return snaps;
        }

        const selectedRetailersByValue = keyBy(filters.retailers, 'value');

        return snaps.filter((snap) => selectedRetailersByValue[snap.spark.retailerAccountId]);
    };

    const applyBrandFilter = (snaps: SnapTableRow[]) => {
        if (!filters.brands.length) {
            return snaps;
        }

        const selectedBrandsByValue = keyBy(filters.brands, 'value');

        return snaps.filter((snap) => selectedBrandsByValue[snap.spark.sparkBrandId]);
    };

    const applyTemplateFilter = (snaps: SnapTableRow[]) => {
        if (!filters.templates.length) {
            return snaps;
        }

        // TODO: We currently have no way to know which snaps were created from which templates
        // but this is mocked in the designs so the filter is just stubbed out for now
        return snaps;
    };
    const applySparkStatusFilter = (snaps: SnapTableRow[]) => {
        return snaps.filter((snap) => {
            if (!filters.sparkStatus) {
                return true;
            }

            return snap.status === filters.sparkStatus;
        });
    };
    return {
        filters,
        setFilters,
        applyRetailerFilter,
        applyBrandFilter,
        applyTemplateFilter,
        applySparkStatusFilter,
        applySearch,
        searchFilter,
        onChangeSearchFilter,
    };
};

interface SparkSnapsDashboardViewProps {
    account: IAccount;
    snapsEnabled: boolean;
}

export const SparkSnapsDashboardView: FC<SparkSnapsDashboardViewProps> = ({
    account,
    snapsEnabled,
}) => {
    const { history } = useApp();
    const { accountSparkSnapsAreReady, accountSparkSnaps = [] } = useAccountSparkSnapsQuery(
        {
            accountId: account._id,
        },
        snapsEnabled,
    );

    const { snapTemplatesAreReady, snapTemplates = [] } = useSnapTemplatesQuery(
        { accountId: account._id },
        snapsEnabled,
    );

    const { stickyLeftHeadCells, stickyRightHeadCells, headCells, SnapViewer } =
        useSnapsDashboardHeadCells({ account });

    const retailerOptions = useMemo(() => {
        return uniqBy(
            accountSparkSnaps.map(({ spark }) => ({
                value: spark.retailerAccountId,
                label: spark.retailer.name,
            })),
            'value',
        );
    }, [accountSparkSnaps]);

    const brandOptions = useMemo(() => {
        return uniqBy(
            accountSparkSnaps.map(({ spark }) => ({
                value: spark.sparkBrandId,
                label: spark.sparkBrand.name,
            })),
            'value',
        );
    }, [accountSparkSnaps]);

    const templateOptions = useMemo(() => {
        return uniqBy(
            snapTemplates.map((snapTemplate) => ({
                value: String(snapTemplate.id),
                label: snapTemplate.name,
            })),
            'value',
        );
    }, [accountSparkSnaps, snapTemplates]);

    const {
        filters,
        setFilters,
        onChangeSearchFilter,
        applyRetailerFilter,
        applyBrandFilter,
        applyTemplateFilter,
        applySparkStatusFilter,
        applySearch,
        searchFilter,
    } = useSnapDashboardFilters({
        initialFilterValues: {
            templates: [],
            retailers: [],
            sparkStatus: 'active',
        },
    });

    const rows = useMemo(() => {
        return accountSparkSnaps
            .filter((snap) => !['rejected', 'expired'].includes(snap.spark.requestState ?? ''))
            .map((snap) => ({
                ...snap,
                key: `${snap.storifymeSnapId}${snap.spark._id}`,
                status: getSparkStatus(snap),
                snapLaunchDate: moment(snap.createdAt).isAfter(moment(snap.spark.startDate))
                    ? snap.createdAt
                    : snap.spark.startDate,
            }));
    }, [accountSparkSnaps]);

    const isAllRetailers =
        !filters.retailers.length || filters.retailers.length === retailerOptions.length;
    const isAllBrands = !filters.brands.length || filters.brands.length === brandOptions.length;
    const isAllTemplates =
        !filters.templates.length || filters.templates.length === templateOptions.length;

    return (
        <>
            {(accountSparkSnapsAreReady && rows.length === 0) || !snapsEnabled ? (
                <SnapsEmptyState
                    snapsEnabled={snapsEnabled}
                    buttonLabel="Create Snap"
                    emptyStateProps={{
                        graphic: <img src="/static/images/snaps-preview.jpg" alt="placeholder" />,
                        label: 'Supercharge your incentives with Snaps',

                        smallText:
                            'Snaps are stories for your Sparks, providing employees with relevant product info, quizzes, and surveys. Create a Spark to get started.',
                    }}
                    onClick={() => {
                        history.push(`/${account?._id}/sparks/create`);
                    }}
                />
            ) : (
                <>
                    <Toolbar justifyContentStart>
                        <Toolbar.Search
                            name="search"
                            defaultValue={searchFilter}
                            onChange={onChangeSearchFilter}
                        />
                        {account.type === 'brand' && (
                            <Toolbar.DropdownListSelector
                                label={`${isAllBrands ? 'All ' : ''}Brands`}
                                options={brandOptions}
                                selected={filters.brands.length ? filters.brands : brandOptions}
                                onSelectionChanged={(value) =>
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        brands: value,
                                    }))
                                }
                                clear={{
                                    active: !isAllBrands,
                                    onClear: () => {
                                        setFilters((prevValue) => ({
                                            ...prevValue,
                                            brands: [],
                                        }));
                                    },
                                }}
                            />
                        )}
                        {account.type === 'brand' && (
                            <Toolbar.DropdownListSelector
                                label={`${isAllRetailers ? 'All ' : ''}Retailers`}
                                options={retailerOptions}
                                selected={
                                    filters.retailers.length ? filters.retailers : retailerOptions
                                }
                                onSelectionChanged={(value) =>
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        retailers: value,
                                    }))
                                }
                                clear={{
                                    active: !isAllRetailers,
                                    onClear: () => {
                                        setFilters((prevValue) => ({
                                            ...prevValue,
                                            retailers: [],
                                        }));
                                    },
                                }}
                            />
                        )}
                        <Toolbar.Dropdown
                            label={null}
                            options={sparkStatusOptions}
                            value={filters.sparkStatus}
                            onSelect={(value) =>
                                setFilters((prevValue) => ({
                                    ...prevValue,
                                    sparkStatus: value,
                                }))
                            }
                            titlePlaceholder="All Spark Statuses"
                            clear={{
                                active: !!filters.sparkStatus,
                                onClear: () => {
                                    setFilters((prevValue) => ({
                                        ...prevValue,
                                        sparkStatus: undefined,
                                    }));
                                },
                            }}
                        />
                    </Toolbar>

                    <AdvancedTable
                        defaultOptions={{
                            orderBy: 'spark.endDate',
                            order: 'desc',
                        }}
                        className="spark-snaps-table"
                        isLoading={!accountSparkSnapsAreReady}
                        filters={[
                            applyRetailerFilter,
                            applyBrandFilter,
                            applyTemplateFilter,
                            applySparkStatusFilter,
                            applySearch,
                        ]}
                        rows={rows}
                        stickyLeft={stickyLeftHeadCells}
                        headCells={headCells}
                        stickyRight={stickyRightHeadCells}
                        variant="raised"
                    >
                        <Table.RenderHead />
                        <Table.RenderBody />
                    </AdvancedTable>

                    <SnapViewer />
                </>
            )}
        </>
    );
};

export default () => {
    const { account, hasSnapsEntitlement } = useSparkplugAccount();

    if (!account) {
        return <></>;
    }

    return <SparkSnapsDashboardView account={account} snapsEnabled={hasSnapsEntitlement} />;
};
