import { FC, useMemo } from 'react';

import SparkSnapsPanel from '@features/spark-snaps/components/SparkSnapsPanel';

import { useSpark } from '@hooks/SparksHooks';

import { formatSparkInfo } from '@helpers/sparks';

import { cn } from '@app/componentLibrary/utils';

import './SparkWizardSnapPanel.scss';

interface SparkWizardSnapPanelProps {
    className?: string;
    labelPosition?: 'in-panel' | 'above-panel';
}
const SparkWizardSnapPanel: FC<SparkWizardSnapPanelProps> = ({
    className = '',
    labelPosition = 'in-panel',
}) => {
    const { spark, updateSpark } = useSpark();
    const sparkStatus = useMemo(() => {
        return formatSparkInfo(spark.startDate || '', spark.endDate || '')?.status;
    }, [spark]);

    const sharedSparkSnapsPanelProps = {
        sparkId: spark._id,
        accountId: spark.originatorGroupId ?? spark.groupId,
        initialSnapIds: spark.snaps?.snapIds,
        sparkStatus,
        onChange: async (params: {
            accountId: string;
            storifymeAccountId: string;
            snapIds: number[];
        }) => {
            updateSpark({
                snaps: params,
            });
        },
    };

    if (spark?.snaps?.snapIds?.length === 0 && sparkStatus === 'Complete') {
        return <></>;
    }

    if (labelPosition === 'above-panel') {
        return (
            <div
                className={cn('flex flex-col w-full gap-4', className)}
                data-testid="spark-wizard-snap-panel_above-panel-label"
            >
                <h3
                    className={cn(
                        // Add a unique css class to handle the ::after bottom border
                        'spark-snaps_panel-label',
                        'text-base leading-5 tracking-[0.0175em] text-[#00000099]',
                    )}
                >
                    Spark Snaps
                </h3>
                <SparkSnapsPanel hideTitle {...sharedSparkSnapsPanelProps} />
            </div>
        );
    }

    return <SparkSnapsPanel className={className} {...sharedSparkSnapsPanelProps} />;
};

export default SparkWizardSnapPanel;
