import { useEffect, useMemo } from 'react';

import { internalTrackingFieldValueNames } from '@constants/SparkConstants';
import { find } from 'lodash';
import moment from 'moment';

import {
    SparkInternalInvoiceStatus,
    SparkInternalPayoutStatus,
    formatCurrency as formatNumberToCurrency,
} from '@sparkplug/lib';

import {
    groupPlanPromoBalanceKey,
    retailerReferralBalancesKey,
    useGroupPlanPromoBalance,
    useRetailerReferralBalances,
} from '@features/spark-credits/queries';

import PanelField from '@components/layout/PanelField';

import { useQueryClient } from '@hooks/QueryHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import { getSparkPaymentFromPayouts } from '@helpers/sparks';
import { appendClasses } from '@helpers/ui';

import { IAccount } from '@app/types/AccountsTypes';
import { IPayoutLineItem, ISparkPayoutGroup } from '@app/types/SparksTypes';

import './SparkPaymentSummary.scss';

export const getLineItemsFromPayoutGroups = (
    payoutGroups: ISparkPayoutGroup[],
    // Only pass sparkId if you want to show the payment label for a specific spark if it is pending (like in the CheckoutModal)
    showValueForSparkId?: string,
) =>
    payoutGroups.map((payoutGroup) => ({
        name: payoutGroup.locationLabel,
        formattedValue:
            payoutGroup.payoutsAreFinalized ||
            (!!showValueForSparkId && payoutGroup.sparkId === showValueForSparkId)
                ? getSparkPaymentFromPayouts(payoutGroup.payouts).paymentLabel
                : 'Pending',
    }));

const PaymentStatusSection = ({
    invoiceStatus,
    payoutStatus,
    payoutsFinalizedAt,
}: {
    invoiceStatus: SparkInternalInvoiceStatus;
    payoutStatus: SparkInternalPayoutStatus;
    payoutsFinalizedAt?: string;
}) => {
    const finalizedDateTimeStr = payoutsFinalizedAt
        ? moment(payoutsFinalizedAt).format('dddd MMMM Do [at] h:mma')
        : undefined;

    const finalizedAtText =
        payoutsFinalizedAt && invoiceStatus !== 'not-sent'
            ? `Finalized at ${finalizedDateTimeStr}`
            : undefined;

    const payoutStatusText = finalizedAtText || internalTrackingFieldValueNames[payoutStatus];

    const invoiceStatusText = internalTrackingFieldValueNames[invoiceStatus];

    return (
        <section className="spark-payment-status-section">
            <PanelField label="Invoice Status" value={invoiceStatusText} />
            <PanelField label="Payout Status" value={payoutStatusText} />
        </section>
    );
};

const useSparkPaymentStatuses = () => {
    const { spark, sparkSubGroups, detailedSparkType } = useSpark();

    const multiLeaderboardPayoutStatus = sparkSubGroups.every(
        ({ internalTracking }) => internalTracking?.payoutStatus === 'paid',
    )
        ? 'paid'
        : 'not-paid';

    const invoiceStatus: SparkInternalInvoiceStatus =
        spark?.internalTracking?.invoiceStatus || 'not-sent';

    const payoutStatus: SparkInternalPayoutStatus =
        detailedSparkType === 'leaderboardMulti'
            ? multiLeaderboardPayoutStatus
            : spark?.internalTracking?.payoutStatus || 'not-paid';

    return {
        invoiceStatus,
        payoutStatus,
    };
};

const useMaxSparkCreditDeduction = ({
    totalDue,
    account,
}: {
    totalDue: number;
    account: IAccount;
}) => {
    const { spark } = useSpark();

    const { retailerReferralBalances = [], retailerReferralBalancesAreReady } =
        useRetailerReferralBalances(account);

    const { groupPlanPromoBalance = 0, groupPlanPromoBalanceIsReady } = useGroupPlanPromoBalance({
        groupId: spark.originatorGroupId ?? spark.groupId,
    });

    const maxCreditsDeductionIsReady =
        groupPlanPromoBalanceIsReady && retailerReferralBalancesAreReady;

    const queryClient = useQueryClient();
    useEffect(() => {
        // Clear the cache every time useMaxSparkCreditDeduction is mounted
        // Necessary for when multiple sparks are checked out for the same group
        queryClient.invalidateQueries(groupPlanPromoBalanceKey);
        queryClient.invalidateQueries(retailerReferralBalancesKey);
    }, []);

    return useMemo(() => {
        const applicableReferralBalance =
            find(retailerReferralBalances, { groupId: spark?.groupId })?.balance ?? 0;

        const totalApplicableCreditBalance = applicableReferralBalance + groupPlanPromoBalance;

        return {
            maxCreditsDeduction: Math.min(totalDue, totalApplicableCreditBalance),
            maxCreditsDeductionIsReady,
        };
    }, [
        retailerReferralBalances,
        groupPlanPromoBalance,
        maxCreditsDeductionIsReady,
        spark?.groupId,
        totalDue,
    ]);
};

const getTotalDueLabel = ({
    invoiceStatus,
    rawTotalDue,
    maxCreditDeduction,
}: {
    invoiceStatus: string;
    rawTotalDue: number;
    maxCreditDeduction: number;
}) => {
    if (invoiceStatus === 'sent') {
        return 'Pending';
    }

    const finalTotalDue = invoiceStatus === 'paid' ? 0 : rawTotalDue - maxCreditDeduction;
    return finalTotalDue === 0 ? '$0' : formatNumberToCurrency(finalTotalDue);
};

interface ISparkCheckoutPaymentProps {
    account: IAccount;
    paymentValue: number;
    lineItems: IPayoutLineItem[];
    payoutsFinalizedAt?: string;
    showStatusSection?: boolean;
    view?: 'checkout' | 'invoice';
}
export const SparkPaymentSummary = ({
    account,
    paymentValue,
    payoutsFinalizedAt,
    lineItems,
    showStatusSection = true,
    view = 'checkout',
}: ISparkCheckoutPaymentProps) => {
    const { invoiceStatus, payoutStatus } = useSparkPaymentStatuses();

    const { maxCreditsDeduction, maxCreditsDeductionIsReady } = useMaxSparkCreditDeduction({
        account,
        totalDue: paymentValue,
    });

    const showCreditDeduction =
        invoiceStatus !== 'paid' && maxCreditsDeductionIsReady && maxCreditsDeduction > 0;

    const totalDueLabel = getTotalDueLabel({
        invoiceStatus,
        rawTotalDue: paymentValue,
        maxCreditDeduction: showCreditDeduction ? maxCreditsDeduction : 0,
    });

    const checkoutPaymentSummaryContent = (
        <div className="spark-checkout-payment-amounts">
            <div className="totals">
                <div className="total-amount">
                    <span>Total Payment Amount</span>
                    <span>{formatNumberToCurrency(paymentValue)}</span>
                </div>

                {showCreditDeduction && (
                    <div className="credit-deduction">
                        <span>Spark Credits</span>
                        <span>{`- ${formatNumberToCurrency(maxCreditsDeduction)}`}</span>
                    </div>
                )}

                <hr />

                <div className="total-due">
                    <span>Total Balance Due</span>
                    <span>{totalDueLabel}</span>
                </div>
            </div>

            {lineItems.length > 1 && (
                <div className="line-items">
                    {lineItems.map(({ name, formattedValue }) => (
                        <div
                            key={name}
                            className={formattedValue === 'Pending' ? 'is-pending-approval' : ''}
                        >
                            <span>{name}</span>
                            <span>{formattedValue}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );

    const adminInvoicePayoutContent = (
        <div className="spark-checkout-payment-amounts is-invoice-content">
            <div className="totals">
                <div className="total-amount">
                    <span>Total Payout Amount</span>
                    <span>{formatNumberToCurrency(paymentValue)}</span>
                </div>
            </div>
        </div>
    );

    return (
        <div
            className={appendClasses([
                'spark-checkout-payment-amounts_container',
                showStatusSection ? 'has-status-section' : null,
            ])}
        >
            {view === 'checkout' ? checkoutPaymentSummaryContent : adminInvoicePayoutContent}

            {showStatusSection && (
                <PaymentStatusSection
                    invoiceStatus={invoiceStatus}
                    payoutStatus={payoutStatus}
                    payoutsFinalizedAt={payoutsFinalizedAt}
                />
            )}
        </div>
    );
};

export default (props: Omit<ISparkCheckoutPaymentProps, 'account'>) => {
    const { account } = useSparkplugAccount();
    if (!account) {
        return <></>;
    }

    return <SparkPaymentSummary {...props} account={account} />;
};
