// import { useEffect, useRef } from 'react';
import { appendClasses } from '@helpers/ui';

function LottieGraphic({
    className,
    json,
    speed = 1,
    autoplay = true,
    startPlay = false,
    direction = 1,
    loop = false,
    onInit = () => {},
}: {
    className?: string;
    json: any;
    speed?: number;
    autoplay?: boolean;
    startPlay?: boolean;
    direction?: number;
    loop?: boolean;
    onInit?: (ref: any) => void;
}) {
    // const playerRef = useRef<any>(null);
    const classNamesAppended = appendClasses(['graphic', 'lottie-graphic', className]);

    // useEffect(() => {
    //     playerRef.current.autoplay = autoplay;
    //     onInit(playerRef);
    // }, []);

    // useEffect(() => {
    //     if (startPlay && playerRef?.current?.seek && playerRef?.current?.play) {
    //         playerRef.current.seek(0);
    //         playerRef.current.play();
    //     }
    // }, [startPlay]);

    // const style = {
    //     width: '300px',
    //     height: '300px',
    // };

    return (
        <div className={classNamesAppended} data-chromatic="ignore">
            <></>
        </div>
    );
}

export default LottieGraphic;
