import { FC } from 'react';

import moment from 'moment';

import { IEmployeeWithdrawal } from '@sparkplug/lib';

import { RightArrow } from '@components/icons';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import MobileList from '@components/layout/MobileList';

import { sortByDate } from '@helpers/ui';

import { CashOutMethodIcon } from '../../../../components/CashOutMethodIcon';
import { CashDiffLabel } from '../CashDiffLabel';
import { ReactComponent as EmptyCardGraphic } from './EmptyCard.svg';

import './CashOutList.scss';

interface CashOutListProps {
    withdrawals: IEmployeeWithdrawal[];
    onClick: (withdrawal: IEmployeeWithdrawal) => void;
    isLoading?: boolean;
}

export const CashOutList: FC<CashOutListProps> = ({
    withdrawals = [],
    onClick,
    isLoading = false,
}) => {
    if (!withdrawals.length) {
        return (
            <div className="wallet-cashout-list">
                <EmptyStateDisplay
                    graphic={<EmptyCardGraphic />}
                    label="You haven't cashed out any rewards yet."
                />
            </div>
        );
    }

    const sortedWithdrawals = withdrawals.sort(sortByDate('createdAt', 'desc'));

    return (
        <div className="wallet-cashout-list">
            <MobileList isLoading={isLoading}>
                {sortedWithdrawals.map((withdrawal) => (
                    <MobileList.Item
                        key={withdrawal._id}
                        startElement={
                            <CashOutMethodIcon withdrawalMethod={withdrawal.withdrawalType} />
                        }
                        label={withdrawal.withdrawalType}
                        description={moment(withdrawal.createdAt).format('MMM D')}
                        endElement={
                            <>
                                <CashDiffLabel type="withdrawal" amount={withdrawal.amount} />
                                <RightArrow className="claimed-arrow" />
                            </>
                        }
                        onClick={() => onClick(withdrawal)}
                    />
                ))}
            </MobileList>
        </div>
    );
};
