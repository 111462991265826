import { FC, useEffect, useMemo } from 'react';

import { CloverLogoIcon, ShopifyLogoIcon } from '@components/icons';
import Paper from '@components/layout/Paper';
import Spinner from '@components/layout/Spinner';
import { SparkplugIcon } from '@components/logos';
import { useParams, useQueryParams } from '@components/router';

import { useApp } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import './OauthView.scss';

export const OauthSuccessView: FC = () => {
    const { isNewUser, isNewStore }: { isNewUser?: string; isNewStore?: string } = useParams();
    const {
        type,
    }: {
        type?: 'clover' | 'shopify';
    } = useQueryParams();
    const { history, user, appIsReady } = useApp();
    const { account, changeAccountById } = useSparkplugAccount();

    const accountId = useMemo(
        () => user?.accounts?.[0]?._id || account?._id,
        [appIsReady, user, account],
    );

    useEffect(() => {
        // this is necessary to ensure the user's "account" info is properly loaded
        changeAccountById(accountId);
    }, []);

    let integrationType = '';
    let integrationEntity = '';
    let integrationLogo = <></>;
    switch (type) {
        case 'clover':
            integrationType = 'Clover';
            integrationEntity = 'merchant';
            integrationLogo = <CloverLogoIcon />;
            break;
        case 'shopify':
            integrationType = 'Shopify ';
            integrationEntity = 'store';
            integrationLogo = <ShopifyLogoIcon />;
            break;
        default:
            break;
    }

    if (!appIsReady) {
        return <Spinner />;
    }

    return (
        <Paper className="oauth-success">
            <Paper.Title className="center">
                <div className="icon-wrapper">
                    <div>{integrationLogo}</div>
                    <div>
                        <SparkplugIcon />
                    </div>
                </div>
                <div>
                    Congratulations - you have successfully linked SparkPlug to your{' '}
                    {integrationType} {integrationEntity}!
                </div>
            </Paper.Title>
            {isNewUser === 'isNewUser' && (
                <p>
                    Be sure to check your email for a link to set the password for your new
                    SparkPlug account. You can also always log in from {integrationType} as well.
                </p>
            )}
            {isNewStore === 'isNewStore' && (
                <>
                    <p>
                        Because this is your first time linking this {integrationEntity} to
                        SparkPlug, we will need to import your data. The process will start
                        automatically soon.
                    </p>
                    <p>
                        Please note: Dashboards and product listings may be unavailable during this
                        process.
                    </p>
                </>
            )}
            <Paper.DetailsButton
                variant="filled"
                onClick={() => history.push(`/${accountId}/dashboard`)}
            >
                Go to your dashboard
            </Paper.DetailsButton>
        </Paper>
    );
};

export default OauthSuccessView;
