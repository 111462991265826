import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { Model, Survey } from 'survey-react-ui';

import {
    useSaveSurveyResponseProgess,
    useSubmitSurveyResponse,
} from '@features/learn-and-earn/mutations/useSubmitSurveyResponse';
import { useGetSurveyUserResponse } from '@features/learn-and-earn/queries/useGetEmployeeSurveyResponse';
import { useGetSurvey } from '@features/learn-and-earn/queries/useGetSurvey';

import AppHeaderMobile from '@components/layout/AppHeaderMobile';
import PageLoading from '@components/layout/PageLoading';

import { useApp } from '@hooks/AppHooks';

import 'survey-core/defaultV2.min.css';

const LearnAndEarnSurvey: React.FC = () => {
    const { user, history } = useApp();
    const { learningResourceId, surveyId } = useParams<{
        learningResourceId: string;
        surveyId: string;
    }>();
    const { mutate: submitSurveyResponse } = useSubmitSurveyResponse();
    const { mutate: saveSurveyResponseProgress } = useSaveSurveyResponseProgess();
    const { survey, isLoadingSurvey } = useGetSurvey(surveyId);
    const { surveyUserResponse } = useGetSurveyUserResponse(surveyId, user?._id || '');
    const surveyModel = new Model(survey?.surveyData || {});

    const saveSurveyDataToAPI = async (model: Model) => {
        const data = { ...surveyModel.data, pageNo: model.currentPageNo };
        await saveSurveyResponseProgress({
            surveyId: surveyId || '',
            data,
            learningResourceId,
            status: 'in_progress',
        });
    };

    const handleCompleteSurvey = async (model: Model) => {
        const data = { ...surveyModel.data, pageNo: model.currentPageNo };
        await submitSurveyResponse({
            surveyId: surveyId || '',
            data,
            learningResourceId,
            status: 'completed',
        });
        history.push('/user/wallet');
    };

    useEffect(() => {
        if (survey?.surveyData && !isLoadingSurvey) {
            surveyModel.applyTheme(survey.themeData || {});
            surveyModel.onValueChanged.add((model) => saveSurveyDataToAPI(model));
            surveyModel.onCurrentPageChanged.add((model) => saveSurveyDataToAPI(model));
            surveyModel.onComplete.add(() => handleCompleteSurvey(surveyModel!));
        }
    }, [survey, isLoadingSurvey]);

    useEffect(() => {
        // set survey data to survey model if survey user response is available
        if (surveyUserResponse?.data && surveyModel) {
            surveyModel.data = surveyUserResponse.data;
        }
    }, [surveyUserResponse]);

    if (isLoadingSurvey) {
        return <PageLoading label="Loading survey..." />;
    }

    if (!survey && !isLoadingSurvey) {
        return <div>No survey found</div>;
    }

    return (
        <div>
            <AppHeaderMobile
                hideAppFooter
                back={{ to: '/user/sparks?tab=learn-and-earn', component: 'Learn & Earn' }}
            />
            <div className="absolute top-[60px] left-0 right-0 bottom-0 overflow-y-auto">
                <Survey model={surveyModel || {}} />
            </div>
        </div>
    );
};

export default LearnAndEarnSurvey;
