import { FC, useMemo } from 'react';

import { uniq } from 'lodash';

import { Spark, SparkCommission } from '@sparkplug/lib';

import toast from '@components/toast';

import { useModal } from '@hooks/ModalHooks';
import { useSpark } from '@hooks/SparksHooks';
import { useTableContext } from '@hooks/TableHooks';

import { IBrandRetailer } from '@app/types/AccountsTypes';

import SparkProductSelectorTable, {
    ProductTableFilters,
    useProductTableFilters,
} from '../../../SparkProductSelectorTable';

import './ManuallyManagedVendorSparkProductSelector.scss';

export const getModalNext =
    ({ brandIds }: { brandIds: string[] }) =>
    () => {
        const { spark, sparkCommissionMap, updateSpark } = useSpark();
        const { tableSelected, tableRows } = useTableContext();
        const { updateValidationFn } = useModal();

        const applySparkProducts = () => {
            const hasUniqueProductIds = tableSelected.length !== tableRows.length;

            const updatedSparkData: Pick<
                Spark,
                | 'posBrandIds'
                | 'posCategoryIds'
                | 'posProductIds'
                | 'productFilters'
                | 'commissions'
                | 'vendorFilters'
            > = {
                posBrandIds: !hasUniqueProductIds ? brandIds : [],
                posCategoryIds: [],
                posProductIds: hasUniqueProductIds ? tableSelected : [],
                productFilters: hasUniqueProductIds ? { brandIds } : {},
                commissions: [],
                vendorFilters: undefined,
            };

            if (spark.type === 'commission') {
                updatedSparkData.commissions = tableSelected.map((productId) => {
                    const commissionItem = sparkCommissionMap?.get(productId);

                    return {
                        posProductId: productId,
                        type: 'flat',
                        value: commissionItem ? commissionItem.value : undefined,
                    } as SparkCommission;
                });
            }

            updateSpark(updatedSparkData);
        };

        updateValidationFn(() => {
            const validation = [
                () => {
                    if (!tableSelected.length) {
                        toast.error('Select products to proceed.');

                        return false;
                    }

                    return true;
                },
            ];

            const allValid = validation.every((fn) => fn());

            if (allValid) {
                requestAnimationFrame(() => {
                    applySparkProducts();
                });
            }

            return allValid;
        });

        return <></>;
    };

interface ManuallyManagedVendorSparkProductSelectorProps {
    spark: Spark;
    brandRetailer: IBrandRetailer;
    initialFilterValues?: Partial<ProductTableFilters>;
}

const ManuallyManagedVendorSparkProductSelector: FC<
    ManuallyManagedVendorSparkProductSelectorProps
> = ({ spark, brandRetailer, initialFilterValues }) => {
    const individualBasedTableFilters = useProductTableFilters({ spark, initialFilterValues });
    const initialSelectedIds = useMemo<string[]>(() => {
        if (spark.posProductIds.length) {
            return spark.posProductIds;
        }

        if (spark.posBrandIds.length) {
            return brandRetailer.products
                .filter(({ brandId }) => spark.posBrandIds.includes(brandId ?? ''))
                .map(({ _id }) => _id);
        }

        return [];
    }, [spark, brandRetailer.products]);

    const brandIds = useMemo<string[]>(() => {
        return uniq(
            brandRetailer.products
                .filter(({ brandId }) => !!brandId)
                .map(({ brandId }) => brandId!),
        );
    }, [brandRetailer.products]);

    const ModalNext = getModalNext({ brandIds });

    return (
        <SparkProductSelectorTable
            products={brandRetailer.products}
            defaultSelectedProductIds={initialSelectedIds}
            ModalNext={ModalNext}
            tableFilters={individualBasedTableFilters}
        />
    );
};

export default ManuallyManagedVendorSparkProductSelector;
