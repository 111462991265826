import { ReactElement, useEffect, useMemo, useState } from 'react';

import { updatePosLocation } from '@core/accounts';

import Button from '@components/buttons/Button';
import Form from '@components/form/Form';
import { Lock as LockIcon } from '@components/icons';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';
import toast from '@components/toast';

import { useSparkplugAccountUsers } from '@hooks/SparkplugAccountsHooks';

import { appendClasses } from '@helpers/ui';

import { IPosLocation } from '@app/types/PosTypes';

import './EditRetailerLocationModal.scss';

const statusOptions = [
    {
        value: 'disabled',
        label: 'Disabled',
    },
    {
        value: 'active',
        label: 'Active',
    },
];

interface IEditRetailerLocationModal extends IDefaultModalProps {
    location: IPosLocation;
}

interface IAdvancedFormField {
    className?: string;
    label: string;
    description: ReactElement;
    fieldComponent: ReactElement;
}
const LocationFormField = ({
    className,
    label,
    description,
    fieldComponent,
}: IAdvancedFormField) => {
    const classNamesAppended = appendClasses([className, 'form-group-advanced']);

    return (
        <div className={classNamesAppended}>
            <Form.Label className="location-form-field_label" shrink={false}>
                {label}
            </Form.Label>
            <Form.Description className="location-form-field_description">
                {description}
            </Form.Description>
            <div className="location-form-field_field">{fieldComponent}</div>
        </div>
    );
};

const EditRetailerLocationModal = ({
    isVisible = false,
    location,
    onClose,
}: IEditRetailerLocationModal) => {
    const [status, setStatus] = useState<'active' | 'disabled'>('active');
    const [displayName, setDisplayName] = useState<string>('');
    const [managerUserIds, setManagerUserIds] = useState<string[]>([]);

    const { refetchAccountUsers, accountUsers = [] } = useSparkplugAccountUsers(true);
    const managerUserOptions = useMemo(() => {
        return accountUsers
            .filter(
                ({ userId, locationIds, role }) =>
                    !!userId &&
                    (locationIds?.includes(location?._id) || role === 'group-admin') &&
                    role !== 'none',
            )
            .map(({ userId, fullName }) => ({
                value: userId!,
                label: fullName,
            }));
    }, [location?._id, accountUsers]);

    useEffect(() => {
        setStatus(location?.disabled ? 'disabled' : 'active');
        setDisplayName(location?.displayName || location?.name);
        setManagerUserIds(location?.managerUserIds ?? []);
    }, [location]);

    const onSave = () => {
        toast.promise(
            updatePosLocation(location?._id, {
                disabled: status === 'disabled',
                displayName,
                managerUserIds,
            }),
            {
                loading: null,
                success: () => {
                    onClose(true);
                    refetchAccountUsers();
                    return 'Location saved.';
                },
                error: 'Something went wrong.',
            },
        );
    };

    return (
        <Modal
            className="edit-retailer-location-modal"
            isVisible={isVisible}
            onClose={() => onClose(false)}
        >
            <Form>
                <Modal.Title borderBottom dense>
                    Edit Location
                </Modal.Title>
                <Modal.Content>
                    <Form.Grid>
                        <Form.GridItem className="manager-dropdown-wrapper" sm={6}>
                            <LocationFormField
                                label="Location Manager"
                                description={
                                    <p className="manager-description">
                                        Managers are eligible for Location Leaderboard prizes.
                                    </p>
                                }
                                fieldComponent={
                                    <Form.SearchSelect
                                        name="managerUserIds"
                                        options={managerUserOptions}
                                        mode="multiSelect"
                                        value={managerUserIds}
                                        onChange={(selectedOptions) =>
                                            setManagerUserIds(
                                                selectedOptions.map(({ value }) => value),
                                            )
                                        }
                                    />
                                }
                            />
                        </Form.GridItem>

                        <hr />

                        <Form.GridItem sm={6}>
                            <LocationFormField
                                label="Default Name"
                                description={
                                    <p>
                                        This is the name of the location as defined in your
                                        point-of-sale. This cannot be changed.
                                    </p>
                                }
                                fieldComponent={
                                    <Form.TextField
                                        name="defaultName"
                                        defaultValue={location?.name}
                                        endIcon={<LockIcon />}
                                        disabled
                                    />
                                }
                            />
                        </Form.GridItem>
                        <Form.GridItem sm={6}>
                            <LocationFormField
                                label="Display Name"
                                description={
                                    <p>
                                        This is the name used to identify this location in
                                        SparkPlug. You can edit this to be more recognizable.
                                    </p>
                                }
                                fieldComponent={
                                    <Form.TextField
                                        name="displayName"
                                        defaultValue={displayName}
                                        onChange={(e) => {
                                            setDisplayName(e.target.value);
                                        }}
                                    />
                                }
                            />
                        </Form.GridItem>
                    </Form.Grid>

                    <hr />

                    <Form.Grid>
                        <Form.GridItem sm={12}>
                            <LocationFormField
                                className="retailer-location-status-field"
                                label="Status"
                                description={
                                    <p>
                                        By default, all locations from your point-of-sale are set to
                                        active. You can disable a location from appearing anywhere
                                        in SparkPlug. This should be used for locations like a
                                        distribution center or training store where there are no
                                        active sales employees.
                                    </p>
                                }
                                fieldComponent={<></>}
                            />
                        </Form.GridItem>
                    </Form.Grid>
                </Modal.Content>
                <Modal.Actions>
                    <Form.ButtonGroup
                        className="retailer-location-status-button-group"
                        value={status}
                        options={statusOptions}
                        onChange={(v: any) => {
                            setStatus(v);
                        }}
                    />
                    <Button color="neutral" variant="flat" onClick={() => onClose(false)}>
                        Cancel
                    </Button>
                    <Form.Button color="blue" variant="filled" onClick={onSave}>
                        Save
                    </Form.Button>
                </Modal.Actions>
            </Form>
        </Modal>
    );
};

export default EditRetailerLocationModal;
