import { FC, useState } from 'react';
import { createPortal } from 'react-dom';

import AccountsAPI from '@api/AccountsAPI';

import { SparksViewFilters } from '@sparkplug/lib';

import { SparkDashboardTableRowData } from '@features/spark-dashboard/types';

import IconButton from '@components/buttons/IconButton';
import Tooltip from '@components/layout/Tooltip';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { THeadCell } from '@app/types/TableTypes';

import ExportSparksModal from '../ExportSparksModal';
import { ReactComponent as DownloadIcon } from './DownloadIcon.svg';

import './ExportSparkDashboardButton.scss';

interface ExportSparkDashboardButtonProps {
    stickyLeftCells: THeadCell<SparkDashboardTableRowData>[];
    baseCells: THeadCell<SparkDashboardTableRowData>[];
    data: SparkDashboardTableRowData[];
    sparkFilters?: SparksViewFilters;
}
export const ExportSparkDashboardButton: FC<ExportSparkDashboardButtonProps> = ({
    sparkFilters,
}) => {
    const { account } = useSparkplugAccount();
    const [showAllSparksReportModal, setShowAllSparksReportModal] = useState(false);

    return (
        <>
            <Tooltip title="Export to CSV">
                <span className="export-to-csv">
                    <IconButton
                        onClick={() => setShowAllSparksReportModal(true)}
                        variant="flat"
                        color="blue"
                    >
                        <DownloadIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <ExportSparksModal
                isVisible={showAllSparksReportModal}
                onConfirm={() => {
                    AccountsAPI.requestSparksReport(account?._id || '', sparkFilters)
                        .then(() => {
                            toast.success('Export started');
                        })
                        .catch((error) => {
                            toast.error(`Export initialization failed`);
                        });
                }}
                onClose={() => setShowAllSparksReportModal(false)}
                initialStatus={sparkFilters?.status || 'active'}
            />
        </>
    );
};

export default (props: ExportSparkDashboardButtonProps) => {
    const sparksViewToolbarPlaceholder = document.querySelector('.export-to-csv-placeholder');

    if (!sparksViewToolbarPlaceholder) {
        return <></>;
    }

    return createPortal(<ExportSparkDashboardButton {...props} />, sparksViewToolbarPlaceholder);
};
