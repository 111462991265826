import React, { ReactElement, useMemo } from 'react';

import clsx from 'clsx';

import Table from '@components/table/Table';
import { ITableExternalProvider, ITableInternalProvider } from '@components/table/Table/Table';

import TableCell from '../TableCell/TableCell';
import { TableRow } from '../TableRenderRow/TableRenderRow';
import ExpandableRow from './ExpandableRow';

export type ExpandableTableProps<T> = Omit<
    ITableInternalProvider<T> | ITableExternalProvider<T>,
    'children'
> & {
    collapse: (row: T & { key: string; selectionDisabled?: boolean }) => ReactElement;
    children?: React.ReactNode;
    emptyStateText?: string | ReactElement;
};

const ExpandableTable = <T extends {}>({
    headCells = [],
    rows = [],
    isLoading,
    className,
    useExternalProvider,
    enableQueryParams,
    showPagination,
    disableFrontendFiltering,
    rowCountOverride,
    defaultOptions,
    filters,
    collapse,
    emptyStateText,
    useInfiniteScrollStyling,
    ...otherProps
}: ExpandableTableProps<T>) => {
    const expandableHeadCells = useMemo(
        () => [{ id: 'expandButton', label: '', sortable: false }, ...headCells],
        [headCells],
    );

    const filteredRows = useMemo(() => {
        if (!filters || filters.length === 0) return rows;
        return filters.reduce((acc, filter) => filter(acc), rows);
    }, [rows, filters]);

    return (
        <Table
            className={clsx('expandable-table rounded', className)}
            rows={filteredRows}
            isLoading={isLoading}
            headCells={expandableHeadCells}
            useExternalProvider={useExternalProvider}
            enableQueryParams={enableQueryParams}
            showPagination={showPagination}
            useInfiniteScrollStyling={useInfiniteScrollStyling}
            disableFrontendFiltering={disableFrontendFiltering}
            filters={filters}
            defaultOptions={
                defaultOptions ?? {
                    orderBy: 'name',
                    order: 'asc',
                }
            }
            rowCountOverride={rowCountOverride}
            {...otherProps}
        >
            <Table.RenderHead />
            <Table.Body>
                {filteredRows.length > 0 ? (
                    <>
                        {filteredRows.map((row) => (
                            <ExpandableRow
                                key={row.key}
                                tableHeadData={headCells}
                                row={row}
                                collapse={collapse}
                            />
                        ))}
                    </>
                ) : (
                    <TableRow>
                        <TableCell
                            className="empty-state"
                            align="center"
                            colSpan={expandableHeadCells.length}
                        >
                            {emptyStateText}
                        </TableCell>
                    </TableRow>
                )}
            </Table.Body>
        </Table>
    );
};

export default ExpandableTable;
