import { FC, useCallback, useMemo, useState } from 'react';

import { clsx } from 'clsx';

import { HydratedBrandProduct } from '@sparkplug/lib';

import { TableProvider } from '@contexts/TableContext';

import CalloutMessage from '@components/layout/CalloutMessage';
import Skeleton from '@components/layout/Skeleton';
import Table from '@components/table/Table';

import { useModal } from '@hooks/ModalHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import MinThresholdField from '../MinThresholdField';
import { useProductTableFilters } from '../SparkProductSelector/SparkProductSelectorTable';
import CommissionRulesRadioGroup, {
    useSparkCommissionStructureState,
} from './components/CommissionRulesRadioGroup/CommissionRulesRadioGroup';
import CommissionTableToolbar from './components/CommissionTableToolbar';
import getHeadCells from './helpers/getHeadCells';
import {
    ProductCommissionRow,
    useCommissionTableProducts,
} from './hooks/useCommissionTableProducts';
import { useCommissionTableState } from './hooks/useCommissionTableState';
import { useCommissionTableValidation } from './hooks/useCommissionTableValidation';
import { useMultiRetailerCommissionTableFilters } from './hooks/useMultiRetailerCommissionTableFilters';

import './CommissionCommissionsFormFields.scss';

export const UpdateSparkCommissionsWizardStage = 3 as const;

interface CommissionCommissionsFormFieldsProps {
    isVendorEditExperience?: boolean;
}

const CommissionCommissionsFormFields: FC<CommissionCommissionsFormFieldsProps> = ({
    isVendorEditExperience = false,
}) => {
    const { account } = useSparkplugAccount();
    const {
        sparkCommissionType,
        spark,
        sparkIsReady,
        isCreatingMultiRetailerSpark,
        isVendorTagsRulesBasedSpark,
    } = useSpark();

    const { productRowsAreReady, unsortedProductRows, associatedProductIds } =
        useCommissionTableProducts();
    const {
        commissionValuesRef,
        isFulfilledBySparkPlug,
        updateIsFulfilledBySparkPlug,
        commissionValuesMap,
        originalCommissionValuesMap,
        updateCommissionValue,
        updateDisplayedCommissionValues,
        updateSparkContextCommissions,
        productRows,
    } = useCommissionTableState({
        isVendorEditExperience,
        unsortedProductRows,
    });

    const {
        singleCommissionsEnabled,
        showFormData,
        commissionStructure,
        updateFixedCommissionValue,
        updateSparkCommissionStructure,
    } = useSparkCommissionStructureState({
        productRows,
        updateDisplayedCommissionValues,
    });

    const { modalContentRef } = useModal();

    const tableFilters = useProductTableFilters({ spark });
    const multiTableFilters = useMultiRetailerCommissionTableFilters({});
    const { applySearch, applyBrandFilters, applyCategoryFilters } = tableFilters;
    const {
        applySearch: applyTagRulesSearch,
        applyRetailerFilters,
        applyTagFilters,
    } = multiTableFilters;

    const [showInvalidValues, setShowInvalidValues] = useState(false);

    // `useMemo` to prevent entire table rerender
    const headCells = useMemo(
        () =>
            getHeadCells({
                accountType: account?.type!,
                showInvalidValues,
                commissionType: sparkCommissionType!,
                isFulfilledBySparkPlug,
                updateIsFulfilledBySparkPlug,
                updateCommissionValue,
                isCreatingMultiRetailerSpark,
                commissionValuesMap,
                originalCommissionValuesMap,
                isVendorTagsRulesBasedSpark: !!isVendorTagsRulesBasedSpark,
                isVendorEditExperience,
                isFixedCommission: spark.commissionRules?.type === 'fixed',
            }),
        [showInvalidValues, commissionValuesMap, spark.commissionRules?.type],
    );

    useCommissionTableValidation({
        singleCommissionsEnabled,
        spark,
        associatedProductIds,
        originalCommissionValuesMap,
        commissionValuesRef,
        sparkCommissionType,
        updateShowInvalidValues: () => setShowInvalidValues(true),
        updateSparkContextCommissions,
        productRows,
    });

    const rowRenderKeyFn = useCallback(
        (row: ProductCommissionRow) =>
            [row._id, commissionStructure, commissionValuesMap[row._id]].join('-'),
        [commissionStructure, commissionValuesMap],
    );

    const isVendorTagsRulesBasedOrCreatingMultiRetailerSpark =
        isVendorTagsRulesBasedSpark || isCreatingMultiRetailerSpark;

    const filters = isVendorTagsRulesBasedOrCreatingMultiRetailerSpark
        ? [
              applyTagRulesSearch,
              applyTagFilters,
              applyBrandFilters,
              applyRetailerFilters,
              applyCategoryFilters,
          ]
        : [applySearch, applyBrandFilters, applyCategoryFilters];

    if (!productRowsAreReady) {
        return (
            <Skeleton height={isVendorTagsRulesBasedOrCreatingMultiRetailerSpark ? 400 : '100%'} />
        );
    }

    return (
        <>
            <div
                className={clsx(
                    'commission-values-form-fields',
                    account?.type === 'retailer' && 'show-prices-column',
                    isCreatingMultiRetailerSpark && 'show-multi-retailer-columns',
                    !isCreatingMultiRetailerSpark &&
                        isVendorTagsRulesBasedSpark &&
                        'show-vendor-edit-columns',
                )}
            >
                {singleCommissionsEnabled && (
                    <CommissionRulesRadioGroup
                        commissionStructure={commissionStructure}
                        updateSparkCommissionStructure={updateSparkCommissionStructure}
                    />
                )}

                {showFormData && (
                    <div>
                        <TableProvider
                            showPagination={false}
                            headCells={headCells}
                            rows={productRows}
                            filters={filters}
                            isLoading={!sparkIsReady || !productRowsAreReady}
                        >
                            <CommissionTableToolbar
                                isVendorEditExperience={isVendorEditExperience}
                                tableFilters={tableFilters}
                                multiTableFilters={multiTableFilters}
                                products={productRows as HydratedBrandProduct[]}
                                updateFixedCommissionValue={updateFixedCommissionValue}
                                originalCommissionValuesMap={originalCommissionValuesMap}
                                updateDisplayedCommissionValues={updateDisplayedCommissionValues}
                            />

                            {!isFulfilledBySparkPlug && (
                                <CalloutMessage
                                    className="non-fbs-callout"
                                    color="yellow"
                                    variant="filled"
                                    message={
                                        <span>
                                            <strong>Just a heads up!</strong> The commissions on
                                            this Spark will be <strong>your</strong> responsibility
                                            to fulfill!
                                        </span>
                                    }
                                />
                            )}

                            <Table
                                className="commission-values-table"
                                useExternalProvider
                                useInfiniteScrollStyling
                                variant="smooth"
                            >
                                <Table.RenderHead />
                                <Table.RenderBody
                                    rowRenderKeyFn={rowRenderKeyFn}
                                    dynamicHeight={{
                                        infiniteScroll: true,
                                        containerRef: modalContentRef,
                                        bottomOffset: showFormData ? 85 : 0,
                                        otherDependencies: [
                                            isFulfilledBySparkPlug,
                                            commissionStructure,
                                        ],
                                    }}
                                />
                            </Table>
                        </TableProvider>
                        <MinThresholdField disabled={isVendorEditExperience} />
                    </div>
                )}
            </div>
        </>
    );
};

export default CommissionCommissionsFormFields;
