import { useMemo, useState } from 'react';

import { PaymentMethod, Spark } from '@sparkplug/lib';

import { createAndChargeInvoice as createAndChargeInvoiceRequest } from '@core/accounts';

import PageLoading from '@components/layout/PageLoading';
import ConfirmModal from '@components/overlays/ConfirmModal';
import Modal, { IDefaultModalProps } from '@components/overlays/Modal';
import { getLineItemsFromPayoutGroups } from '@components/sparks/SparkPaymentSummary/SparkPaymentSummary';
import toast from '@components/toast';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSpark } from '@hooks/SparksHooks';

import {
    getOtherGroupName,
    getSparkPaymentFromPayouts,
    isSparkExternallyCreated,
} from '@helpers/sparks';

import { IAccount, TAccountType } from '@app/types/AccountsTypes';
import { ISparkPayout, ISparkPayoutGroup } from '@app/types/SparksTypes';

import SparkCheckoutModalContent, { MultiLeaderboardSteps } from './SparkCheckoutModalContent';
import { useInvoiceLabelState } from './useInvoiceLabelState';

import './SparkCheckoutModal.scss';

const getCurrentMultiLeaderboardStep = (
    sparkPayoutGroups: ISparkPayoutGroup[],
): MultiLeaderboardSteps => {
    const confirmedMultiLeaderboardCount = sparkPayoutGroups.filter(
        ({ payoutsAreFinalized }) => payoutsAreFinalized,
    ).length;
    const canInvoiceMultiLeaderboard = sparkPayoutGroups.every(
        ({ payoutsAreFinalized }) => payoutsAreFinalized,
    );

    if (canInvoiceMultiLeaderboard) {
        return 'invoice';
    }

    if (confirmedMultiLeaderboardCount < sparkPayoutGroups.length - 1) {
        return 'approveOne';
    } else {
        return 'approveFinal'; // Used with `shouldInvoice` to update internal tracking if nothing is to be charged
    }
};

interface IPayerInfo {
    payerAccountType: TAccountType;
    payerAccountName: string;
}
const getPayerInfo = (spark: Spark, account?: IAccount): IPayerInfo => {
    const currentAccountType = account?.type;
    const currentGroupName = account?.name ?? '';

    // Always charge the brand for externally created sparks because only
    // brands can initiate sparks on behalf of other groups
    const sparkWasExternallyCreated = isSparkExternallyCreated(spark);
    const isBrandCreatedSpark = sparkWasExternallyCreated || currentAccountType === 'brand';

    const payerAccountName =
        isBrandCreatedSpark && currentAccountType === 'retailer'
            ? getOtherGroupName(spark, account)
            : currentGroupName;
    return {
        payerAccountType: isBrandCreatedSpark ? 'brand' : 'retailer',
        payerAccountName,
    };
};

interface ILoadingModalProps {
    isVisible: boolean;
    onClose: (didWork?: boolean) => any;
}
const LoadingModal = ({ isVisible, onClose }: ILoadingModalProps) => (
    <Modal isVisible={isVisible} className="spark-checkout-confirm-loading-modal" onClose={onClose}>
        <PageLoading label="Finalizing Amounts..." />
    </Modal>
);

interface ISparkCheckoutModalProps extends IDefaultModalProps {
    sparkPayouts: ISparkPayout[];
    sparkPayoutGroups: ISparkPayoutGroup[];
    sparkPayoutsAreReady: boolean;
    confirmSparkPayouts: () => void;
    paymentMethods: PaymentMethod[];
    paymentMethodsAreReady: boolean;
    defaultPaymentMethodId?: string;
    finalizeCallback?: () => void;
}
const SparkCheckoutModal = ({
    isVisible,
    sparkPayoutsAreReady,
    onClose,
    sparkPayouts,
    sparkPayoutGroups,
    confirmSparkPayouts,
    paymentMethods = [],
    paymentMethodsAreReady,
    defaultPaymentMethodId = '',
    finalizeCallback,
}: ISparkCheckoutModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { account } = useSparkplugAccount();
    const { spark, detailedSparkType, refetchSparkData } = useSpark();
    const paymentDataIsNotReady = !sparkPayoutsAreReady || !paymentMethodsAreReady;

    const [invoiceName, setInvoiceName] = useInvoiceLabelState({ spark, account });
    const [selectedPaymentId, setSelectedPaymentId] = useState<string>(defaultPaymentMethodId);

    const { payerAccountType, payerAccountName } = useMemo(
        () => getPayerInfo(spark, account),
        [spark, account],
    );

    const { paymentValue: balanceDue } = useMemo(
        () => getSparkPaymentFromPayouts(sparkPayouts),
        [sparkPayouts],
    );

    const lineItems = getLineItemsFromPayoutGroups(sparkPayoutGroups, spark?._id);

    if (!account?.type || !account?.name || !spark?.name || paymentDataIsNotReady) {
        return (
            <LoadingModal
                isVisible={isVisible && paymentDataIsNotReady}
                onClose={() => onClose(false)}
            />
        );
    }

    const isMultiLeaderboard = detailedSparkType === 'leaderboardMulti';
    const currentMultiLeaderboardStep = isMultiLeaderboard
        ? getCurrentMultiLeaderboardStep(sparkPayoutGroups)
        : undefined;

    const shouldInvoice = balanceDue > 0;
    const shouldInvoiceSingleLeaderboard = shouldInvoice && !isMultiLeaderboard;
    const shouldInvoiceMultiLeaderboard =
        shouldInvoice && isMultiLeaderboard && currentMultiLeaderboardStep === 'invoice';

    const payerGroupId = spark?.originatorGroupId || spark?.groupId;

    const invoiceHasNotBeenSent =
        !spark.internalTracking?.invoiceStatus ||
        spark.internalTracking?.invoiceStatus === 'not-sent';
    const payoutsPaid = spark.internalTracking?.payoutStatus === 'paid';

    // Finalize Spark
    const confirmRewardPayouts = async (): Promise<boolean> => {
        try {
            // Only confirm payouts if they haven't been confirmed
            if (!payoutsPaid) {
                await confirmSparkPayouts();
            }

            return true;
        } catch (e) {
            return false;
        }
    };

    const createAndChargeInvoice = async (): Promise<void> => {
        return new Promise(() => {
            const selectedPaymentMethod = paymentMethods.find(({ id }) => id === selectedPaymentId);
            return toast.promise(
                createAndChargeInvoiceRequest(payerGroupId, {
                    paymentId: selectedPaymentId,
                    paymentType: selectedPaymentMethod?.type,
                    sparkId: spark?._id,
                    amount: balanceDue,
                    memo: invoiceName,
                }),
                {
                    loading: 'Creating invoice and attempting to charge payment method...',
                    success: () => {
                        if (finalizeCallback) {
                            // functionlity exclusive to the CC ux
                            finalizeCallback();
                        }
                        refetchSparkData();
                        return 'Success. The invoice has been sent and the payment method was charged, if applicable.';
                    },
                    error: 'Error: Something went while creating/charging for the invoice.',
                },
                {
                    success: {
                        duration: 5000,
                    },
                },
            );
        });
    };

    const handleSparkCheckout = async (): Promise<void> => {
        if (!payerGroupId) {
            toast.error('Something went wrong. Try refreshing.');
            return;
        }

        setIsLoading(true);

        const rewardsAreConfirmed = !shouldInvoiceMultiLeaderboard
            ? await confirmRewardPayouts()
            : true;

        if (
            rewardsAreConfirmed &&
            (shouldInvoiceSingleLeaderboard || shouldInvoiceMultiLeaderboard)
        ) {
            await createAndChargeInvoice();
        }
        await refetchSparkData();
        if (finalizeCallback) {
            await finalizeCallback();
        }
        setIsLoading(false);
    };

    const shouldDisableConfirmButton =
        isLoading ||
        (shouldInvoiceMultiLeaderboard && !invoiceName) ||
        (shouldInvoice && !invoiceName);

    const singleLeaderboardTitle = shouldInvoice
        ? 'Finalize Payment Amounts'
        : 'Finalize Spark Payouts/Rewards';
    const multiLeaderboardTitle = shouldInvoiceMultiLeaderboard
        ? 'Send Invoice'
        : 'Finalize Spark Payouts/Rewards';

    const singleLeaderboardButtonText = shouldInvoice ? 'Charge & Finalize' : 'Confirm Rewards';
    const multiLeaderboardButtonText = shouldInvoiceMultiLeaderboard
        ? 'Submit Invoice'
        : 'Confirm Rewards';

    let confirmText = isMultiLeaderboard ? multiLeaderboardButtonText : singleLeaderboardButtonText;

    if (!isMultiLeaderboard && payoutsPaid && invoiceHasNotBeenSent) {
        confirmText = 'Send Invoice';
    }

    return (
        <ConfirmModal
            isVisible={isVisible && sparkPayoutsAreReady && paymentMethodsAreReady}
            className="spark-checkout-confirm-modal"
            onConfirm={handleSparkCheckout}
            onClose={() => onClose(false)}
            title={isMultiLeaderboard ? multiLeaderboardTitle : singleLeaderboardTitle}
            disabled={shouldDisableConfirmButton}
            confirmText={confirmText}
            message={
                <SparkCheckoutModalContent
                    shouldInvoice={shouldInvoiceSingleLeaderboard || shouldInvoiceMultiLeaderboard}
                    currentMultiLeaderboardStep={currentMultiLeaderboardStep}
                    balanceDue={balanceDue}
                    lineItems={lineItems}
                    payerAccountName={payerAccountName}
                    payerAccountType={payerAccountType}
                    paymentMethods={paymentMethods}
                    selectedPaymentMethodId={selectedPaymentId}
                    onPaymentMethodChange={(paymentId) => setSelectedPaymentId(paymentId)}
                    invoiceName={invoiceName}
                    onInvoiceNameChange={setInvoiceName}
                />
            }
        />
    );
};

export default SparkCheckoutModal;
