import { FC, useEffect, useMemo, useState } from 'react';

import moment from 'moment';

import { GetSparkRewardsResponse, Spark } from '@sparkplug/lib';

import CalloutMessage from '@components/layout/CalloutMessage';
import Tooltip from '@components/layout/Tooltip';
import AdvancedTable from '@components/table/AdvancedTable';
import Table from '@components/table/Table';
import Toolbar from '@components/toolbar/Toolbar';

import { useSparkRewardsQuery } from '@hooks/SparksHooks';

import Typography from '@app/componentLibrary/Typography';
import { cn } from '@app/componentLibrary/utils';
import { THeadCell } from '@app/types/TableTypes';

import { NFBIcon } from './SparkAwardsPanel';

type SparkRewardRow = GetSparkRewardsResponse[number];

const FulfillmentStatusOptions = {
    Unclaimed: 'Unclaimed',
    Received: 'Received',
    Pending: 'Pending',
};

type FulfillmentStatus = (typeof FulfillmentStatusOptions)[keyof typeof FulfillmentStatusOptions];

const FulfillmentTableToolbar = ({
    setFilteredSparkRewards,
    sparkRewards,
}: {
    setFilteredSparkRewards: (rewards: GetSparkRewardsResponse) => void;
    sparkRewards: GetSparkRewardsResponse;
}) => {
    const [selectedStatuses, setSelectedStatuses] = useState<FulfillmentStatus[]>([]);
    const [search, setSearch] = useState<string>('');

    const applyFilters = (filters: { search: string; statuses: FulfillmentStatus[] }) => {
        const { search: searchQuery } = filters;
        let { statuses } = filters;
        if (statuses.length === 0) {
            statuses = Object.values(FulfillmentStatusOptions);
        }
        setFilteredSparkRewards(
            sparkRewards.filter((reward) => {
                const nameMatch = reward.ui?.fullName
                    ?.toLowerCase()
                    .includes(searchQuery.toLowerCase());
                const statusMatch = statuses.includes(
                    // eslint-disable-next-line no-nested-ternary
                    reward.nonFbsReceiptConfirmedAt
                        ? FulfillmentStatusOptions.Received
                        : reward.status === 'claimed'
                        ? FulfillmentStatusOptions.Pending
                        : FulfillmentStatusOptions.Unclaimed,
                );

                return nameMatch && statusMatch;
            }),
        );
    };

    return (
        <div className="flex w-full items-center">
            <Toolbar justifyContentStart>
                <Toolbar.Search
                    name="fulfillment-search"
                    onChange={(event) => {
                        const { value = '' } = event.target;
                        setSearch(value);
                        applyFilters({ search: value, statuses: selectedStatuses });
                    }}
                />
                <Toolbar.MultiSelectDropdown
                    label="Status"
                    options={Object.values(FulfillmentStatusOptions).map((status) => ({
                        label: status,
                        value: status,
                    }))}
                    selected={selectedStatuses}
                    onApply={(value) => {
                        setSelectedStatuses(value);
                        applyFilters({ search, statuses: value });
                    }}
                />
            </Toolbar>
        </div>
    );
};

const Tag = ({ label }: { label: FulfillmentStatus }) => {
    const tagConfig = {
        [FulfillmentStatusOptions.Unclaimed]: {
            classes: 'bg-buttercup-50 text-buttercup-900',
            tooltip: 'This employee has not yet claimed the reward or seen claim instructions.',
        },
        [FulfillmentStatusOptions.Received]: {
            classes: 'bg-aqua-50 text-aqua-900',
            tooltip: 'This employee has confirmed they received the reward.',
        },
        [FulfillmentStatusOptions.Pending]: {
            classes: 'bg-sienna-50 text-sienna-900',
            tooltip: "This employee has not yet confirmed they've received the reward.",
        },
    };

    const { classes = 'bg-gray-50 text-gray-900', tooltip = '' } = tagConfig[label] || {};
    return (
        <Tooltip title={tooltip}>
            <div
                className={cn(
                    'px-2 py-[2px] rounded-md text-[10px] font-semibold uppercase text-center',
                    `${classes}`,
                )}
            >
                {label}
            </div>
        </Tooltip>
    );
};

const getHeadCells: () => THeadCell<SparkRewardRow>[] = () => {
    const headCells: THeadCell<SparkRewardRow>[] = [
        {
            id: 'ui.fullName',
            sortType: 'string',
            label: 'Employee',
            render: (row) => {
                return (
                    <Table.Cell>
                        <div>{row.ui?.fullName ?? '--'}</div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'ui.locationName',
            sortType: 'string',
            label: 'Location',
            render: (row) => {
                return <Table.Cell>{row.ui?.locationName || '--'}</Table.Cell>;
            },
        },
        {
            id: 'name',
            label: 'Non-cash Reward',
            render: (row) => {
                return (
                    <Table.Cell>
                        <div>
                            {row.name} &nbsp;
                            <NFBIcon tooltip={row.claimInstructions} />
                        </div>
                    </Table.Cell>
                );
            },
        },
        {
            id: 'status',
            label: 'Status',
            render: (row) => {
                let status = FulfillmentStatusOptions.Unclaimed;
                if (row.status === 'claimed') {
                    status = FulfillmentStatusOptions.Pending;
                }
                if (row.nonFbsReceiptConfirmedAt) {
                    status = FulfillmentStatusOptions.Received;
                }
                return (
                    <Table.Cell>
                        <Tag label={status} />
                    </Table.Cell>
                );
            },
        },
        {
            id: 'nonFbsReceiptConfirmedAt',
            label: 'Date Received',
            render: (row) => (
                <Table.Cell>
                    <div>
                        {row.nonFbsReceiptConfirmedAt
                            ? moment(row.nonFbsReceiptConfirmedAt).format('MM/DD/YYYY')
                            : '--'}
                    </div>
                </Table.Cell>
            ),
        },
    ];

    return headCells;
};

const FulfillmentTable: FC<{ spark: Spark }> = ({ spark }) => {
    const { sparkRewards, isLoadingSparkRewards } = useSparkRewardsQuery(spark._id);

    const [filteredSparkRewards, setFilteredSparkRewards] =
        useState<SparkRewardRow[]>(sparkRewards);

    useEffect(() => {
        setFilteredSparkRewards(sparkRewards);
    }, [sparkRewards]);

    const headCells = useMemo(() => getHeadCells(), []);

    return (
        <div className="flex flex-col gap-2">
            <CalloutMessage
                message={
                    <Typography className="text-gray-950 !mb-0">
                        This Spark was completed on {moment(spark.endDate).format('MMM D, YYYY')}.
                        Below is a list of employees who earned non-cash rewards (fulfilled by the
                        Spark creator) and their current prize receipt status.
                    </Typography>
                }
            />

            <FulfillmentTableToolbar
                setFilteredSparkRewards={setFilteredSparkRewards}
                sparkRewards={sparkRewards}
            />

            <AdvancedTable
                className="spark-dashboard-table"
                variant="smooth"
                rows={filteredSparkRewards
                    .filter((reward) => !reward.amount)
                    .map((reward) => ({
                        ...reward,
                        key: reward._id || `${reward.sparkId}${reward.userId}`,
                    }))}
                isLoading={isLoadingSparkRewards}
                headCells={headCells}
                useExternalProvider={false}
                stickyLeft={[]}
                stickyRight={[]}
                showPagination={false}
            >
                <Table.RenderHead />
                <Table.RenderBody />
            </AdvancedTable>
        </div>
    );
};

export default FulfillmentTable;
