import { Link, Redirect, Route, Switch } from 'react-router-dom';

import AppView from '@components/layout/AppView';
import Paper from '@components/layout/Paper';
import Tabs from '@components/layout/Tabs';

import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useRouteTabs } from '@hooks/UIHooks';

import AdminSMSNotificationsView from './AdminSMSNotificationsView';
import SettingsTitle from './components/SettingsTitle';

const AdminSparkSettingsView = () => {
    const { account } = useSparkplugAccount();
    const { tabValue, currentPath } = useRouteTabs();

    const TabLinks =
        account !== undefined
            ? [
                  {
                      url: `/${account._id}/settings/sparks/sms`,
                      text: 'SMS Notifications',
                  },
              ]
            : [];

    return (
        <AppView.Content
            title={<SettingsTitle label="SMS Updates" />}
            className="admin-spark-settings-view"
        >
            <Paper>
                <Tabs color="neutral" value={tabValue}>
                    {TabLinks.map(({ url, text }) => (
                        <Tabs.Tab key={url} label={text} value={url} component={Link} to={url} />
                    ))}
                </Tabs>
                <Switch>
                    <Route path={`${currentPath}/sms`} component={AdminSMSNotificationsView} />
                    <Redirect from={`${currentPath}`} to={`${currentPath}/sms`} />
                </Switch>
            </Paper>
        </AppView.Content>
    );
};

export default AdminSparkSettingsView;
