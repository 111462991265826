import { FC, useEffect, useState } from 'react';

import { CommissionRuleType, Spark } from '@sparkplug/lib';

import RadioGroup from '@components/inputs/RadioGroup';

import { useSpark } from '@hooks/SparksHooks';

import { ProductCommissionRow } from '../../hooks/useCommissionTableProducts';

import './CommissionRulesRadioGroup.scss';

const CommissionStructureOptions = [
    {
        value: 'fixed',
        label: 'Fixed Commission',
        description: 'All qualifying products will be rewarded the same commission value.',
    },
    {
        value: 'manual',
        label: 'Variable Commission',
        description: 'Assign individual commission amounts to each qualifying product.',
    },
];

const getDefaultCommissionStructure = (spark: Spark): CommissionRuleType | undefined => {
    if (spark.commissionRules?.type) {
        return spark.commissionRules?.type;
    }

    const isEditingSpark = !!spark._id;

    if (isEditingSpark) {
        return 'manual';
    }

    return undefined;
};

export const useSparkCommissionStructureState = ({
    productRows,
    updateDisplayedCommissionValues,
}: {
    productRows: ProductCommissionRow[];
    updateDisplayedCommissionValues: (productIds: string[], commissionValue: number) => void;
}) => {
    const { spark, updateSpark } = useSpark();

    const [commissionStructure, setCommissionStructure] = useState(
        getDefaultCommissionStructure(spark),
    );
    const [fixedCommissionValue, setFixedCommissionValue] = useState<number>(
        spark.commissionRules?.fixedValue ?? 0,
    );

    const { REACT_APP_SINGLE_COMMISSIONS = false } = process.env;
    const singleCommissionsEnabled = REACT_APP_SINGLE_COMMISSIONS === 'true';
    const showFormData = !singleCommissionsEnabled || !!commissionStructure;

    useEffect(() => {
        if (commissionStructure === 'fixed') {
            updateDisplayedCommissionValues(
                productRows.map(({ _id }) => _id),
                fixedCommissionValue,
            );
        }
    }, [commissionStructure, fixedCommissionValue]);

    const updateSparkCommissionStructure = (updatedCommissionStructure: CommissionRuleType) => {
        if (updatedCommissionStructure === 'fixed') {
            setCommissionStructure(updatedCommissionStructure);
            updateSpark({
                commissionRules: {
                    type: 'fixed',
                    fixedValue: fixedCommissionValue,
                },
            });
        }

        if (updatedCommissionStructure === 'manual') {
            setCommissionStructure(updatedCommissionStructure);
            updateSpark({
                commissionRules: {
                    type: 'manual',
                },
            });
        }
    };

    const updateFixedCommissionValue = (updatedValue: number) => {
        setFixedCommissionValue(updatedValue);
        updateSpark({
            commissionRules: {
                type: 'fixed',
                fixedValue: updatedValue,
            },
        });
    };

    return {
        showFormData,
        singleCommissionsEnabled,
        commissionStructure,
        updateSparkCommissionStructure,
        updateFixedCommissionValue,
    };
};

type UseSparkCommissionStructureState = ReturnType<typeof useSparkCommissionStructureState>;

interface CommissionRulesRadioGroupProps
    extends Pick<
        UseSparkCommissionStructureState,
        'commissionStructure' | 'updateSparkCommissionStructure'
    > {}

const CommissionRulesRadioGroup: FC<CommissionRulesRadioGroupProps> = ({
    commissionStructure,
    updateSparkCommissionStructure,
}) => {
    return (
        <RadioGroup
            label="Commission Structure"
            required
            name="reward-method"
            className="reward-method_container"
            variant="grid"
            color="blue"
            value={commissionStructure ?? ''}
            onChange={(e) => updateSparkCommissionStructure(e.target.value)}
            options={CommissionStructureOptions}
        />
    );
};

export default CommissionRulesRadioGroup;
