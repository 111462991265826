import { SparkLeaderboardTableDataRow } from '@views/sparks/SparkManageView/useSparkLeaderboardTableData';

import Grid from '@components/layout/Grid';
import PageLoading from '@components/layout/PageLoading';
import SparkAwardsPanel from '@components/sparks/SparkAwardsPanel';
import SparkLeaderboardsPaper from '@components/sparks/SparkLeaderboardsPaper';

import { useSpark } from '@hooks/SparksHooks';

import { ISparkContext } from '@app/types/SparksTypes';
import { THeadCell } from '@app/types/TableTypes';

import SparkSMSParticipantsTable from './SparkSMSParticipantsTable';

import './SparkSendSMSView.scss';

interface ISparkSendSMSViewProps {
    isLoadingTableData: boolean;
    tableHeadCells: THeadCell<SparkLeaderboardTableDataRow>[];
    tableRows: SparkLeaderboardTableDataRow[];
}

const SparkSendSMSView = ({
    isLoadingTableData,
    tableHeadCells: headCells,
    tableRows: rows,
}: ISparkSendSMSViewProps) => {
    const { sparkIsReady, spark, detailedSparkType }: ISparkContext = useSpark();

    const SparkSMSTable = (
        <SparkSMSParticipantsTable
            isLoading={isLoadingTableData}
            sparkType={spark?.type}
            headCells={headCells}
            rows={rows}
        />
    );

    return (
        <>
            {sparkIsReady ? (
                <div className="spark-send-sms-view">
                    {spark?.type !== 'commission' && <SparkAwardsPanel spark={spark} />}

                    {detailedSparkType === 'leaderboardMulti' ? (
                        <Grid>
                            <Grid.Item sm={3}>
                                <SparkLeaderboardsPaper />
                            </Grid.Item>
                            <Grid.Item sm={9}>{SparkSMSTable}</Grid.Item>
                        </Grid>
                    ) : (
                        SparkSMSTable
                    )}
                </div>
            ) : (
                <PageLoading label="Loading Spark..." />
            )}
        </>
    );
};

export default SparkSendSMSView;
