import { FC, useEffect, useMemo, useState } from 'react';

import CreateEditMemberDrawer from '@views/admin/AdminEmployeesView/VendorMembersTable/CreateEditMemberDrawer';
import { Markets } from '@views/admin/AdminEmployeesView/VendorMembersTable/constants';

import { DO_NOT_HAVE_PERMISSIONS_MESSAGE } from '@sparkplug/lib';

import {
    useSendResetPasswordEmailRequest,
    useSetActiveMutation,
    useUnenrollAndSetInactiveMutation,
} from '@core/users';

import { TableProvider } from '@contexts/TableContext';

import Chip from '@components/chips/Chip';
import ManagerChip from '@components/chips/ManagerChip';
import Dropdown from '@components/dropdown/Dropdown';
import { NoUsersGraphic } from '@components/graphics';
import { Add, Edit, MoreHoriz, RemoveCircleOutline, ResetIcon, Send } from '@components/icons';
import LabelWithAdditionalChip from '@components/labels/LabelWithAdditionalChip';
import EmptyStateDisplay from '@components/layout/EmptyStateDisplay';
import Table from '@components/table/Table';
import toast from '@components/toast';
import Toolbar from '@components/toolbar/Toolbar';

import { useApp } from '@hooks/AppHooks';
import { useAccountUsersFilters, useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';
import { useSearch } from '@hooks/UIHooks';

import { getCurrentUserId } from '@helpers/auth';
import { getStateAbbreviationByName, isEmpty } from '@helpers/util';

import { IAccount } from '@app/types/AccountsTypes';
import { THeadCell } from '@app/types/TableTypes';
import { IAccountUser } from '@app/types/UsersTypes';

import './VendorMembersTable.scss';

interface IVendorMembersTableProps {
    variant?: 'raised' | 'flat';
    account?: IAccount;
    isLoadingAccountUsers: boolean;
    accountUsers: MemberRow[];
    refetchAccountUsers: () => void;
}

const AccountRoleFilterOptions = [
    {
        label: 'All Members',
        value: 'all',
    },
    {
        label: 'Active Members',
        value: 'active',
    },
    {
        label: 'Inactive Members',
        value: 'none',
    },
];

type AccountUserRow = IAccountUser & { key: string };
export type MemberRow = AccountUserRow;

const YouChip = () => <Chip dense className="name-chip" label="You" color="blue" />;
const OwnerChip = () => <Chip dense className="name-chip" label="Owner" color="neutral" />;

const MemberRowActions = ({
    row,
    onShowEditUser,
    onShowResetPassword,
    onSetInactive,
    onSetActive,
}: {
    row: MemberRow;
    onShowEditUser: (row: MemberRow) => void;
    onShowResetPassword: (row: MemberRow) => void;
    onSetInactive: (row: MemberRow) => void;
    onSetActive: (row: MemberRow) => void;
}) => {
    const { user } = useApp();
    const { account } = useSparkplugAccount();

    const isCCView = !account?.type;

    return (
        <Table.Cell>
            <Dropdown className="user-dropdown">
                <Dropdown.IconButton data-testid={`user-dropdown.${row.key}`}>
                    <MoreHoriz />
                </Dropdown.IconButton>
                <Dropdown.Menu data-testid={`user-dropdown-menu.${row.key}`}>
                    <Dropdown.MenuItem
                        className="user-dropdown_edit-menu-item"
                        StartIcon={Edit}
                        onClick={() => onShowEditUser(row)}
                    >
                        Edit user
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                        hidden={row.email == null}
                        StartIcon={Send}
                        onClick={() => onShowResetPassword(row)}
                    >
                        Re-send reset password email
                    </Dropdown.MenuItem>

                    {!(isCCView || row.role === 'none' || row.userId === getCurrentUserId()) && (
                        <Dropdown.MenuDivider />
                    )}

                    <Dropdown.MenuItem
                        hidden={
                            isCCView || row.role === 'none' || row.userId === getCurrentUserId()
                        }
                        className="user-dropdown_set-inactive-menu-item"
                        StartIcon={RemoveCircleOutline}
                        color="red"
                        onClick={() => onSetInactive(row)}
                    >
                        Set inactive
                    </Dropdown.MenuItem>
                    {!(isCCView || row.role !== 'none') && <Dropdown.MenuDivider />}

                    <Dropdown.MenuItem
                        hidden={isCCView || row.role !== 'none'}
                        className="user-dropdown_set-inactive-menu-item"
                        StartIcon={ResetIcon}
                        color="blue"
                        onClick={() => onSetActive(row)}
                    >
                        Reactivate member
                    </Dropdown.MenuItem>
                </Dropdown.Menu>
            </Dropdown>
        </Table.Cell>
    );
};

const memberHeadCells: THeadCell<MemberRow>[] = [
    {
        id: 'fullName',
        sortType: 'string',
        label: 'Name',
        render: (row) => {
            const { user } = useApp();
            const { account } = useSparkplugAccount();
            const rowIsManager = !!row.managedLocationIds?.length;
            const rowIsAuthUser = user?._id === row.userId ?? false;
            const rowIsOwner = row?.userId === account?.assignedOwnerUserId;
            const fullName = `${row.firstName} ${row.lastName} ${
                row?.groupRole === 'none' ? ' (Inactive)' : ''
            }`.trim();

            return (
                <Table.Cell align="left">
                    {fullName}
                    {rowIsManager && <ManagerChip />}
                    {rowIsOwner && <OwnerChip />}
                    {rowIsAuthUser && <YouChip />}
                </Table.Cell>
            );
        },
    },
    {
        id: 'email',
        sortType: 'string',
        label: 'Email',
        render: (row) => (
            <Table.Cell align="left">
                <LabelWithAdditionalChip
                    label={row.email ?? '--'}
                    count={row.externalGroups?.length}
                    tooltip={row.externalGroups?.map(({ groupName }) => groupName).join(', ') ?? ''}
                    placement="top"
                />
            </Table.Cell>
        ),
    },
    {
        id: 'phoneNumber',
        sortType: 'string',
        label: 'Phone Number',
        render: (row) => <Table.Cell align="left">{row.phoneNumberFormatted || '--'}</Table.Cell>,
    },
    {
        id: 'markets',
        sortType: 'string',
        label: 'Assigned Markets',
        render: (row) => {
            const { markets = [] } = row.permissions ?? {};
            const marketsLabel = markets.length ? markets.join(', ') : 'All Markets';
            return <Table.Cell align="left">{marketsLabel}</Table.Cell>;
        },
    },
];
const VendorMemberTable: FC<IVendorMembersTableProps> = ({
    isLoadingAccountUsers,
    variant = 'raised',
    account,
    accountUsers,
    refetchAccountUsers,
}) => {
    const { userCan } = useSparkplugAccount();
    const userCanManageUsers = userCan('manageUsers');
    const { userIsSuperAdmin } = useApp();

    const [showCreateEditDrawer, setShowCreateEditDrawer] = useState(false);
    const [currentEditUserId, setCurrentEditUserId] = useState<string>();
    const { unenrollAndSetInactive: setInactive } = useUnenrollAndSetInactiveMutation(
        account?._id as string,
    );
    const { setActive } = useSetActiveMutation(account?._id as string);

    const { sendResetPasswordEmailRequest } = useSendResetPasswordEmailRequest();

    const { searchFilter, onChangeSearchFilter, applySearch } = useSearch([
        'firstName',
        'lastName',
        'email',
    ]);

    const { userFilters, updateUserFilters, applyUserFilters } = useAccountUsersFilters({
        role: 'all',
        market: 'all',
    });

    const marketOptions = useMemo(() => {
        const options =
            account?.markets.map((market) => ({
                label: market,
                value: getStateAbbreviationByName(market),
            })) || [];
        return [...options];
    }, [account?.markets]);

    const accountUserRows = useMemo<MemberRow[]>(
        () =>
            accountUsers.map((accountUser) => ({
                ...accountUser,
                key: accountUser.flexibleEmployeeId,
            })),
        [account, accountUsers],
    );

    // Only show active users by default
    useEffect(() => {
        const hasInactiveUsers = accountUsers?.some(({ groupRole }) => groupRole === 'none');
        if (!isLoadingAccountUsers && hasInactiveUsers) {
            updateUserFilters({ role: 'active' });
        }
    }, [isLoadingAccountUsers]);

    const handleSetInactive = (row: MemberRow) => {
        // allow owners of churned accounts to be set to inactive.
        if (
            row?.userId === account?.assignedOwnerUserId &&
            account?.status === 'churned' &&
            userIsSuperAdmin
        ) {
            setInactive({ userId: row.userId, userData: row }, { onSuccess: refetchAccountUsers });
        } else if (row?.userId === account?.assignedOwnerUserId) {
            toast.error('You cannot set the account owner to inactive.');
        } else {
            setInactive({ userId: row.userId, userData: row }, { onSuccess: refetchAccountUsers });
        }
    };

    const editCell = {
        id: 'actions',
        isHidden: !userCanManageUsers,
        render: (_row) => (
            <MemberRowActions
                row={_row}
                onShowEditUser={(row) => {
                    setCurrentEditUserId(row.userId);
                    setShowCreateEditDrawer(true);
                }}
                onShowResetPassword={(row) => {
                    sendResetPasswordEmailRequest(row.userId ?? '');
                }}
                onSetInactive={(row) => {
                    handleSetInactive(row);
                }}
                onSetActive={(row) => {
                    setActive(
                        {
                            userId: row?.userId ?? '',
                            role: 'group-admin',
                        },
                        {
                            onSuccess: () => refetchAccountUsers(),
                        },
                    );
                }}
            />
        ),
    } as THeadCell<MemberRow>;
    const headCells = [...memberHeadCells, ...(userCanManageUsers ? [editCell] : [])];

    const handleAdd = () => {
        setCurrentEditUserId(undefined);
        setShowCreateEditDrawer(true);
    };
    const handleMarketChange = (value: string[]) => {
        let marketValue = value;
        if (userFilters.market === 'all' && value.length > 1 && value.includes('all')) {
            marketValue = value.filter((v) => v !== 'all');
        } else if (value.includes('all')) {
            marketValue = ['all'];
        }
        updateUserFilters({ market: marketValue.join(', ') });
    };

    const rowRenderClassNameFn = (row: MemberRow) => {
        return row.role === 'none' ? 'inactive-member' : '';
    };

    return (
        <div className="vendor-members-table">
            {isEmpty(accountUserRows) && !isLoadingAccountUsers ? (
                <div className="empty-user-table">
                    <EmptyStateDisplay
                        graphic={<NoUsersGraphic />}
                        label="No members found"
                        actionButton={{
                            label: 'Invite Member',
                            onClick: handleAdd,
                            startIcon: <Add />,
                        }}
                    />
                </div>
            ) : (
                <TableProvider
                    headCells={headCells}
                    rows={accountUserRows}
                    filters={[applyUserFilters, applySearch]}
                    defaultOptions={{
                        orderBy: 'firstName',
                    }}
                >
                    <Toolbar scrollOnMobile>
                        <Toolbar.Search
                            name="search"
                            defaultValue={searchFilter}
                            onChange={onChangeSearchFilter}
                        />
                        <Toolbar.MultiSelectDropdown
                            className="market-select"
                            label={userFilters.market === 'all' ? 'All Markets' : 'Markets'}
                            selected={userFilters.market ? userFilters.market.split(', ') : []}
                            onApply={(value) => handleMarketChange(value)}
                            primaryOptions={Markets}
                            options={marketOptions}
                            clear={{
                                active: userFilters.market !== 'all',
                                onClear: () => updateUserFilters({ market: 'all' }),
                            }}
                        />
                        <Toolbar.Dropdown
                            label={null}
                            value={userFilters.role}
                            onSelect={(value) => updateUserFilters({ role: value })}
                            options={AccountRoleFilterOptions}
                            clear={{
                                active: userFilters.role !== 'all',
                                onClear: () => updateUserFilters({ role: 'all' }),
                            }}
                        />

                        <Toolbar.Button
                            className="toolbar-group-end"
                            startIcon={<Add />}
                            color="blue"
                            variant="filled"
                            onClick={handleAdd}
                            disabled={!userCanManageUsers}
                            tooltip={
                                !userCanManageUsers ? DO_NOT_HAVE_PERMISSIONS_MESSAGE : undefined
                            }
                        >
                            Invite Member
                        </Toolbar.Button>
                    </Toolbar>
                    <Table useExternalProvider variant={variant}>
                        <Table.RenderHead />
                        <Table.RenderBody
                            rowClassNameFn={rowRenderClassNameFn}
                            rowRenderKeyFn={(row: MemberRow) =>
                                // TODO: if we expose an `updatedAt` timestamp on the user, we can use that here instead of this monstrosity
                                `${row.userId}-${row.role}-${row.firstName}-${row.lastName}-${row.phoneNumber}-${row.email}-${row.permissions?.markets}`
                            }
                        />
                    </Table>
                </TableProvider>
            )}
            <CreateEditMemberDrawer
                account={account!}
                userId={currentEditUserId}
                isVisible={showCreateEditDrawer}
                onClose={() => {
                    setShowCreateEditDrawer(false);
                }}
                markets={marketOptions}
                handleSetInactive={handleSetInactive}
            />
        </div>
    );
};

export default VendorMemberTable;
