import { useEffect, useRef } from 'react';

import { AccountProvider } from '@contexts/AccountContext';

import PageLoading from '@components/layout/PageLoading';
import { SparkplugLogo } from '@components/logos';
import QRCode from '@components/qrcode';
import { useParams } from '@components/router';

import { useAppBodyClasses } from '@hooks/AppHooks';
import { useSparkplugAccount } from '@hooks/SparkplugAccountsHooks';

import { IAccount } from '@app/types/AccountsTypes';

import graphicBottom from './assets/GraphicBottom.png';
import graphicLeft from './assets/GraphicLeft.png';
import graphicRight from './assets/GraphicRight.png';
import { ReactComponent as QRArrow } from './assets/QRArrow.svg';

import './AdminEnrollmentPoster.scss';

const FAQ = ({ question, answer }: { question: string; answer: any }) => {
    return (
        <div className="onboarding-poster_faq">
            <div className="onboarding-poster_faq-question">{question}</div>
            <div className="onboarding-poster_faq-answer">{answer}</div>
        </div>
    );
};

interface IAdminEnrollmentPosterProps {
    account?: IAccount;
    showPrint?: boolean;
}

export const AdminEnrollmentPoster = ({
    account,
    showPrint = false,
}: IAdminEnrollmentPosterProps) => {
    const didOpenPrint = useRef(false);

    useEffect(() => {
        if (!didOpenPrint.current && showPrint) {
            didOpenPrint.current = true;
            setTimeout(() => {
                window.print();
            }, 1000);
        }
    }, []);

    const url = account?.onboardingUrl || '';
    const shortenedUrl = url.replace('https://', '');

    const perks = [
        '🎁️ Digital Gift Cards',
        '💰️ Live Commission Earnings',
        '🥇️ Brand Incentive Standings',
    ];

    const faqs = [
        {
            question: 'QR Code not working?',
            answer: (
                <>
                    {`Visit: `}
                    <u className="onboarding-poster-font-blue">{shortenedUrl}</u>
                </>
            ),
        },
        {
            question: 'Have questions or need help?',
            answer: (
                <>
                    Text CHAT to <span className="onboarding-poster-font-blue">36809</span>
                </>
            ),
        },
    ];

    const graphics = {
        graphicLeft,
        graphicRight,
        graphicBottom,
    };

    return (
        <div className="admin-enrollment-poster">
            <div className="onboarding-poster">
                <div className="onboarding-poster_inner">
                    <div className="onboarding-poster_logo">
                        <SparkplugLogo />
                    </div>
                    <div className="onboarding-poster_tagline">
                        Get <span className="onboarding-poster-font-green">paid extra</span> for
                        your efforts
                    </div>
                    <div className="onboarding-poster_perks">
                        {perks.map((perk, i) => (
                            <div key={i} className="onboarding-poster_perk-item">
                                {perk}
                            </div>
                        ))}
                    </div>
                    <div className="onboarding-poster_qr-code">
                        <QRCode url={url} />
                    </div>
                    <div className="onboarding-poster_instructions">
                        <div className="onboarding-poster_instructions-title">
                            <span>
                                Enroll Today!
                                <QRArrow />
                            </span>
                        </div>
                        <div className="onboarding-poster_instructions-message">
                            <strong>Scan the QR Code</strong> and enter your info into the
                            enrollment form to start receiving live incentive and commission
                            updates.
                        </div>
                    </div>

                    {faqs.map((faqProps, i) => (
                        <FAQ key={i} {...faqProps} />
                    ))}
                </div>

                {Object.keys(graphics).map((graphic, i) => {
                    const graphicSrc = graphics[graphic as keyof typeof graphics];
                    return (
                        <div key={i} className={`onboarding-poster_graphic ${graphic}`}>
                            <img src={graphicSrc} alt={graphic} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const AdminEnrollmentPosterWrapped = () => {
    const { accountIsReady, account } = useSparkplugAccount();

    if (accountIsReady && account != null) {
        return <AdminEnrollmentPoster account={account} showPrint />;
    }

    return <PageLoading />;
};

export default () => {
    const {
        accountId,
    }: {
        accountId: string;
    } = useParams();

    useAppBodyClasses(['show-app-content-only']);

    return (
        <AccountProvider initialAccountId={accountId}>
            <AdminEnrollmentPosterWrapped />
        </AccountProvider>
    );
};
