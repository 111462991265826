import { FC } from 'react';

import { WithdrawalType } from '@sparkplug/lib';

import { CashOutMethod } from '@features/wallet/types';

import { ReactComponent as AchIcon } from './AchIcon.svg';
import { ReactComponent as AmazonIcon } from './AmazonIcon.svg';
import { ReactComponent as VenmoIcon } from './VenmoIcon.svg';
import { ReactComponent as VisaIcon } from './VisaIcon.svg';

interface CashOutMethodIconProps {
    className?: string;
    withdrawalMethod?: WithdrawalType;
}

export const CashOutMethodIcon: FC<CashOutMethodIconProps> = ({
    className = '',
    withdrawalMethod,
}) => {
    switch (withdrawalMethod) {
        case CashOutMethod.ACH:
            return <AchIcon className={className} />;
        case CashOutMethod.VENMO:
            return <VenmoIcon className={className} />;
        case CashOutMethod.VISA:
            return <VisaIcon className={className} />;
        case CashOutMethod.AMAZON:
            return <AmazonIcon className={className} />;
        default:
            return <></>;
    }
};
