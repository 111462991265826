import { FC } from 'react';

import { AccountSparkBrand } from '@sparkplug/lib';

import LinkLabel from '@features/account-links/components/LinkLabel';

import Tabs from '@components/layout/Tabs';

import './TagGroupsSidebar.scss';

interface TagGroupsSidebarProps {
    selectedBrandId?: string;
    updateSelectedBrand: (updatedBrandId: string) => void;
    brands: AccountSparkBrand[];
    brandsAreReady: boolean;
}

export const TagGroupsSidebar: FC<TagGroupsSidebarProps> = ({
    brands,
    selectedBrandId,
    updateSelectedBrand,
}) => {
    return (
        <>
            <Tabs value={selectedBrandId || brands[0]?._id} color="blue" orientation="vertical">
                {brands.map(({ _id, photo, name, isEnabled }) => (
                    <Tabs.Tab
                        onClick={() => updateSelectedBrand(_id)}
                        key={_id}
                        label={<LinkLabel imageUrl={photo} title={name} />}
                        disabled={!isEnabled}
                        value={_id}
                    />
                ))}
            </Tabs>
        </>
    );
};
